import { Column, ColumnChooser, Scrolling, Toolbar, Item } from 'devextreme-react/data-grid';
import { useMemo } from 'react';
import StandardDataGrid from 'components/StandardDataGrid';

const ReadOnlyAccountMappingGrid = (props) => {
    const { initialData, accountMappingData } = props;

    const viewData = useMemo(() => {
        const viewData = [];

        const parentAccNoList = [...new Set(initialData.chartOfAccountData.map(item => item.parentAccNo))];
        initialData.chartOfAccountData.forEach(x => {
            const viewItem = {
                key: x.accNo,
                parentKey: x.parentAccNo || x.accType,
                ...x
            };
            viewData.push(viewItem);

            const accountMapping = accountMappingData.find(x => x.accNo === viewItem.accNo);
            if (accountMapping) {
                viewItem.liquidity = accountMapping.liquidity;
                viewItem.pde = accountMapping.pde;
            }

            if (parentAccNoList.includes(x.accNo)) {
                viewItem.isParentAccount = true;
            }
        });

        initialData.accountTypeData.forEach(x => {
            viewData.push({
                key: x.accountType,
                description: x.accountTypeDescription,
                isHeader: true
            });
        });

        return viewData.filter(x => !x.isHeader && !x.isParentAccount && x.pde);
    }, []);

    return (
        <div className="row">
            <StandardDataGrid
                dataSource={viewData}
                allowColumnReordering={false}
                columnAutoWidth={false}
                height="calc(100vh - 283px)"
            >
                <ColumnChooser enabled={false} />
                <Scrolling mode="virtual" />
                <Toolbar>
                    <Item name="columnChooserButton" />
                    <Item name="searchPanel" />
                </Toolbar>
                <Column
                    caption="Acc. No."
                    dataField="accNo"
                    dataType="string"
                    width="120px"
                />
                <Column
                    caption="Description"
                    dataField="description"
                    dataType="string"
                    cellRender={e => {
                        return (
                            <>
                                {e.data.description}
                                {e.data.liquidity &&
                                    <div style={{ lineHeight: '14px' }} className="badge bg-dark text-light fw-normal ms-2 align-self-center">
                                        {e.data.liquidity}
                                    </div>
                                }
                            </>
                        );
                    }}
                />
                <Column
                    caption="Acc. Type"
                    dataField="accTypeDescription"
                    dataType="string"
                    width="300px"
                />
                <Column
                    caption="PDE"
                    dataField="pde"
                    dataType="string"
                    calculateDisplayValue={e => {
                        const pde = initialData.pdeList.find(x => x.dataId === e.pde);
                        return pde && pde.description;
                    }}
                    groupIndex={0}
                />
            </StandardDataGrid>
        </div>
    );
};

export default ReadOnlyAccountMappingGrid;
const DraftFormContentHeaderRow = (props) => {
    const { description, level } = props;

    return (
        <tr className="header-row border">
            <th style={{ paddingLeft: `${10 + 20 * (level - 1)}px` }}>{description}</th>
            <th />
            <th />
        </tr>
    );
};

export default DraftFormContentHeaderRow;
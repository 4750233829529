import { Button } from 'devextreme-react/button';
import { ToolbarItem } from 'devextreme-react/popup';
import { DeleteButton } from 'components/StandardButton';
import StandardPopup from 'components/StandardPopup';
import * as annualReturnStatusConstant from 'pages/AnnualReturn/data/annualReturnStatusConstant';

const DeleteAccountBookDialog = (props) => {
    const { initialData, onSubmit, onCancel } = props;

    return (
        <StandardPopup
            visible
            onHiding={onCancel}
            dragEnabled={false}
            width={600}
            height="auto"
            showTitle={false}
            wrapperAttr={{ class: 'remove-dxpopup-content-bottom-padding' }}
        >
            {initialData.annualReturnStatus && initialData.annualReturnStatus !== annualReturnStatusConstant.FormNew &&
                <div className="alert alert-warning" role="alert">
                    <div className="fs-5 fw-bold mb-2">Warning</div>
                    The filing of Annual Return is in progress.
                </div>
            }
            <div className="fs-7 mb-3 text-center">
                Are you sure on deleting the filing of
                <span className="mx-1 fw-bold">Annual Return {initialData.yearOfAssessment - 1}</span>
                for company <b>"{initialData.companyName}"</b> ?
            </div>
            <div className="fs-7 d-flex justify-content-center mb-0"> ( This action cannot be reverted. )</div>
            <ToolbarItem
                toolbar="bottom"
                location="center"
            >
                <DeleteButton
                    text="Delete"
                    onClick={() => onSubmit(initialData.accountBookDataId)}
                />
            </ToolbarItem>
            <ToolbarItem
                toolbar="bottom"
                location="center"
                render={() => (
                    <Button
                        text="Cancel"
                        onClick={onCancel}
                    />
                )}
            />
        </StandardPopup>
    );
};
export default DeleteAccountBookDialog;
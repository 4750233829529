import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import TreeView from 'devextreme-react/tree-view';
import { forwardRef, useEffect, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import { generalPageNavigation, formPageNavigation } from 'app-navigation';
import { useAuth } from 'contexts/AuthContext';
import * as AccountBookDataStatusConstant from 'data/accountBookDataStatusConstant';
import { useScreenSize } from 'utils/media-query';
import './SideNavigationMenu.scss';

const SideNavigationMenu = (props) => {
    const {
        selectedItemChanged,
        onMenuReady
    } = props;

    const { accountBookData } = useAccountBookData();
    const { isLarge } = useScreenSize();
    const { canAccessPage } = useAuth();
    const { pathname: currentPath } = useLocation();

    const items = useMemo(() => {
        let paths = [];

        if (generalPageNavigation.some(x => x.path === currentPath)) {
            for (const item of generalPageNavigation) {
                if (item.path && !(/^\//.test(item.path)))
                    item.path = `/${item.path}`;

                if (canAccessPage(item.path))
                    paths.push({ ...item, expanded: isLarge });
            }
        } else {
            for (const item of formPageNavigation) {
                if (item.path && !(/^\//.test(item.path)))
                    item.path = `/${item.path}`;

                if (item.path === formPageNavigation[0].path)
                    item.isStepComplete = accountBookData.stepList?.includes(AccountBookDataStatusConstant.CompletedCompanyInfo);
                else if (item.path === formPageNavigation[1].path)
                    item.isStepComplete = accountBookData.stepList?.includes(AccountBookDataStatusConstant.CompletedAccountMapping);
                else if (item.path === formPageNavigation[2].path)
                    item.isStepComplete = accountBookData.stepList?.includes(AccountBookDataStatusConstant.CompletedAnnualReturn);
                else if (item.path === formPageNavigation[3].path)
                    item.isStepComplete = accountBookData.stepList?.includes(AccountBookDataStatusConstant.CompletedFormCS);

                paths.push({ ...item, expanded: isLarge });
            }
        }

        return paths;
    }, [accountBookData, currentPath]);

    const treeViewRef = useRef();

    useEffect(() => {
        const treeView = treeViewRef.current && treeViewRef.current.instance;
        if (!treeView) {
            return;
        }
        if (currentPath !== undefined) {
            const hasItemSelected = treeView.selectItem(currentPath);
            if (!hasItemSelected) {
                treeView.unselectAll();
            } else {
                treeView.expandItem(currentPath);
            }
        }
    }, [currentPath, items]);


    return (
        <div
            className="dx-theme-background-color side-navigation-menu"
        >
            <Toolbar className="navigation-header">
                <Item
                    location="before"
                    render={() => (
                        <img src="/images/logo.png?v=2" style={{ width: '170px' }} alt="" className="m-1 ms-3" />
                    )}
                />
            </Toolbar>
            <div className="menu-container">
                <TreeView
                    ref={treeViewRef}
                    items={items}
                    keyExpr="path"
                    selectionMode="single"
                    focusStateEnabled={false}
                    expandEvent="click"
                    onItemClick={selectedItemChanged}
                    onContentReady={onMenuReady}
                    width="100%"
                    itemRender={e => {
                        if (e.separator) {
                            return <div className="separator"></div>;
                        }

                        const isGeneralPath = generalPageNavigation.some(x => x.path === currentPath);
                        if (isGeneralPath) {
                            return (
                                <div className="menu-item">
                                    <span className="circle-label" style={e.path === currentPath ? { backgroundColor: '#40c057' } : null}>
                                        <span className="circle-text">
                                            <i className={`dx-icon dx-icon-${e.icon}`} style={e.path === currentPath ? { color: 'white' } : null}></i>
                                        </span>
                                    </span>
                                    <span className="text-label">
                                        <span className="text">{e.text}</span>
                                    </span>
                                </div>
                            );
                        } else {
                            return (
                                <div className="menu-item">
                                    <span className="circle-label" style={e.path === currentPath ? { backgroundColor: '#40c057' } : null}>
                                        <span className="circle-text" style={e.path === currentPath ? { color: 'white' } : null}>{e.displayStep}</span>
                                    </span>
                                    <span className="text-label" style={{ width: '138px' }}>
                                        <span className="text text-wrap">{e.text}</span>
                                    </span>
                                    {e.isStepComplete &&
                                        <span className="complete-label">
                                            <CheckBadgeIcon className="dx-icon" />
                                        </span>
                                    }
                                </div>
                            );
                        }
                    }}
                />
            </div>
        </div>
    );
};

// eslint-disable-next-line react/no-multi-comp
const SideNavigationMenuRef = forwardRef((props, ref) => <SideNavigationMenu {...props} forwardedRef={ref} />);
SideNavigationMenuRef.displayName = 'SideNavigationMenu';

export default SideNavigationMenuRef;
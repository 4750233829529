import NumberBox from 'devextreme-react/number-box';

const FormNumberBox = (props) => {
    const { value, onValueChanged, children, ...rest } = props;

    return (
        <NumberBox
            value={value}
            onValueChanged={e => {
                if (onValueChanged) {
                    e.value = Math.floor(e.value);
                    onValueChanged(e);
                }
            }}
            format="#,##0.00"
            min={0}
            {...rest}
        >
            {children}
        </NumberBox>
    );
};

export default FormNumberBox;
import * as Api from 'utils/api';
import { buildApiUrl } from 'utils/session';

const basePath = 'renovationExpense';

export function getRenovationExpenseList(accountBookDataId) {
    let url = buildApiUrl(`${basePath}/getListing`, { accountBookDataId });
    return Api.getData(url);
}

export function getRenovationExpenseEntry(accountBookDataId, id) {
    let url = buildApiUrl(`${basePath}`, { accountBookDataId, id });
    return Api.getData(url);
}

export function createRenovationExpenseEntry(accountBookDataId, data) {
    let url = buildApiUrl(`${basePath}`, { accountBookDataId });
    return Api.postData(url, data);
}

export function updateRenovationExpense(accountBookDataId, id, data) {
    let url = buildApiUrl(`${basePath}`, { accountBookDataId, id });
    return Api.putData(url, data);
}

export function deleteRenovationExpense(accountBookDataId, id) {
    let url = buildApiUrl(`${basePath}`, { accountBookDataId, id });
    return Api.deleteData(url);
}
import { Column, Toolbar } from 'devextreme-react/data-grid';
import StandardDataGrid from 'components/StandardDataGrid';

const TreasuryShareDetailsDataGrid = (props) => {
    const { shares } = props.data;

    return (
        <StandardDataGrid
            dataSource={shares}
        >
            <Column
                dataField="classOfShare"
                caption="Class Of Share"
                dataType="string"
            />
            <Column
                dataField="sharesHeld"
                caption="No. Shares Held"
                dataType="number"
            />
            <Column
                dataField="amountOfIssuedShareCapital"
                caption="Amount of Issued Share Capital"
                dataType="number"
            />
            <Column
                dataField="amountOfPaidUpShareCapital"
                caption="Amount of Paid Up Share Capital"
                dataType="number"
            />
            <Toolbar visible={false} />
        </StandardDataGrid>
    );
};

export default TreasuryShareDetailsDataGrid;
import RadioGroup from 'devextreme-react/radio-group';
import SelectBox from 'devextreme-react/select-box';
import Validator from 'devextreme-react/validator';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as AccountBookDataStatusConstant from 'data/accountBookDataStatusConstant';
import * as AuditorOpinionType from '../data/financialStatementIndependentAuditOpinionType';

const FinancialStatementEntryDirectorStatementAuditorReportView = (props) => {
    const { data, onChange } = props;

    const { accountBookData } = useAccountBookData();
    const readOnly = accountBookData.stepList.some(x => x === AccountBookDataStatusConstant.CompletedAnnualReturn);

    const financialStatementIndependentAuditOpinionDataSource = [
        { text: AuditorOpinionType.NotAudited, value: AuditorOpinionType.NotAudited },
        { text: AuditorOpinionType.UnqualifiedAuditOpinion, value: AuditorOpinionType.UnqualifiedAuditOpinion },
        { text: AuditorOpinionType.UnqualifiedWithEmphasisMatter, value: AuditorOpinionType.UnqualifiedWithEmphasisMatter },
        { text: AuditorOpinionType.QualifiedOpinion, value: AuditorOpinionType.QualifiedOpinion },
        { text: AuditorOpinionType.AdverseOpinion, value: AuditorOpinionType.AdverseOpinion },
        { text: AuditorOpinionType.DisclaimerOpinion, value: AuditorOpinionType.DisclaimerOpinion }
    ];

    const financialStatementAuditingStandardDataSource = [
        { text: 'Singapore Standards on Auditing', value: 'Singapore Standards on Auditing' },
        { text: 'International Standards on Auditing', value: 'International Standards on Auditing' },
        { text: 'US Generally Accepted Auditing Standards', value: 'US Generally Accepted Auditing Standards' },
        { text: 'Others', value: 'Others' }
    ];

    const currentYearContainChangesDataSource = [
        { text: 'Yes', value: 'Yes' },
        { text: 'No', value: 'No' }
    ];

    const companyEmployeeMoreThanFiftyDataSource = [
        { text: 'Yes', value: 'Yes' },
        { text: 'No', value: 'No' }
    ];

    const financialStatementPreparedOnConcernDataSource = [
        { text: 'Yes', value: 'Yes' },
        { text: 'No', value: 'No' }
    ];

    const financialStatementDirectorOpinionDataSource = [
        { text: 'Yes', value: 'Yes' },
        { text: 'No', value: 'No' }
    ];

    const companyAbleToPayDebtDataSource = [
        { text: 'Yes', value: 'Yes' },
        { text: 'No', value: 'No' }
    ];

    const materialUncertaintyAuditorReportDataSource = [
        { text: 'Yes', value: 'Yes' },
        { text: 'No', value: 'No' }
    ];

    const auditOpinionAccountingKeptAccordanceDataSource = [
        { text: 'Yes', value: 'Yes' },
        { text: 'No', value: 'No' },
        { text: 'Not applicable (disclaimer issued by auditor)', value: 'Not applicable (disclaimer issued by auditor)' }
    ];

    const validationRules = {
        independentAuditorOpinionType: [
            { type: 'required', message: 'Audit Opinion Type is required' }
        ],
        auditingStandard: [
            { type: 'custom', message: 'Auditing Standard is required', validationCallback: validateAuditStandard, reevaluate: true }
        ],
        isCurrentYearContainChangesToComparativeAmounts: [
            {
                type: 'custom',
                message: 'Please state if current year has changes to comparative amounts',
                validationCallback: () => {
                    return data.isCurrentYearContainChangesToComparativeAmounts !== null;
                },
                reevaluate: true
            }
        ],
        isCompanyEmployeeMoreThanFifty: [
            {
                type: 'custom',
                message: 'Please state if company employees more than 50',
                validationCallback: () => {
                    return data.isCompanyEmployeeMoreThanFifty !== null;
                },
                reevaluate: true
            }
        ],
        isFinancialStatementPreparedOnConcernBasis: [
            {
                type: 'custom',
                message: 'Please state if financial statement prepared on concern basis',
                validationCallback: () => {
                    return data.isFinancialStatementPreparedOnConcernBasis !== null;
                },
                reevaluate: true
            }
        ],
        isFinancialStatementDirectorOpinionTrueAndFair: [
            {
                type: 'custom',
                message: 'Please state if director opinion is true and fair',
                validationCallback: () => {
                    return data.isFinancialStatementDirectorOpinionTrueAndFair !== null;
                },
                reevaluate: true
            }
        ],
        isCompanyAbleToPayDebtsOnDateOfStatement: [
            {
                type: 'custom',
                message: 'Please state if company is able to pay debts on statement date',
                validationCallback: () => {
                    return data.isCompanyAbleToPayDebtsOnDateOfStatement !== null;
                },
                reevaluate: true
            }
        ],
        isMaterialUncertaintyInAuditorReport: [
            {
                type: 'custom',
                message: 'Please state if there are material of uncertainty in audit report',
                validationCallback: validateIsMaterialUncertaintyInAuditorReport,
                reevaluate: true
            }
        ],
        isAuditOpinionAccountingRecordsKeptAccordanceWithCompanyAct: [
            {
                type: 'custom',
                message: 'Please state if accounting records are kept in accordance',
                validationCallback: validateIsAuditOpinionAccountingRecordsKeptAccordanceWithCompanyAct,
                reevaluate: true
            }
        ]
    };

    function validateAuditStandard() {
        if (!data.independentAuditorOpinionType ||
            data.independentAuditorOpinionType === '' ||
            data.independentAuditorOpinionType === AuditorOpinionType.NotAudited) {
            return true;
        } else {
            return !!data.auditingStandard;
        }
    }

    function validateIsMaterialUncertaintyInAuditorReport() {
        if (!data.independentAuditorOpinionType ||
            data.independentAuditorOpinionType === '' ||
            data.independentAuditorOpinionType === AuditorOpinionType.NotAudited) {
            return true;
        } else {
            return data.isMaterialUncertaintyInAuditorReport !== null;
        }
    }

    function validateIsAuditOpinionAccountingRecordsKeptAccordanceWithCompanyAct() {
        if (!data.independentAuditorOpinionType ||
            data.independentAuditorOpinionType === '' ||
            data.independentAuditorOpinionType === AuditorOpinionType.NotAudited) {
            return true;
        } else {
            return data.isAuditOpinionAccountingRecordsKeptAccordanceWithCompanyAct !== null;
        }
    }

    return (
        <div className="px-4 py-3">
            <div className="row mb-3">
                <div className="col-md-4">
                    <label className="form-label required fw-bolder">Type of Audit Opinion in Independent Auditors' report</label>
                    <SelectBox
                        dataSource={financialStatementIndependentAuditOpinionDataSource}
                        displayExpr="text"
                        valueExpr="value"
                        readOnly={readOnly}
                        value={data.independentAuditorOpinionType}
                        onValueChanged={(e) => {
                            if (e.value === AuditorOpinionType.NotAudited) {
                                onChange({
                                    independentAuditorOpinionType: e.value,
                                    auditingStandard: '',
                                    isMaterialUncertaintyInAuditorReport: null,
                                    isAuditOpinionAccountingRecordsKeptAccordanceWithCompanyAct: null
                                });
                            } else {
                                onChange({ independentAuditorOpinionType: e.value });
                            }
                        }}
                    >
                        <Validator validationRules={validationRules.independentAuditorOpinionType} />
                    </SelectBox>
                </div>
                <div className="offset-md-2 col-md-4">
                    <label className="form-label required fw-bolder">Auditing Standards used to conduct the Audit</label>
                    <SelectBox
                        dataSource={financialStatementAuditingStandardDataSource}
                        displayExpr="text"
                        valueExpr="value"
                        readOnly={readOnly}
                        value={data.auditingStandard}
                        onValueChanged={(e) => onChange({ auditingStandard: e.value })}
                        disabled={data.independentAuditorOpinionType === AuditorOpinionType.NotAudited || !data.independentAuditorOpinionType || data.independentAuditorOpinionType === ''}
                    >
                        <Validator validationRules={validationRules.auditingStandard} />
                    </SelectBox>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-12">
                    <label className="form-label required fw-bolder">Whether there are any changes to comparative amounts (due to restatements/reclassifications or any other reason)</label>
                    <RadioGroup
                        layout="horizontal"
                        dataSource={currentYearContainChangesDataSource}
                        displayExpr="text"
                        valueExpr="value"
                        readOnly={readOnly}
                        value={data.isCurrentYearContainChangesToComparativeAmounts}
                        onValueChanged={(e) => onChange({ isCurrentYearContainChangesToComparativeAmounts: e.value })}
                        disabled={!data.priorYearEndDate}
                    >
                        <Validator validationRules={validationRules.isCurrentYearContainChangesToComparativeAmounts} />
                    </RadioGroup>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-12">
                    <label className="form-label required fw-bolder">Whether company (or group if consolidated accounts are prepared) has more than 50 employees</label>
                    <RadioGroup
                        layout="horizontal"
                        dataSource={companyEmployeeMoreThanFiftyDataSource}
                        displayExpr="text"
                        valueExpr="value"
                        readOnly={readOnly}
                        value={data.isCompanyEmployeeMoreThanFifty}
                        onValueChanged={(e) => onChange({ isCompanyEmployeeMoreThanFifty: e.value })}
                    >
                        <Validator validationRules={validationRules.isCompanyEmployeeMoreThanFifty} />
                    </RadioGroup>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-12">
                    <label className="form-label required fw-bolder">Whether the financial statements are prepared on going concern basis</label>
                    <RadioGroup
                        layout="horizontal"
                        dataSource={financialStatementPreparedOnConcernDataSource}
                        displayExpr="text"
                        valueExpr="value"
                        readOnly={readOnly}
                        value={data.isFinancialStatementPreparedOnConcernBasis}
                        onValueChanged={(e) => onChange({ isFinancialStatementPreparedOnConcernBasis: e.value })}
                    >
                        <Validator validationRules={validationRules.isFinancialStatementPreparedOnConcernBasis} />
                    </RadioGroup>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-12">
                    <label className="form-label required fw-bolder">Whether in directors' opinion financial statements are drawn up so as to exhibit true and fair view</label>
                    <RadioGroup
                        layout="horizontal"
                        dataSource={financialStatementDirectorOpinionDataSource}
                        displayExpr="text"
                        valueExpr="value"
                        readOnly={readOnly}
                        value={data.isFinancialStatementDirectorOpinionTrueAndFair}
                        onValueChanged={(e) => onChange({ isFinancialStatementDirectorOpinionTrueAndFair: e.value })}
                    >
                        <Validator validationRules={validationRules.isFinancialStatementDirectorOpinionTrueAndFair} />
                    </RadioGroup>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-12">
                    <label className="form-label required fw-bolder">Whether there are reasonable grounds to believe that company will be able to pay its debts as and when they fall due at date of statement</label>
                    <RadioGroup
                        layout="horizontal"
                        dataSource={companyAbleToPayDebtDataSource}
                        displayExpr="text"
                        valueExpr="value"
                        readOnly={readOnly}
                        value={data.isCompanyAbleToPayDebtsOnDateOfStatement}
                        onValueChanged={(e) => onChange({ isCompanyAbleToPayDebtsOnDateOfStatement: e.value })}
                    >
                        <Validator validationRules={validationRules.isCompanyAbleToPayDebtsOnDateOfStatement} />
                    </RadioGroup>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-12">
                    <label className="form-label required fw-bolder">Whether there is any material uncertainty relating to going concern, reported in the independent auditors' report</label>
                    <RadioGroup
                        layout="horizontal"
                        dataSource={materialUncertaintyAuditorReportDataSource}
                        displayExpr="text"
                        valueExpr="value"
                        readOnly={readOnly}
                        value={data.isMaterialUncertaintyInAuditorReport}
                        onValueChanged={(e) => onChange({ isMaterialUncertaintyInAuditorReport: e.value })}
                        disabled={data.independentAuditorOpinionType === AuditorOpinionType.NotAudited || !data.independentAuditorOpinionType || data.independentAuditorOpinionType === ''}
                    >
                        <Validator validationRules={validationRules.isMaterialUncertaintyInAuditorReport} />
                    </RadioGroup>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <label className="form-label required fw-bolder">Whether in auditors' opinion, accounting and other records required are properly kept in accordance with Companies Act</label>
                    <RadioGroup
                        layout="horizontal"
                        dataSource={auditOpinionAccountingKeptAccordanceDataSource}
                        displayExpr="text"
                        valueExpr="value"
                        readOnly={readOnly}
                        value={data.isAuditOpinionAccountingRecordsKeptAccordanceWithCompanyAct}
                        onValueChanged={(e) => onChange({ isAuditOpinionAccountingRecordsKeptAccordanceWithCompanyAct: e.value })}
                        disabled={data.independentAuditorOpinionType === AuditorOpinionType.NotAudited || !data.independentAuditorOpinionType || data.independentAuditorOpinionType === ''}
                    >
                        <Validator validationRules={validationRules.isAuditOpinionAccountingRecordsKeptAccordanceWithCompanyAct} />
                    </RadioGroup>
                </div>
            </div>
        </div>
    );
};

export default FinancialStatementEntryDirectorStatementAuditorReportView;
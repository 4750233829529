export function buildApiUrl(path, parameters) {
    let url = `/api/${path}`;

    if (parameters) {
        let queryString = '';

        for (const prop in parameters) {
            let propValue = parameters[prop];

            if (propValue !== null && propValue !== undefined) {
                if (Array.isArray(propValue)) {
                    for (let i = 0; i < propValue.length; i++) {
                        queryString += `${queryString ? '&' : '?'}${prop}=${encodeURIComponent(propValue[i])}`;
                    }
                } else {
                    queryString += `${queryString ? '&' : '?'}${prop}=${encodeURIComponent(propValue)}`;
                }
            }
        }

        url += queryString;
    }

    return url;
}

export function getLanguage() {
    let language = localStorage.getItem('language');
    return ['en', 'zh', 'ms'].includes(language) ? language : 'en';
}

export function setLanguage(language) {
    localStorage.setItem('language', language);
}
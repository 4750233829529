import {
    AccountBookDataPage,
    AccountMappingMaintenancePage,
    AnnualReturnPage,
    AnnualReturnWithFSPage,
    FinancialStatementPage,
    FixedAssetMaintenancePage,
    FormCsPage,
    HomePage,
    RenovationExpenseMaintenancePage,
    UserMaintenancePage
} from './pages';

const routes = [
    {
        name: 'HomePage',
        path: '/',
        component: HomePage
    },
    {
        name: 'AccountBookDataPage',
        path: '/accountBookData',
        component: AccountBookDataPage
    },
    {
        name: 'AccountMappingMaintenancePage',
        path: '/accountMappingMaintenance',
        component: AccountMappingMaintenancePage
    },
    {
        name: 'RenovationExpenseMaintenancePage',
        path: '/renovationExpenseMaintenance',
        component: RenovationExpenseMaintenancePage
    },
    {
        name: 'FixedAssetMaintenancePage',
        path: '/fixedAssetMaintenance',
        component: FixedAssetMaintenancePage
    },
    {
        name: 'FinancialStatementPage',
        path: '/financialStatement',
        component: FinancialStatementPage
    },
    {
        name: 'AnnualReturnPage',
        path: '/annualReturn',
        component: AnnualReturnPage
    },
    {
        name: 'AnnualReturnPage',
        path: '/annualReturnWithFS',
        component: AnnualReturnWithFSPage
    },
    {
        name: 'FormCSPage',
        path: '/formCS',
        component: FormCsPage
    },
    {
        name: 'UserMaintenancePage',
        path: '/userMaintenance',
        component: UserMaintenancePage
    }
];

export default routes;
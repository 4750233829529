import { useMemo } from 'react';
import ReportGrid from '../components/ReportGrid';

const TaxComputationView = (props) => {
    const { data } = props;

    const viewData = useMemo(() => {
        const list = [];

        const rawData = data.generatedDraft.dataTCSch;

        Object.entries(rawData).forEach(([key, value]) => {
            const pde = data.pdeList.find(x => x.dataId === key);

            const row = { description: pde && pde.description };
            if (key === 'receiptNotTxAmt') row.description = 'Total Non-Taxable Income';

            if ([
                'sgIntDisc',
                'c1_GrossRent',
                'sgOtherI',
                'oneTierTaxDividendIncome',
                'otherNonTaxableIncome',
                'amortisationExpense',
                'depreciationExpense',
                'donations',
                'commissionExpRentalIncome',
                'insuranceExpRentalIncome',
                'interestExpRentalIncome',
                'propertyTaxExpRentalIncome',
                'repairMaintenanceExpRentalIncome',
                'otherExpRentalIncome',
                'fixedAssetsExpdOff',
                'netGainsOrLossesOnDisposalOfPPE',
                'netGainsOrLossesOnOtherItems',
                'otherPrivateOrCapitalExp',
                'penaltiesOrFine',
                'repairsMaintenanceForPrivateVehicles',
                'upkeepPrivateVehicles',
                'medicalExpDisallowed'
            ].includes(key)
            ) {
                row.amount1 = value;
            } else {
                row.amount2 = value;
            }

            if (key === 'renoWorksExpS14Q' || key === 'unutilCABFNorm' || key === 'unutilLossBFNorm' ||
                key === 'taxRebate' || key === 'taxExemptAmount' || key === 'unutilDonationBFNorm') {
                row.tag = 'Less:';
            } else if (key === 'c1_BC') {
                row.tag = 'Add:';
            }

            list.push(row);

            if (key === 'profitLossBeforeTaxation') {
                list.push({});
                list.push({ tag: 'Less :', description: 'Separate Source Income' });
            } else if (key === 'sepSrcIncome') {
                list.push({});
                list.push({ tag: 'Less :', description: 'Non-Taxable Income' });
            } else if (key === 'receiptNotTxAmt') {
                list.push({});
                list.push({ tag: 'Add :', description: 'Non-Tax Deductible Income' });
            } else if (key === 'adjPLBefDed') {
                list.push({});
            } else if (key === 'sgAdjPLAft') {
                list.push({});
            } else if (key === 'adjustedProfitOrLossAfterCapitalAllowanceAndUnutilisedLossesBF') {
                list.push({});
                list.push({ tag: 'Add:', description: 'Separate Source Income' });
            } else if (key === 'sgRent') {
                list.push({ description: 'Interest Income', amount2: rawData.sgIntDisc });
                list.push({ description: 'Other Types of Income - Taxable Income', amount2: rawData.sgOtherI });
            } else if (key === 'netTaxPayable') {
                list.push({});
            } else if (key === 'chargeableIncomeAftTaxExemptAmt') {
                list.push({});
            }
        });

        return list;
    }, [data]);

    return (
        <ReportGrid
            dataSource={viewData}
            onCellPrepared={(e) => {
                if (e.rowType === 'data') {
                    if (e.column.name === 'amount1') {
                        if ([
                            'Other Taxable Income',
                            'Other Non-taxable Income',
                            'Medical Expenses Disallowed'
                        ].includes(e.row.data.description))
                            e.cellElement.classList.add('view-underline');
                    } else if (e.column.name === 'amount2') {
                        if ([
                            'Net Tax Payable',
                            'Chargeable Income (after Tax Exempt Amount)'
                        ].includes(e.row.data.description))
                            e.cellElement.classList.add('view-double-underline');
                        else if ([
                            'Non-tax Deductible Expenses',
                            'Further Deductions/ Other Deductions',
                            'Unutilised Losses b/f',
                            'Other Types of Income - Taxable Income',
                            'Current Year Donations',
                            'Tax Exempt Amount',
                            'Tax Rebate'
                        ].includes(e.row.data.description))
                            e.cellElement.classList.add('view-underline');
                    } else if (e.column.name === 'description') {
                        if ([
                            'Separate Source Income',
                            'Non-Taxable Income',
                            'Non-Tax Deductible Income'
                        ].includes(e.row.data.description) && !e.row.data.amount1 && !e.row.data.amount2)
                            e.cellElement.classList.add('text-decoration-underline');
                    }
                }
            }}
        />
    );
};

export default TaxComputationView;
import { CheckBox } from 'devextreme-react/check-box';

const DeclarationView = (props) => {
    const { formData, handleFormDataChange } = props;

    return (
        <>
            <div className="row mb-3">
                <div>1. The company maintains Singapore dollar as its functional and presentation currency.</div>
            </div>
            <div className="row mb-3">
                <div>2. The company is not an investment holding company or a service company that provides only related party services.</div>
            </div>
            <div className="row mb-3">
                <div>3. The company does not own subsidiaries, associates or joint ventures, and has no investment in intangibles.</div>
            </div>
            <hr />
            <div>
                <div className="mb-1">
                    <CheckBox
                        className="col-12 col-md-6"
                        value={formData.declaration && formData.declaration.isQualifiedToUseConvFormCS}
                        text="I agree to all the above declarations."
                        onValueChanged={e => {
                            handleFormDataChange({
                                declaration: { isQualifiedToUseConvFormCS: e.value }
                            });
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default DeclarationView;
import { useState, useEffect } from 'react';

const xSmallMedia = window.matchMedia('(max-width: 599.99px)');
const smallMedia = window.matchMedia('(min-width: 600px) and (max-width: 959.99px)');
const mediumMedia = window.matchMedia('(min-width: 960px) and (max-width: 1279.99px)');
const largeMedia = window.matchMedia('(min-width: 1280px)');

export const useScreenSize = () => {
    const [screenSize, setScreenSize] = useState(getScreenSize());

    useEffect(() => {
        const listener = (e) => {
            if (e.matches) setScreenSize(getScreenSize());
        };

        [xSmallMedia, smallMedia, mediumMedia, largeMedia].forEach(media => {
            media.addListener(listener);
        });

        return () => {
            [xSmallMedia, smallMedia, mediumMedia, largeMedia].forEach(media => {
                media.removeListener(listener);
            });
        };
    }, []);

    return screenSize;
};

export const useScreenSizeClass = () => {
    const screenSize = useScreenSize();

    if (screenSize.isLarge) {
        return 'screen-large';
    }

    if (screenSize.isMedium) {
        return 'screen-medium';
    }

    if (screenSize.isSmall) {
        return 'screen-small';
    }

    return 'screen-x-small';
};

function getScreenSize() {
    return {
        isXSmall: xSmallMedia.matches,
        isSmall: smallMedia.matches,
        isMedium: mediumMedia.matches,
        isLarge: largeMedia.matches
    };
}
import { formatDate as dxFormatDate } from 'devextreme/localization';

// Note: date values are in string format (DATE_SERIALIZATION_FORMAT or DATETIME_SERIALIZATION_FORMAT) in json
export const DATE_SERIALIZATION_FORMAT = 'yyyy-MM-dd';
export const DATETIME_SERIALIZATION_FORMAT = 'yyyy-MM-ddTHH:mm:ss';

function padZero(num, size) {
    let s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
}

export function dateToString(date) {
    return `${date.getFullYear()}-${padZero(date.getMonth() + 1, 2)}-${padZero(date.getDate(), 2)}T00:00:00`;
}

export function stringToDate(dateString) {
    return new Date(dateString);
}

export function todayDateString() {
    return dateToString(new Date());
}

export function formatDate(value, format = 'dd/MM/yyyy') {
    if (value instanceof Date) {
        return dxFormatDate(value, format);
    } else if (value && value.length) {
        let dateValue = stringToDate(value);
        return dxFormatDate(dateValue, format);
    } else {
        return '';
    }
}

export function compareDate(dateString1, dateString2) {
    //  -1 : if date1 < date2
    //   0 : if date1 = date2
    //   1 : if date1 > date2

    let date1 = stringToDate(dateString1);
    let date2 = stringToDate(dateString2);
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);
    return (date1 > date2) - (date1 < date2);
}

export function isFirstDayOfMonth(dateString) {
    let date = stringToDate(dateString);
    return date.getDate() === 1;
}

export function isLastDayOfMonth(dateString) {
    let date = stringToDate(dateString);
    date.setDate(date.getDate() + 1);
    return date.getDate() === 1;
}

export function addDays(dateString, days) {
    let date = stringToDate(dateString);
    date.setDate(date.getDate() + days);
    return dateToString(date);
}

export function addMonths(dateString, months) {
    let date = stringToDate(dateString);
    date.setMonth(date.getMonth() + months);
    return dateToString(date);
}

export function periodToDateString(period) {
    let year = Math.trunc(period / 100);
    let month = period % 100;
    return dateToString(new Date(year, month - 1, 1));
}

export function getNextPeriod(period) {
    let year = Math.trunc(period / 100);
    let month = period % 100;
    let date = new Date(year, month, 1);
    return date.getFullYear() * 100 + date.getMonth() + 1;
}

export function getLastDayOfMonth(dateString) {
    let date = stringToDate(dateString);
    let nextMonthDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    return addDays(dateToString(nextMonthDate), -1);
}

export function getShortMonthName(month) {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return month >= 1 && month <= 12 ? monthNames[month - 1] : `${month}`;
}
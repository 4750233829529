import {
    Page,
    StyleSheet,
    Text,
    View
} from '@react-pdf/renderer';

const PartAPdfView = (props) => {
    const { formData } = props;

    const styles = StyleSheet.create({
        page: {
            padding: 40
        },
        header: {
            fontSize: 15,
            fontWeight: 1000,
            marginBottom: 10,
            display: 'table',
            width: 'auto',
            textDecoration: 'underline',
            textAlign: 'center'
        },
        normalText: {
            fontSize: 8,
            margin: 5
        },
        table: {
            display: 'table',
            width: 'auto',
            borderStyle: 'solid',
            borderWidth: 1
        },
        tableHeader: {
            backgroundColor: '#21a84a',
            color: 'white',
            fontWeight: 1000
        },
        tableHeaderText: {
            fontSize: 10,
            margin: 5,
            fontWeight: 1000
        }
    });

    return (
        <Page size="A4" style={styles.page}>
            <View style={styles.container}>
                <View style={styles.header}>
                    <Text>Part A</Text>
                </View>   
            </View>
            <View style={styles.table}>

                <View style={styles.tableHeader}>
                    <Text style={styles.tableHeaderText}>The company has met the qualifying conditions for filing Form C-S</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={styles.normalText}>The company's revenue for the financial period is $5,000,000 or below.</Text>
                        <Text style={styles.normalText}>{formData.declaration.isRevBelow5M ? 'Yes' : 'No'}</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={styles.normalText}>The company derives only income subject to tax at 17%.</Text>
                        <Text style={styles.normalText}>{formData.declaration.isOnlyIncTxbl17 ? 'Yes' : 'No'}</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={styles.normalText}>The Company is not claiming/ utilising any of the following items:</Text>
                        <Text style={styles.normalText}>{formData.declaration.isNotClaimingSpecificItems ? 'Yes' : 'No'}</Text>
                    </View>
                    <Text style={[styles.normalText, { marginLeft: 15 }]}>a. Carry-back Of Current Year Capital Allowances/Losses</Text>
                    <Text style={[styles.normalText, { marginLeft: 15 }]}>b. Group Relief</Text>
                    <Text style={[styles.normalText, { marginLeft: 15 }]}>c. Investment Allowance</Text>
                    <Text style={[styles.normalText, { marginLeft: 15 }]}>d. Foreign Tax Credit And Tax Deducted At Source</Text>
                </View>

                <View style={styles.tableHeader}>
                    <Text style={styles.tableHeaderText}>Particulars of Person who Reviewed the Return</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flex: 1, flexWrap: 'wrap', marginRight: 150 }}>
                            <Text style={styles.normalText}>The return has been reviewed by a person who is a Singapore Chartered Tax Professionals Limited (SCTP) Accredited Tax Advisor or Accredited Tax Practitioner for Income Tax.</Text>
                        </View>
                        <Text style={styles.normalText}>{formData.dataFormCS.sctpInd ? 'Yes' : 'No'}</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={styles.normalText}>Name of Person</Text>
                        <Text style={styles.normalText}>{formData.dataFormCS.sctpName}</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={styles.normalText}>SCTP Membership Number</Text>
                        <Text style={styles.normalText}>{formData.dataFormCS.sctpNo}</Text>
                    </View>
                </View>
                <View style={styles.tableHeader}>
                    <Text style={styles.tableHeaderText}>The Company declares the following:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flex: 1, flexWrap: 'wrap' }}>
                            <Text style={styles.normalText}>The financial period for this return based on the company's financial statements is</Text>
                        </View>
                        <Text style={styles.normalText}>{formData.dataFormCS.basisPeriodFrom} to {formData.dataFormCS.basisPeriodTo}</Text>
                    </View>
                </View>
            </View>
        </Page>
    );
};

export default PartAPdfView;
import {
    Page,
    StyleSheet,
    Text,
    View
} from '@react-pdf/renderer';

const DetailedProfitAndLossPdfView = (props) => {
    const { data } = props;
    let i = 0;

    const styles = StyleSheet.create({
        page: {
            padding: 40
        },
        header: {
            fontSize: 15,
            fontWeight: 1000,
            marginBottom: 10,
            display: 'table',
            width: 'auto',
            textDecoration: 'underline',
            textAlign: 'center'
        },
        normalText: {
            fontSize: 8,
            margin: 3,
            paddingBottom: 5
        },
        table: {
            display: 'table',
            width: 'auto',
            borderStyle: 'solid',
            borderWidth: 1
        },
        tableHeader: {
            backgroundColor: '#21a84a',
            color: 'white',
            fontWeight: 1000
        },
        tableHeaderText: {
            fontSize: 10,
            margin: 5,
            fontWeight: 1000
        }
    });

    const viewData = (() => {
        const list = [];

        const {
            costOfGoodsSold, grossPL, depreciationExpense,
            ...restData
        } = data.generatedDraft.dataDtlPNL;

        Object.entries(restData).forEach(([key, value]) => {
            const pde = data.pdeList.find(x => x.dataId === key);
            const row = { description: pde && pde.description };

            if ([
                'totalRevenue',
                'totalOtherIncome',
                'totalExpenses',
                'profitLossBeforeTaxation'].includes(key)
            ) {
                row.amount2 = value;
            } else {
                row.amount1 = value;
            }

            if (key === 'sgIntDisc') {
                row.tag = 'Add:';
            }

            list.push(row);

            if (key === 'totalRevenue') {
                list.push({ tag: 'Less :', description: 'Cost of Goods Sold', amount2: costOfGoodsSold });
                list.push({ description: 'Gross Profit/ Loss', amount2: grossPL });
                list.push({});
            } else if (key === 'totalOtherIncome') {
                list.push({});
                list.push({ tag: 'Less :', description: 'Operating expenses' });
            } else if (key === 'commissionOther') {
                list.push({ description: 'Depreciation Expense', amount1: depreciationExpense });
            }
        });

        return list;
    })();

    const generateRow = (adjustment, description, amount1, amount2) => {
        return (
            <View key={++i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <View style={[styles.normalText, { width: 30}]}>
                    <Text>{adjustment ? `${adjustment}`: null} </Text>
                </View>
                <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }, (description === 'Operating expenses' && !amount1 && !amount2) ? { textDecoration: 'underline' } : null]}>
                    <Text>{description}</Text>
                </View>
                <View style={[styles.normalText, { width: 60, alignItems: 'flex-end', marginRight: 20 }, (description === 'Upkeep of Private Motor Vehicles') ? { borderBottom: '1px solid underline' } : null]}>
                    <Text>{amount1 ? formatValue(Number(amount1)) : null}</Text>
                </View>
                <View style={[styles.normalText, { width: 60, alignItems: 'flex-end' }, (description === 'Profit/ (Loss) before Taxation') ? { borderBottom: '1px solid underline' } : null, (['Cost of Goods Sold', 'Total Expenses'].includes(description)) ? { borderBottom: '1px solid underline' } : null]}>
                    <Text>{amount2 ? formatValue(Number(amount2)) : null}</Text>
                </View>
            </View>
        );
    };

    const formatValue = (value) => {
        if (typeof value === 'number') {
            if (value < 0) {
                return `(${Math.abs(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')})`;
            } else {
                return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            }
        }
        return value;
    };

    return (
        <Page size="A4" style={styles.page}>
            <View style={styles.container}>
                <View style={styles.header}>
                    <Text>Detailed Profit And Loss Statement</Text>
                </View>
            </View>
            <View style={styles.table}>
                <View style={[styles.tableHeader, { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }]}>
                    <View style={[styles.tableHeaderText, { width: 30 }]}>
                        <Text></Text>
                    </View>
                    <View style={[styles.tableHeaderText, { flex: 1, alignItems: 'flex-start' }]}>
                        <Text>Description</Text>
                    </View>
                    <View style={[styles.tableHeaderText, { width: 60, alignItems: 'flex-end', marginRight: 20 }]}>
                        <Text>S$</Text>
                    </View>
                    <View style={[styles.tableHeaderText, { width: 60, alignItems: 'flex-end' }]}>
                        <Text>S$</Text>
                    </View>
                </View>
                {viewData.map(data => {
                    return generateRow(data.tag, data.description, data.amount1, data.amount2);
                })}
            </View>
        </Page>
    );
};

export default DetailedProfitAndLossPdfView;
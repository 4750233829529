import * as Dialog from 'devextreme/ui/dialog';

/*global Oidc*/

if (!window.UserManager) {
    const config = {
        authority: window.APP_SETTINGS_AuthServerUrl,
        client_id: 'sg-seamless-filing-portal',
        redirect_uri: `${window.APP_SETTINGS_SingaporeSeamlessFilingPortalUrl}/callback.html`,
        silent_redirect_uri: `${window.APP_SETTINGS_SingaporeSeamlessFilingPortalUrl}/silent.html`,
        post_logout_redirect_uri: `${window.APP_SETTINGS_SingaporeSeamlessFilingPortalUrl}`,
        response_type: 'code',
        scope: 'openid profile IdentityServerApi sg-seamless-filing-portal-api',
        automaticSilentRenew: true
    };

    window.UserManager = new Oidc.UserManager(config);

    window.UserManager.events.addUserSessionChanged(function () {
        console.error('User session changed. Logging out...');

        window.UserManager.removeUser().then(() => {
            showLoggedOutMessage();
        });
    });

    window.UserManager.events.addUserSignedOut(function () {
        console.error('User signed out. Logging out...');

        window.UserManager.removeUser().then(() => {
            showLoggedOutMessage();
        });
    });

    window.UserManager.events.addAccessTokenExpired(function () {
        console.error('Access token expired. Logging out...');

        window.UserManager.removeUser().then(() => {
            showLoggedOutMessage();
        });
    });

    window.UserManager.events.addSilentRenewError(function (error) {
        console.error('error while renewing the access token', error);
    });
}

export function getUser() {
    return window.UserManager.getUser();
}

export function logIn() {
    let url = window.location.pathname + window.location.search;
    return window.UserManager.signinRedirect({ state: url });
}

export function logOut() {
    return window.UserManager.signoutRedirect();
}

function showLoggedOutMessage() {
    let dlg = Dialog.custom({
        showTitle: true,
        title: 'You are now logged out',
        dragEnabled: false,
        messageHtml: '<div style="max-width:500px">Click Login button below to return to the AutoCount Seamless Filing Portal.</div>',
        buttons: [{
            text: 'Login',
            type: 'default',
            onClick: () => logIn()
        }]
    });

    return dlg.show();
}
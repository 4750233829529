import * as Api from 'utils/api';
import { buildApiUrl } from 'utils/session';

const basePath = 'accountBookData';

export function getEntryList() {
    let url = buildApiUrl(`${basePath}/getEntryList`);
    return Api.getData(url);
}

export function createAccountBookData(accountBookData) {
    let url = buildApiUrl(`${basePath}/createAccountBookData`);
    return Api.postData(url, accountBookData).then(response => response.json());
}

export function deleteAccountBookData(accountBookDataId) {
    let url = buildApiUrl(`${basePath}/deleteAccountBookData`, { accountBookDataId });
    return Api.deleteData(url);
}
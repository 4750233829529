import { useEffect, useState } from 'react';
import * as PageUtils from 'utils/pageUtils';

export default function useIrasAuth() {
    const [postAuthProcess, setPostAuthProcess] = useState({ postAuthFunction: null, postAuthData: null });

    useEffect(() => {
        function onAuthCallback(e) {
            const { postAuthFunction, postAuthData } = postAuthProcess;

            if (e.data === 'IRASAuthCompleted') {
                setPostAuthProcess({ postAuthFunction: null, postAuthData: null });
                postAuthFunction(postAuthData);
            }
        }

        window.onmessage = onAuthCallback;

        return () => {
            window.onmessage = null;
        };
    }, [postAuthProcess]);

    function startAuth(authURL, actionMethod, actionData) {
        setPostAuthProcess({
            postAuthFunction: actionMethod,
            postAuthData: actionData
        });

        PageUtils.blockPage();

        let windowWidth = 700;
        let windowHeight = 850;
        let windowTop = window.outerHeight / 2 + window.screenY - (windowHeight / 2);
        let windowLeft = window.outerWidth / 2 + window.screenX - (windowWidth / 2);

        let popupWindow = window.open(authURL, 'IRAS Auth', `width=${windowWidth},height=${windowHeight},top=${windowTop},left=${windowLeft}`);

        let timer = setInterval(() => {
            if (popupWindow && popupWindow.closed) {
                clearInterval(timer);
                PageUtils.unblockPage();
            }
        }, 1000);
    }

    return { postAuthProcess, setPostAuthProcess, startAuth };
}
import {
    Path,
    StyleSheet,
    Svg,
    Text,
    View
} from '@react-pdf/renderer';

const DeclarationPdfView = (props) => {
    const { formData } = props;

    const styles = StyleSheet.create({
        header: {
            fontSize: 15,
            fontWeight: 1000,
            marginBottom: 10,
            marginTop: 20,
            display: 'table',
            width: 'auto',
            textDecoration: 'underline',
            textAlign: 'center'
        },
        alertText: {
            fontSize: 10,
            fontWeight: 'bold',
            marginBottom: 5
        },
        normalText: {
            fontSize: 8,
            marginBottom: 5
        },
        line: {
            height: 1,
            backgroundColor: 'black',
            width: '100%',
            marginTop: 10,
            marginBottom: 10
        },
        checkboxContainer: {
            width: 8,
            height: 8,
            border: '1 solid #000',
            marginRight: 5
        }
    });

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <Text>Declaration</Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'top', marginBottom: 10 }}>
                <View style={[styles.checkboxContainer, { paddingRight: 8 }]}>
                    {formData.declaration.isFullAndTrueAccOfCompInc &&
                        <Svg height="8" width="8" viewBox="0 0 24 24">
                            <Path
                                d="M20 6L9 17l-5-5"
                                fill="none"
                                stroke="black"
                                strokeWidth={4}
                            />
                        </Svg>
                    }
                </View>
                <Text style={styles.normalText}>The company declares that this return gives a full and true account of the whole of the company's income for the financial period ending in the preceding year.</Text>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'top' }}>
                <View style={[styles.checkboxContainer, { paddingRight: 8 }]}>
                    {formData.declaration.isQualifiedToUseConvFormCS &&
                        <Svg height="8" width="8" viewBox="0 0 24 24">
                            <Path
                                d="M20 6L9 17l-5-5"
                                fill="none"
                                stroke="black"
                                strokeWidth={4}
                            />
                        </Svg>
                    }
                </View>
                <Text style={styles.normalText}>The company maintains Singapore dollar as its functional/ presentation currency.</Text>
            </View>
            <Text style={[styles.normalText, { marginLeft: 15 }]}>The company is not an investment holding company or a service company that provides only related party services.</Text>
            <Text style={[styles.normalText, { marginLeft: 15 }]}>The company does not own subsidiaries, associates or joint ventures, and has no investment in intangibles.</Text>
            <View style={styles.line} />
            <Text style={styles.alertText}>Details of Filer & Contact Person</Text>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View style={{ width: '30%' }}>
                    <Text style={styles.normalText}>Name of Filer</Text>
                    <Text style={styles.normalText}>Designation</Text>
                    {formData.taxAgentFirmName && <Text style={styles.normalText}>Tax Agent Firm</Text>}
                </View>
                <View style={{ width: '30%' }}>
                    <Text style={styles.normalText}>{formData.nameOfFiler}</Text>
                    <Text style={styles.normalText}>{formData.filingInfo.designationOfFiler}</Text>
                    {formData.taxAgentFirmName && <Text style={styles.normalText}>{formData.taxAgentFirmName}</Text>}
                </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View style={{ width: '30%' }}>
                    <Text style={styles.normalText}>Name of Contact Person</Text>
                    <Text style={styles.normalText}>Designation</Text>
                    <Text style={styles.normalText}>Contact No.</Text>
                </View>
                <View style={{ width: '30%' }}>
                    <Text style={styles.normalText}>{formData.filingInfo.nameOfContactPerson}</Text>
                    <Text style={styles.normalText}>{formData.filingInfo.designationOfContactPerson}</Text>
                    <Text style={styles.normalText}>{formData.filingInfo.contactNumber}</Text>
                </View>
            </View>
        </View>
    );
};

export default DeclarationPdfView;
import { useMemo, useState } from 'react';
import CustomStore from 'devextreme/data/custom_store';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import { Column, Selection, Paging, Item as DataGridToolbarItem, Toolbar as DataGridToolbar } from 'devextreme-react/data-grid';
import { ToolbarItem } from 'devextreme-react/popup';
import { PrimaryButton } from 'components/StandardButton';
import StandardDataGrid from 'components/StandardDataGrid';
import StandardPopup from 'components/StandardPopup';
import * as ApplicationUtils from 'utils/applicationUtils';
import * as AccountBookDataApi from '../api/accountBookDataApi';
import '../Home.scss';

const ImportFromOnPremiseDialog = (props) => {
    const { onSubmit, onCancel } = props;
    const currentYA = ApplicationUtils.getYearOfAssessment();

    const [timestamp, setTimestamp] = useState(Date.now());
    const [selectedAccountBook, setSelectedAccountBook] = useState(null);
    const [isCurrentYA, setIsCurrentYA] = useState(true);
    const yearOfAssessment = currentYA + (isCurrentYA ? 0 : 1);

    const dataSource = useMemo(() => {
        let cache;
        return new CustomStore({
            key: 'connectionId',
            loadMode: 'raw',
            load: async function () {
                if (!cache) cache = await AccountBookDataApi.getOnPremiseAccountingAccountBookList(yearOfAssessment);
                return cache;
            }
        });
    }, [timestamp, yearOfAssessment]);

    function refreshData() {
        setTimestamp(Date.now());
    }

    return (
        <StandardPopup
            visible
            onHiding={onCancel}
            dragEnabled={false}
            showCloseButton
            showTitle
            fullScreen
            title="Synchronize Account Book Data from On-Premise Accounting"
        >
            {selectedAccountBook && selectedAccountBook.existingAccountBookDataId ?
                <div className="alert alert-warning">
                    <div className="mb-2">
                        <p className="d-inline fw-bold">Warning!</p>
                        There is an existing dataset of Account Book "<p className="d-inline fw-bold">{selectedAccountBook.companyName}</p>" for <p className="d-inline fw-bold">YA {window.APP_SETTINGS_YearOfAssessment}</p> found.
                    </div>
                    <p className="mb-1">Synchronizing this account book will overwrite existing account book data and may result in following actions:</p>
                    <ul className="mb-0 fw-bold">
                        <li>Overwriting of Existing Account Book Data</li>
                        <li>Data such as "UEN", "Prior Year Start Date", "Current Year Start Date", "Fixed Asset" and "Renovation Expense" remains unchanged.</li>
                        <li>Removal of Annual Return / Form C-S Submission Draft</li>
                    </ul>
                    <div className="pt-3">Each page in other web browser tab requires refresh after synchronizing.</div>
                </div>
                :
                <div className="alert alert-success">
                    Please click on <b>"Connect to Cloud"</b> in your Singapore Seamless e-Filing <a href="/help#downloadSection" target="_blank">plugin</a> to view your account book below.
                </div>
            }
            <StandardDataGrid
                dataSource={dataSource}
                onEditorPreparing={e => {
                    if (e.type === 'selection' && e.parentType === 'headerRow') {
                        e.editorOptions.onValueChanged = () => { };
                    }
                }}
                onCellPrepared={e => {
                    if (e.column && e.column.type === 'selection') {
                        if (e.rowType === 'header' || (e.rowType === 'data' && e.data.invalidReason)) {
                            if (e.cellElement.children[0]) e.cellElement.children[0].style.visibility = 'hidden';
                        }
                    }
                }}
                onSelectionChanged={e => {
                    if (e.selectedRowsData.length > 1) {
                        if (e.selectedRowsData[1].invalidReason) {
                            e.component.selectRows([e.selectedRowsData[0].connectionId], false);
                        } else {
                            e.component.selectRows([e.selectedRowsData[1].connectionId], false);
                            setSelectedAccountBook({ ...e.selectedRowsData[1], ya: yearOfAssessment });
                        }
                    } else if (e.selectedRowsData.length) {
                        if (e.selectedRowsData[0].invalidReason) {
                            e.component.selectRows([], false);
                        } else {
                            e.component.selectRows([e.selectedRowsData[0].connectionId], false);
                            setSelectedAccountBook({ ...e.selectedRowsData[0], ya: yearOfAssessment });
                        }
                    } else {
                        setSelectedAccountBook(null);
                    }
                }}
            >
                <DataGridToolbar>
                    <DataGridToolbarItem
                        location="after"
                        locateInMenu="auto"
                        widget="dxButton"
                        options={{
                            icon: 'refresh',
                            type: 'default',
                            onClick: () => refreshData()
                        }}
                    />
                    <DataGridToolbarItem
                        location="before"
                        locateInMenu="auto"
                    >
                        <CheckBox
                            text={`Annual Return ${currentYA - 1}`}
                            value={isCurrentYA}
                            onValueChanged={(e) => {
                                if (isCurrentYA !== e.value) setIsCurrentYA(e.value);
                            }}
                        />
                    </DataGridToolbarItem>
                    <DataGridToolbarItem
                        location="before"
                        locateInMenu="auto"
                    >
                        <CheckBox
                            text={`Form C-S YA ${currentYA}`}
                            value={isCurrentYA}
                            onValueChanged={(e) => {
                                if (isCurrentYA !== e.value) setIsCurrentYA(e.value);
                            }}
                        />
                    </DataGridToolbarItem>
                    <DataGridToolbarItem
                        location="before"
                        locateInMenu="auto"
                    >
                        <CheckBox
                            text={`Annual Return ${currentYA}`}
                            value={!isCurrentYA}
                            onValueChanged={(e) => {
                                if (isCurrentYA !== e.value) setIsCurrentYA(e.value);
                            }}
                        />
                    </DataGridToolbarItem>
                </DataGridToolbar>
                <Paging enabled={false} />
                <Selection mode="multiple" showCheckBoxesMode="always" />
                <Column
                    fixed
                    fixedPosition="left"
                    type="selection"
                />
                <Column
                    dataField="companyName"
                    caption="Company Name"
                    dataType="string"
                />
                <Column
                    dataField="countryName"
                    caption="Country"
                    dataType="string"
                />
                <Column
                    dataField="currencyCode"
                    caption="Currency"
                    dataType="string"
                />
                <Column
                    dataField="priorFiscalYear"
                    caption="Prior Fiscal Year"
                    dataType="string"
                />
                <Column
                    dataField="currentFiscalYear"
                    caption="Current Fiscal Year"
                    dataType="string"
                />
                <Column
                    dataField="invalidReason"
                    caption="Reason"
                    dataType="string"
                />
            </StandardDataGrid>
            <ToolbarItem
                toolbar="bottom"
                location="after"
            >
                <Button
                    text="Close"
                    onClick={() => onCancel()}
                />
            </ToolbarItem>
            <ToolbarItem
                toolbar="bottom"
                location="after"
                render={() => (
                    <PrimaryButton
                        text="Synchronize"
                        onClick={() => onSubmit(selectedAccountBook)}
                        disabled={selectedAccountBook === null || selectedAccountBook.filingCompleted}
                    />
                )}
            />
        </StandardPopup>
    );
};
export default ImportFromOnPremiseDialog;
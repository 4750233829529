import CustomStore from 'devextreme/data/custom_store';
import { Editing, Column, Toolbar, Item, Button } from 'devextreme-react/data-grid';
import { useRef, useState } from 'react';
import PageTitle from 'components/PageTitle';
import { PrimaryButton } from 'components/StandardButton';
import StandardDataGrid from 'components/StandardDataGrid';
import * as ErrorMessage from 'utils/errorMessage';
import * as PageUtils from 'utils/pageUtils';
import * as Toast from 'utils/toast';
import * as UserApi from './api/userApi';
import UserDialog from './partials/UserDialog';

const UserMaintenancePage = () => {
    const [dialogData, setDialogData] = useState(false);
    const [showUserDialog, setShowUserDialog] = useState(false);

    const dataGridRef = useRef();

    const dataSource = new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: function () {
            return UserApi.getUserList();
        },
        remove: function (key) {
            PageUtils.blockPage();
            return UserApi.deleteRecord(key)
                .then(() => {
                    PageUtils.unblockPage();
                    Toast.showSuccessMessage('User is deleted successfully.');
                })
                .catch(error => {
                    PageUtils.unblockPage();
                    Toast.showErrorMessage(ErrorMessage.updateError(error));
                });
        }
    });

    function inviteUser() {
        setDialogData({});
        setShowUserDialog(true);
    }

    function editRecord(key) {
        PageUtils.blockPage();
        UserApi.getRecord(key)
            .then(data => {
                PageUtils.unblockPage();
                setDialogData(data);
                setShowUserDialog(true);
            }).catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    }

    function saveRecord(original, current) {
        PageUtils.blockPage();
        if (!original.id) {
            UserApi.createRecord(current)
                .then(() => {
                    PageUtils.unblockPage();
                    setShowUserDialog(false);
                    Toast.showSuccessMessage(`An invitation email is sent to "${current.emailAddress}".`);
                    dataGridRef.current.instance.refresh();
                })
                .catch(error => {
                    PageUtils.unblockPage();
                    Toast.showErrorMessage(ErrorMessage.createError(error));
                });
        } else {
            UserApi.updateRecord(original.id, current)
                .then(() => {
                    PageUtils.unblockPage();
                    setShowUserDialog(false);
                    Toast.showSuccessMessage('User is updated successfully.');
                    dataGridRef.current.instance.refresh();
                }).catch(error => {
                    PageUtils.unblockPage();
                    Toast.showErrorMessage(ErrorMessage.updateError(error));
                });
        }
    }

    return (
        <>
            <div className="content-block">
                <PageTitle title="User" />
            </div>
            <div className="content-block">
                <div className="dx-card responsive-paddings">
                    <StandardDataGrid
                        dataSource={dataSource}
                        ref={dataGridRef}
                    >
                        <Toolbar>
                            <Item
                                location="before"
                                locateInMenu="auto"
                                menuItemRender={() => (
                                    <PrimaryButton
                                        text="Invite User"
                                        onClick={() => inviteUser()}
                                        width="100%"
                                    />
                                )}
                            >
                                <PrimaryButton
                                    text="Invite User"
                                    onClick={() => inviteUser()}
                                    stylingMode="outlined"
                                />
                            </Item>
                            <Item
                                name="columnChooserButton"
                            />
                            <Item
                                name="searchPanel"
                            />
                        </Toolbar>
                        <Editing
                            allowDeleting
                        />
                        <Column
                            caption="Email Address"
                            dataField="emailAddress"
                            dataType="string"
                        />
                        <Column
                            caption="Has Full Access"
                            dataField="hasFullAccess"
                            dataType="boolean"
                        />
                        <Column
                            caption="Accessible Company List"
                            dataField="accessibleCompanyList"
                            cellRender={e => (
                                <>
                                    {e.data.accessibleCompanyList.map(companyName => (
                                        <div className="dx-grid-cell-tag-content">
                                            {companyName}
                                        </div>
                                    ))}
                                </>
                            )}
                        />
                        <Column
                            type="buttons"
                            width={100}
                            fixed
                            fixedPosition="right"
                        >
                            <Button
                                text="Edit"
                                onClick={e => editRecord(e.row.data.id)}
                                visible={e => e.row.data.userId !== e.row.data.ownerId}
                            />
                            <Button
                                name="delete"
                                visible={e => e.row.data.userId !== e.row.data.ownerId}
                            />
                        </Column>
                    </StandardDataGrid>
                </div>
            </div>

            {showUserDialog &&
                <UserDialog
                    initialData={dialogData}
                    onSubmit={saveRecord}
                    onCancel={() => setShowUserDialog(false)}
                />
            }
        </>
    );
};

export default UserMaintenancePage;
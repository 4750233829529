// Cloud Accounting Document Type
const documentTypeConstant = [
    { docType: 'QT', category: 'Sales', name: 'Quotation' },
    { docType: 'IV', category: 'Sales', name: 'Invoice' },
    { docType: 'CN', category: 'Sales', name: 'Credit Note' },

    { docType: 'PO', category: 'Purchase', name: 'Purchase Order' },
    { docType: 'PI', category: 'Purchase', name: 'Purchase Invoice' },
    { docType: 'PR', category: 'Purchase', name: 'Purchase Return' },

    { docType: 'PV', category: 'Accounting', name: 'Payment Voucher' },
    { docType: 'OR', category: 'Accounting', name: 'Receipt Voucher' },
    { docType: 'JE', category: 'Accounting', name: 'Journal Entry' },
    { docType: 'KO', category: 'Accounting', name: 'Knock Off Entry' },

    { docType: 'SA', category: 'Stock', name: 'Stock Adjustment' },
    { docType: 'ST', category: 'Stock', name: 'Stock Transfer' },

    { docType: 'O1', category: 'Opening Balance', name: 'Opening Balance' },
    { docType: 'O2', category: 'Opening Balance', name: 'Opening Balance' },
    { docType: 'O3', category: 'Opening Balance', name: 'Stock Opening Balance' },

    { docType: 'OS', category: 'External', name: 'Point Of Sale' },
    { docType: 'MP', category: 'External', name: 'Marketplace Order' },
    { docType: 'MR', category: 'External', name: 'Marketplace Return' },
    { docType: 'SS', category: 'External', name: 'Stock Transit' },
    { docType: 'PP', category: 'External', name: 'Pos Posting' }
];

export default documentTypeConstant;

// PDE must have a value ("Current" or "Non-Current") in account mapping
export const liquidityPdeConstant = [
    'tradeAndOtherReceivablesDueFromThirdParties',
    'tradeAndOtherReceivablesDueFromRelatedParties',
    'contractAssets',
    'otherReceivables',
    'tradeAndOtherPayablesDueToThirdParties',
    'tradeAndOtherPayablesDueToRelatedParties',
    'contractLiabilities',
    'otherPayables'
];

export default liquidityPdeConstant;
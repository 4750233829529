import React from 'react';
import { Document } from '@react-pdf/renderer';
import AcknowledgementPdfView from './AcknowledgementPdfView';
import PartAPdfView from './PartAPdfView';
import PartBPdfView from './PartBPdfView';
import PartCPdfView from './PartCPdfView';
import MedicalExpenseSchedulePdfView from './MedicalExpenseSchedulePdfView';
import RenovationSchedulePdfView from './RenovationSchedulePdfView';
import CapitalAllowanceSchedulePdfView from './CapitalAllowanceSchedulePdfView';
import RentalSchedulePdfView from './RentalSchedulePdfView';
import DetailedProfitAndLossPdfView from './DetailedProfitAndLossPdfView';
import TaxComputationPdfView from './TaxComputationPdfView';

const FormCsPdfView = (props) => {
    const { initialData, accountBookData } = props;

    return (
        <Document>
            <AcknowledgementPdfView
                initialData={initialData}
                accountBookData={accountBookData}
            />
            <PartAPdfView formData={initialData.formDraft} />
            <PartBPdfView formData={initialData.formDraft} />
            <PartCPdfView formData={initialData.formDraft} />
            <MedicalExpenseSchedulePdfView initialData={initialData.generatedDraft.dataMedExpSch} />
            <RenovationSchedulePdfView
                initialData={initialData.generatedDraft.dataRRSch}
                accountBookData={accountBookData}
            />
            <CapitalAllowanceSchedulePdfView initialData={initialData.generatedDraft.dataCASch} />
            <RentalSchedulePdfView initialData={initialData.generatedDraft.dataRentalSch} />
            <DetailedProfitAndLossPdfView data={initialData} />
            <TaxComputationPdfView data={initialData} />
        </Document>
    );
};

export default FormCsPdfView;
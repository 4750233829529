import { Template } from 'devextreme-react/core/template';
import Drawer from 'devextreme-react/drawer';
import ScrollView from 'devextreme-react/scroll-view';
import { useNavigate } from 'react-router';
import { useState, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import Header from '../Header/Header';
import SideNavigationMenu from '../SideNavigationMenu/SideNavigationMenu';
import Footer from '../Footer/Footer';
import * as AccountBookDataStatusConstant from 'data/accountBookDataStatusConstant';
import { useScreenSize } from 'utils/media-query';
import { useMenuPatch } from 'utils/patches';
import * as Toast from 'utils/toast';
import './SideNavInnerToolbar.scss';

export default function SideNavOuterToolbar({ children }) {
    const scrollViewRef = useRef();
    const navigationMenuRef = useRef();
    const navigate = useNavigate();
    const { accountBookData } = useAccountBookData();
    const { isXSmall, isLarge } = useScreenSize();
    const [patchCssClass, onMenuReady] = useMenuPatch();
    const [isMenuOpen, setIsMenuOpen] = useState(isLarge);

    const { pathname: currentPath } = useLocation();

    const toggleMenu = ({ event }) => {
        event.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
    };

    const onOutsideClick = () => {
        if (!isLarge && isMenuOpen) {
            setIsMenuOpen(false);
        }
    };

    const onNavigationChanged = useCallback(({ itemData: { path, items }, event, node }) => {
        if (!isMenuOpen || !path || node.selected || (items && items.length > 0)) {
            event.preventDefault();
            return;
        }

        if (path === 'accountMappingMaintenance') {
            if (!accountBookData.stepList.includes(AccountBookDataStatusConstant.CompletedCompanyInfo)) {
                Toast.showErrorMessage('Complete Company UEN is required before proceed to this step');
                return;
            }
        }

        const filingPaths = ['/financialStatement', '/annualReturnWithFS', '/renovationExpenseMaintenance', '/fixedAssetMaintenance', '/formCS'];
        if (filingPaths.includes(path)) {
            if (!accountBookData.isAccountMappingComplete) {
                Toast.showErrorMessage(`${!accountBookData.uen ? 'Company UEN and' : ''} Complete Account Mapping is required before proceeding to this step`);
                return;
            }
        }

        navigate(path);
        scrollViewRef.current.instance.scrollTo(0);

        if (!isLarge) {
            setIsMenuOpen(false);
            event.stopPropagation();
        }
    }, [navigate, isMenuOpen, isLarge, accountBookData]);

    return (
        <div className="side-nav-inner-toolbar">
            <Drawer
                className={['drawer', patchCssClass].join(' ')}
                position="before"
                closeOnOutsideClick={onOutsideClick}
                openedStateMode={isLarge ? 'shrink' : 'overlap'}
                revealMode={isXSmall ? 'slide' : 'expand'}
                minSize={0}
                maxSize={250}
                shading={!isLarge}
                opened={isMenuOpen}
                template="menu"
            >
                <Header
                    className="layout-header"
                    menuToggleEnabled
                    toggleMenu={toggleMenu}
                    accountBookData={accountBookData}
                    currentPath={currentPath}
                />
                <ScrollView ref={scrollViewRef} className="layout-body with-footer" useNative height="calc(100vh - 56px)">
                    <div className="content">
                        {children}
                    </div>
                    <div className="content-block">
                        <Footer />
                    </div>
                </ScrollView>
                <Template name="menu">
                    <SideNavigationMenu
                        selectedItemChanged={onNavigationChanged}
                        onMenuReady={onMenuReady}
                        ref={navigationMenuRef}
                    />
                </Template>
            </Drawer>
        </div>
    );
}
import * as Api from 'utils/api';
import { buildApiUrl } from 'utils/session';

const basePath = 'accountBookData';

export function getAccountBookData(accountBookDataId) {
    let url = buildApiUrl(`${basePath}/getAccountBookData`, { accountBookDataId });
    return Api.getData(url);
}

export function updateAccountBookData(accountBookDataId, data) {
    let url = buildApiUrl(`${basePath}/updateAccountBookData`, { accountBookDataId });
    return Api.putData(url, data);
}
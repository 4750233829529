import DraftFormContentHeaderRow from './DraftFormContentHeaderRow';
import DraftFormContentDataRow from './DraftFormContentDataRow';
import DraftFormContentFooterRow from './DraftFormContentFooterRow';
import * as pdeDataCurrentType from '../data/pdeDataCurrentType';

const FinancialStatementXBRLFormNonCurrentLiabilitiesView = (props) => {
    const { initialData, onViewPDEDrillDown } = props;

    const dataRows = [
        { dataId: 'tradeAndOtherPayablesNoncurrent' },
        { dataId: 'noncurrentLoansAndBorrowings' },
        { dataId: 'noncurrentFinancialLiabilitiesDerivativesandMeasuredAtFairValueThroughProfitOrLoss' },
        { dataId: 'noncurrentFinanceLeaseLiabilities' },
        { dataId: 'otherNoncurrentFinancialLiabilities' },
        { dataId: 'deferredTaxLiabilities' },
        { dataId: 'noncurrentProvisions' },
        { dataId: 'otherNoncurrentNonfinancialLiabilities' },
        { dataId: 'noncurrentLiabilities', isFooter: true, enableTopBorder: true, enableBottomBorder: true }
    ];

    const hasPriorYearFinancialData = initialData.xbrlData.priorFinancialYear;

    return (
        <>
            <DraftFormContentHeaderRow description="Non-Current Liabilities" level={4} />
            {dataRows.map((item, index) => {
                const pde = initialData.pdeList.find(x => x.dataId === item.dataId);
                const pdeDescription = pde && pde.description;

                const priorYearValue = hasPriorYearFinancialData && initialData.xbrlData.priorFinancialYear.liabilities.nonCurrentLiabilities[item.dataId];
                const currentYearValue = initialData.xbrlData.currentFinancialYear.liabilities.nonCurrentLiabilities[item.dataId];

                if (item.isFooter) {
                    return (
                        <DraftFormContentFooterRow
                            key={index}
                            description={pdeDescription}
                            priorYearValue={priorYearValue}
                            currentYearValue={currentYearValue}
                            enableTopBorder={item.enableTopBorder}
                            enableBottomBorder={item.enableBottomBorder}
                            onViewPDEDrillDown={(isCurrentYear) => onViewPDEDrillDown({ pdeDataId: item.dataId, isCurrentYearFinancialData: isCurrentYear, pdeCurrentType: pdeDataCurrentType.NonCurrent })}
                        />
                    );
                }

                return (
                    <DraftFormContentDataRow
                        key={index}
                        level={4}
                        description={pdeDescription}
                        priorYearValue={priorYearValue}
                        currentYearValue={currentYearValue}
                        onViewPDEDrillDown={(isCurrentYear) => onViewPDEDrillDown({ pdeDataId: item.dataId, isCurrentYearFinancialData: isCurrentYear, pdeCurrentType: pdeDataCurrentType.NonCurrent })}
                    />
                );
            })}
        </>
    );
};

export default FinancialStatementXBRLFormNonCurrentLiabilitiesView;
const IrasFilingQualificationCriteria = () => {
    return (
        <>
            <h5>IRAS Filing Qualification Criteria</h5>
            <hr />
            <b>A company can file tax returns to IRAS using this web portal if it meets the following conditions:</b>
            <ol type="a">
                <li>The company must qualify to file Form C-S, i.e.</li>
                <ul>
                    <li>incorporated in Singapore;</li>
                    <li>has an annual revenue of $5 million or below in the preceding financial year; </li>
                    <li>derives income taxable at prevailing corporate tax rate of 17%;</li>
                    <li>Not claiming or utilising carry-back of current year capital allowance or losses, group relief, investment allowance or foreign tax credit and tax deducted at source; </li>
                </ul>
                <li>The company maintains Singapore dollar as its functional and presentation currency;</li>
                <li>The company is not an investment holding company or a service company that provides only related party services; and</li>
                <li>The company does not own subsidiaries, associates or joint ventures, and has no investment in intangibles.</li>
            </ol>
        </>
    );
};

export default IrasFilingQualificationCriteria;
import { RadioGroup, Item } from 'devextreme-react/radio-group';

const FormRadioGroup = (props) => {
    const { value, onValueChanged, children, ...rest } = props;

    return (
        <RadioGroup
            displayExpr="text"
            valueExpr="value"
            value={value}
            onValueChanged={onValueChanged}
            layout="horizontal"
            {...rest}
        >
            <Item text="Yes" value={Boolean(true)} />
            <Item text="No" value={Boolean(false)} />
            {children}
        </RadioGroup>
    );
};

export default FormRadioGroup;
import { useEffect, useRef, useState } from 'react';
import validationEngine from 'devextreme/ui/validation_engine';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import useIrasAuth from 'hooks/useIrasAuth';
import * as ErrorMessage from 'utils/errorMessage';
import * as PageUtils from 'utils/pageUtils';
import * as Toast from 'utils/toast';
import * as FormCSApi from '../../api/formCsApi';
import * as FormCsStatusConstant from '../../data/formCsStatusConstant';
import Schedule_RenovationRefurbishment from '../FinancialSchedule/RenovationRefurbishmentSchedule';
import Schedule_Medical from '../FinancialSchedule/MedicalExpenseSchedule';
import Schedule_Rental from '../FinancialSchedule/RentalSchedule';
import Schedule_CapitalAllowance from '../FinancialSchedule/CapitalAllowanceSchedule';
import PartAView from './PartAView';
import PartBView from './PartBView';
import PartCView from './PartCView';
import TaxComputationView from './TaxComputationView';
import DetailedProfitAndLossView from './DetailedProfitAndLossView';
import DeclarationView from './DeclarationView';
import DataRequireUpdateDialog from './DataChangeAcknowledgementDialog';
import ConfirmationDialog from '../ConfirmBeforeSubmissionDialog';
import BottomActionBar from '../../partials/components/BottomActionBar';
import ErrorMessageView from '../ErrorMessageView';

const FormCsDraftView = (props) => {
    const { initialData, loadData } = props;

    const { accountBookData, refreshAccountBookData } = useAccountBookData();
    const accountBookDataId = accountBookData && accountBookData.accountBookDataId;

    const { startAuth } = useIrasAuth();

    const [formData, setFormData] = useState(initialData.formDraft);
    const [formErrors, setFormErrors] = useState([]);

    useEffect(() => {
        setFormData(initialData.formDraft);
    }, [initialData.formDraft]);

    const tabPanelRef = useRef();
    const validatorErrors = useRef([]);

    const [showDataChangeAcknowledgementDialog, setShowDataChangeAcknowledgementDialog] = useState(false);

    const [showMedicalSchedule, setShowMedicalSchedule] = useState(false);
    const [showRenovationRefurbishmentSchedule, setShowRenovationRefurbishmentSchedule] = useState(false);
    const [showRentalSchedule, setShowRentalSchedule] = useState(false);
    const [showCapitalAllowanceSchedule, setShowCapitalAllowanceSchedule] = useState(false);

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    function handleFilingInfoDataChange(value, dataIsModified = true) {
        setFormData(prevState => ({ ...prevState, filingInfo: { ...prevState.filingInfo, ...value }, dataIsModified }));
    }

    function handleTaxConversionDataChange(value, dataIsModified = true) {
        setFormData(prevState => ({ ...prevState, taxConversion: { ...prevState.taxConversion, ...value }, dataIsModified }));
    }

    function handleDraftDataChange(value, dataIsModified = true) {
        // if any field in the list is modified, recalculation is required.
        const fields = [
            'unutilCABFNorm',
            'unutilLossBFNorm',
            'unutilDonationBFNorm',
            'cyDonation',
            'uCALDChangePrinAct',
            'sholderChange',
            'unutilCALDClaimS23S37',
            'fullTxX',
            'expRD',
            'expRDSG',
            'enhanceDeductRD',
            'appStockConvAsset'
        ];
        if (Object.keys(value).some(key => fields.includes(key))) {
            initialData.requireNewFiling = true;
        }
        setFormData(prevState => ({ ...prevState, dataFormCS: { ...prevState.dataFormCS, ...value }, dataIsModified }));
    }

    function handleDeclarationDataChange(value, dataIsModified = true) {
        setFormData(prevState => ({ ...prevState, declaration: { ...prevState.declaration, ...value }, dataIsModified }));
    }

    function saveDraft() {
        PageUtils.blockPage();
        FormCSApi.updateDraft(accountBookDataId, formData)
            .then(() => {
                PageUtils.unblockPage();
                loadData();
                Toast.showSuccessMessage('Form Draft is updated successfully.');
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.updateError(error));
            });
    }

    function recalculateTax() {
        PageUtils.blockPage();

        const taxCalculationValidationResult = validationEngine.validateGroup('taxCalculationValidation');
        if (!taxCalculationValidationResult.isValid) {

            PageUtils.unblockPage();

            let isPartBError = document.querySelector('.partBView .dx-validator.dx-invalid');
            let isPartCError = document.querySelector('.partCView .dx-validator.dx-invalid');

            if (isPartBError)
                scrollToError(isPartBError, 1);
            else if (isPartCError)
                scrollToError(isPartCError, 2);

            return;
        }

        FormCSApi.regenerateDraft(accountBookDataId, formData)
            .then(result => {
                PageUtils.unblockPage();
                if (result.isSuccess) {
                    setFormErrors([]);
                    loadData();
                    Toast.showSuccessMessage('Tax is recalculated.');
                } else {
                    setFormErrors([...result.errors]);
                    validatorErrors.current = result.errors;

                    scrollToTop();
                }
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.createError(error));
            });
    }

    function confirmBeforeSubmit() {
        if (initialData.requireNewFiling) {
            setShowDataChangeAcknowledgementDialog(true);
            return;
        }

        const formDraftValidationResult = validationEngine.validateGroup('draftValidation');
        const taxCalculationValidationResult = validationEngine.validateGroup('taxCalculationValidation');

        if (!formDraftValidationResult.isValid || !taxCalculationValidationResult.isValid) {
            let isPartAError = document.querySelector('.partAView .dx-validator.dx-invalid');
            let isPartBError = document.querySelector('.partBView .dx-validator.dx-invalid');
            let isPartCError = document.querySelector('.partCView .dx-validator.dx-invalid');
            let isDeclarationError = document.querySelector('.declarationView .dx-validator.dx-invalid');

            if (isPartAError) scrollToError(isPartAError, 0);
            else if (isPartBError) scrollToError(isPartBError, 1);
            else if (isPartCError) scrollToError(isPartCError, 2);
            else if (isDeclarationError) scrollToError(isDeclarationError, 3);

            return;
        }

        setShowConfirmationDialog(true);
    }

    function submitForm() {
        setShowConfirmationDialog(false);
        scrollToTop();
        PageUtils.blockPage();

        FormCSApi.submitFormCS(accountBookDataId, formData)
            .then(result => {
                PageUtils.unblockPage();
                scrollToTop();
                if (result.requireAuth) {
                    startAuth(result.authUrl, submitForm, { accountBookDataId });
                } else if (result) {
                    if (result.isSuccess) {
                        setFormErrors([]);
                        loadData();
                        refreshAccountBookData();
                        Toast.showSuccessMessage('Form C-S is submitted successfully.');
                    } else {
                        setFormErrors([...result.errors]);
                        validatorErrors.current = result.errors;
                    }
                }
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.createError(error));
            });
    }

    function scrollToError(element, index) {
        tabPanelRef.current.instance.option('selectedIndex', index);
        element.scrollIntoView();
        element.classList.add('dx-state-focused');
    }

    function scrollToTop() {
        const element = document.querySelector('.content');
        element.scrollIntoView();
    }

    function viewFinancialSchedule() {
        if (tabPanelRef.current) {
            tabPanelRef.current.instance.option('selectedIndex', 1);
        }
    }

    const isReadOnlyForm = initialData.status === FormCsStatusConstant.FormSubmitted;

    return (
        <>
            {showDataChangeAcknowledgementDialog &&
                <DataRequireUpdateDialog onCancel={() => setShowDataChangeAcknowledgementDialog(false)} />
            }
            <ErrorMessageView errors={formErrors} />
            <div className="dx-card responsive-paddings mb-3">
                <TabPanel
                    className="formcs-tabpanel"
                    focusStateEnabled={false}
                    scrollingEnabled
                    showNavButtons
                    deferRendering={isReadOnlyForm}
                    ref={tabPanelRef}
                >
                    <Item title="Part A">
                        <div className="mt-3" />
                        <PartAView
                            formData={formData}
                            handleDraftDataChange={handleDraftDataChange}
                            handleDeclarationDataChange={handleDeclarationDataChange}
                            status={initialData.status}
                        />
                    </Item>
                    <Item title="Part B">
                        <div className="mt-3" />
                        <PartBView
                            formData={formData}
                            handleFilingInfoDataChange={handleFilingInfoDataChange}
                            handleTaxConversionDataChange={handleTaxConversionDataChange}
                            handleDraftDataChange={handleDraftDataChange}
                            showCapitalAllowanceSchedule={() => setShowCapitalAllowanceSchedule(true)}
                            showMedicalSchedule={() => setShowMedicalSchedule(true)}
                            showRenovationRefurbishmentSchedule={() => setShowRenovationRefurbishmentSchedule(true)}
                            showRentalSchedule={() => setShowRentalSchedule(true)}
                            status={initialData.status}
                        />
                    </Item>
                    <Item title="Part C">
                        <div className="mt-3" />
                        <PartCView
                            formData={formData}
                            handleFilingInfoDataChange={handleFilingInfoDataChange}
                            handleTaxConversionDataChange={handleTaxConversionDataChange}
                            handleDraftDataChange={handleDraftDataChange}
                            status={initialData.status}
                        />
                    </Item>
                    <Item title="Declaration">
                        <div className="mt-3" />
                        <DeclarationView
                            status={initialData.status}
                            formData={formData}
                            handleDeclarationDataChange={handleDeclarationDataChange}
                            viewFinancialSchedule={viewFinancialSchedule}
                        />
                    </Item>
                    <Item title="Detailed Profit Loss Statement">
                        <DetailedProfitAndLossView data={initialData} />
                    </Item>
                    <Item title="Tax Computation">
                        <TaxComputationView data={initialData} />
                    </Item>
                </TabPanel>
            </div>
            {showCapitalAllowanceSchedule &&
                <Schedule_CapitalAllowance
                    initialData={initialData.generatedDraft.dataCASch}
                    onCancel={() => setShowCapitalAllowanceSchedule(false)}
                />
            }
            {showRenovationRefurbishmentSchedule &&
                <Schedule_RenovationRefurbishment
                    initialData={initialData.generatedDraft.dataRRSch}
                    onCancel={() => setShowRenovationRefurbishmentSchedule(false)}
                />
            }
            {showRentalSchedule &&
                <Schedule_Rental
                    initialData={initialData.generatedDraft.dataRentalSch}
                    onCancel={() => setShowRentalSchedule(false)}
                />
            }
            {showMedicalSchedule &&
                <Schedule_Medical
                    initialData={initialData.generatedDraft.dataMedExpSch}
                    onCancel={() => setShowMedicalSchedule(false)}
                />
            }
            {showConfirmationDialog &&
                <ConfirmationDialog
                    onSubmit={submitForm}
                    onCancel={() => setShowConfirmationDialog(false)}
                />
            }
            {initialData.status === FormCsStatusConstant.FormPending &&
                <BottomActionBar
                    onSave={() => saveDraft()}
                    onSubmit={() => confirmBeforeSubmit()}
                    onRecalculate={() => recalculateTax()}
                />
            }
        </>
    );
};

export default FormCsDraftView;
import Button from 'devextreme-react/button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as FormCSApi from './api/formCsApi';
import * as ApplicationApi from 'api/applicationApi';
import AccountBookNotSelected from 'layouts/AccountBookNotSelected';
import PageTitle from 'components/PageTitle';
import { PrimaryButton } from 'components/StandardButton';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as AccountBookDataStatusConstant from 'data/accountBookDataStatusConstant';
import * as ErrorMessage from 'utils/errorMessage';
import * as MessageBox from 'utils/messageBox';
import * as PageUtils from 'utils/pageUtils';
import * as Toast from 'utils/toast';
import * as formCsStatusConstant from './data/formCsStatusConstant';
import AcknowledgementView from './partials/AcknowledgementView';
import FormCsInitialView from './partials/InitialView/FormCsInitialView';
import FormCsDraftView from './partials/DraftView/FormCsDraftView';
import IrasFilingQualificationCriteria from './partials/IrasFilingQualificationCriteria';
import './FormCs.scss';

const FormCs = () => {
    const { accountBookData, refreshAccountBookData } = useAccountBookData();
    const accountBookDataId = accountBookData && accountBookData.accountBookDataId;
    const isAccountMappingComplete = accountBookData && accountBookData.isAccountMappingComplete;

    const navigate = useNavigate();

    const [formCsData, setFormCsData] = useState(null);

    useEffect(() => {
        loadData();
    }, [accountBookDataId]);

    useEffect(() => {
        if (formCsData?.status === formCsStatusConstant.FormInitiated &&
            accountBookData.stepList.includes(AccountBookDataStatusConstant.InitiatedFormCS) &&
            !accountBookData.stepList.includes(AccountBookDataStatusConstant.VisitedRenovationExpense)
        ) {
            MessageBox.showConfirmMessage('Do you want to enter Renovation Expense before proceeding to Form C-S?')
                .then(result => {
                    if (result.confirm) navigate('/renovationExpenseMaintenance');
                    ApplicationApi.updateAccountBookDataStatus(accountBookDataId, AccountBookDataStatusConstant.VisitedRenovationExpense)
                        .then(() => refreshAccountBookData());
                });
        } else if (formCsData?.status === formCsStatusConstant.FormInitiated &&
            accountBookData.stepList.includes(AccountBookDataStatusConstant.InitiatedFormCS) &&
            !accountBookData.stepList.includes(AccountBookDataStatusConstant.VisitedFixedAsset)
        ) {
            MessageBox.showConfirmMessage('Do you want to enter Fixed Asset before proceeding to Form C-S?')
                .then(result => {
                    if (result.confirm) navigate('/fixedAssetMaintenance');
                    ApplicationApi.updateAccountBookDataStatus(accountBookDataId, AccountBookDataStatusConstant.VisitedFixedAsset)
                        .then(() => refreshAccountBookData());
                });
        }
    }, [formCsData?.status, accountBookData.stepList]);

    function loadData() {
        if (accountBookDataId) {
            PageUtils.blockPage();
            FormCSApi.getDraft(accountBookDataId)
                .then(data => {
                    PageUtils.unblockPage();
                    const { formDraftInJson, generatedDraftInJson, ...rest } = data;

                    setFormCsData({
                        formDraft: formDraftInJson ? JSON.parse(formDraftInJson) : null,
                        generatedDraft: generatedDraftInJson ? JSON.parse(generatedDraftInJson) : null,
                        ...rest
                    });
                })
                .catch(error => {
                    PageUtils.unblockPage();
                    Toast.showErrorMessage(ErrorMessage.fetchError(error));
                });
        }
    }

    function initiateFormCsFiling() {
        PageUtils.blockPage();
        FormCSApi.initiateFormCSFiling(accountBookDataId)
            .then(() => {
                PageUtils.unblockPage();
                refreshAccountBookData();
                loadData();
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    }

    function resetFiling() {
        MessageBox.showConfirmDangerMessage('Are you sure you want to reset Form C-S ? (This action can\'t be reverted).', false, 'Yes, proceed', 'No, keep it')
            .then(result => {
                if (result && result.confirm) {
                    FormCSApi.deleteDraft(accountBookDataId)
                        .then(() => {
                            Toast.showSuccessMessage('Form C-S Filing is reset successfully.');
                            loadData();
                            refreshAccountBookData();
                        })
                        .catch(error => {
                            Toast.showErrorMessage(ErrorMessage.deleteError(error));
                        });
                }
            });
    }

    return (
        <>
            <div className="content-block">
                <PageTitle title="Form C-S">
                    <div className="align-self-center" style={{ flexGrow: 100 }}>
                        <PrimaryButton
                            width={170}
                            visible={formCsData && formCsData.status !== formCsStatusConstant.FormNew}
                            className="ms-3 mb-1 mb-md-0"
                            text="Renovation Expense"
                            onClick={() => navigate('/renovationExpenseMaintenance')}
                        />
                        <PrimaryButton
                            width={170}
                            visible={formCsData && formCsData.status !== formCsStatusConstant.FormNew}
                            className="ms-3"
                            text="Fixed Asset"
                            onClick={() => navigate('/fixedAssetMaintenance')}
                        />
                    </div>
                    <div className="align-self-center" >
                        <Button
                            width={170}
                            visible={formCsData && formCsData.status !== formCsStatusConstant.FormNew && formCsData.status !== formCsStatusConstant.FormSubmitted}
                            className="ms-3 mb-1 mb-md-0"
                            text="Reset Filing"
                            onClick={() => resetFiling()}
                        />
                    </div>
                </PageTitle>
            </div>
            {isAccountMappingComplete ?
                <>
                    {accountBookDataId && formCsData ?
                        <div className="content-block">
                            {formCsData.status === formCsStatusConstant.FormSubmitted &&
                                <AcknowledgementView initialData={formCsData} />
                            }
                            {formCsData.status === formCsStatusConstant.FormNew &&
                                <div className="dx-card responsive-paddings">
                                    <div className="dx-card px-3 pt-3 mb-3">
                                        <IrasFilingQualificationCriteria />
                                    </div>
                                    <div className="alert alert-primary p-3">
                                        Please click on Initiate Form C-S Filing to start filing.
                                    </div>
                                    <PrimaryButton
                                        text="Initiate Form C-S Filing"
                                        onClick={initiateFormCsFiling}
                                    />
                                </div>
                            }
                            {formCsData.status === formCsStatusConstant.FormInitiated &&
                                <FormCsInitialView
                                    initialData={formCsData}
                                    loadData={loadData}
                                />
                            }
                            {(formCsData.status === formCsStatusConstant.FormPending || formCsData.status === formCsStatusConstant.FormSubmitted) &&
                                <FormCsDraftView
                                    initialData={formCsData}
                                    loadData={loadData}
                                />
                            }
                        </div> :
                        <AccountBookNotSelected />
                    }
                </>
                :
                <div className="content-block">
                    <div className="alert alert-danger"><p className="fw-bold d-inline">Account Mapping</p> is not complete. Please complete it in <p className="d-inline text-decoration-underline" role="button" onClick={() => navigate('/accountMappingMaintenance')}>Account Mapping Maintenance</p>.</div>
                </div>
            }
        </>
    );
};

export default FormCs;
import { Button } from 'devextreme-react/button';
import { Column, Paging, Sorting, Toolbar } from 'devextreme-react/data-grid';
import { ToolbarItem } from 'devextreme-react/popup';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import StandardDataGrid from 'components/StandardDataGrid';
import StandardPopup from 'components/StandardPopup';

const RenovationRefurbishmentSchedule = (props) => {
    const { initialData, onCancel } = props;

    const { accountBookData } = useAccountBookData();
    const yearOfAssessment = accountBookData && accountBookData.yearOfAssessment;

    const dataSource = [
        {
            key: `YA ${yearOfAssessment - 2}`,
            leaseholdImprovementsAndRenoCostIncurred: initialData ? initialData.leaseholdImprovementsAndRenoCostIncurredInYAMinus2 : 0,
            s14QDeduction: initialData ? initialData.s14QDeductionForCurrentYAMinus2 : 0,
            qualifyingRAndRCost: initialData ? initialData.qualifyingRAndRCostsforCurrentYAMinus2 : 0
        },
        {
            key: `YA ${yearOfAssessment - 1}`,
            leaseholdImprovementsAndRenoCostIncurred: initialData ? initialData.leaseholdImprovementsAndRenoCostIncurredInYAMinus1 : 0,
            s14QDeduction: initialData ? initialData.s14QDeductionForCurrentYAMinus1 : 0,
            qualifyingRAndRCost: initialData ? initialData.qualifyingRAndRCostsforCurrentYAMinus1 : 0
        },

        {
            key: `YA ${yearOfAssessment}`,
            leaseholdImprovementsAndRenoCostIncurred: initialData ? initialData.leaseholdImprovementsAndRenoCostIncurredInCurrentYA : 0,
            s14QDeduction: initialData ? initialData.s14QDeductionForCurrentYA : 0,
            qualifyingRAndRCost: initialData ? initialData.qualifyingRAndRCostsforCurrentYA : 0
        },
        {
            key: 'Total Section 14N Deduction',
            s14QDeduction: initialData ? initialData.renoWorksExpS14Q : 0
        }
    ];

    return (
        <StandardPopup
            visible
            onHiding={onCancel}
            showCloseButton
            width="1000px"
            height="auto"
            title="Renovation & Refurbishment Schedule"
        >
            <div className="text-left">The <u>Leasehold Improvements and Renovation Cost</u> do not require the approval of the Commissioner of Building Control under the Building Control Act.</div>
            <div className="mt-4">
                <StandardDataGrid
                    dataSource={dataSource}
                    showColumnLines
                    showRowLines={false}
                    allowColumnReordering={false}
                    wordWrapEnabled
                >
                    <Column
                        width="230px"
                        caption="Year of Assessment (YA)"
                        dataField="key"
                        dataType="string"
                    />
                    <Column
                        caption="Leasehold Improvements And Renovation Cost Incurred"
                        dataField="leaseholdImprovementsAndRenoCostIncurred"
                        format="#,##0.00"
                        dataType="number"
                    />
                    <Column
                        width="200px"
                        caption="Qualifying R&R Cost"
                        dataField="qualifyingRAndRCost"
                        format="#,##0.00"
                        dataType="number"
                    />
                    <Column
                        width="200px"
                        caption="Section 14N Deduction"
                        dataField="s14QDeduction"
                        format="#,##0.00"
                        dataType="number"
                    />
                    <Toolbar visible={false} />
                    <Paging enabled={false} />
                    <Sorting mode="none" />
                </StandardDataGrid>
            </div>
            <ToolbarItem
                toolbar="bottom"
                location="after"
            >
                <Button
                    text="Close"
                    onClick={onCancel}
                />
            </ToolbarItem>
        </StandardPopup >
    );
};

export default RenovationRefurbishmentSchedule;
import React, { useState, useEffect } from 'react';
import CheckBox from 'devextreme-react/check-box';
import { Column, Toolbar, Sorting, Summary, TotalItem } from 'devextreme-react/data-grid';
import { ActionButton } from 'components/StandardButton';
import StandardDataGrid from 'components/StandardDataGrid';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as AnnualReturnApi from 'pages/AnnualReturn/api/annualReturnApi';
import * as Toast from 'utils/toast';
import * as PageUtils from 'utils/pageUtils';
import * as MessageBox from 'utils/messageBox';

const PaymentForm = (props) => {
    const { accountBookData } = useAccountBookData();
    const accountBookDataId = accountBookData && accountBookData.accountBookDataId;

    const { previewFileInfoData, paymentData, onMakePayment } = props;
    const { paymentDetails } = paymentData;
    const [formData, setFormData] = useState({
        isPreviewCopyDownloaded: false,
        isVerifiedCopyAccurate: false
    });
    const [previewCopyUrl, setPreviewCopyUrl] = useState(null);
    const [previewCopyDownloadData, setPreviewCopyDownloadData] = useState({ downloadUrl: '', isExpired: true });

    useEffect(() => {
        if (previewFileInfoData) {
            PageUtils.blockPage();
            const { documentId, fileName } = previewFileInfoData;

            AnnualReturnApi.downloadTransactionPreview(accountBookDataId, { documentId: documentId, documentName: fileName })
                .then(result => {
                    PageUtils.unblockPage();
                    setPreviewCopyUrl(URL.createObjectURL(result));
                })
                .catch(error => {
                    PageUtils.unblockPage();
                    Toast.showErrorMessage(error);
                });
        }
    }, [previewFileInfoData]);

    function downloadPreviewCopy() {
        PageUtils.blockPage();
        const { documentId, fileName } = previewFileInfoData;
        const { downloadUrl, isExpired } = previewCopyDownloadData;

        if (isExpired) {
            AnnualReturnApi.getAttachmentDownloadUrl(accountBookDataId, { documentId: documentId, documentName: fileName })
                .then(result => {
                    PageUtils.unblockPage();
                    setPreviewCopyDownloadData({ downloadUrl: result, isExpired: false });
                    setTimeout(() => setPreviewCopyDownloadData(prevState => ({ ...prevState.previewCopyDownloadData, isExpired: true })), [288000]);

                    window.open(result, '_blank', 'noopener');
                })
                .catch(error => {
                    PageUtils.unblockPage();
                    Toast.showErrorMessage(error);
                });
        } else {
            PageUtils.unblockPage();
            window.open(downloadUrl, '_blank', 'noopener');
        }

        handleChange({ isPreviewCopyDownloaded: true });
    }

    function handleChange(value, dataIsModified = true) {
        setFormData(Object.assign({}, formData, value, { dataIsModified }));
    }

    function handlePayment() {
        MessageBox.showConfirmDangerMessage('Are you sure you want to Make Payment?')
            .then(result => {
                if (result.confirm) {
                    onMakePayment();
                }
            });
    }

    return (
        <>
            {previewCopyUrl ?
                <object data={previewCopyUrl} type="application/pdf" name="AnnualReturn_PreviewCopy" width="100%" height="480px">
                    <p>PDF Preview not supported on your web browser.</p>
                </object> :
                <p>PDF Preview currently not available</p>
            }

            <div className="d-flex">
                <ActionButton className="me-2" icon="dx-icon dx-icon-download" text="Download Preview to Proceed" onClick={downloadPreviewCopy} />
            </div>
            <div className="form-row mt-4">
                <CheckBox
                    className="mb-4"
                    text="I have verified and confirm that the review copy is accurate"
                    value={formData.isVerifiedCopyAccurate}
                    onValueChanged={e => handleChange({ isVerifiedCopyAccurate: e.value })}
                    disabled={!formData.isPreviewCopyDownloaded}
                />
            </div>
            <div>
                <div className="dx-card dx-theme-accent-as-background-color p-2 mb-3">
                    <span className="fs-5 text-white">Payment Details</span>
                </div>

                <StandardDataGrid
                    dataSource={paymentDetails}
                >
                    <Column
                        caption="Item Description"
                        dataField="itemDescription"
                        dataType="string"
                    />
                    <Column
                        caption="Amount"
                        dataField="amount"
                        dataType="number"
                        format="#0.00"
                    />
                    <Summary>
                        <TotalItem
                            column="amount"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat="#0.00"
                        />
                    </Summary>
                    <Sorting enabled={false} />
                    <Toolbar visible={false} />
                </StandardDataGrid>
            </div>

            <div className="form-row mt-2">
                <div className="justify-content-end">
                    <ActionButton text="Make Payment" disabled={!formData.isVerifiedCopyAccurate} onClick={handlePayment} />
                </div>
            </div>
        </>
    );
};

export default PaymentForm;
import React from 'react';

const Unauthorized = () => {
    return (
        <>
            <div className="content-block d-flex justify-content-center">
                <div className="mt-5 pt-5 text-center">
                    <div className="pb-4"><span className="dx-icon-clear text-danger" style={{ fontSize: '140px' }} /></div>
                    <h1 style={{ fontSize: '60px', paddingBottom: '10px' }}>401</h1>
                    <p className="text-danger" style={{ fontSize: '30px' }}>You are not authorized to access Seamless Filing Portal.</p>
                </div>
            </div>
        </>
    );
};

export default Unauthorized;
import StandardDataGrid from 'components/StandardDataGrid';
import { Column, Paging, Sorting, Toolbar } from 'devextreme-react/data-grid';

const ReportGrid = (props) => {
    const { dataSource, ...rest } = props;

    return (
        <div className="d-flex justify-content-center">
            <StandardDataGrid
                className="report-grid"
                dataSource={dataSource}
                showRowLines={false}
                showColumnLines={false}
                allowColumnReordering={false}
                {...rest}
            >
                <Column width={80} caption="" dataField="tag" dataType="string" />
                <Column caption="Description" dataField="description" dataType="string" />
                <Column
                    caption="S$"
                    dataField="amount1"
                    format="#,##0.00;(#,##0.00)"
                    dataType="number"
                    alignment="right"
                    width={150}
                />
                <Column
                    caption="S$"
                    dataField="amount2"
                    format="#,##0.00;(#,##0.00)"
                    dataType="number"
                    alignment="right"
                    width={150}
                />
                <Toolbar visible={false} />
                <Paging enabled={false} />
                <Sorting mode="none" />
            </StandardDataGrid>
        </div>
    );
};

export default ReportGrid;
import React from 'react';
import TextBox from 'devextreme-react/text-box';
import Validator from 'devextreme-react/validator';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as AccountBookDataStatusConstant from 'data/accountBookDataStatusConstant';

const FinancialStatementEntryEntityDetailsView = (props) => {
    const { data, onChange } = props;

    const { accountBookData } = useAccountBookData();
    const readOnly = accountBookData.stepList.some(x => x === AccountBookDataStatusConstant.CompletedAnnualReturn);

    const validationRules = {
        uen: [
            { type: 'required', message: 'Entity UEN is required.' }
        ],
        parentEntityName: [
            { type: 'required', message: 'Parent Entity Name is required.' }
        ],
        ultimateParentGroupName: [
            { type: 'required', message: 'Ultimate Parent Group Name is required.' }
        ],
        entityOperationsActivitiesNatureDescription: [
            { type: 'required', message: 'Description of Entity Operation/Activity is required.' }
        ],
        companyPrincipalBusinessPlace: [
            { type: 'required', message: 'Principal Business Place is required' }
        ]
    };

    return (
        <div className="px-4 py-3">
            <div className="row mb-3">
                <div className="col-md-4">
                    <label className="form-label fw-bolder">Entity Name</label>
                    <TextBox
                        value={data.companyName}
                        readOnly
                    />
                </div>

                <div className="offset-md-2 col-md-4">
                    <label className="form-label fw-bolder">Entity UEN</label>
                    <TextBox
                        value={data.uen}
                        readOnly
                    >
                        <Validator validationRules={validationRules.uen} />
                    </TextBox>
                    <div className="form-text">Hint: You can maintain your Company UEN in the Home Page</div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-4">
                    <label className="form-label required fw-bolder">Name of Parent Entity</label>
                    <TextBox
                        value={data.parentEntityName}
                        onValueChanged={(e) => onChange({ parentEntityName: e.value })}
                        readOnly={readOnly}
                    >
                        <Validator validationRules={validationRules.parentEntityName} />
                    </TextBox>
                </div>

                <div className="offset-md-2 col-md-4">
                    <label className="form-label required fw-bolder">Name of Ultimate Parent of Group</label>
                    <TextBox
                        value={data.ultimateParentGroupName}
                        onValueChanged={(e) => onChange({ ultimateParentGroupName: e.value })}
                        readOnly={readOnly}
                    >
                        <Validator validationRules={validationRules.ultimateParentGroupName} />
                    </TextBox>
                </div>
            </div>

            <div className="row">
                <div className="col-md-5">
                    <label className="form-label required fw-bolder">Description of Nature of Entity's Operations and Principal Activities</label>
                    <TextBox
                        value={data.entityOperationsActivitiesNatureDescription}
                        onValueChanged={(e) => onChange({ entityOperationsActivitiesNatureDescription: e.value })}
                        readOnly={readOnly}
                    >
                        <Validator validationRules={validationRules.entityOperationsActivitiesNatureDescription} />
                    </TextBox>
                </div>
                <div className="offset-md-1 col-md-5">
                    <label className="form-label required fw-bolder">Principal place of business - the company (or group, if consolidated FS)</label>
                    <TextBox
                        value={data.companyPrincipalBusinessPlace}
                        onValueChanged={(e) => onChange({ companyPrincipalBusinessPlace: e.value })}
                        readOnly={readOnly}
                    >
                        <Validator validationRules={validationRules.companyPrincipalBusinessPlace} />
                    </TextBox>
                </div>
            </div>
        </div>
    );
};

export default FinancialStatementEntryEntityDetailsView;
import { ActionButton, PrimaryButton } from 'components/StandardButton';

const BottomActionBar = (props) => {
    const { onSubmit, onReload, onSave } = props;

    return (
        <div className="dx-card p-3 form-row d-flex" style={{ position: 'sticky', display: 'block', bottom: 0, zIndex: 999 }}>
            <div className="card-body form-row d-flex">
                <div className="d-flex col-md-4 justify-content-start">
                    <ActionButton text="Save Draft" onClick={onSave} />
                    <PrimaryButton text="Refresh" onClick={onReload} style={{ marginLeft: '20px' }} />
                </div>
                <div className="d-flex offset-md-5 col-md-3 justify-content-end">
                    <ActionButton
                        text="Submit"
                        onClick={onSubmit}
                    />
                </div>
            </div>
        </div>
    );
};

export default BottomActionBar;
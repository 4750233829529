import NumberBox from 'devextreme-react/number-box';

const DraftFormContentDataRow = (props) => {
    const {
        level = 1,
        description,
        priorYearValue,
        currentYearValue,
        onViewPDEDrillDown
    } = props;

    const expensesDescription = [
        'Employee Benefits Expense',
        'Depreciation Expense',
        'Amortisation Expense',
        'Repairs and Maintenance Expense',
        'Sales and Marketing Expense',
        'Other Expenses',
        'Finance Costs (Net)'
    ];

    const formatValue = (value) => {
        if (expensesDescription.includes(description) && value !== 0) {
            return -value;
        }
        return value;
    };

    return (
        <tr>
            <td className="align-middle" style={{ paddingLeft: `${10 + 20 * (level - 1)}px` }}>{description}</td>
            <td className="text-center">
                <button
                    className="border-0 bg-transparent"
                    onClick={() => onViewPDEDrillDown(true)}
                >
                    <NumberBox
                        className="text-center border-0 bg-transparent pe-auto"
                        value={formatValue(currentYearValue)}
                        format="#,##0.00;(#,##0.00)"
                        readOnly
                    />
                </button>
            </td>
            <td className="text-center">
                {(priorYearValue || priorYearValue === 0) &&
                    <button
                        className="border-0 bg-transparent"
                        onClick={() => onViewPDEDrillDown(false)}
                    >
                        <NumberBox
                            className="text-center border-0 bg-transparent pe-auto"
                            value={formatValue(priorYearValue)}
                            format="#,##0.00;(#,##0.00)"
                            readOnly
                        />
                    </button>
                }
            </td>
        </tr>
    );
};

export default DraftFormContentDataRow;
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { PrimaryButton } from 'components/StandardButton';
import StandardPopup from 'components/StandardPopup';

const DataChangeAcknowledgementDialog = (props) => {
    const { onCancel } = props;

    return (
        <StandardPopup
            width="500px"
            height="280px"
            visible
            onHiding={onCancel}
            showCloseButton
            title="Form C-S - Data Change"
        >
            <div className="row mb-0">
                <p className="mb-1">The occurrence is attributed to any of the following reasons:</p>
                <div className="ml-3">
                    <ol className="mb-3">
                        <li>Modifications in <b>Fixed Asset</b> data</li>
                        <li>Modifications in <b>Renovation Expense</b> data</li>
                        <li>Modifications in <b>Form C-S</b> for <b>Part B</b> and <b>Part C</b> data</li>
                    </ol>
                </div>
                <p className="mb-0">Update the form data by clicking the <b>"Recalculate Tax"</b> button.</p>
                <p>(Form C-S submission cannot proceed without recalculation.)</p>
            </div>
            <Toolbar>
                <Item
                    location="center"
                    render={() => (
                        <PrimaryButton
                            text="Yes, I am acknowledged."
                            onClick={onCancel}
                        />
                    )}
                />
            </Toolbar>
        </StandardPopup>
    );
};

export default DataChangeAcknowledgementDialog;
import CustomStore from 'devextreme/data/custom_store';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import { Column, Selection, Paging, Toolbar, Item } from 'devextreme-react/data-grid';
import { ToolbarItem } from 'devextreme-react/popup';
import { useEffect, useMemo, useRef, useState } from 'react';
import { PrimaryButton } from 'components/StandardButton';
import StandardDataGrid from 'components/StandardDataGrid';
import StandardPopup from 'components/StandardPopup';
import * as ApplicationUtils from 'utils/applicationUtils';
import * as AccountBookDataApi from '../api/accountBookDataApi';
import '../Home.scss';

const ImportFromCloudAccountingDialog = (props) => {
    const { onSubmit, onCancel } = props;
    const currentYA = ApplicationUtils.getYearOfAssessment();

    const [selectedAccountBook, setSelectedAccountBook] = useState(null);
    const [isCurrentYA, setIsCurrentYA] = useState(true);
    const yearOfAssessment = currentYA + (isCurrentYA ? 0 : 1);

    const dataGridRef = useRef();

    useEffect(() => {
        if (dataGridRef && dataGridRef.current) {
            dataGridRef.current.instance.clearSelection();
            setSelectedAccountBook(null);
        }
    }, [isCurrentYA]);

    const dataSource = useMemo(() => {
        let cache;
        return new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: async function () {
                if (!cache) cache = await AccountBookDataApi.getCloudAccountingAccountBookList(yearOfAssessment);
                return cache;
            }
        });
    }, [isCurrentYA]);

    return (
        <StandardPopup
            visible
            onHiding={onCancel}
            dragEnabled={false}
            showCloseButton
            showTitle
            fullScreen
            title="Synchronize Account Book Data from Cloud Accounting"
        >
            {selectedAccountBook && selectedAccountBook.existingAccountBookDataId &&
                <div className="alert alert-warning">
                    <div className="mb-2">
                        <p className="d-inline fw-bold">Warning!</p>
                        There is an existing dataset of Account Book "<p className="d-inline fw-bold">{selectedAccountBook.companyName}</p>" for <p className="d-inline fw-bold">YA {yearOfAssessment}</p> found.
                    </div>
                    <p className="mb-1">Synchronizing this account book will overwrite existing account book data and may result in following actions:</p>
                    <ul className="mb-0 fw-bold">
                        <li>Overwriting of Existing Account Book Data</li>
                        <li>Data such as "UEN", "Prior Year Start Date", "Current Year Start Date", "Fixed Asset" and "Renovation Expense" remains unchanged.</li>
                        <li>Removal of Annual Return / Form C-S Submission Draft</li>
                    </ul>
                    <div className="pt-3">Each page in other web browser tab requires refresh after synchronizing.</div>
                </div>
            }
            <div className="fw-bold px-0 mb-3">Which type of filing below is intended to submit?</div>
            <StandardDataGrid
                dataSource={dataSource}
                onEditorPreparing={e => {
                    if (e.type === 'selection' && e.parentType === 'headerRow') {
                        e.editorOptions.onValueChanged = () => { };
                    }
                }}
                onCellPrepared={e => {
                    if (e.column && e.column.type === 'selection') {
                        if (e.rowType === 'header' || (e.rowType === 'data' && e.data.invalidReason)) {
                            if (e.cellElement.children[0]) e.cellElement.children[0].style.visibility = 'hidden';
                        }
                    }
                }}
                onSelectionChanged={e => {
                    if (e.selectedRowsData.length > 1) {
                        if (e.selectedRowsData[1].invalidReason) {
                            e.component.selectRows([e.selectedRowsData[0].id], false);
                        } else {
                            e.component.selectRows([e.selectedRowsData[1].id], false);
                            setSelectedAccountBook({ ...e.selectedRowsData[1], ya: yearOfAssessment });
                        }
                    } else if (e.selectedRowsData.length) {
                        if (e.selectedRowsData[0].invalidReason) {
                            e.component.selectRows([], false);
                        } else {
                            e.component.selectRows([e.selectedRowsData[0].id], false);
                            setSelectedAccountBook({ ...e.selectedRowsData[0], ya: yearOfAssessment });
                        }
                    } else {
                        setSelectedAccountBook(null);
                    }
                }}
                ref={dataGridRef}
            >
                <Toolbar>
                    <Item
                        location="before"
                        locateInMenu="auto"
                    >
                        <CheckBox
                            text={`Annual Return ${currentYA - 1}`}
                            value={isCurrentYA}
                            onValueChanged={(e) => {
                                if (isCurrentYA !== e.value) setIsCurrentYA(e.value);
                            }}
                        />
                    </Item>
                    <Item
                        location="before"
                        locateInMenu="auto"
                    >
                        <CheckBox
                            text={`Form C-S YA ${currentYA}`}
                            value={isCurrentYA}
                            onValueChanged={(e) => {
                                if (isCurrentYA !== e.value) setIsCurrentYA(e.value);
                            }}
                        />
                    </Item>
                    <Item
                        location="before"
                        locateInMenu="auto"
                    >
                        <CheckBox
                            text={`Annual Return ${currentYA}`}
                            value={!isCurrentYA}
                            onValueChanged={(e) => {
                                if (isCurrentYA !== e.value) setIsCurrentYA(e.value);
                            }}
                        />
                    </Item>
                </Toolbar>
                <Paging enabled={false} />
                <Selection mode="multiple" showCheckBoxesMode="always" />
                <Column
                    fixed
                    fixedPosition="left"
                    type="selection"
                />
                <Column
                    dataField="accountBookId"
                    caption="Account Book ID"
                    dataType="string"
                />
                <Column
                    dataField="companyName"
                    caption="Company Name"
                    dataType="string"
                />
                <Column
                    dataField="countryName"
                    caption="Country"
                    dataType="string"
                />
                <Column
                    caption="Currency"
                    dataField="currencyCode"
                    dataType="string"
                />
                <Column
                    dataField="priorFiscalYear"
                    caption="Prior Fiscal Year"
                    dataType="string"
                />
                <Column
                    dataField="currentFiscalYear"
                    caption="Current Fiscal Year"
                    dataType="string"
                />
                <Column
                    dataField="invalidReason"
                    caption="Reason"
                    dataType="string"
                />
            </StandardDataGrid>
            <ToolbarItem
                toolbar="bottom"
                location="after"
                render={() => (
                    <Button
                        text="Cancel"
                        onClick={() => onCancel()}
                    />
                )}
            />
            <ToolbarItem
                toolbar="bottom"
                location="after"
                render={() => (
                    <PrimaryButton
                        text="Synchronize"
                        onClick={() => onSubmit(selectedAccountBook)}
                        disabled={selectedAccountBook === null || selectedAccountBook.filingCompleted}
                    />
                )}
            />
        </StandardPopup >
    );
};
export default ImportFromCloudAccountingDialog;
import { useMemo, useState, useRef } from 'react';
import StandardPopup from 'components/StandardPopup';
import { PrimaryButton } from 'components/StandardButton';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import CustomStore from 'devextreme/data/custom_store';
import { TagBox } from 'devextreme-react/tag-box';
import { TextBox } from 'devextreme-react/text-box';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { Validator, RequiredRule, EmailRule } from 'devextreme-react/validator';
import * as LookupApi from 'api/lookupApi';

const UserDialog = (props) => {
    const { initialData, onSubmit, onCancel } = props;

    const isNewRecord = !initialData.userId;

    const title = isNewRecord ? 'Invite User' : 'Edit User';
    const [data, setData] = useState(isNewRecord ? { emailAddress: null, hasFullAccess: false, accessibleCompanyList: [] } : initialData);

    const initialFocusRef = useRef();

    function handleSubmit(e) {
        e.preventDefault();
        onSubmit(initialData, data);
    }

    const dataSource = useMemo(() => new CustomStore({
        key: 'companyName',
        loadMode: 'raw',
        load: function () {
            return LookupApi.getOwnedCompany();
        }
    }), []);

    return (
        <StandardPopup
            visible
            onHiding={onCancel}
            dragEnabled={false}
            showCloseButton
            showTitle
            width={600}
            height="auto"
            title={title}
            onShown={() => {
                initialFocusRef.current.instance.focus();
            }}
        >
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label className="form-label required">Email Address</label>
                    <TextBox
                        value={data.emailAddress}
                        onValueChanged={e => setData({ ...data, emailAddress: e.value })}
                        readOnly={!!initialData.userId}
                        ref={initialFocusRef}
                    >
                        <Validator>
                            <RequiredRule message="Email address is required." />
                            <EmailRule message="Please enter valid email address." />
                        </Validator>
                    </TextBox>
                </div>

                <div className="mb-3">
                    <CheckBox
                        value={data.hasFullAccess}
                        onValueChanged={e => setData({ ...data, hasFullAccess: e.value })}
                        text="Can Access All Company"
                    />
                </div>

                <div className="mb-3">
                    <TagBox
                        value={data.accessibleCompanyList}
                        dataSource={dataSource}
                        displayExpr="companyName"
                        valueExpr="companyName"
                        onValueChanged={e => setData({ ...data, accessibleCompanyList: e.value })}
                        text="Accessible Company List"
                        disabled={data.hasFullAccess}
                        showClearButton
                    />
                </div>

                <Toolbar>
                    <Item
                        location="after"
                        render={() => (
                            <Button
                                text="Cancel"
                                onClick={() => onCancel()}
                            />
                        )}
                    />
                    <Item
                        location="after"
                        render={() => (
                            <PrimaryButton
                                text={isNewRecord ? 'Invite' : 'Save'}
                                useSubmitBehavior
                            />
                        )}
                    />
                </Toolbar>
            </form>
        </StandardPopup>
    );
};
export default UserDialog;
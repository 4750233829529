import { useEffect, useState, useRef } from 'react';
import { Column, Item, Toolbar } from 'devextreme-react/tree-list';
import NumberBox from 'devextreme-react/number-box';
import StandardTreeList from 'components/StandardTreeList';

const ProfitAndLossStatementView = (props) => {
    const { isCurrentYear, visible } = props;
    const [dataSource, setDataSource] = useState(null);
    const [netProfitAndLossAmount, setNetProfitAndLossAmount] = useState(0);
    const treeListRef = useRef();

    useEffect(() => {
        if (visible) {
            const { netProfitLossAmount, profitAndLossStatementDetailData } = isCurrentYear ? props.currentYearData : props.priorYearData;

            setDataSource(profitAndLossStatementDetailData);
            setNetProfitAndLossAmount(netProfitLossAmount);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, props.currentYearData, props.priorYearData, isCurrentYear]);

    function onCellPrepared(e) {
        if (e.rowType === 'data') {
            if (e.column.dataField === 'description') {
                if (e.data.isHeader) {
                    e.cellElement.style.textDecoration = 'underline';
                }

                if (e.data.isHeader || e.data.isFooter) {
                    e.cellElement.style.fontWeight = 'bold';
                }
            } else if (e.column.dataField.includes('balance')) {
                if (e.data.isFooter) {
                    e.cellElement.style.fontWeight = 'bold';
                }
            }
        }
    }

    return (
        <>
            <StandardTreeList
                dataSource={dataSource}
                keyExpr="key"
                parentIdExpr="parentKey"
                onCellPrepared={onCellPrepared}
                autoExpandAll
                visible={visible}
                ref={treeListRef}
            >
                <Column caption="Description" dataField="description" dataType="string" />
                <Column caption="Account Code" dataField="accNo" dataType="string" />
                <Column caption="Balance" dataField="balance" format="#,##0.00" dataType="number" />
                <Toolbar>
                    <Item
                        name="exportButton"
                    />
                    <Item
                        name="columnChooserButton"
                    />
                </Toolbar>
            </StandardTreeList>

            {visible &&
                <div className="form-group row align-items-right mt-3" style={{ textAlign: 'right' }}>
                    <label className="col-form-label offset-sm-6 offset-md-8 col-sm-4 col-md-2">
                        <b>Net Profit Amount</b>
                    </label>
                    <div className="col-2">
                        <NumberBox
                            className="number-align-right"
                            value={netProfitAndLossAmount}
                            format="#,##0.00"
                            readOnly
                        />
                    </div>
                </div>
            }
        </>
    );
};

export default ProfitAndLossStatementView;
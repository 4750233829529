import * as Api from 'utils/api';
import { buildApiUrl } from 'utils/session';

const basePath = 'accountMapping';

export function getAccountMapping(accountBookDataId) {
    let url = buildApiUrl(`${basePath}`, { accountBookDataId });
    return Api.getData(url);
}

export function updateAccountMapping(accountBookDataId, data) {
    let url = buildApiUrl(`${basePath}`, { accountBookDataId });
    return Api.putData(url, data).then(response => response.json());
}

export function importAccountMapping(accountBookDataId, data) {
    let url = buildApiUrl(`${basePath}/importAccountMapping`, { accountBookDataId });
    return Api.postData(url, data).then(response => response.json());
}

export function generateAutoAccountMapping(accountBookDataId) {
    let url = buildApiUrl(`${basePath}/generateAutoAccountMapping`, { accountBookDataId });
    return Api.getData(url);
}

export function getOnPremiseAccountMapping(accountBookDataId, mappingMasterId) {
    let url = buildApiUrl(`${basePath}/getOnPremiseAccountMapping`, { accountBookDataId, mappingMasterId });
    return Api.getData(url);
}
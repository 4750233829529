import CustomStore from 'devextreme/data/custom_store';
import DropDownButton, { Item } from 'devextreme-react/drop-down-button';
import { Button } from 'devextreme-react/button';
import { Column, Item as DataGridToolbarItem, Toolbar as DataGridToolbar } from 'devextreme-react/data-grid';
import { useMemo, useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import PageTitle from 'components/PageTitle';
import StandardDataGrid from 'components/StandardDataGrid';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as ApplicationSourceConstant from 'data/applicationSourceConstant';
import * as ErrorMessage from 'utils/errorMessage';
import * as MessageBox from 'utils/messageBox';
import * as PageUtils from 'utils/pageUtils';
import * as Toast from 'utils/toast';
import * as ApplicationApi from 'api/applicationApi';
import * as AccountBookDataApi from './api/accountBookDataApi';
import AccountBookDeleteDialog from './partials/AccountBookDataDeleteDialog';
import ImportCloudAccountingAccountBookDialog from './partials/ImportFromCloudAccountingDialog';
import ImportOnPremiseAccountBookDialog from './partials/ImportFromOnPremiseAccountingDialog';
import './Home.scss';

const HomePage = () => {
    const { accountBookData: contextAccountBookData, setAccountBookData: setContextAccountBookData } = useAccountBookData();
    const contextAccountBookDataId = contextAccountBookData && contextAccountBookData.accountBookDataId;
    const navigate = useNavigate();

    const [timestamp, setTimestamp] = useState(Date.now());
    const [accountBookData, setAccountBookData] = useState(false);
    const [showAccountBookDataDeleteDialog, setShowAccountBookDataDeleteDialog] = useState(false);
    const [showImportCloudAccountingAccountBookDialog, setShowImportCloudAccountingAccountBookDialog] = useState(false);
    const [showImportOnPremiseAccountBookDialog, setShowImportOnPremiseAccountBookDialog] = useState(false);

    const dataGridRef = useRef();

    const dataSource = useMemo(() => new CustomStore({
        key: 'accountBookDataId',
        loadMode: 'raw',
        load: function () {
            return AccountBookDataApi.getAccountBookDataList();
        }
    }), [timestamp]);

    useEffect(() => {
        PageUtils.blockPage();
        AccountBookDataApi.getPendingAcknowledgementImportAccountBookDataResultList()
            .then(result => {
                PageUtils.unblockPage();
                displayAccountBookDataImportStatus(result);
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    }, [timestamp]);

    function refreshData() {
        setTimestamp(Date.now());
    }

    function viewRecord(accountBookDataId) {
        PageUtils.blockPage();
        ApplicationApi.getAccountBookData(accountBookDataId)
            .then(result => {
                PageUtils.unblockPage();
                setContextAccountBookData(result);
                sessionStorage.setItem('accountBookData', JSON.stringify(result));
                navigate('/accountBookData');
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    }

    function importCloudAccountingAccountBookData(accountBook) {
        PageUtils.blockPage();
        AccountBookDataApi.importCloudAccountingAccountBookData(accountBook.id, accountBook.priorFiscalYear, accountBook.currentFiscalYear, accountBook.existingAccountBookDataId, accountBook.ya)
            .then((data) => {
                PageUtils.unblockPage();
                MessageBox.showMessage(`Your account book "${accountBook.companyName}" is now being synchronized from Cloud Accounting.`)
                    .then(() => {
                        PageUtils.blockPage('Synchronizing Account Book ...');

                        pollImportAccountBookStatus(accountBook.existingAccountBookDataId, data.accountBookDataId);
                    });
            }).catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.createError(error));
            });
    }

    function importOnPremiseAccountingAccountBookData(accountBook) {
        PageUtils.blockPage();

        AccountBookDataApi.importOnPremiseAccountingAccountBookData(accountBook.connectionId, accountBook.priorFiscalYear, accountBook.currentFiscalYear, accountBook.existingAccountBookDataId, accountBook.ya)
            .then(data => {
                PageUtils.unblockPage();

                MessageBox.showMessage(`Your account book "${accountBook.companyName}" is now being uploaded from On-Premise Accounting.`)
                    .then(() => {
                        PageUtils.blockPage('Synchronizing Account Book ...');

                        pollImportAccountBookStatus(accountBook.existingAccountBookDataId, data.accountBookDataId);
                    });
            }).catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.createError(error));
            });
    }

    function displayAccountBookDataImportStatus(accountBooks) {
        if (!accountBooks || accountBooks.length === 0) return;

        let message = '';

        if (accountBooks.length === 1) {
            let accountBook = accountBooks[0];
            if (accountBook.isPending) {
                message += `Account Book "${accountBooks[0].companyName}" is synchronizing from AutoCount ${accountBook.applicationSource}`;
            } else if (accountBook.isSuccess) {
                message += `Account Book "${accountBooks[0].companyName}" has been synchronized from AutoCount ${accountBook.applicationSource} successfully.`;
            } else {
                message += `Account Book "${accountBooks[0].companyName}" has failed to be synchronized from AutoCount ${accountBook.applicationSource}`;
            }
        } else {
            accountBooks.forEach((row, index) => {
                if (row.isPending) {
                    message += `${index + 1}. Account Book "${row.companyName}" is synchronizing from AutoCount ${row.applicationSource}\n`;
                } else if (row.isSuccess) {
                    message += `${index + 1}. Account Book "${row.companyName}" has been synchronized from AutoCount ${row.applicationSource} successfully.\n`;
                } else {
                    message += `${index + 1}. Account Book "${row.companyName}" has failed to be synchronized from AutoCount ${row.applicationSource}\n`;
                }
            });
        }

        MessageBox.showMessage(message)
            .then(() => {
                let accountBookIds = accountBooks.filter(x => !x.isPending).map(y => y.id);
                if (accountBookIds.length > 0) {
                    PageUtils.blockPage();

                    AccountBookDataApi.acknowledgeImportAccountBooksResult(accountBookIds)
                        .then(() => {
                            PageUtils.unblockPage();

                            let importCompleteAccountBooks = accountBooks.filter(x => !x.isPending && x.isSuccess).map(y => y.id);
                            if (importCompleteAccountBooks.length > 0) {
                                // redirect to setup for first upload success accountbook
                                viewRecord(importCompleteAccountBooks[0]);
                            }
                        })
                        .catch(error => {
                            PageUtils.unblockPage();
                            Toast.showErrorMessage(ErrorMessage.updateError(error));
                        });
                }
            });
    }

    function pollImportAccountBookStatus(existingAccountBookDataId, accountBookDataId) {
        AccountBookDataApi.pollImportAccountBookStatus(accountBookDataId)
            .then(data => {
                if (data.isSuccess) {
                    setShowImportOnPremiseAccountBookDialog(false);
                    PageUtils.unblockPage();
                    Toast.showSuccessMessage('Account Book data is synchronized successfully.');
                    displayAccountBookDataImportStatus([data]);
                } else {
                    if (data.applicationSource === ApplicationSourceConstant.CloudAccounting) {
                        MessageBox.showMessage(`Failed to synchronize Account Book "${data.companyName}", please try again. If this issue persists, please contact our support team.`);
                    } else {
                        if (data.isConnectionInactive) {
                            PageUtils.unblockPage();
                            MessageBox.showMessage(`Connection to the Account Book "${data.companyName}" is lost. Please press "OK" to resume synchronizing after the connection is established.`)
                                .then(() => {
                                    PageUtils.blockPage('Synchronizing Account Book ...');
                                    setTimeout(() => pollImportAccountBookStatus(existingAccountBookDataId, accountBookDataId), 3000);
                                });
                        } else {
                            // polling continuosly until upload is complete
                            setTimeout(() => pollImportAccountBookStatus(existingAccountBookDataId, accountBookDataId), 3000);
                        }
                    }
                }
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.createError(error));
            });
    }

    function deleteRecord(accountBookDataId) {
        PageUtils.blockPage();
        AccountBookDataApi.deleteAccountBookData(accountBookDataId)
            .then(() => {
                PageUtils.unblockPage();
                setShowAccountBookDataDeleteDialog(false);
                if (contextAccountBookDataId === accountBookDataId) {
                    setContextAccountBookData(null);
                    sessionStorage.removeItem('accountBookData');
                }
                refreshData();
                Toast.showSuccessMessage('Account Book Data is deleted successfully.');
            })
            .catch(error => {
                PageUtils.unblockPage();
                setShowAccountBookDataDeleteDialog(false);
                Toast.showErrorMessage(ErrorMessage.deleteError(error));
            });
    }

    return (
        <>
            <div className="content-block">
                <PageTitle title="Home" />
                <div className="px-3">
                    Please select a company to start filing.
                </div>
            </div>
            <div className="content-block">
                <div className="dx-card responsive-paddings">
                    <StandardDataGrid
                        dataSource={dataSource}
                        wordWrapEnabled
                        ref={dataGridRef}
                    >
                        <DataGridToolbar>
                            <DataGridToolbarItem
                                location="before"
                            >
                                <DropDownButton
                                    text="Synchronize Data"
                                    displayExpr="text"
                                    selectedItemKey={null}
                                    width={160}
                                    dropDownOptions={{ width: 290 }}
                                >
                                    <Item
                                        text="Synchronize from Cloud Accounting"
                                        onClick={() => setShowImportCloudAccountingAccountBookDialog(true)}
                                    />
                                    <Item
                                        text="Synchronize from On-Premise Accounting"
                                        onClick={() => setShowImportOnPremiseAccountBookDialog(true)}
                                    />
                                </DropDownButton>
                            </DataGridToolbarItem>
                            <DataGridToolbarItem name="searchPanel" />
                        </DataGridToolbar>
                        <Column
                            caption="Company Name"
                            dataField="companyName"
                            dataType="string"
                            allowEditing={false}
                            cellRender={(cellInfo) => {
                                return (
                                    <a
                                        className="btn btn-sm btn-link p-0 text-start"
                                        href={`/accountBookData?accountBookDataId=${cellInfo.row.data.accountBookDataId}`}
                                        onClick={e => {
                                            e.preventDefault();
                                            viewRecord(cellInfo.row.data.accountBookDataId);
                                        }}
                                    >
                                        {cellInfo.data.companyName}
                                    </a>
                                );
                            }}
                            sortIndex={1}
                            sortOrder="asc"
                        />
                        <Column caption="Assessment Year"
                            dataField="yearOfAssessment"
                            dataType="number"
                            allowEditing={false}
                            sortIndex={0}
                            sortOrder="desc"
                            width={150}
                        />
                        <Column
                            caption="UEN"
                            dataField="uen"
                            dataType="string"
                            width={110}
                        />
                        <Column
                            caption="Completed Filing"
                            dataType="string"
                            width={165}
                            allowEditing={false}
                            cellRender={(cellInfo) => {
                                return (
                                    <div className="d-flex">
                                        {cellInfo.data.formCsSubmittedTimestamp && !cellInfo.data.annualReturnSubmittedTimestamp && <div className="form-cs-badge">Form C-S</div>}
                                        {!cellInfo.data.formCsSubmittedTimestamp && cellInfo.data.annualReturnSubmittedTimestamp && <div className="annual-return-badge">Annual Return</div>}
                                        {cellInfo.data.formCsSubmittedTimestamp && cellInfo.data.annualReturnSubmittedTimestamp &&
                                            <>
                                                <div className="form-cs-badge" style={{ marginRight: 3 }} >Form C-S</div>
                                                <div className="annual-return-badge">Annual Return</div>
                                            </>
                                        }
                                    </div>
                                );
                            }}
                        />
                        <Column
                            allowEditing={false}
                            groupIndex={0}
                            calculateGroupValue={(rowData) => {
                                if (rowData.isOwner) {
                                    return 'Owned Company';
                                } else {
                                    return 'Invited Company';
                                }
                            }}
                            groupCellTemplate={(e, data) => {
                                e.append(data.value);
                            }}
                        />
                        <Column
                            type="buttons"
                            width={50}
                            fixed
                            fixedPosition="right"
                            alignment="left"
                            cellRender={(e) => {
                                return (
                                    <>
                                        <Button
                                            className="delete-button"
                                            height={20}
                                            width={50}
                                            onClick={() => {
                                                setAccountBookData(e.row.data);
                                                setShowAccountBookDataDeleteDialog(true);
                                            }}
                                            icon="trash"
                                            hint="Delete"
                                            visible={
                                                e.row.data.isOwner &&
                                                e.row.data.formCsSubmittedTimestamp === null &&
                                                e.row.data.annualReturnSubmittedTimestamp === null
                                            }
                                        />
                                    </>
                                );
                            }}
                        />
                    </StandardDataGrid>
                </div>
            </div>

            {showImportCloudAccountingAccountBookDialog &&
                <ImportCloudAccountingAccountBookDialog
                    onSubmit={importCloudAccountingAccountBookData}
                    onCancel={() => setShowImportCloudAccountingAccountBookDialog(false)}
                />
            }

            {showImportOnPremiseAccountBookDialog &&
                <ImportOnPremiseAccountBookDialog
                    onSubmit={importOnPremiseAccountingAccountBookData}
                    onCancel={() => setShowImportOnPremiseAccountBookDialog(false)}
                />
            }

            {showAccountBookDataDeleteDialog &&
                <AccountBookDeleteDialog
                    initialData={accountBookData}
                    onSubmit={deleteRecord}
                    onCancel={() => setShowAccountBookDataDeleteDialog(false)}
                />
            }
        </>
    );
};

export default HomePage;
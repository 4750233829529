import { Button } from 'devextreme-react/button';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { SelectBox } from 'devextreme-react/select-box';
import { useState } from 'react';
import { PrimaryButton } from 'components/StandardButton';
import StandardPopup from 'components/StandardPopup';
import '../AccountMappingMaintenance.scss';

const AccountMappingImportDialog = (props) => {
    const { onPremiseAccountMappingDataSource, changeOnPremiseAccountMappingData, selectedOnPremiseAccountMapping, onCancel } = props;

    const [onPremiseAccountMapping, setOnPremiseAccountMapping] = useState(selectedOnPremiseAccountMapping);

    return (
        <StandardPopup
            visible
            onHiding={onCancel}
            showCloseButton
            width={600}
            height="auto"
            title="Import On-Premise Account Mapping"
        >
            <div className="alert alert-warning">
                Account Mapping are imported from <b>On-Premise Accounting</b> which using Plugin Version 2.1.8.11 and before.
            </div>
            <div className="d-inline-block">
                <SelectBox
                    dataSource={onPremiseAccountMappingDataSource}
                    value={onPremiseAccountMapping}
                    displayExpr={itemData => itemData && itemData.mappingMastName}
                    onValueChanged={(e) => setOnPremiseAccountMapping(e.value)}
                    width="560px"
                />
            </div>
            <Toolbar
                className="mt-3"
            >
                <Item
                    location="after"
                    render={() => (
                        <Button
                            text="Cancel"
                            onClick={onCancel}
                        />
                    )}
                />
                <Item
                    location="after"
                    render={() => (
                        <PrimaryButton
                            text="Import"
                            onClick={() => {
                                changeOnPremiseAccountMappingData(onPremiseAccountMapping);
                                onCancel();
                            }}
                        />
                    )}
                />
            </Toolbar>
        </StandardPopup>
    );
};

export default AccountMappingImportDialog;
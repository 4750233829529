import React from 'react';
import { } from 'components/StandardButton';
import StandardPopup from 'components/StandardPopup';
import StandardDataGrid from 'components/StandardDataGrid';
import NumberBox from 'devextreme-react/number-box';
import { Button, Column, Sorting, Toolbar, MasterDetail } from 'devextreme-react/data-grid';
import { ToolbarItem } from 'devextreme-react/popup';
import CloseButton from 'devextreme-react/button';
import TextArea from 'devextreme-react/text-area';
import TextBox from 'devextreme-react/text-box';
import * as DateUtils from 'utils/dateUtils';

const FinancialStatementPDEDrillDownDialog = (props) => {
    const { data, entryData, onViewPDEDrillDown, onCancel } = props;
    const { currentYearStartDate, currentYearEndDate, priorYearStartDate, priorYearEndDate } = entryData;
    const title = data.isCurrentYearFinancialData ? `PDE Drill Down [(Current Financial Year (${DateUtils.formatDate(currentYearStartDate)} - ${DateUtils.formatDate(currentYearEndDate)})]` :
        `PDE Drill Down [Prior Financial Year (${DateUtils.formatDate(priorYearStartDate)} - ${DateUtils.formatDate(priorYearEndDate)})]`;

    const expensesDescription = [
        // Employee Benefits Expense
        'Employee Benefits Expense',
        'Director\'s Fee',
        'Director\'s Remuneration (excluding Director\'s Fees)',
        'CPF Contribution',
        'Medical Expenses (including Medical Insurance)',
        'Skills Development Levy/ Foreign Worker Levy',
        'Staff Remuneration (Other than the Director\'s Remuneration)',
        'Staff Welfare',
        'Commission (Other Than Expenses Incurred to Derive Rental Income)',

        // Depreciation Expense
        'Depreciation Expense',

        // Amortisation Expense
        'Amortisation Expense',

        // Repairs and Maintenance Expense
        'Repairs and Maintenance Expense',
        'Expenses Incurred to Derive Rental Income - Repair and Maintenance',
        'Repairs and Maintenance (excluding Private Motor Vehicles and Expense Incurred to Drive Rental Income)',
        'Repairs and Maintenance (Private Motor Vehicles)',

        // Sales and Marketing Expense
        'Sales and Marketing Expense',

        // Other Expenses
        'Other Expenses',
        'Cost of Goods Sold',
        'Donations',
        'Entertainment Expenses',
        'Expenses Incurred to Derive Rental Income - Commission',
        'Expenses Incurred to Derive Rental Income - Insurance',
        'Expenses Incurred to Derive Rental Income - Property Tax',
        'Expenses Incurred to Derive Rental Income - Others',
        'Fixed Assets Expensed Off',
        'Insurance (Other Than Medical Expenses and Expenses Incurred to Derive Rental Income)',
        'Miscellaneous Expenses',
        'Other Private/ Capital Expenses',
        'Penalties/ Fine',
        'Professional Fees',
        'Property Tax (Other than Expenses Incurred to Derive Rental Income)',
        'Rent Expense',
        'Telecommunication/ Utilities',
        'Training',
        'Transport/ Travelling Expenses',
        'Upkeep of Non-private Motor Vehicles',
        'Upkeep of Private Motor Vehicles',
        'Bank Charges',

        // Finance Costs
        'Finance Costs (Net)',
        'Expenses Incurred to Derive Rental Income - Interest',
        'Interest Expenses (Other Than Expenses Incurred to Derive Rental Income)',
        'Other Finance Cost',

        // Income Tax (Expense) Benefit from Continuing Operations
        'Income Tax (Expense) Benefit from Continuing Operations'
    ];

    const formatValue = (value) => {
        if ((expensesDescription.includes(data.description) || expensesDescription.includes(value.description)) && value !== 0) {
            return -value;
        }
        return value;
    };

    return (
        <StandardPopup
            visible
            showCloseButton
            showTitle
            title={title}
            position="center"
            width="1080px"
            height="auto"
            maxHeight="90%%"
            onHiding={onCancel}
        >
            <div className="form-row mb-3">
                <div className="col-md-12">
                    <label className="form-label fw-bolder">Description</label>
                    <TextBox
                        value={data.description}
                        readOnly
                    />
                </div>
            </div>

            {data.isAggregatePDE &&
                <div className="form-row mb-3">
                    <div className="col-md-12">
                        <label className="form-label fw-bolder">Formula</label>
                        <TextArea
                            value={data.formula}
                            height="120px"
                            readOnly
                        />
                    </div>
                </div>
            }

            {data.isPdeSupported ?
                <StandardDataGrid
                    className="mb-3"
                    dataSource={data.detailData}
                >
                    <Column
                        caption="Account No."
                        dataField="accNo"
                        dataType="string"
                        visible={!data.isAggregatePDE}
                    />
                    <Column
                        caption="Description"  
                        dataField="description"
                        dataType="string"
                    />
                    <Column
                        caption="Is Control Account"
                        dataField="isControlAccount"
                        dataType="boolean"
                        visible={data.hasControlAccount}
                    />
                    <Column
                        caption="Amount"
                        format="#,##0.00;(#,##0.00)"
                        minWidth={100}
                        dataType="number"
                        calculateCellValue={rowData => formatValue(rowData.amount)}
                    />
                    <Column
                        type="buttons"
                        width={80}
                        fixed
                        fixedPosition="right"
                        visible={data.isAggregatePDE}
                    >
                        <Button
                            text="View"
                            onClick={cellInfo => {
                                const { data: detailData } = cellInfo.row;

                                onViewPDEDrillDown({
                                    pdeDataId: detailData.pdeDataId,
                                    isCurrentYearFinancialData: data.isCurrentYearFinancialData,
                                    pdeDataCurrentType: data.pdeDataCurrentType
                                });
                            }}
                        />
                    </Column>
                    {data.hasControlAccount &&
                        <MasterDetail
                            enabled
                            render={(props) => {
                                const { controlAccountDetailsInJson, isControlAccount } = props.data;
                                const dataSource = controlAccountDetailsInJson ? JSON.parse(controlAccountDetailsInJson) : [];

                                return isControlAccount ? (
                                    <StandardDataGrid
                                        dataSource={dataSource}
                                    >
                                        <Column
                                            caption="Account No."
                                            dataField="accNo"
                                            dataType="string"
                                        />
                                        <Column
                                            caption="Description"
                                            dataField="description"
                                            dataType="string"
                                        />
                                        <Column
                                            caption="Amount"
                                            dataField="amount"
                                            format="#,##0.00"
                                            dataType="number"
                                        />
                                        <Sorting mode="none" />
                                        <Toolbar visible={false} />
                                    </StandardDataGrid>
                                ) : null;
                            }}
                        />
                    }
                    <Sorting mode="none" />
                    <Toolbar visible={false} />
                </StandardDataGrid> :
                <div className="content-block dx-card responsive-paddings d-flex justify-content-center mb-3">
                    <div className="pt-3 text-center">
                        <p className="text-danger fs-4">This PDE is not supported.</p>
                    </div>
                </div>
            }

            <div className="form-row d-flex mb-2">
                <div className="offset-6 col-3 justify-content-end align-items-center d-flex">
                    <label className="fw-bolder">Total Amount</label>
                </div>
                <div className="offset-1 col-2">
                    <NumberBox
                        className="text-right"
                        value={formatValue(data.totalAmount)}
                        format="#,##0.00;(#,##0.00)"
                        readOnly
                    />
                </div>
            </div>

            <ToolbarItem
                toolbar="bottom"
                location="after"
                locateInMenu="auto"
            >
                <CloseButton
                    text="Close"
                    onClick={onCancel}
                />
            </ToolbarItem>
        </StandardPopup>
    );
};

export default FinancialStatementPDEDrillDownDialog;
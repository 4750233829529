import {
    Page,
    StyleSheet,
    Text,
    View
} from '@react-pdf/renderer';

const CapitalAllowanceSchedulePdfView = (props) => {
    const { initialData } = props;
    let i = 0;

    const styles = StyleSheet.create({
        page: {
            padding: 40
        },
        header: {
            fontSize: 15,
            fontWeight: 1000,
            marginBottom: 10,
            display: 'table',
            width: 'auto',
            textDecoration: 'underline',
            textAlign: 'center'
        },
        alertText: {
            fontSize: 10,
            fontWeight: 'bold',
            marginBottom: 10
        },
        normalText: {
            fontSize: 8,
            margin: 5
        },
        table: {
            display: 'table',
            width: 'auto',
            borderStyle: 'solid',
            borderWidth: 1
        },
        tableHeader: {
            backgroundColor: '#21a84a',
            color: 'white',
            fontWeight: 1000,
            borderStyle: 'solid'
        },
        tableHeaderText: {
            fontSize: 10,
            margin: 5,
            fontWeight: 1000
        },
        footer: {
            position: 'absolute',
            fontSize: 8,
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'grey'
        }
    });

    const data = {
        hpDataSource: {
            additionsDuringTheYear: [...initialData.hpAdditionsDuringTheYear.hpOtherPPE],
            priorYearAdditions: [...initialData.hpPriorYearAdditions.hpOtherPPE],
            disposalsDuringTheYear: [...initialData.hpDisposalsDuringTheYear.hpOtherPPE]
        },

        nonHPDataSource: {
            additionsDuringTheYear: [
                ...initialData.nonHPAdditionsDuringTheYear.nonHPCompCommEquipment,
                ...initialData.nonHPAdditionsDuringTheYear.nonHpOtherPPE_LowValueAsset,
                ...initialData.nonHPAdditionsDuringTheYear.nonHpOtherPPE
            ],
            priorYearAdditions: [...initialData.nonHPPriorYearAdditions.nonHpOtherPPE],
            disposalsDuringTheYear: [
                ...initialData.nonHPDisposalsDuringTheYear.nonHPCompCommEquipment,
                ...initialData.nonHPDisposalsDuringTheYear.nonHpOtherPPE_LowValueAsset,
                ...initialData.nonHPDisposalsDuringTheYear.nonHpOtherPPE
            ]
        }
    };

    function calculateBalancingAllowanceOrBalancingChargeCellValue(rowData) {
        if (rowData.salesProceedEachAsset > rowData.taxWrittenDownValueBFEachAsset)
            return rowData.balancingChargeEachAssetDisposedOfOrWrittenOff.toFixed(2);
        else
            return rowData.balancingAllowanceEachAssetDisposedOfOrWrittenOff.toFixed(2);
    }

    const generateNonHirePurchaseAssetsRow = (data) => {
        return (
            <View key={++i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', borderTopWidth: 1, borderStyle: 'solid' }}>
                <View style={[styles.normalText, { width: '20%', marginRight: 10 }]}>
                    <Text>{data.descriptionEachAsset}</Text>
                </View>
                <View style={[styles.normalText, { width: '13%', alignItems: 'flex-end' }]}>
                    <Text>{data.yaOfPurchaseEachAsset}</Text>
                </View>
                <View style={[styles.normalText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                    <Text>{data.costEachAsset.toFixed(2)}</Text>
                </View>
                <View style={[styles.normalText, { width: '15%', alignItems: 'flex-end', marginLeft: 5 }]}>
                    <Text>{data.noOfYearsOfWorkingLifeBFEachAsset}</Text>
                </View>
                <View style={[styles.normalText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                    <Text>{data.taxWrittenDownValueBFEachAsset ? data.taxWrittenDownValueBFEachAsset.toFixed(2) : ''}</Text>
                </View>
                <View style={[styles.normalText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                    <Text>{data.annualAllowanceEachAsset.toFixed(2)}</Text>
                </View>
                <View style={[styles.normalText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                    <Text>{data.taxWrittenDownValueCFEachAsset ? data.taxWrittenDownValueCFEachAsset.toFixed(2) : ''}</Text>
                </View>
            </View>
        );
    };

    const generateNonHirePurchaseDisposalDuringTheYearRow = (data) => {
        return (
            <View key={++i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <View style={[styles.normalText, { width: '15%', marginRight: 10 }]}>
                    <Text>{data.descriptionEachAsset}</Text>
                </View>
                <View style={[styles.normalText, { width: '13%', alignItems: 'flex-end' }]}>
                    <Text>{data.yaOfPurchaseEachAsset}</Text>
                </View>
                <View style={[styles.normalText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                    <Text>{Number(data.costEachAsset).toFixed(2)}</Text>
                </View>
                <View style={[styles.normalText, { width: '15%', alignItems: 'flex-end', marginLeft: 5 }]}>
                    <Text>{data.noOfYearsOfWorkingLifeBFEachAsset}</Text>
                </View>
                <View style={[styles.normalText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                    <Text>{data.taxWrittenDownValueBFEachAsset ? data.taxWrittenDownValueBFEachAsset.toFixed(2) : ''}</Text>
                </View>
                <View style={[styles.normalText, { width: '15%', alignItems: 'flex-end', marginLeft: 5 }]}>
                    <Text>{data.yaOfDisposalEachAsset}</Text>
                </View>
                <View style={[styles.normalText, { width: '12%', alignItems: 'flex-end', marginLeft: 5 }]}>
                    <Text>{data.salesProceedEachAsset.toFixed(2)}</Text>
                </View>
                <View style={[styles.normalText, { width: '18%', alignItems: 'flex-end', marginLeft: 5 }]}>
                    <Text>{calculateBalancingAllowanceOrBalancingChargeCellValue(data)}</Text>
                </View>
            </View>
        );
    };

    const generateHirePurchaseAssetsRow = (data) => {
        return (
            <View key={++i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <View style={[styles.normalText, { width: '20%'}]}>
                    <Text>{data.descriptionEachAsset}</Text>
                </View>
                <View style={[styles.normalText, { width: '10%', alignItems: 'flex-end' }]}>
                    <Text>{data.yaOfPurchaseEachAsset}</Text>
                </View>
                <View style={[styles.normalText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                    <Text>{data.costEachAsset.toFixed(2)}</Text>
                </View>
                <View style={[styles.normalText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                    <Text>{data.taxWrittenDownValueBFEachAsset ? data.taxWrittenDownValueBFEachAsset.toFixed(2) : ''}</Text>
                </View>
                <View style={[styles.normalText, { width: '10%', alignItems: 'flex-end', marginLeft: 5 }]}>
                    <Text>{data.annualAllowanceEachAsset.toFixed(2)}</Text>
                </View>
                <View style={[styles.normalText, { width: '12%', alignItems: 'flex-end', marginLeft: 5 }]}>
                    <Text>{data.taxWrittenDownValueCFEachAsset ? data.taxWrittenDownValueCFEachAsset.toFixed(2) : ''}</Text>
                </View>
                <View style={[styles.normalText, { width: '16%', alignItems: 'flex-end', marginLeft: 5 }]}>
                    <Text>{data.depositOrPrincipalExcludingInterestIncludingDownpaymentEachAsset.toFixed(2)}</Text>
                </View>
            </View>
        );
    };

    const generateHirePurchaseDisposalDuringTheYearRow = (data) => {
        return (
            <View key={++i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', borderTopWidth: 1, borderStyle: 'solid' }}>
                <View style={[styles.normalText, { width: '15%' }]}>
                    <Text>{data.descriptionEachAsset}</Text>
                </View>
                <View style={[styles.normalText, { width: '13%', alignItems: 'flex-end' }]}>
                    <Text>{data.yaOfPurchaseEachAsset}</Text>
                </View>
                <View style={[styles.normalText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                    <Text>{data.costEachAsset.toFixed(2)}</Text>
                </View>
                <View style={[styles.normalText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                    <Text>{data.taxWrittenDownValueBFEachAsset ? data.taxWrittenDownValueBFEachAsset.toFixed(2) : ''}</Text>
                </View>
                <View style={[styles.normalText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                    <Text>{data.yaOfDisposalEachAsset}</Text>
                </View>
                <View style={[styles.normalText, { width: '15%', alignItems: 'flex-end', marginLeft: 5 }]}>
                    <Text>{data.salesProceedEachAsset.toFixed(2)}</Text>
                </View>
                <View style={[styles.normalText, { width: '18%', alignItems: 'flex-end', marginLeft: 5 }]}>
                    <Text>{calculateBalancingAllowanceOrBalancingChargeCellValue(data)}</Text>
                </View>
            </View>
        );
    };

    const emptyRow = () => {
        return (
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <View style={[styles.normalText, { marginTop: 20, marginBottom: 20 }]}>
                    <Text>No Data</Text>
                </View>
            </View>
        );
    };

    return (
        <>
            <Page size="A4" style={styles.page} orientation="landscape">
                <View style={styles.container}>
                    <View style={styles.header}>
                        <Text>Capital Allowance Schedule - Non-Hire-Purchase Assets</Text>
                    </View>
                </View>
                <Text style={styles.alertText}>Additions during the Year</Text>
                <View style={styles.table}>
                    <View style={[styles.tableHeader, { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }]}>
                        <View style={[styles.tableHeaderText, { width: '20%', marginRight: 10 }]}>
                            <Text>Description</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '13%', alignItems: 'flex-end' }]}>
                            <Text>YA of Purchase</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Cost (S$)</Text>                                                                
                        </View>                                                                                   
                        <View style={[styles.tableHeaderText, { width: '15%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>No. of Years of Working Life B/F Tax</Text>                                     
                        </View>                                                                                   
                        <View style={[styles.tableHeaderText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Tax Written Down Value B/F (S$)</Text>                                          
                        </View>                                                                                   
                        <View style={[styles.tableHeaderText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Annual Allowance-AA (S$)</Text>                                                 
                        </View>                                                                                   
                        <View style={[styles.tableHeaderText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Tax Written Down Value C/F (S$)</Text>
                        </View>
                    </View>
                    {
                        data.nonHPDataSource.additionsDuringTheYear.length !== 0
                            ? data.nonHPDataSource.additionsDuringTheYear.map(data => generateNonHirePurchaseAssetsRow(data))
                            : emptyRow()
                    }
                </View>
                <Text style={[styles.alertText, { marginTop: 20 }]}>Prior Year Additions</Text>
                <View style={styles.table}>
                    <View style={[styles.tableHeader, { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }]}>
                        <View style={[styles.tableHeaderText, { width: '20%' }]}>
                            <Text>Description</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>YA of Purchase</Text>                                             
                        </View> 
                        <View style={[styles.tableHeaderText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Cost (S$)</Text>
                        </View> 
                        <View style={[styles.tableHeaderText, { width: '15%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>No. of Years of Working Life B/F Tax</Text> 
                        </View>
                        <View style={[styles.tableHeaderText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Tax Written Down Value B/F (S$)</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Annual Allowance-AA (S$)</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Tax Written Down Value C/F (S$)</Text>
                        </View>
                    </View>
                    {
                        data.nonHPDataSource.priorYearAdditions.length !== 0
                            ? data.nonHPDataSource.priorYearAdditions.map(data => generateNonHirePurchaseAssetsRow(data))
                            : emptyRow()
                    }
                </View>
                <Text style={[styles.alertText, { marginTop: 20 }]}>Disposals during the Year</Text>
                <View style={styles.table}>
                    <View style={[styles.tableHeader, { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }]}>
                        <View style={[styles.tableHeaderText, { width: '15%', marginRight: 10 }]}>
                            <Text>Description</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '13%', alignItems: 'flex-end' }]}>
                            <Text>YA of Purchase</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Cost (S$)</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '15%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>No. of Years of Working Life B/F Tax</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Tax Written Down Value B/F (S$)</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '15%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>YA of Disposal</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '12%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Sales Proceed (S$)</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '18%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Balancing Allowance (BA) / Balancing Charge (BC) (S$)</Text>
                        </View>
                    </View>
                    {
                        data.nonHPDataSource.disposalsDuringTheYear.length !== 0
                            ? data.nonHPDataSource.disposalsDuringTheYear.map(data => generateNonHirePurchaseDisposalDuringTheYearRow(data))
                            : emptyRow()
                    }
                </View>
                <Text style={styles.footer} fixed>In order to enhance the viewing experience, this page is optimized for landscape orientation</Text>
            </Page>

            <Page size="A4" style={styles.page} orientation="landscape">
                <View style={styles.container}>
                    <View style={styles.header}>
                        <Text>Capital Allowance Schedule - Hire-Purchase Assets</Text>
                    </View>
                </View>
                <Text style={styles.alertText}>Additions during the Year</Text>
                <View style={styles.table}>
                    <View style={[styles.tableHeader, { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }]}>
                        <View style={[styles.tableHeaderText, { width: '20%' }]}>
                            <Text>Description</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '10%', alignItems: 'flex-end' }]}>
                            <Text>YA of Purchase</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Cost (S$)</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Tax Written Down Value B/F (S$)</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '10%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Annual Allowance-AA (S$)</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '12%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Tax Written Down Value C/F (S$)</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '16%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Deposit/ Principal Paid During the Year (excluding Interest, including Down Payment) (S$)</Text>
                        </View>
                    </View>
                    {
                        data.hpDataSource.additionsDuringTheYear.length !== 0
                            ? data.hpDataSource.additionsDuringTheYear.map(data => generateHirePurchaseAssetsRow(data))
                            : emptyRow()
                    }
                </View>
                <Text style={[styles.alertText, { marginTop: 20 }]}>Prior Year Additions</Text>
                <View style={styles.table}>
                    <View style={[styles.tableHeader, { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }]}>
                        <View style={[styles.tableHeaderText, { width: '20%' }]}>
                            <Text>Description</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '10%', alignItems: 'flex-end' }]}>
                            <Text>YA of Purchase</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Cost (S$)</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Tax Written Down Value B/F (S$)</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '10%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Annual Allowance-AA (S$)</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '12%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Tax Written Down Value C/F (S$)</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '16%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Deposit/ Principal Paid During the Year (excluding Interest, including Down Payment) (S$)</Text>
                        </View>
                    </View>
                    {
                        data.hpDataSource.priorYearAdditions.length !== 0
                            ? data.hpDataSource.priorYearAdditions.map(data => generateHirePurchaseAssetsRow(data))
                            : emptyRow()
                    }
                </View>
                <Text style={[styles.alertText, { marginTop: 20 }]}>Disposals during the Year</Text>
                <View style={styles.table}>
                    <View style={[styles.tableHeader, { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }]}>
                        <View style={[styles.tableHeaderText, { width: '15%' }]}>
                            <Text>Description</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '13%', alignItems: 'flex-end' }]}>
                            <Text>YA of Purchase</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Cost (S$)</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Tax Written Down Value B/F (S$)</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '13%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>YA of Disposal</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '15%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Sales Proceed (S$)</Text>
                        </View>
                        <View style={[styles.tableHeaderText, { width: '18%', alignItems: 'flex-end', marginLeft: 5 }]}>
                            <Text>Balancing Allowance (BA) / Balancing Charge (BC) (S$)</Text>
                        </View>
                    </View>
                    {
                        data.hpDataSource.disposalsDuringTheYear.length !== 0
                            ? data.hpDataSource.disposalsDuringTheYear.map(data => generateHirePurchaseDisposalDuringTheYearRow(data))
                            : emptyRow()
                    }
                </View>
                <Text style={styles.footer} fixed>In order to enhance the viewing experience, this page is optimized for landscape orientation</Text>
            </Page>
        </>
    );
};

export default CapitalAllowanceSchedulePdfView;
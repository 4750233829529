import LoadPanel from 'devextreme/ui/load_panel';

const element = document.getElementById('page-block');
let loadPanelCount = 0;

const ui = new LoadPanel(element, {
    shading: true,
    showPane: true,
    shadingColor: 'rgba(255, 255, 255, 0.8)',
    wrapperAttr: { class: 'page-block' }
});

export function blockPage(message = 'Loading...') {
    loadPanelCount += 1;
    ui.option('message', message);

    if (loadPanelCount > 0) ui.show();
}

export function unblockPage() {
    loadPanelCount -= 1;
    if (loadPanelCount <= 0) ui.hide();
}
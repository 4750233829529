import ContextMenu, { Item as ContextMenuItem, Position } from 'devextreme-react/context-menu';
import List, { Item as ListItem } from 'devextreme-react/list';
import { useAuth } from 'contexts/AuthContext';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import './UserPanel.scss';

export default function UserPanel({ menuMode }) {
    const { user, signOut } = useAuth();

    return (
        <div className="user-panel">
            <div className="user-info">
                <div className="user-name">{user.name}</div>
                <img src="/images/user.png" alt="user-icon" />
                <div className="menu-indicator" ><i className="dx-icon-chevrondown" /></div>
            </div>

            {menuMode === 'context' && (
                <ContextMenu
                    target=".user-button .dx-button"
                    position={{ my: 'top right', at: 'bottom right' }}
                    showEvent="dxclick"
                    cssClass="user-menu"
                >
                    <ContextMenuItem
                        render={() => (
                            <>
                                <i className="dx-icon dx-icon-help" />
                                <span className="dx-menu-item-text">Help</span>
                                <ArrowTopRightOnSquareIcon className="dx-icon" />
                            </>
                        )}
                        onClick={() => window.open('/help', '_blank')}
                    />
                    <ContextMenuItem
                        text="Logout"
                        icon="runner"
                        onClick={() => signOut()}
                    />
                    <Position my="top center" at="bottom center" />
                </ContextMenu>
            )}

            {menuMode === 'list' && (
                <List className="dx-toolbar-menu-action" >
                    <ListItem
                        render={() => (
                            <div>
                                <ArrowTopRightOnSquareIcon />
                                <span className="dx-menu-item-text">FAQ</span>
                            </div>
                        )}
                    />
                    <ListItem
                        text="Logout"
                        icon="runner"
                        onClick={() => signOut()}
                    />
                </List>
            )}
        </div>
    );
}

import StandardDataGrid from 'components/StandardDataGrid';
import StandardDateBox from 'components/StandardDateBox';
import { Column, SearchPanel } from 'devextreme-react/data-grid';
import TextBox from 'devextreme-react/text-box';
import NumberBox from 'devextreme-react/number-box';

const PaymentResultView = (props) => {
    const { data } = props;

    return (
        <div>
            <div className="dx-card dx-theme-accent-as-background-color p-2 mb-3">
                <span className="fs-5 text-white">Enquire Payment Result</span>
            </div>

            <div className="form-row d-flex mb-3">
                <div className="col-7">
                    <label className="form-label fw-bold">Paid By</label>
                    <TextBox
                        value={data.paidBy}
                        readOnly
                    />
                </div>
                <div className="offset-1 col-3">
                    <label className="form-label fw-bold">Payment Date</label>
                    <StandardDateBox
                        value={data.dateTime}
                        type="datetime"
                        readOnly
                    />
                </div>
            </div>

            <div className="form-row d-flex mb-3">
                <div className="col-7">
                    <label className="form-label fw-bold">Paid Via</label>
                    <TextBox
                        value={data.paidVia}
                        readOnly
                    />
                </div>
                <div className="offset-1 col-3">
                    <label className="form-label fw-bold">ACRA Payment Ref No.</label>
                    <TextBox
                        value={data.arnNo}
                        readOnly
                    />
                </div>
            </div>

            <div className="form-row d-flex mb-3">
                <div className="col-3">
                    <label className="form-label fw-bold">Receipt Number</label>
                    <TextBox
                        value={data.receiptNumber}
                        readOnly
                    />
                </div>
                <div className="offset-1 col-3">
                    <label className="form-label fw-bold">EP Reference No.</label>
                    <TextBox
                        value={data.epReferenceNo}
                        readOnly
                    />
                </div>
                <div className="offset-1 col-3">
                    <label className="form-label fw-bold">GST Registration No.</label>
                    <TextBox
                        value={data.gstRegistrationNo}
                        readOnly
                    />
                </div>
            </div>

            <StandardDataGrid
                dataSource={data.transactionDetails}
                className="mb-3"
                wordWrapEnabled
            >
                <Column
                    caption="Transaction No."
                    dataField="transactionNo"
                    dataType="string"
                />
                <Column
                    caption="Entity Name"
                    dataField="entityName"
                    dataType="string"
                    visible={false}
                />
                <Column
                    caption="UEN"
                    dataField="uen"
                    dataType="string"
                    visible={false}
                />
                <Column
                    caption="Date"
                    dataField="date"
                    dataType="date"
                />
                <Column
                    caption="Description"
                    dataField="description"
                    dataType="string"
                />
                <Column
                    caption="Status"
                    dataField="status"
                    dataType="string"
                    visible={false}
                />
                <Column
                    caption="Amount"
                    dataField="amount"
                    alignment="right"
                    dataType="number"
                    format="#0.00"
                />
                <SearchPanel visible={false} />
            </StandardDataGrid>

            <div className="form-row d-flex mb-2">
                <div className="offset-6 col-3 justify-content-end align-items-center d-flex">
                    <span>GST Amount</span>
                </div>
                <div className="offset-1 col-2">
                    <NumberBox
                        className="text-right"
                        value={parseFloat(data.gstAmount, 2)}
                        format="#,##0.00"
                        readOnly
                    />
                </div>
            </div>

            <div className="form-row d-flex mb-3">
                <div className="offset-6 col-3 justify-content-end align-items-center d-flex">
                    <span>Total Amount</span>
                </div>
                <div className="offset-1 col-2">
                    <NumberBox
                        className="text-right"
                        value={parseFloat(data.totalAmount, 2)}
                        format="#,##0.00"
                        readOnly
                    />
                </div>
            </div>
        </div>
    );
};

export default PaymentResultView;
import DraftFormContentHeaderRow from '../components/DraftFormContentHeaderRow';
import DraftFormContentDataRow from '../components/DraftFormContentDataRow';
import DraftFormContentFooterRow from '../components/DraftFormContentFooterRow';

const FinancialStatementXBRLFormProfitLossStatementView = (props) => {
    const { initialData, onViewPDEDrillDown } = props;

    const dataRows = [
        { isHeader: true, description: 'Profit (Loss)' },
        { dataId: 'totalRevenue' },
        { dataId: 'totalOtherIncome' },
        { dataId: 'employeeBenefitsExpense' },
        { dataId: 'depreciationExpense' },
        { dataId: 'amortisationExpense' },
        { dataId: 'repairsAndMaintenanceExpense' },
        { dataId: 'salesAndMarketingExpense' },
        { dataId: 'otherExpenses' },
        { dataId: 'otherGainsLosses' },
        { dataId: 'financeCosts' },
        { dataId: 'shareOfProfitLossOfAssociatesAndJointVenturesAccountedForUsingEquityMethod' },
        { dataId: 'profitLossBeforeTaxation', isFooter: true, enableTopBorder: true },
        { dataId: 'taxExpenseBenefitContinuingOperations', isFooter: true },
        { dataId: 'profitLossFromDiscontinuedOperations', isFooter: true, enableBottomBorder: true },
        { dataId: 'profitLoss', isFooter: true, enableBottomBorder: true },
        { isHeader: true, description: 'Profit (Loss), Attributable To' },
        { dataId: 'profitLossAttributableToOwnersOfCompany' },
        { dataId: 'profitLossAttributableToNoncontrollingInterests' }
    ];

    const hasPriorYearFinancialData = initialData.xbrlData.priorFinancialYear;

    return (
        <>
            <DraftFormContentHeaderRow description="Statement of Profit or Loss" level={1} />

            {dataRows.map((item, index) => {
                const pde = initialData.pdeList.find(x => x.dataId === item.dataId);
                const pdeDescription = pde && pde.description;

                const priorYearValue = hasPriorYearFinancialData && initialData.xbrlData.priorFinancialYear.profitLoss[item.dataId];
                const currentYearValue = initialData.xbrlData.currentFinancialYear.profitLoss[item.dataId];

                if (item.isHeader) {
                    return (
                        <DraftFormContentHeaderRow
                            key={index}
                            description={item.description}
                            level={2}
                        />
                    );
                } else if (item.isFooter) {
                    return (
                        <DraftFormContentFooterRow
                            key={index}
                            description={pdeDescription}
                            priorYearValue={priorYearValue}
                            currentYearValue={currentYearValue}
                            enableTopBorder={item.enableTopBorder}
                            enableBottomBorder={item.enableBottomBorder}
                            onViewPDEDrillDown={(isCurrentYear) => onViewPDEDrillDown({ pdeDataId: item.dataId, isCurrentYearFinancialData: isCurrentYear })}
                        />
                    );
                }

                return (
                    <DraftFormContentDataRow
                        key={index}
                        level={2}
                        description={pdeDescription}
                        priorYearValue={priorYearValue}
                        currentYearValue={currentYearValue}
                        onViewPDEDrillDown={(isCurrentYear) => onViewPDEDrillDown({ pdeDataId: item.dataId, isCurrentYearFinancialData: isCurrentYear })}
                    />
                );
            })}
        </>
    );
};

export default FinancialStatementXBRLFormProfitLossStatementView;
import StandardDataGrid from 'components/StandardDataGrid';
import { Paging, Scrolling, Sorting, Toolbar } from 'devextreme-react/data-grid';

const FinancialScheduleGrid = (props) => {
    const { dataSource, children, ...rest } = props;

    return (
        <StandardDataGrid
            dataSource={dataSource}
            showRowLines={false}
            showColumnLines={false}
            allowColumnReordering={false}
            wordWrapEnabled
            {...rest}
        >
            <Scrolling mode="virtual" />
            <Toolbar visible={false} />
            <Paging enabled={false} />
            <Sorting mode="none" />
            {children}
        </StandardDataGrid>
    );
};

export default FinancialScheduleGrid;
import CustomStore from 'devextreme/data/custom_store';
import { Button } from 'devextreme-react/button';
import { Column, Item as DataGridToolbarItem, Toolbar as DataGridToolbar } from 'devextreme-react/data-grid';
import { useMemo, useRef, useState } from 'react';
import PageTitle from 'components/PageTitle';
import { PrimaryButton } from 'components/StandardButton';
import StandardDataGrid from 'components/StandardDataGrid';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as ErrorMessage from 'utils/errorMessage';
import * as PageUtils from 'utils/pageUtils';
import * as Toast from 'utils/toast';
import * as ApplicationApi from 'api/applicationApi';
import * as AccountBookDataApi from './api/accountBookDataApi';
import AccountBookDeleteDialog from './partials/DeleteAccountBookDataDialog';
import CreateAccountBookDataDialog from './partials/CreateAccountBookDataDialog';
import AnnualReturnDialog from './partials/AnnualReturnDialog';
import './Page.scss';

const Page = () => {
    const { accountBookData: contextAccountBookData, setAccountBookData: setContextAccountBookData } = useAccountBookData();
    const contextAccountBookDataId = contextAccountBookData && contextAccountBookData.accountBookDataId;

    const [accountBookData, setAccountBookData] = useState(false);
    const [showAccountBookDataDeleteDialog, setShowAccountBookDataDeleteDialog] = useState(false);
    const [showCreateAccountBookDataDialog, setShowCreateAccountBookDataDialog] = useState(false);
    const [showAnnualReturnDialog, setShowAnnualReturnDialog] = useState(false);

    const dataGridRef = useRef();

    const dataSource = useMemo(() => new CustomStore({
        key: 'accountBookDataId',
        loadMode: 'raw',
        load: function () {
            return AccountBookDataApi.getEntryList();
        }
    }), []);

    function viewRecord(accountBookDataId) {
        PageUtils.blockPage();
        ApplicationApi.getAccountBookData(accountBookDataId)
            .then(result => {
                PageUtils.unblockPage();
                setContextAccountBookData(result);
                sessionStorage.setItem('accountBookData', JSON.stringify(result));
                setShowAnnualReturnDialog(true);
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    }

    function createAccountBookData(accountBookData) {
        PageUtils.blockPage();
        AccountBookDataApi.createAccountBookData(accountBookData)
            .then(result => {
                PageUtils.unblockPage();
                setShowCreateAccountBookDataDialog(false);

                dataGridRef.current.instance.refresh();
                setContextAccountBookData(result);
                sessionStorage.setItem('accountBookData', JSON.stringify(result));
                setShowAnnualReturnDialog(true);
            }).catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.createError(error));
            });
    }

    function deleteRecord(accountBookDataId) {
        PageUtils.blockPage();
        AccountBookDataApi.deleteAccountBookData(accountBookDataId)
            .then(() => {
                PageUtils.unblockPage();
                setShowAccountBookDataDeleteDialog(false);
                if (contextAccountBookDataId === accountBookDataId) {
                    setContextAccountBookData(null);
                    sessionStorage.removeItem('accountBookData');
                }
                dataGridRef.current.instance.refresh();
                Toast.showSuccessMessage('Account Book Data is deleted successfully.');
            })
            .catch(error => {
                PageUtils.unblockPage();
                setShowAccountBookDataDeleteDialog(false);
                Toast.showErrorMessage(ErrorMessage.deleteError(error));
            });
    }

    return (
        <>
            <div className="content-block">
                <PageTitle title="Annual Return" />
            </div>
            <div className="content-block">
                <div className="dx-card responsive-paddings">
                    <StandardDataGrid
                        dataSource={dataSource}
                        wordWrapEnabled
                        ref={dataGridRef}
                    >
                        <DataGridToolbar>
                            <DataGridToolbarItem
                                location="before"
                            >
                                <PrimaryButton
                                    text="New Entry for Annual Return"
                                    onClick={() => setShowCreateAccountBookDataDialog(true)}
                                />
                            </DataGridToolbarItem>
                            <DataGridToolbarItem name="searchPanel" />
                        </DataGridToolbar>
                        <Column
                            caption="Company Name"
                            dataField="companyName"
                            dataType="string"
                            allowEditing={false}
                            cellRender={(cellInfo) => {
                                return (
                                    <a
                                        className="btn btn-sm btn-link p-0 text-start"
                                        onClick={() => viewRecord(cellInfo.row.data.accountBookDataId)}
                                    >
                                        {cellInfo.data.companyName}
                                    </a>
                                );
                            }}
                            sortIndex={1}
                            sortOrder="asc"
                        />
                        <Column caption="Year"
                            dataType="number"
                            allowEditing={false}
                            sortIndex={0}
                            sortOrder="desc"
                            width={150}
                            calculateCellValue={e => e.yearOfAssessment - 1}
                        />
                        <Column
                            caption="UEN"
                            dataField="uen"
                            dataType="string"
                            width={110}
                        />
                        <Column
                            caption="Completed Filing"
                            dataType="string"
                            width={165}
                            allowEditing={false}
                            cellRender={(cellInfo) => {
                                return (
                                    <div className="d-flex">
                                        {cellInfo.data.annualReturnSubmittedTimestamp && <div className="annual-return-badge">Annual Return</div>}
                                    </div>
                                );
                            }}
                        />
                        <Column
                            type="buttons"
                            width={50}
                            fixed
                            fixedPosition="right"
                            alignment="left"
                            cellRender={(e) => {
                                return (
                                    <>
                                        <Button
                                            className="delete-button"
                                            height={20}
                                            width={50}
                                            onClick={() => {
                                                setAccountBookData(e.row.data);
                                                setShowAccountBookDataDeleteDialog(true);
                                            }}
                                            icon="trash"
                                            hint="Delete"
                                            visible={
                                                e.row.data.isOwner &&
                                                e.row.data.formCsSubmittedTimestamp === null &&
                                                e.row.data.annualReturnSubmittedTimestamp === null
                                            }
                                        />
                                    </>
                                );
                            }}
                        />
                    </StandardDataGrid>
                </div>
            </div>

            {showCreateAccountBookDataDialog &&
                <CreateAccountBookDataDialog
                    onSubmit={createAccountBookData}
                    onCancel={() => setShowCreateAccountBookDataDialog(false)}
                />
            }

            {showAccountBookDataDeleteDialog &&
                <AccountBookDeleteDialog
                    initialData={accountBookData}
                    onSubmit={deleteRecord}
                    onCancel={() => setShowAccountBookDataDeleteDialog(false)}
                />
            }

            {showAnnualReturnDialog &&
                <AnnualReturnDialog
                    onCancel={() => setShowAnnualReturnDialog(false)}
                />
            }
        </>
    );
};

export default Page;
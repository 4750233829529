import { Button } from 'devextreme-react/button';
import { TextBox } from 'devextreme-react/text-box';
import { ToolbarItem } from 'devextreme-react/popup';
import { NumberBox } from 'devextreme-react/number-box';
import { TextArea } from 'devextreme-react/text-area';
import { Validator, RequiredRule, RangeRule } from 'devextreme-react/validator';
import { useState } from 'react';
import { SubmitButton } from 'components/StandardButton';
import StandardDateBox from 'components/StandardDateBox';
import StandardPopup from 'components/StandardPopup';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as AccountBookDataStatusConstant from 'data/accountBookDataStatusConstant';

const RenovationExpenseDataDialog = (props) => {
    const { initialData, onSubmit, onCancel } = props;

    const { accountBookData } = useAccountBookData();
    const readOnly = accountBookData.stepList.some(x => x === AccountBookDataStatusConstant.CompletedFormCS);
    const yearOfAssessment = accountBookData.yearOfAssessment;

    const [data, setData] = useState({
        transactionDate: new Date(),
        yearOfAssessment: yearOfAssessment,
        deductionPeriod: 3,
        amount: 0,
        ...initialData
    });

    function handleSubmit(e) {
        onSubmit(data);
        e.preventDefault();
    }

    return (
        <StandardPopup
            visible
            onHiding={onCancel}
            dragEnabled={false}
            width={1000}
            height={600}
            title="Renovation Expense Entry"
        >
            <form id="renovationExpenseForm" onSubmit={handleSubmit}>
                <div className="content-block responsive-paddings pt-0">
                    <div className="row">
                        <div className="col-6 mb-3">
                            <label className="form-label">Transaction Date</label>
                            <StandardDateBox
                                value={data.transactionDate}
                                onValueChanged={e => setData({ ...data, transactionDate: e.value })}
                                type="date"
                                readOnly={readOnly}
                            />
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">Year of Assessment</label>
                            <NumberBox
                                value={data.yearOfAssessment}
                                onValueChanged={e => setData({ ...data, yearOfAssessment: e.value })}
                                readOnly={readOnly}
                            >
                                <Validator>
                                    <RangeRule
                                        min={yearOfAssessment - 4}
                                        max={yearOfAssessment}
                                        message={`Year of Assessment must be between ${yearOfAssessment - 4} and ${yearOfAssessment}`}
                                    />
                                </Validator>
                            </NumberBox>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <label className="form-label">Description</label>
                            <TextBox
                                value={data.description}
                                onValueChanged={e => setData({ ...data, description: e.value })}
                                maxLength={100}
                                readOnly={readOnly}
                            >
                                <Validator>
                                    <RequiredRule message="Description is required" />
                                </Validator>
                            </TextBox>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 mb-3">
                            <label className="form-label">Amount</label>
                            <NumberBox
                                value={data.amount}
                                onValueChanged={e => setData({ ...data, amount: e.value })}
                                format="#0.00"
                                readOnly={readOnly}
                            >
                                <Validator>
                                    <RangeRule
                                        min={0}
                                        message="Amount must not be negative"
                                    />
                                </Validator>
                            </NumberBox>
                        </div>
                        <div className="col-6 mb-3">
                            <label className="form-label">Deduction Period</label>
                            <NumberBox
                                value={data.deductionPeriod}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <label className="form-label">Remark</label>
                            <TextArea
                                minHeight="150px"
                                value={data.remark}
                                onValueChanged={e => setData({ ...data, remark: e.value })}
                                maxLength={500}
                                readOnly={readOnly}
                            />
                        </div>
                    </div>
                </div >
            </form>
            <ToolbarItem
                toolbar="bottom"
                location="after"
                render={() => (
                    <Button
                        text={readOnly ? 'Close' : 'Cancel'}
                        onClick={() => onCancel()}
                    />
                )}
            />
            <ToolbarItem
                toolbar="bottom"
                location="after"
                visible={!readOnly}
                render={() => (
                    <SubmitButton
                        text="Save"
                        submitForm="renovationExpenseForm"
                    />
                )}
            />
        </StandardPopup >
    );
};

export default RenovationExpenseDataDialog;
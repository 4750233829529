import React, { useRef } from 'react';
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import Validator from 'devextreme-react/validator';
import * as AnnualReturnApi from 'pages/AnnualReturn/api/annualReturnApi';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as ErrorMessage from 'utils/errorMessage';
import * as PageUtils from 'utils/pageUtils';
import * as Toast from 'utils/toast';

const FileBox = (props) => {
    const { accountBookData } = useAccountBookData();
    const accountBookDataId = accountBookData && accountBookData.accountBookDataId;

    const { value, maxFileSize, acceptedFileType, allowDownload = true, allowClear = true, handleFormDataChange: onChange, validationRules = null, readOnly } = props;
    const data = value ? value : {
        documentName: '',
        documentId: null
    };

    const fileInput = useRef();

    function onFileChanged() {
        let files = fileInput.current.files;
        const reader = new FileReader();
        if (files && files[0]) {
            let file = files[0];

            if (file.size <= maxFileSize) {
                if (file.type === acceptedFileType) {
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        PageUtils.blockPage();
                        const fileName = file.name;
                        const fileContent = reader.result.replace('data:', '').replace(/^.+,/, '');

                        AnnualReturnApi.uploadAttachment(accountBookDataId, { documentName: fileName, documentContent: fileContent })
                            .then(documentId => {
                                PageUtils.unblockPage();

                                onChange({
                                    documentName: fileName,
                                    documentId: documentId
                                });
                            })
                            .catch(error => {
                                PageUtils.unblockPage();
                                Toast.showErrorMessage(ErrorMessage.fetchError(error));
                                fileInput.current.value = '';
                            });
                    };
                } else {
                    fileInput.current.value = '';
                    Toast.showErrorMessage('Unable to upload file as file type is invalid.');
                }
            } else {
                fileInput.current.value = '';
                Toast.showErrorMessage('Unable to upload file as file size has exceeded upload file size.');
            }
        }
    }

    function clearFile() {
        onChange(null);
        fileInput.current.value = '';
    }

    function downloadFile() {
        if (data && data.documentId) {
            AnnualReturnApi.getAttachmentDownloadUrl(accountBookDataId, data)
                .then(result => {
                    window.open(result, '_blank', 'noopener');
                })
                .catch(error => {
                    Toast.showErrorMessage(error);
                });
        } else {
            Toast.showErrorMessage('Unable to download empty file.');
        }
    }

    return (
        <div>
            <TextBox
                value={data.documentName ? data.documentName : ''}
                readOnly
            >
                <Validator validationRules={validationRules} />
            </TextBox>
            {maxFileSize &&
                <div className="form-text mb-2">Note: Max file size ({maxFileSize / 1000000} MB)</div>
            }
            <div>
                <Button
                    className="btn position-relative shadow-sm px-2"
                    visible={!readOnly}
                >
                    <span>Browse</span>
                    <input
                        className="position-absolute w-100 h-100 opacity-0 top-0 right-0"
                        type="file"
                        accept={acceptedFileType}
                        onChange={onFileChanged}
                        ref={fileInput}
                    />
                </Button>
                {allowClear && data.documentId &&
                    <Button
                        className="btn position-relative shadow-sm px-2 ms-1"
                        onClick={clearFile}
                        visible={!readOnly}
                    >
                        <span>Clear</span>
                    </Button>
                }
                {allowDownload && data.documentId &&
                    <Button
                        className={readOnly ? 'btn position-relative shadow-sm px-2' : 'btn position-relative shadow-sm px-2 ms-1'}
                        onClick={downloadFile}
                    >
                        <span>Download</span>
                    </Button>
                }
            </div>
        </div>
    );
};

export default FileBox;
import { Accordion, Item } from 'devextreme-react/accordion';
import validationEngine from 'devextreme/ui/validation_engine';
import { useEffect, useRef, useState } from 'react';
import { PrimaryButton } from 'components/StandardButton';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import useIrasAuth from 'hooks/useIrasAuth';
import * as ErrorMessage from 'utils/errorMessage';
import * as PageUtils from 'utils/pageUtils';
import * as Toast from 'utils/toast';
import * as FormCSApi from '../../api/formCsApi';
import ErrorMessageView from '../ErrorMessageView';
import DeclarationView from './DeclarationView';
import InformationView from './InformationView';
import TaxAdjustmentView from './TaxAdjustmentView';

const FormCSInitialView = (props) => {
    const { initialData, loadData } = props;

    const { accountBookData } = useAccountBookData();
    const accountBookDataId = accountBookData && accountBookData.accountBookDataId;

    const { startAuth } = useIrasAuth();

    const [formData, setFormData] = useState(initialData.formDraft);
    const [formErrors, setFormErrors] = useState([]);

    const validatorErrors = useRef([]);

    function handleFormDataChange(value, dataIsModified = true) {
        setFormData(prevState => ({ ...prevState, ...value, dataIsModified }));
    }

    function handleFilingInfoDataChange(value, dataIsModified = true) {
        setFormData(prevState => ({ ...prevState, filingInfo: { ...prevState.filingInfo, ...value }, dataIsModified }));
    }

    function handleTaxConversionDataChange(value, dataIsModified = true) {
        setFormData(prevState => ({ ...prevState, taxConversion: { ...prevState.taxConversion, ...value }, dataIsModified }));
    }

    function handleDraftDataChange(value, dataIsModified = true) {
        setFormData(prevState => ({ ...prevState, dataFormCS: { ...prevState.dataFormCS, ...value }, dataIsModified }));
    }

    useEffect(() => {
        setFormData(initialData.formDraft);

        // when switching account book data
        const validationGroup = validationEngine.getGroupConfig('initialView');
        if (validationGroup) validationEngine.resetGroup('initialView');

        setFormErrors([]);
        validatorErrors.current = [];
    }, [initialData.formDraft]);

    function scrollToTop() {
        const element = document.querySelector('.content');
        element.scrollIntoView();
    }

    function generateDraft() {
        const validationResult = validationEngine.validateGroup('initialView');

        if (validationResult.isValid) {
            PageUtils.blockPage();
            FormCSApi.generateDraft(accountBookDataId, formData)
                .then(result => {
                    PageUtils.unblockPage();
                    if (result.requireAuth) {
                        startAuth(result.authUrl, generateDraft, { accountBookDataId });
                    } else if (result) {
                        if (result.isSuccess) {
                            loadData();
                        } else {
                            setFormErrors([...result.errors]);
                            validatorErrors.current = result.errors;

                            scrollToTop();
                        }
                    }
                })
                .catch(error => {
                    PageUtils.unblockPage();
                    Toast.showErrorMessage(ErrorMessage.createError(error));
                });
        } else {
            const errors = validationResult.brokenRules.map((err) => ({ message: err.message }));

            setFormErrors(errors);
            validatorErrors.current = errors;

            scrollToTop();
        }
    }

    return (
        <div className="dx-card responsive-paddings mb-3">
            <ErrorMessageView errors={formErrors} />
            <Accordion
                onItemRendered={(e) => {
                    const items = e.component.option('items');
                    e.component.option('selectedItems', items);
                }}
                keyExpr="text"
                collapsible
                multiple
            >
                <Item
                    text="1"
                    title="Information"
                >
                    <InformationView
                        formData={formData}
                        handleFormDataChange={handleFormDataChange}
                        handleFilingInfoDataChange={handleFilingInfoDataChange}
                        handleTaxConversionDataChange={handleTaxConversionDataChange}
                        handleDraftDataChange={handleDraftDataChange}
                        validatorErrors={validatorErrors.current}
                    />
                </Item>
                <Item
                    text="2"
                    title="Tax Adjustment"
                >
                    <TaxAdjustmentView
                        formData={formData}
                        handleTaxConversionDataChange={handleTaxConversionDataChange}
                        handleDraftDataChange={handleDraftDataChange}
                        validatorErrors={validatorErrors.current}
                    />
                </Item>
                <Item
                    text="3"
                    title="Declaration"
                >
                    <DeclarationView
                        formData={formData}
                        handleFormDataChange={handleFormDataChange}
                    />
                </Item>
            </Accordion>
            {formData.declaration.isQualifiedToUseConvFormCS &&
                <div className="mt-2">
                    <PrimaryButton
                        text="Generate Form C-S"
                        onClick={generateDraft}
                    />
                </div>
            }
        </div>
    );
};

export default FormCSInitialView;
import { Column, Toolbar } from 'devextreme-react/data-grid';
import StandardDataGrid from 'components/StandardDataGrid';

const ShareholderDetailsDataGrid = (props) => {
    const { shares } = props.data;

    return (
        <StandardDataGrid
            dataSource={shares}
        >
            <Column
                dataField="classOfShare"
                caption="Class Of Share"
                dataType="string"
            />
            <Column
                dataField="sharesHeld"
                caption="No. Shares Held"
                alignment="left"
                dataType="number"
            />
            <Toolbar visible={false} />
        </StandardDataGrid>
    );
};

export default ShareholderDetailsDataGrid;
import * as Api from 'utils/api';
import { buildApiUrl } from 'utils/session';

const basePath = 'accountBookData';

export function getAccountBookDataList() {
    let url = buildApiUrl(`${basePath}/getAccountBookDataList`);
    return Api.getData(url);
}

export function getAccountBookData(accountBookDataId) {
    let url = buildApiUrl(`${basePath}/getAccountBookData`, { accountBookDataId });
    return Api.getData(url);
}

export function updateAccountBookData(accountBookDataId, data) {
    let url = buildApiUrl(`${basePath}/updateAccountBookData`, { accountBookDataId });
    return Api.putData(url, data);
}

export function getCloudAccountingAccountBookList(yearOfAssessment) {
    let url = buildApiUrl(`${basePath}/getCloudAccountingAccountBookList`, { yearOfAssessment });
    return Api.getData(url);
}

export function getOnPremiseAccountingAccountBookList(yearOfAssessment) {
    let url = buildApiUrl(`${basePath}/getOnPremiseAccountingAccountBookList`, { yearOfAssessment });
    return Api.getData(url);
}

export function importCloudAccountingAccountBookData(accountBookId, priorFiscalYear, currentFiscalYear, existingAccountBookDataId, yearOfAssessment) {
    let url = buildApiUrl(`${basePath}/importCloudAccountingAccountBookData`);
    return Api.postData(url, { accountBookId, priorFiscalYear, currentFiscalYear, existingAccountBookDataId, yearOfAssessment }).then(response => response.json());
}

export function importOnPremiseAccountingAccountBookData(connectionId, priorFiscalYear, currentFiscalYear, existingAccountBookDataId, yearOfAssessment) {
    let url = buildApiUrl(`${basePath}/importOnPremiseAccountingAccountBookData`);
    return Api.postData(url, { connectionId, priorFiscalYear, currentFiscalYear, existingAccountBookDataId, yearOfAssessment }).then(response => response.json());
}

export function getPendingAcknowledgementImportAccountBookDataResultList() {
    let url = buildApiUrl(`${basePath}/getPendingAcknowledgementImportAccountBookDataResultList`);
    return Api.getData(url);
}

export function acknowledgeImportAccountBooksResult(accountBookDataIds) {
    let url = buildApiUrl(`${basePath}/acknowledgeImportAccountBooksResult`);
    return Api.postData(url, { accountBookDataIds });
}

export function pollImportAccountBookStatus(accountBookDataId) {
    let url = buildApiUrl(`${basePath}/pollImportAccountBookStatus`);
    return Api.postData(url, { accountBookDataId }).then(response => response.json());
}

export function deleteAccountBookData(accountBookDataId) {
    let url = buildApiUrl(`${basePath}/deleteAccountBookData`, { accountBookDataId });
    return Api.deleteData(url);
}
import * as DateUtils from 'utils/dateUtils';
import FinancialStatementData_StatementOfFinancialPositionStatementView from './components/FinancialStatementData_StatementOfFinancialPositionStatementView';
import FinancialStatementData_ProfitLossStatementView from './components/FinancialStatementData_ProfitLossStatementView';
import FinancialStatementData_RevenueView from './components/FinancialStatementData_RevenueView';
import FinancialStatementData_TradeAndOtherPayablesView from './components/FinancialStatementData_TradeAndOtherPayablesView';
import FinancialStatementData_TradeAndOtherReceivablesView from './components/FinancialStatementData_TradeAndOtherReceivablesView';
import * as FinancialStatementStatus from '../../data/financialStatementStatus';

const FormContentView = (props) => {
    const { initialData, onViewPDEDrillDown } = props;
    const { status } = initialData;
    const { currentFinancialYear, priorFinancialYear } = initialData.xbrlData;

    let currentFinancialYearStartDate = DateUtils.formatDate(currentFinancialYear.periodStartDate);
    let currentFinancialYearEndDate = DateUtils.formatDate(currentFinancialYear.periodEndDate);
    let priorFinancialYearStartDate = priorFinancialYear !== null ? DateUtils.formatDate(priorFinancialYear.periodStartDate) : null;
    let priorFinancialYearEndDate = priorFinancialYear !== null ? DateUtils.formatDate(priorFinancialYear.periodEndDate) : null;

    return (
        <>
            {status === FinancialStatementStatus.AcknowledgeComplete &&
                <div className="alert alert-info">
                    The following Financial Statement XBRL has already been confirmed and uploaded to ACRA. Please proceed to file the financial statement as part of Annual Return
                </div>
            }
            <div className="dx-card">
                <table className="table table-hover table-borderless">
                    <thead>
                        <tr>
                            <th />
                            <th className="text-center align-middle">
                                Current Financial Year<br />({currentFinancialYearStartDate} - {currentFinancialYearEndDate})
                            </th>
                            <th className="text-center align-middle">
                                Prior Financial Year<br />
                                {priorFinancialYear ? `(${priorFinancialYearStartDate} - ${priorFinancialYearEndDate})` : '(N/A)'}
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <FinancialStatementData_StatementOfFinancialPositionStatementView
                            initialData={initialData}
                            onViewPDEDrillDown={onViewPDEDrillDown}
                        />

                        <FinancialStatementData_ProfitLossStatementView
                            initialData={initialData}
                            onViewPDEDrillDown={onViewPDEDrillDown}
                        />

                        <FinancialStatementData_TradeAndOtherReceivablesView
                            initialData={initialData}
                            onViewPDEDrillDown={onViewPDEDrillDown}
                        />

                        <FinancialStatementData_TradeAndOtherPayablesView
                            initialData={initialData}
                            onViewPDEDrillDown={onViewPDEDrillDown}
                        />

                        <FinancialStatementData_RevenueView
                            initialData={initialData}
                            onViewPDEDrillDown={onViewPDEDrillDown}
                        />
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default FormContentView;
import validationEngine from 'devextreme/ui/validation_engine';
import { MultiView, Item as ViewItem } from 'devextreme-react/multi-view';
import Tabs, { Item as TabItem } from 'devextreme-react/tabs';
import React, { useEffect, useRef, useState } from 'react';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import useAcraAuth from 'hooks/useAcraAuth';
import * as ErrorMessage from 'utils/errorMessage';
import * as MessageBox from 'utils/messageBox';
import * as PageUtils from 'utils/pageUtils';
import * as Toast from 'utils/toast';
import * as AnnualReturnApi from '../../api/annualReturnApi';
import * as annualReturnStatusConstant from '../../data/annualReturnStatusConstant';
import BottomActionBar from './components/BottomActionBar';
import AnnualReturnDetailsView from './AnnualReturnDetailsView';
import AnnualReturnProfileView from '../AnnualReturnProfileView/AnnualReturnProfileView';
import CompanyDetailsView from './CompanyDetailsView';
import ValidationErrorMessageView from '../ValidationErrorMessageView';

const FormTab_AnnualReturnDetails = 'Annual Return Details';
const FormTab_CompanyDetails = 'Company Details';

const AnnualReturnFormView = (props) => {
    const { initialData, loadData } = props;

    const { accountBookData } = useAccountBookData();
    const accountBookDataId = accountBookData && accountBookData.accountBookDataId;
    const { startAuth } = useAcraAuth();

    const [formData, setFormData] = useState(initialData.annualReturnFormDraft?.formData ? initialData.annualReturnFormDraft?.formData : generateEmptyFormDraftData());

    const [status, setStatus] = useState();
    const [validationErrorData, setValidationErrorData] = useState({ hasValidationError: false, validationErrors: null });

    const annualReturnDetailsViewRef = useRef();
    const companyDetailsViewRef = useRef();

    const multiViewTabsRef = useRef();
    const multiViewRef = useRef();

    useEffect(() => {
        setStatus(initialData.status);
    }, [initialData.status]);

    useEffect(() => {
        setFormData(initialData.annualReturnFormDraft?.formData ? initialData.annualReturnFormDraft?.formData : generateEmptyFormDraftData());
    }, [initialData.annualReturnFormDraft]);

    function generateEmptyFormDraftData() {
        return {
            agmCopyPDF: {
                documentName: '',
                documentId: null
            },
            agmCopyContinuationPDF: {
                documentName: '',
                documentId: null
            },
            agmFinancialStatementDate: null,
            auditorRegistrationNo: null,
            auditorReportDate: null,
            auditorReportEmphasisMatter: null,
            auditorReportModifiedOpinion: null,
            declareInfoAccurateUpToDate: false,
            director1IdNo: null,
            director2IdNo: null,
            entityActiveDormant: null,
            entitySolvency: null,
            entityType: null,
            financialStatementSentDate: null,
            financialStatementType: null,
            isDormantExemptAudit: false,
            isDormantExemptFinancialStatement: false,
            isEntityAGMRequired: false,
            isFinancialStatementAudited: false,
            isListedSecurityExchangeAtFinancial: null,
            isListedSecurityExchangeAtLodge: null,
            isSmallCompanyExemptAudit: false,
            publicAccountingEntityNo: null,
            registerControllersKept: null,
            registerNomineeDirectorsKept: null,
            verifiedFrom: null,
            xbrlKey: null,
            xbrlNature: null,
            isXbrlKeyRequired: false
        };
    }

    function handleFormDataChange(value, dataIsModified = true) {
        setFormData(prevState => ({ ...prevState, ...value, dataIsModified }));
    }

    function setAnnualReturnDetailsTab() {
        multiViewRef.current.instance.option('selectedIndex', 0);
        multiViewTabsRef.current.instance.option('selectedIndex', 0);
    }

    function setCompanyDetailsTab() {
        multiViewRef.current.instance.option('selectedIndex', 1);
        multiViewTabsRef.current.instance.option('selectedIndex', 1);
    }

    function handlePreSubmitFilingFormAction() {
        const validationResult = validationEngine.validateGroup();

        if (!validationResult.isValid) {
            annualReturnDetailsViewRef.current.expandView();
            companyDetailsViewRef.current.expandView();

            // scroll to first invalid field with error message
            let element = document.querySelector('.dx-validator.dx-invalid');
            if (element) {
                let detailsTabElement = document.querySelector('.detailsTab .dx-validator.dx-invalid');
                let companyInfoTabElement = document.querySelector('.companyInfoTab .dx-validator.dx-invalid');

                if (detailsTabElement) {
                    // validation message is in details Tab
                    setAnnualReturnDetailsTab();
                } else if (companyInfoTabElement) {
                    // validation message is in companyInfo Tab
                    setCompanyDetailsTab();
                }
                let prevElement = element.previousElementSibling;
                if (prevElement) {
                    // scroll to previous element to include label when scrolling into view
                    prevElement.scrollIntoView();
                } else {
                    element.scrollIntoView();
                }
                element.classList.add('dx-state-focused');
            }
        }

        return validationResult.isValid;
    }

    function handleAnnualReturnFormSubmit() {
        const isValid = handlePreSubmitFilingFormAction();
        if (!isValid) return;

        if (formData.isXbrlKeyRequired && formData.xbrlKey === null) {
            Toast.showErrorMessage('Please select one of the financial information in XBRL Format under Section B: Financial Information in Annual Return Details.');

            setAnnualReturnDetailsTab();
            let element = document.querySelector('#xbrlFinancialStatementList');
            element.scrollIntoView();
        } else {
            MessageBox.showConfirmDangerMessage('Are you sure you want to submit this Annual Return?', null, 'Yes', 'No')
                .then(result => {
                    if (result.confirm) {
                        submitAnnualReturnFormData();
                    }
                });
        }
    }

    function reloadAnnualReturnProfileData() {
        PageUtils.blockPage();

        AnnualReturnApi.reloadAnnualReturnForm(accountBookDataId)
            .then(result => {
                PageUtils.unblockPage();

                if (result.requireAuth) {
                    startAuth(result.authUrl, reloadAnnualReturnProfileData, null);
                } else {
                    if (result.isSuccess) {
                        loadData();
                        setValidationErrorData({ hasValidationError: false, validationErrors: null });
                    } else {
                        Toast.showWarningMessage('There are validation errors returned by ACRA, please check them and then re-submit the form.');
                        setValidationErrorData({ hasValidationError: true, validationErrors: result.validationErrors });
                    }
                }
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    }

    function handlePostSubmitAnnualReturnProfileData() {
        loadData();
        setValidationErrorData({ hasValidationError: false, validationErrors: null });
    }

    function saveAnnualReturnFormData() {
        PageUtils.blockPage();
        AnnualReturnApi.saveAnnualReturnFilingFormData(accountBookDataId, formData)
            .then(result => {
                PageUtils.unblockPage();

                if (result.data) {
                    loadData();
                    setValidationErrorData({ hasValidationError: false, validationErrors: null });
                    Toast.showSuccessMessage('Annual Return is updated successfully.');
                }
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    }

    function submitAnnualReturnFormData() {
        PageUtils.blockPage();

        AnnualReturnApi.confirmAnnualReturn(accountBookDataId, formData)
            .then(result => {
                if (result.requireAuth) {
                    PageUtils.unblockPage();
                    startAuth(result.authUrl, submitAnnualReturnFormData, null);
                } else {
                    PageUtils.unblockPage();
                    if (result.isSuccess) {
                        loadData();
                        setValidationErrorData({ hasValidationError: false, validationErrors: null });
                    } else {
                        Toast.showWarningMessage('There are validation errors returned by ACRA, please check them and then re-submit the form.');
                        setValidationErrorData({ hasValidationError: true, validationErrors: result.validationErrors });
                    }
                }
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    }

    return initialData ? (
        <>
            <div className="dx-card responsive-paddings">
                {status !== annualReturnStatusConstant.PaymentCompleted &&
                    <ValidationErrorMessageView
                        validationErrorData={validationErrorData}
                        setValidationErrorData={setValidationErrorData}
                    />
                }
                {status === annualReturnStatusConstant.FormInitiated &&
                    <>
                        <AnnualReturnProfileView
                            initialData={initialData.profileData.local}
                            onSubmitPostAction={handlePostSubmitAnnualReturnProfileData}
                        />
                    </>
                }

                {status === annualReturnStatusConstant.FormPending &&
                    <>
                        <Tabs
                            className="mb-2"
                            keyExpr="text"
                            defaultSelectedIndex={0}
                            scrollByContent
                            showNavButtons
                            ref={multiViewTabsRef}
                        >
                            <TabItem
                                text={FormTab_AnnualReturnDetails}
                                onClick={() => setAnnualReturnDetailsTab()}
                            />
                            <TabItem
                                text={FormTab_CompanyDetails}
                                onClick={() => setCompanyDetailsTab()}
                            />
                        </Tabs>
                        <MultiView
                            deferRendering={false}
                            swipeEnabled={false}
                            ref={multiViewRef}
                        >
                            <ViewItem
                                render={() => (
                                    <AnnualReturnDetailsView
                                        initialData={initialData}
                                        formData={formData}
                                        handleFormDataChange={handleFormDataChange}
                                        ref={annualReturnDetailsViewRef}
                                    />
                                )}
                            />
                            <ViewItem
                                render={() => (
                                    <CompanyDetailsView
                                        profileData={initialData.profileData}
                                        formData={formData}
                                        handleFormDataChange={handleFormDataChange}
                                        ref={companyDetailsViewRef}
                                    />
                                )}
                            />
                        </MultiView>
                    </>
                }
            </div>
            {status === annualReturnStatusConstant.FormPending &&
                <BottomActionBar
                    onSubmit={() => handleAnnualReturnFormSubmit()}
                    onReload={() => reloadAnnualReturnProfileData()}
                    onSave={() => saveAnnualReturnFormData()}
                />
            }
        </>
    ) : <></>;
};

export default AnnualReturnFormView;
import React from 'react';
import Button from 'devextreme-react/button';
import { Template } from 'devextreme-react/core/template';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useNavigate } from 'react-router';
import { formPageNavigation, filingDataPageNavigation } from 'app-navigation';
import UserPanel from '../UserPanel/UserPanel';
import './Header.scss';

export default function Header({ menuToggleEnabled, toggleMenu, currentPath, accountBookData }) {
    const navigate = useNavigate();
    const showCompanyInfo = formPageNavigation.some(x => x.path === currentPath) || filingDataPageNavigation.some(x => x.path === currentPath);

    return (
        <header className="header-component">
            <Toolbar className="header-toolbar">
                <Item
                    location="before"
                    widget="dxButton"
                    cssClass="ps-3 menu-button"
                >
                    <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
                </Item>

                <Item
                    visible={menuToggleEnabled}
                    location="before"
                    widget="dxButton"
                    cssClass="ps-3 menu-button"
                >
                    <Button icon="home" stylingMode="text" onClick={() => navigate('/')} />
                </Item>

                <Item
                    visible={showCompanyInfo}
                    location="before"
                    cssClass="ps-3"
                    style={{ width: '100%' }}
                >
                    {`YA ${accountBookData && accountBookData.yearOfAssessment} ${accountBookData && accountBookData.companyName} ${accountBookData && accountBookData.uen ? `- ${accountBookData.uen}` : ''}`}
                </Item>

                <Item
                    cssClass="user-button"
                    location="after"
                    locateInMenu="auto"
                    menuItemTemplate="userPanelTemplate"
                >
                    <Button
                        className="authorization"
                        height="100%"
                        stylingMode="text"
                    >
                        <UserPanel menuMode="context" />
                    </Button>
                </Item>
                <Template name="userPanelTemplate">
                    <UserPanel menuMode="list" />
                </Template>
            </Toolbar>
        </header>
    );
}

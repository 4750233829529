import React, { Component } from 'react';

class ErrorBoundary extends Component {
    static getDerivedStateFromError() {
        return { hasError: true };
    }

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidMount() {
        if (this.state.hasError) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({ hasError: false });
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <div
                    style={{
                        padding: '50px',
                        textAlign: 'center',
                        fontSize: '24px',
                        color: 'red'
                    }}
                >
                    Looks like something went wrong. We're working on it.
                </div>
            );
        } else {
            return this.props.children;
        }
    }
}

export default ErrorBoundary;
import React, { useMemo, useState } from 'react';
import CustomStore from 'devextreme/data/custom_store';
import { Accordion, Item } from 'devextreme-react/accordion';
import { ToolbarItem } from 'devextreme-react/popup';
import { Column, ColumnChooser, GroupPanel, Sorting, Paging } from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import CheckBox from 'devextreme-react/check-box';
import TextArea from 'devextreme-react/text-area';
import TextBox from 'devextreme-react/text-box';
import StandardPopup from 'components/StandardPopup';
import StandardDataGrid from 'components/StandardDataGrid';
import StandardDateBox from 'components/StandardDateBox';
import { PrimaryButton } from 'components/StandardButton';
import * as FinancialStatementStatus from '../../../data/financialStatementStatus';

const FinancialStatementValidationResultDialog = (props) => {
    const { data, status, onCancel, onAcknowledgeUpload, onRefresh } = props;
    const { validationErrorsData } = data;
    const [xbrlFileDownloaded, setXBRLFileDownloaded] = useState({
        reviewCopyFile: data.reviewCopyUrl ? false : true,
        validationResultFile: data.validationResultUrl ? false : true
    });

    const genuineErrorsDataSource = useMemo(() => new CustomStore({
        loadMode: 'raw',
        load: function () {
            return validationErrorsData.genuineErrorsInJson ? JSON.parse(validationErrorsData.genuineErrorsInJson) : [];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [validationErrorsData.genuineErrorsInJson]);

    const possibleErrorsDataSource = useMemo(() => new CustomStore({
        loadMode: 'raw',
        load: function () {
            return validationErrorsData.possibleErrorsInJson ? JSON.parse(validationErrorsData.possibleErrorsInJson) : [];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [validationErrorsData.possibleErrorsInJson]);

    const exemptedErrorsDataSource = useMemo(() => new CustomStore({
        loadMode: 'raw',
        load: function () {
            return validationErrorsData.exemptedErrorsInJson ? JSON.parse(validationErrorsData.exemptedErrorsInJson) : [];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [validationErrorsData.exemptedErrorsInJson]);

    return (
        <StandardPopup
            visible
            onHiding={onCancel}
            showCloseButton
            showTitle
            fullScreen
            title="View Financial Statement Validation Result"
            position="center"
        >
            <div className={status === FinancialStatementStatus.ValidationSuccess ? 'alert alert-success' : 'alert alert-warning'}>
                {data.description}
                {data.validationResultUrl && !data.isValidationErrorsRetrieved &&
                    <div>
                        <hr />
                        Unable to Retrieve Validation Errors at this time, Please try again later. If this issue persists, please contact our support team.
                    </div>
                }
            </div>
            <Accordion
                onInitialized={(e) => {
                    const items = e.component.option('items');
                    e.component.option('selectedItemKeys', items);
                }}
                autoExpandAll
                collapsible
                multiple
            >
                <Item
                    key="validationDetails"
                    title="Validation Details"
                >
                    <div className="px-4 py-3">
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <label className="form-label fw-bolder">Sender Name</label>
                                <TextBox
                                    text={data.senderName}
                                    readOnly
                                />
                            </div>
                            <div className="col-md-4">
                                <label className="form-label fw-bolder">Sender Email</label>
                                <TextBox
                                    text={data.senderEmail}
                                    readOnly
                                />
                            </div>
                            <div className="col-md-4">
                                <label className="form-label fw-bolder">Submission Date</label>
                                <StandardDateBox
                                    value={data.uploadedDate}
                                    type="date"
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <label className="form-label fw-bolder">Company UEN</label>
                                <TextBox
                                    text={data.uen}
                                    readOnly
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label fw-bolder">Company Name</label>
                                <TextBox
                                    text={`${data.companyName} (${data.uen})`}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <label className="form-label fw-bolder">Financial Period End</label>
                                <StandardDateBox
                                    value={data.financialPeriodEnd}
                                    type="date"
                                    readOnly
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label fw-bolder">Filing Type</label>
                                <TextBox
                                    text={data.filingType}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-12">
                                <label className="form-label fw-bolder">Description</label>
                                <TextArea
                                    value={data.description}
                                    width="auto"
                                    height="100px"
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            {status === FinancialStatementStatus.ValidatedWithErrors &&
                                <div className="col-md-4">
                                    <CheckBox
                                        text="Has Errors"
                                        value
                                        readOnly
                                    />
                                </div>
                            }
                            <div className="col-md-4">
                                <CheckBox
                                    text="Validation Completed"
                                    value={data.isValidationComplete}
                                    readOnly
                                />
                            </div>
                            {data.exemptionGranted &&
                                <div className="col-md-4">
                                    <CheckBox
                                        text="Exemption Granted"
                                        value={data.exemptionGranted === 'Y'}
                                        readOnly
                                    />
                                </div>
                            }
                        </div>
                        {(data.xbrlDownloadUrl || data.validationResultUrl || data.reviewCopyUrl) &&
                            <div className="row mb-3">
                                <label className="form-label fw-bolder">Financial Statement XBRL File Downloads</label>
                                <div className="col-md-12 d-flex mt-2">
                                    {data.reviewCopyUrl &&
                                        <PrimaryButton
                                            text="Download Review Copy"
                                            icon="dx-icon dx-icon-download"
                                            onClick={() => {
                                                window.open(data.reviewCopyUrl, '_blank', 'noopener');
                                                setXBRLFileDownloaded({ ...xbrlFileDownloaded, reviewCopyFile: true });
                                            }}
                                        />
                                    }

                                    {data.validationResultUrl &&
                                        <PrimaryButton
                                            text="Download Validation Result File"
                                            className="ms-2"
                                            icon="dx-icon dx-icon-download"
                                            onClick={() => {
                                                window.open(data.validationResultUrl, '_blank', 'noopener');
                                                setXBRLFileDownloaded({ ...xbrlFileDownloaded, validationResultFile: true });
                                            }}
                                        />
                                    }

                                    {data.xbrlDownloadUrl &&
                                        <PrimaryButton
                                            text="Download XBRL File"
                                            className="ms-2"
                                            icon="dx-icon dx-icon-download"
                                            onClick={() => window.open(data.xbrlDownloadUrl, '_blank', 'noopener')}
                                        />
                                    }
                                </div>
                                {status === FinancialStatementStatus.ValidationSuccess &&
                                    <div className="form-text mt-2">Note: Please proceed to "Acknowledge and Upload Financial Statement XBRL" only after downloading {data.validationResultUrl && 'Validation Result and'} "Review Copy".</div>
                                }
                            </div>
                        }
                    </div>
                </Item>
                <Item
                    key="genuineErrors"
                    title="Genuine Errors"
                    visible={validationErrorsData.genuineErrorsInJson}
                >
                    <div className="px-4 py-3">
                        <StandardDataGrid
                            dataSource={genuineErrorsDataSource}
                            showBorders
                        >
                            <Paging enabled={false} />
                            <Column
                                caption="S/N"
                                dataField="s/N"
                                dataType="string"
                            />
                            <Column
                                caption="Error Description"
                                dataField="error Description"
                                dataType="string"
                                encodeHtml={false}
                                customizeText={(e) => {
                                    return e.value ? e.value.replace('\n', '<br>') : '';
                                }}
                            />
                            <Column
                                caption="Section"
                                dataField="section"
                                dataType="string"
                            />
                            <Column
                                caption="Error Id"
                                dataField="error Id"
                                dataType="string"
                            />
                            <Column
                                caption="Element Id"
                                dataField="elementId"
                                dataType="string"
                            />
                            <Column
                                caption="Data ID"
                                dataField="dataID"
                                dataType="string"
                            />
                            <ColumnChooser enabled={false} />
                            <GroupPanel visible={false} />
                            <Sorting mode="none" />
                        </StandardDataGrid>
                    </div>
                </Item>
                <Item
                    key="possibleErrors"
                    title="Possible Errors"
                    visible={validationErrorsData.possibleErrorsInJson}
                >
                    <div className="px-4 py-3">
                        <StandardDataGrid
                            dataSource={possibleErrorsDataSource}
                            showBorders
                        >
                            <Paging enabled={false} />
                            <Column
                                caption="S/N"
                                dataField="s/N"
                                dataType="string"
                            />
                            <Column
                                caption="Error Description"
                                dataField="error Description"
                                dataType="string"
                                encodeHtml={false}
                                customizeText={(e) => {
                                    return e.value ? e.value.replace('\n', '<br>') : '';
                                }}
                            />
                            <Column
                                caption="Section"
                                dataField="section"
                                dataType="string"
                            />
                            <Column
                                caption="Error Id"
                                dataField="error Id"
                                dataType="string"
                            />
                            <Column
                                caption="Element Id"
                                dataField="elementId"
                                dataType="string"
                            />
                            <Column
                                caption="Data ID"
                                dataField="dataID"
                                dataType="string"
                            />
                            <ColumnChooser enabled={false} />
                            <GroupPanel visible={false} />
                            <Sorting mode="none" />
                        </StandardDataGrid>
                    </div>
                </Item>
                <Item
                    key="exemptedErrors"
                    title="Exempted Errors"
                    visible={validationErrorsData.exemptedErrorsInJson}
                >
                    <div className="px-4 py-3">
                        <StandardDataGrid
                            dataSource={exemptedErrorsDataSource}
                            showBorders
                        >
                            <Column
                                caption="S/N"
                                dataField="s/N"
                                dataType="string"
                            />
                            <Column
                                caption="Error Description"
                                dataField="error Description"
                                dataType="string"
                                encodeHtml={false}
                                customizeText={(e) => {
                                    return e.value ? e.value.replace('\n', '<br>') : '';
                                }}
                            />
                            <Column
                                caption="Section"
                                dataField="section"
                                dataType="string"
                            />
                            <Column
                                caption="Error Id"
                                dataField="error Id"
                                dataType="string"
                            />
                            <Column
                                caption="Element Id"
                                dataField="elementId"
                                dataType="string"
                            />
                            <Column
                                caption="Data ID"
                                dataField="dataID"
                                dataType="string"
                            />
                            <ColumnChooser enabled={false} />
                            <GroupPanel visible={false} />
                            <Sorting mode="none" />
                        </StandardDataGrid>
                    </div>
                </Item>
            </Accordion>

            <ToolbarItem
                toolbar="bottom"
                location="before"
                locateInMenu="auto"
            >
                <PrimaryButton
                    text="Refresh"
                    icon="dx-icon dx-icon-refresh"
                    onClick={onRefresh}
                />
            </ToolbarItem>

            <ToolbarItem
                toolbar="bottom"
                location="after"
                locateInMenu="auto"
            >
                <div>
                    <Button
                        className="mx-2"
                        text="Close"
                        onClick={onCancel}
                    />
                    {status === FinancialStatementStatus.ValidationSuccess &&
                        <PrimaryButton
                            text="Next: Acknowledge and Upload XBRL"
                            disabled={(!xbrlFileDownloaded.reviewCopyFile) || (!xbrlFileDownloaded.validationResultFile)}
                            onClick={onAcknowledgeUpload}
                        />
                    }
                </div>
            </ToolbarItem>
        </StandardPopup>
    );
};

export default FinancialStatementValidationResultDialog;
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { PrimaryButton } from 'components/StandardButton';
import StandardPopup from 'components/StandardPopup';

const DataChangeAcknowledgementDialog = (props) => {
    const { onCancel } = props;

    return (
        <StandardPopup
            width="460px"
            height="320px"
            visible
            onHiding={onCancel}
            showCloseButton
            title="Financial Statement - Data Change"
        >
            <div className="row mb-0">
                <p className="mb-1">The occurrence is attributed to any of the following reasons:</p>
                <div className="ml-3">
                    <ol className="mb-3">
                        <li>Modifications in <b>UEN</b></li>
                        <li>Modifications in <b>Current Year Start Date</b></li>
                        <li>Modifications in <b>Prior Year Start Date</b></li>
                        <li>Modifications in <b>Account Mapping</b></li>
                        <li>Overwriting <b>Financial Data (Account Balances)</b></li>
                    </ol>
                </div>
                <p className="mb-0">Update the form data by clicking the <b>"Refresh"</b> button.</p>
                <p>(Financial Statement submission cannot proceed without refresh.)</p>
            </div>
            <Toolbar>
                <Item
                    location="center"
                    render={() => (
                        <PrimaryButton
                            text="Yes, I am acknowledged."
                            onClick={onCancel}
                        />
                    )}
                />
            </Toolbar>
        </StandardPopup>
    );
};

export default DataChangeAcknowledgementDialog;
import * as Api from 'utils/api';
import { buildApiUrl } from 'utils/session';

const basePath = 'fixedAsset';

export function createFixedAssetEntry(accountBookDataId, data) {
    let url = buildApiUrl(`${basePath}`, { accountBookDataId });
    return Api.postData(url, data);
}

export function getFixedAssetEntryList(accountBookDataId) {
    let url = buildApiUrl(`${basePath}/getListing`, { accountBookDataId });
    return Api.getData(url);
}

export function getFixedAssetEntry(accountBookDataId, id) {
    let url = buildApiUrl(`${basePath}`, { accountBookDataId, id });
    return Api.getData(url);
}

export function updateFixedAssetEntry(accountBookDataId, id, data) {
    let url = buildApiUrl(`${basePath}`, { accountBookDataId, id });
    return Api.putData(url, data);
}

export function deleteFixedAssetEntry(accountBookDataId, id) {
    let url = buildApiUrl(`${basePath}`, { accountBookDataId, id });
    return Api.deleteData(url);
}

export function getPriorYearFixedAssetEntries(accountBookDataId) {
    let url = buildApiUrl(`${basePath}/getPriorYearFixedAssetEntries`, { accountBookDataId });
    return Api.getData(url);
}

export function importFixedAssetEntries(accountBookDataId, data) {
    let url = buildApiUrl(`${basePath}/importFixedAssetEntries`, { accountBookDataId });
    return Api.putData(url, data);
}
import { NumberBox } from 'devextreme-react/number-box';
import { TextBox } from 'devextreme-react/text-box';
import { SelectBox } from 'devextreme-react/select-box';
import { Validator, RequiredRule } from 'devextreme-react/validator';
import StandardDateBox from 'components/StandardDateBox';

const InformationView = (props) => {
    const { formData,
        handleFormDataChange, handleFilingInfoDataChange, handleDraftDataChange
    } = props;

    return (
        <>
            <div className="row">
                <div className="col-md-3 mb-2 align-self-center">UEN</div>
                <div className="col-md-3 mb-2">
                    <TextBox
                        value={formData.filingInfo.uen}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 mb-2 align-self-center">Year of Assessment</div>
                <div className="col-md-3 mb-2">
                    <NumberBox
                        value={formData.filingInfo.ya}
                        readOnly
                    />
                </div>
                <div className="col-md-6 mb-2 align-self-center fw-bolder">
                    ( Financial Year : {formData.dataFormCS.basisPeriodFrom} - {formData.dataFormCS.basisPeriodTo} )
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-md-3 mb-2 align-self-center">Name of Filer</div>
                <div className="col-md-3 mb-2">
                    <TextBox
                        value={formData.nameOfFiler}
                        onValueChanged={(e) => handleFormDataChange({ nameOfFiler: e.value })}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 mb-2 align-self-center">Designation of Filer</div>
                <div className="col-md-3 mb-2">
                    <TextBox
                        value={formData.filingInfo.designationOfFiler}
                        onValueChanged={(e) => handleFilingInfoDataChange({ designationOfFiler: e.value })}
                        maxLength={30}
                    >
                        <Validator validationGroup="initialView" >
                            <RequiredRule message="Designation of Filer is required" />
                        </Validator>
                    </TextBox>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 mb-2 align-self-center">Tax Agent Firm</div>
                <div className="col-md-3">
                    <TextBox
                        value={formData.taxAgentFirmName}
                        onValueChanged={(e) => handleFormDataChange({ taxAgentFirmName: e.value })}
                        maxLength={100}
                    />
                </div>
                <div className="form-text mb-2">Please fill the field "Tax Agent Firm" if the Form C-S is filed by third party.</div>
            </div>
            <br />
            <div className="row">
                <div className="col-md-3 mb-2 align-self-center">Name of Contact Person</div>
                <div className="col-md-3 mb-2">
                    <TextBox
                        value={formData.filingInfo.nameOfContactPerson}
                        onValueChanged={(e) => handleFilingInfoDataChange({ nameOfContactPerson: e.value })}
                        maxLength={100}
                    >
                        <Validator validationGroup="initialView" >
                            <RequiredRule message="Name of Contact Person is required" />
                        </Validator>
                    </TextBox>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 mb-2 align-self-center">Designation of Contact Person</div>
                <div className="col-md-3 mb-2">
                    <TextBox
                        value={formData.filingInfo.designationOfContactPerson}
                        onValueChanged={(e) => handleFilingInfoDataChange({ designationOfContactPerson: e.value })}
                        maxLength={30}
                    >
                        <Validator validationGroup="initialView" >
                            <RequiredRule message="Designation of Contact Person is required" />
                        </Validator>
                    </TextBox>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-md-3 mb-2 align-self-center">Contact Number</div>
                <div className="col-md-3 mb-2">
                    <TextBox
                        value={formData.filingInfo.contactNumber}
                        onValueChanged={(e) => handleFilingInfoDataChange({ contactNumber: e.value })}
                        maxLength={20}
                    >
                        <Validator validationGroup="initialView" >
                            <RequiredRule message="Contact Number is required" />
                        </Validator>
                    </TextBox>
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="fs-6 fw-bolder">General</div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 align-self-center">How many sets of financial statements did the company prepare for the period declared ?</div>
                <div className="col-4 ">
                    <SelectBox
                        dataSource={[
                            { text: '1', value: 1 },
                            { text: '2', value: 2 },
                            { text: 'N.A. (Not Applicable)', value: null }
                        ]}
                        displayExpr={e => {
                            if (e === null) return 'N.A. (Not Applicable)';
                            return e.text;
                        }}
                        valueExpr="value"
                        value={formData.dataFormCS.fsPrep}
                        onValueChanged={e => handleDraftDataChange({ fsPrep: e.value })}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 align-self-center">The financial year end of the first set of financial statements.</div>
                <div className="col-4 ">
                    <StandardDateBox
                        value={formData.dataFormCS.firstFYE}
                        onValueChanged={(e) => handleDraftDataChange({ firstFYE: e.value })}
                        showClearButton
                        type="date"
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 align-self-center">There is a change in the company's financial year end. The financial year end of the company before the change.</div>
                <div className="col-4 ">
                    <StandardDateBox
                        showClearButton
                        type="date"
                        value={formData.dataFormCS.prevFYE}
                        onValueChanged={(e) => handleDraftDataChange({ prevFYE: e.value })}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 align-self-center">Tax reference number assigned by IRAS to foreign individual not eligible for SingPass</div>
                <div className="col-4 ">
                    <TextBox
                        value={formData.filingInfo.declarantRefNo}
                        onValueChanged={e => handleFilingInfoDataChange({ declarantRefNo: e.value })}
                        maxLength={9}
                    />
                </div>
            </div>
        </>
    );
};

export default InformationView;
import { useEffect, useMemo, useState, useRef } from 'react';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import { Column, ColumnChooser, Scrolling, Toolbar, Item, Editing, Lookup } from 'devextreme-react/tree-list';
import { Tooltip } from 'devextreme-react/tooltip';
import * as Dialog from 'devextreme/ui/dialog';
import StandardTreeList from 'components/StandardTreeList';
import disabledPdeConstant from './data/disabledPdeConstant';
import liquidityPdeConstant from './data/liquidityPdeConstant';
import * as AccountLiquidityConstant from './data/accountLiquidityConstant';
import './AccountMappingMaintenance.scss';

const EditableAccountMappingGrid = (props) => {
    const { initialData, accountMappingData, recordDataChanges } = props;
    const tooltipRef = useRef();
    const viewData = useMemo(() => {
        const viewData = [];

        const parentAccNoList = [...new Set(initialData.chartOfAccountData.map(item => item.parentAccNo))];
        initialData.chartOfAccountData.forEach(x => {
            const viewItem = {
                key: x.accNo,
                parentKey: x.parentAccNo || x.accType,
                isBalanceSheetAccountType: initialData.accountTypeData.find(y => y.accountType === x.accType).isBalanceSheetAccountType,
                ...x
            };
            viewData.push(viewItem);

            const accountMapping = accountMappingData.find(x => x.accNo === viewItem.accNo);
            if (accountMapping) {
                viewItem.liquidity = accountMapping.liquidity;
                viewItem.pde = accountMapping.pde;
            }

            if (parentAccNoList.includes(x.accNo)) {
                viewItem.isParentAccount = true;
            }
        });

        initialData.accountTypeData.forEach(x => {
            viewData.push({
                key: x.accountType,
                description: x.accountTypeDescription,
                isHeader: true
            });
        });

        return viewData;
    }, [initialData]);

    const pdeListData = useMemo(() => {
        return initialData.pdeList.map(pde => {
            const item = { ...pde };

            if (disabledPdeConstant.includes(pde.dataId)) item.disabled = true;
            return item;
        });
    }, []);

    const accountListRef = useRef();
    const [showOnlyUnmappedAccounts, setShowOnlyUnmappedAccounts] = useState(false);
    const [mappingCounter, setMappingCounter] = useState({ mappedCount: 0, totalCount: 0 });

    useEffect(() => {
        setMappingCounter({
            mappedCount: accountMappingData.filter(x => !!x.pde).length,
            totalCount: viewData.filter(x => !x.isHeader && !x.isParentAccount).length
        });
    }, [initialData]);

    function handleAccountListFilterChange(showOnlyUnmappedAccount) {
        if (showOnlyUnmappedAccount) {
            // Show unmapped account only
            const accNoList = viewData.filter(x => !x.isHeader && !x.isParentAccount && !x.pde).map(x => x.accNo);
            const filter = [];
            for (let i = 0; i < accNoList.length; i++) {
                if (i !== 0) {
                    filter.push('or');
                }
                filter.push(['accNo', '=', accNoList[i]]);
            }
            accountListRef.current.instance.filter(filter);
        } else
            accountListRef.current.instance.filter(null);
    }

    function showLiquiditySelectionDialog(rowData, accountMapping) {
        // Debtor/Creditor Control Account no need to set liquidity
        if (rowData.specialAccType === 'SDC' || rowData.specialAccType === 'SCC') return;
        const pdeDescription = pdeListData.find(pde => pde.dataId === rowData.pde).description;

        return Dialog.custom({
            showTitle: false,
            messageHtml: `<div style="max-width:500px">The PDE selected is ${pdeDescription}. Is this a Current or Non-Current account?</div>`,
            buttons: [{
                text: 'Current',
                focusStateEnabled: false,
                onClick: () => ({ liquidity: AccountLiquidityConstant.Current })
            },
            {
                text: 'Non-Current',
                onClick: () => ({ liquidity: AccountLiquidityConstant.NonCurrent })
            }]
        }).show().then(result => {
            rowData.liquidity = result.liquidity;
            accountMapping.liquidity = result.liquidity;
        });
    }

    return (
        <div className="row account-mapping">
            <StandardTreeList
                keyExpr="key"
                parentIdExpr="parentKey"
                autoExpandAll
                columnAutoWidth={false}
                dataSource={viewData}
                ref={accountListRef}
                height="calc(100vh - 320px)"
                onEditorPreparing={e => {
                    if (e.row && e.row.rowType === 'data' && e.dataField === 'pde') {
                        if (e.row.data.isHeader || e.row.data.isParentAccount) {
                            e.cancel = true;
                        } else {
                            e.editorOptions.onValueChanged = async (editor) => {
                                e.row.data.pde = editor.value;
                                e.row.data.liquidity = null;
                                const accountMapping = { accNo: e.row.data.accNo, pde: editor.value, liquidity: null };

                                if (liquidityPdeConstant.includes(e.row.data.pde)) {
                                    await showLiquiditySelectionDialog(e.row.data, accountMapping);
                                }

                                const existingAccountMapping = accountMappingData.find(x => x.accNo === accountMapping.accNo);
                                if (existingAccountMapping) {
                                    if (accountMapping.pde === null) {
                                        // Delete
                                        setMappingCounter({ ...mappingCounter, mappedCount: mappingCounter.mappedCount - 1 });
                                        accountMappingData.splice(accountMappingData.indexOf(existingAccountMapping), 1);
                                    } else {
                                        // Modify
                                        accountMappingData[accountMappingData.indexOf(existingAccountMapping)] = accountMapping;
                                    }
                                } else {
                                    // Add
                                    setMappingCounter({ ...mappingCounter, mappedCount: mappingCounter.mappedCount + 1 });
                                    accountMappingData.push(accountMapping);
                                }

                                recordDataChanges(accountMapping);
                            };

                            e.editorOptions.itemTemplate = (data) => {
                                const div = document.createElement('div');
                                div.classList.add('user-select-pointer');

                                if (data.usedByACRA && !data.usedByIRAS) {
                                    const badge_acra = document.createElement('div');
                                    badge_acra.classList.add('acra-badge');
                                    badge_acra.appendChild(document.createTextNode('ACRA'));
                                    div.appendChild(badge_acra);
                                } else if (!data.usedByACRA && data.usedByIRAS) {
                                    const badge_iras = document.createElement('div');
                                    badge_iras.classList.add('iras-badge');
                                    badge_iras.appendChild(document.createTextNode('IRAS'));
                                    div.appendChild(badge_iras);
                                } else if (data.usedByACRA && data.usedByIRAS) {
                                    const badge_both = document.createElement('div');
                                    badge_both.classList.add('both-badge');
                                    badge_both.appendChild(document.createTextNode('IRAS & ACRA'));
                                    div.appendChild(badge_both);
                                }

                                if (disabledPdeConstant.includes(data.dataId)) {
                                    const span = document.createElement('span');
                                    span.classList.add('disabled-pde-icon');
                                    span.classList.add('select-by-account');
                                    span.classList.add('dx-icon-info');
                                    div.appendChild(span);
                                    span.addEventListener('mouseenter', () => {
                                        tooltipRef.current.instance.option('target', span);
                                        tooltipRef.current.instance.show();
                                    });
                                }

                                const descriptionTextNode = document.createTextNode(data.description);
                                div.appendChild(descriptionTextNode);

                                return div;
                            };

                            e.editorOptions.onFocusIn = (e) => {
                                let input = e.element.querySelector('.dx-texteditor-input');
                                input.select();
                            };
                        }
                    }
                }}
                onRowPrepared={e => {
                    if (e.rowType === 'data' && e.data.isHeader)
                        e.rowElement.classList.add('account-mapping-header-row');
                }}
                onCellPrepared={e => {
                    if (e.rowType === 'data' && !e.data.isHeader && !e.data.isParentAccount && e.column.dataField === 'pde')
                        e.cellElement.classList.add('fillable-field');
                }}
            >
                <Editing
                    mode="cell"
                    allowUpdating
                />
                <ColumnChooser enabled={false} />
                <Scrolling mode="virtual" />
                <Column
                    caption="Description"
                    dataField="description"
                    dataType="string"
                    allowEditing={false}
                />
                <Column
                    caption="Acc. No."
                    dataField="accNo"
                    dataType="string"
                    width="100px"
                    sortOrder="asc"
                    allowEditing={false}
                />
                <Column
                    caption="Acc. Type"
                    dataField="accTypeDescription"
                    dataType="string"
                    width="160px"
                    allowEditing={false}
                />
                <Column
                    caption="PDE"
                    dataField="pde"
                    dataType="string"
                    cellRender={(e) => {
                        return (
                            <>
                                <div className="d-inline-block">
                                    {e.text}
                                    {e.text && e.data.liquidity !== null &&
                                        <Button
                                            className="btn-small ms-2"
                                            type="outlined"
                                            text={e.data.liquidity}
                                            onClick={async () => {
                                                const accountMapping = { accNo: e.row.data.accNo, pde: e.row.data.pde, liquidity: null };
                                                await showLiquiditySelectionDialog(e.row.data, accountMapping);

                                                const existingAccountMapping = accountMappingData.find(x => x.accNo === accountMapping.accNo);
                                                accountMappingData[accountMappingData.indexOf(existingAccountMapping)] = accountMapping;
                                                recordDataChanges(accountMapping);
                                            }}
                                        />
                                    }
                                </div>
                            </>
                        );
                    }}
                >
                    <Lookup
                        dataSource={e => ({
                            store: pdeListData,
                            filter: e.data ? ['isBalanceSheetAccountType', '=', e.data.isBalanceSheetAccountType] : null
                        })}
                        displayExpr="description"
                        valueExpr="dataId"
                        allowClearing
                    />
                </Column>
                <Toolbar>
                    <Item
                        location="before"
                        locateInMenu="auto"
                    >
                        <div className="d-flex">
                            <CheckBox
                                text="Show Only Unmapped Accounts"
                                value={showOnlyUnmappedAccounts}
                                onValueChanged={(e) => {
                                    setShowOnlyUnmappedAccounts(e.value);
                                    handleAccountListFilterChange(e.value);
                                }}
                            />
                            {(mappingCounter.totalCount - mappingCounter.mappedCount) > 0 &&
                                <div style={{ fontSize: '14px', lineHeight: '14px' }} className="badge bg-danger text-light fw-normal ms-2 align-self-center d-inline text-danger">
                                    {mappingCounter.totalCount - mappingCounter.mappedCount} unmapped account(s)
                                </div>
                            }
                        </div>
                    </Item>
                    <Item name="columnChooserButton" />
                    <Item name="searchPanel" />
                </Toolbar>
            </StandardTreeList>
            <Tooltip
                showEvent="mouseenter"
                hideEvent="mouseleave"
                ref={tooltipRef}
            >
                <div className="text-start">
                    This PDE is not supported because current account book data does not support business consolidation.
                </div>
            </Tooltip>
        </div >
    );
};

export default EditableAccountMappingGrid;
import * as Api from 'utils/api';
import { buildApiUrl } from 'utils/session';

const basePath = 'lookup';

export function getAccountBookData(skip, take) {
    let url = buildApiUrl(basePath + '/accountBookDataLookup', { skip, take });
    return Api.getData(url);
}

export function getOwnedCompany() {
    let url = buildApiUrl(basePath + '/ownedCompanyLookup');
    return Api.getData(url);
}

export function getOnPremiseAccountMappingData(accountBookDataId) {
    let url = buildApiUrl(basePath + '/onPremiseAccountMappingDataLookup', { accountBookDataId });
    return Api.getData(url);
}
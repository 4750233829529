import React from 'react';

const AccountBookNotSelected = () => {
    return (
        <div className="content-block dx-card responsive-paddings d-flex justify-content-center">
            <div className="pt-5 text-center">
                <div className="pb-4"><span className="dx-icon-warning text-warning" style={{ fontSize: '140px' }} /></div>
                <p className="text-danger" style={{ fontSize: '30px' }}>Please Select an Account Book Data to view this page.</p>
            </div>
        </div>
    );
};

export default AccountBookNotSelected;
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { useEffect, useState, useRef } from 'react';
import { Column, Export, Item, Toolbar, MasterDetail, Scrolling } from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import StandardDataGrid from 'components/StandardDataGrid';
import AgingDetailDataGrid from './components/AgingDetailDataGrid';

const DebtorAccountBalanceView = (props) => {
    const { isCurrentYear, visible } = props;
    const [dataSource, setDataSource] = useState(null);
    const dataGridRef = useRef();

    useEffect(() => {
        if (visible) {
            setDataSource(isCurrentYear ? props.currentYearData : props.priorYearData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, props.currentYearData, props.priorYearData, isCurrentYear]);

    return (
        <StandardDataGrid
            dataSource={dataSource}
            visible={visible}
            useDefaultPaging={false}
            onExporting={e => {
                const workbook = new Workbook();
                const worksheet = workbook.addWorksheet('Main');

                exportDataGrid({
                    component: e.component,
                    worksheet,
                    autoFilterEnabled: true
                }).then(() => {
                    workbook.xlsx.writeBuffer().then((buffer) => {
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DebtorAccountBalance.xlsx');
                    });
                });
            }}
            ref={dataGridRef}
        >
            <Scrolling mode="virtual" />
            <Column caption="Account Code" dataField="accNo" dataType="string" sortIndex={0} sortOrder="asc" />
            <Column caption="Description" dataField="description" dataType="string" />
            <Column caption="Control Account Code" dataField="controlAccNo" dataType="string" />
            <Column caption="Control Account Description" dataField="controlAccDescription" dataType="string" />
            <Column caption="Current Amount" dataField="currentAmount" format="#,##0.00" dataType="number" />
            <Column caption="Non-Current Amount" dataField="nonCurrentAmount" format="#,##0.00" dataType="number" />
            <Toolbar>
                <Item
                    name="exportButton"
                />
                <Item
                    name="columnChooserButton"
                />
            </Toolbar>
            <Export enabled />
            <MasterDetail
                enabled
                component={AgingDetailDataGrid}
            />
        </StandardDataGrid>
    );
};

export default DebtorAccountBalanceView;
import { PrimaryButton } from 'components/StandardButton';
import StandardPopup from 'components/StandardPopup';

const AccountMappingCompletedDialog = (props) => {
    const { onCancel, startAnnualReturn, startFormCs } = props;

    return (
        <StandardPopup
            title="Account Mapping Completed"
            onHiding={onCancel}
            showCloseButton
            visible
            showTitle
            width={800}
            height="auto"
        >
            <div className="row justify-content-around align-items-center mb-3">
                <img src="/images/acra-logo.png" className="col-6" style={{ height: '80px', objectFit: 'contain' }} />
                <img src="/images/iras-logo.png" className="col-6" style={{ height: '80px', objectFit: 'contain' }} />
            </div>
            <div className="row d-flex justify-content-around">
                <PrimaryButton
                    className="col-4"
                    text="Start Annual Return Filing"
                    onClick={() => {
                        startAnnualReturn();
                        onCancel();
                    }}
                />

                <PrimaryButton
                    className="col-4"
                    text="Start Form C-S Filing"
                    onClick={() => {
                        startFormCs();
                        onCancel();
                    }}
                />
            </div>
        </StandardPopup>
    );
};

export default AccountMappingCompletedDialog;
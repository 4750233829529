import { Column, Toolbar, MasterDetail } from 'devextreme-react/data-grid';
import StandardDataGrid from 'components/StandardDataGrid';
import ShareholderDetailsDataGrid from './ShareholderDetailsDataGrid';

const ShareholderDataGrid = (props) => {
    const { data } = props;

    return (
        <div className="card-body px-4 py-3">
            <StandardDataGrid
                dataSource={data}
            >
                <Column
                    caption="S/No."
                    cellRender={(e) => {
                        return e.row.dataIndex + 1;
                    }}
                    width={55}
                    dataType="number"
                />
                <Column
                    dataField="shareHolderName"
                    caption="Name"
                    dataType="string"
                />
                <Column
                    caption="IDNo./UEN/Nationality"
                    cellRender={(e) => {
                        let cellData = e.data;
                        return `${cellData.identificationNo} / ${cellData.nationality}`;
                    }}
                    dataType="string"
                />
                <Column
                    dataField="currency"
                    caption="Currency"
                    dataType="string"
                />
                <MasterDetail
                    enabled
                    autoExpandAll
                    render={ShareholderDetailsDataGrid}
                />
                <Toolbar visible={false} />
            </StandardDataGrid>
        </div>
    );
};

export default ShareholderDataGrid;
import TextBox from 'devextreme-react/text-box';
import { Validator, RequiredRule, CustomRule } from 'devextreme-react/validator';
import FormRadioGroup from '../components/FormRadioGroup';
import FormSelectBox from '../components/FormSelectBox';
import * as formCsStatusConstant from '../../data/formCsStatusConstant';

const PartAView = (props) => {
    const {
        status,
        formData,
        handleDraftDataChange,
        handleDeclarationDataChange
    } = props;

    const isReadOnlyForm = status === formCsStatusConstant.FormSubmitted;

    return (
        <div className="partAView">
            <div className="mb-2 fs-6 fw-bolder">
                The company has met the qualifying conditions for filing Form C-S
            </div>
            <div className="row">
                <div className="col mb-2 align-self-center">The company's revenue for the financial period is $5,000,000 or below.</div>
                <div className="col-md-2 align-self-center mb-2">
                    <FormRadioGroup
                        readOnly={isReadOnlyForm}
                        value={formData.declaration.isRevBelow5M}
                        onValueChanged={e => handleDeclarationDataChange({ isRevBelow5M: e.value })}
                    >
                        <Validator validationGroup="draftValidation">
                            <CustomRule
                                validationCallback={e => e.value}
                                message="The company's revenue for the financial period must be below $5,000,000"
                            />
                        </Validator>
                    </FormRadioGroup>
                </div>
            </div>
            <div className="row">
                <div className="col mb-2 align-self-center mb-2">The company derives only income subject to tax at 17%.</div>
                <div className="col-md-2 align-self-center mb-2">
                    <FormRadioGroup
                        readOnly={isReadOnlyForm}
                        value={formData.declaration.isOnlyIncTxbl17}
                        onValueChanged={e => handleDeclarationDataChange({ isOnlyIncTxbl17: e.value })}
                    >
                        <Validator validationGroup="draftValidation">
                            <CustomRule
                                validationCallback={e => e.value}
                                message="The company derives only income must be subject to tax at 17%."
                            />
                        </Validator>
                    </FormRadioGroup>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="col align-self-center mb-2">The Company is not claiming/ utilising any of the following items:</div>
                    <ol type="a" className="ms-3 mb-2">
                        <li>Carry-back Of Current Year Capital Allowances/Losses</li>
                        <li>Group Relief</li>
                        <li>Investment Allowance</li>
                        <li>Foreign Tax Credit And Tax Deducted At Source</li>
                    </ol>
                </div>
                <div className="col-md-2 align-self-start">
                    <FormRadioGroup
                        readOnly={isReadOnlyForm}
                        value={formData.declaration.isNotClaimingSpecificItems}
                        onValueChanged={e => handleDeclarationDataChange({ isNotClaimingSpecificItems: e.value })}
                    >
                        <Validator validationGroup="draftValidation">
                            <CustomRule
                                validationCallback={e => e.value}
                                message="The company must not claiming/ utilising any of the items listed."
                            />
                        </Validator>
                    </FormRadioGroup>
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="fs-6 align-self-center fw-bolder">Particulars of Person who Reviewed the Return</div>
            </div>
            <div className="row">
                <div className="col-md-8 mb-2 align-self-center">The return has been reviewed by a person who is a Singapore Chartered Tax Professionals Limited (SCTP) Accredited Tax Advisor or Accredited Tax Practitioner for Income Tax.</div>
                <div className="col-md-4 mb-2">
                    <FormSelectBox
                        isRequired
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.sctpInd}
                        onValueChanged={e => handleDraftDataChange({ sctpInd: e.value })}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-8 mb-2 align-self-center">Name of Person</div>
                <div className="col-md-4 mb-2">
                    <TextBox
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.sctpName}
                        onValueChanged={(e) => handleDraftDataChange({ sctpName: e.value })}
                        disabled={formData.dataFormCS.sctpInd !== 1}
                        maxLength={100}
                    >
                        <Validator validationGroup="draftValidation">
                            {formData.dataFormCS.sctpInd === 1 && <RequiredRule message="Name of Person is required." />}
                        </Validator>
                    </TextBox>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8 mb-2 align-self-center">SCTP Membership Number</div>
                <div className="col-md-4 mb-2">
                    <TextBox
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.sctpNo}
                        onValueChanged={(e) => handleDraftDataChange({ sctpNo: e.value })}
                        disabled={formData.dataFormCS.sctpInd !== 1}
                        maxLength={10}
                    >
                        <Validator validationGroup="draftValidation">
                            {formData.dataFormCS.sctpInd === 1 && <RequiredRule message="SCTP Membership Number is required." />}
                        </Validator>
                    </TextBox>
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="fs-6 fw-bolder">The Company declares the following:</div>
            </div>
            <div className="row">
                <div className="col-md-8 align-self-center mb-2">The financial period for this return based on the company's financial statements is</div>
                <div className="col-md-4 mb-2 fs-6 text-center">
                    <b>{formData.dataFormCS.basisPeriodFrom} to {formData.dataFormCS.basisPeriodTo}</b>
                </div>
            </div>
        </div>
    );
};

export default PartAView;
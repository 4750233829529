import React, { useState, useRef } from 'react';
import TextBox from 'devextreme-react/text-box';
import Validator from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { SubmitButton } from 'components/StandardButton';
import StandardPopup from 'components/StandardPopup';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as FinancialStatementApi from '../../../api/financialStatementApi';
import * as MessageBox from 'utils/messageBox';
import * as ErrorMessage from 'utils/errorMessage';
import * as Toast from 'utils/toast';
import * as PageUtils from 'utils/pageUtils';

const FinancialStatementUploadForValidationDialog = (props) => {
    const { financialStatementId, initialData, onAfterUploadForValidation, onCancel } = props;
    const [data, setData] = useState({
        senderEmail: initialData.senderEmail,
        senderName: initialData.senderName
    });

    const { accountBookData } = useAccountBookData();
    const accountBookDataId = accountBookData && accountBookData.accountBookDataId;

    function handleSubmit(e) {
        e.preventDefault();

        MessageBox.showConfirmDangerMessage('Are you sure you want to upload the Financial Statement XBRL for validation?', false, 'Yes, upload it', 'No, don\'t upload it')
            .then(result => {
                if (result.confirm) {
                    uploadFinancialStatementXBRLForValidation(data);
                }
            });
    }

    function uploadFinancialStatementXBRLForValidation(input) {
        PageUtils.blockPage();

        FinancialStatementApi.uploadFinancialStatementXBRLForValidation(accountBookDataId, financialStatementId, input)
            .then((data) => {
                PageUtils.unblockPage();

                onAfterUploadForValidation(data.financialStatementId);

                Toast.showSuccessMessage('Successfully submitted Financial Statement XBRL to ACRA for validation. Please proceed to Get Validation Results from ACRA');
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    }

    function containsSpecialChars() {
        const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
        return !specialChars.test(data.senderName);
    }

    const validationRules = {
        senderEmail: [
            { type: 'required', message: 'Sender Email is required.' },
            { type: 'email', message: 'Invalid Email Format.' }
        ],
        senderName: [
            { type: 'required', message: 'Sender Name is required.' },
            { type: 'custom', message: 'Sender Name cannot contain special characters.', validationCallback: containsSpecialChars, reevaluate: true }
        ]
    };

    const initialFocusRef = useRef();

    return (
        <StandardPopup
            visible
            showCloseButton
            showTitle
            width="480px"
            height="auto"
            title="Upload Financial Statement for Validation"
            onHiding={onCancel}
            onShown={() => initialFocusRef.current.instance.focus()}
        >
            <form id="frmUploadForValidation" onSubmit={handleSubmit}>
                <div className="row mb-3">
                    <div className="col-md-12">
                        <label className="form-label required fw-bolder">Sender Name</label>
                        <TextBox
                            value={data.senderName}
                            onValueChanged={(e) => setData({ ...data, senderName: e.value })}
                            ref={initialFocusRef}
                        >
                            <Validator validationRules={validationRules.senderName} />
                        </TextBox>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-12">
                        <label className="form-label required fw-bolder">Sender Email</label>
                        <TextBox
                            value={data.senderEmail}
                            onValueChanged={(e) => setData({ ...data, senderEmail: e.value })}
                        >
                            <Validator validationRules={validationRules.senderEmail} />
                        </TextBox>
                    </div>
                </div>
                <Toolbar
                    className="mt-3"
                >
                    <Item
                        location="after"
                        render={() => (
                            <Button
                                text="Cancel"
                                onClick={onCancel}
                            />
                        )}
                    />
                    <Item
                        location="after"
                        render={() => (
                            <SubmitButton
                                text="Upload"
                                submitForm="frmUploadForValidation"
                            />
                        )}
                    />
                </Toolbar>
            </form>
        </StandardPopup >
    );
};

export default FinancialStatementUploadForValidationDialog;
import * as Api from 'utils/api';
import { buildApiUrl } from 'utils/session';

const basePath = 'user';

export function getUserList() {
    let url = buildApiUrl(`${basePath}/getUserList`);
    return Api.getData(url);
}

export function createRecord(data) {
    let url = buildApiUrl(`${basePath}`);
    return Api.postData(url, data);
}

export function getRecord(id) {
    let url = buildApiUrl(`${basePath}`, { id });
    return Api.getData(url);
}

export function updateRecord(id, data) {
    let url = buildApiUrl(`${basePath}`, { id });
    return Api.putData(url, data);
}

export function deleteRecord(id) {
    let url = buildApiUrl(`${basePath}`, { id });
    return Api.deleteData(url);
}
import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import LoadPanel from 'devextreme-react/load-panel';
import * as ApplicationApi from 'api/applicationApi';
import { Unauthorized } from 'layouts';
import * as Authentication from 'utils/authentication';
import * as ErrorMessage from 'utils/errorMessage';
import * as MessageBox from 'utils/messageBox';

function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [isInitialLoading, setIsInitialLoading] = useState(true);

    useEffect(() => {
        Authentication.getUser().then(user => {
            if (!user) {
                Authentication
                    .logIn()
                    .catch(() => {
                        setIsInitialLoading(false);
                        MessageBox.showMessage('Authentication error: Please click Reload button below to try again.', false, 'Reload')
                            .then(() => {
                                window.location.reload();
                            });
                    });
            } else {
                ApplicationApi.getUserData()
                    .then(userData => {
                        if (userData) {
                            setUser({
                                name: user.profile.name,
                                userId: userData.userId,
                                isSubscriber: userData.isSubscriber
                            });
                            setIsInitialLoading(false);
                        } else {
                            window.location.replace(window.APP_SETTINGS_AuthServerUrl);
                        }
                    })
                    .catch((error) => {
                        MessageBox.showMessage(ErrorMessage.fetchError(error), false);
                    });
            }
        });
    }, []);

    const canAccessPage = () => {
        return true;
    };

    const canAccessFunction = () => {
        return true;
    };

    const signOut = useCallback(() => {
        Authentication.logOut();
    }, []);

    return (
        <AuthContext.Provider value={{ user, canAccessPage, canAccessFunction, signOut }} >
            {isInitialLoading && <LoadPanel visible />}
            {!isInitialLoading && !user && <Unauthorized />}
            {!isInitialLoading && user && children}
        </AuthContext.Provider>
    );
}
const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);
// eslint-disable-next-line react/no-multi-comp
const AuthConsumer = props => <AuthContext.Consumer {...props} />;

export { AuthContext, AuthProvider, AuthConsumer, useAuth };
import * as Api from 'utils/api';
import { buildApiUrl } from 'utils/session';

const basePath = 'application';

export function getUserData(view) {
    let url = buildApiUrl(basePath + '/getUserData', { view });
    return Api.getData(url);
}

export function getAccountBookData(accountBookDataId) {
    let url = buildApiUrl(basePath + '/getAccountBookData', { accountBookDataId });
    return Api.getData(url);
}

export function updateAccountBookDataStatus(accountBookDataId, accountBookDataStatus) {
    let url = buildApiUrl(`${basePath}/updateAccountBookDataStatus`, { accountBookDataId });
    return Api.putData(url, accountBookDataStatus);
}
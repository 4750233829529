import {
    Page,
    StyleSheet,
    Text,
    View
} from '@react-pdf/renderer';

const MedicalExpenseSchedulePdfView = (props) => {
    const { initialData } = props;
    let i = 0;

    const styles = StyleSheet.create({
        page: {
            padding: 40
        },
        header: {
            fontSize: 15,
            fontWeight: 1000,
            marginBottom: 10,
            display: 'table',
            width: 'auto',
            textDecoration: 'underline',
            textAlign: 'center'
        },
        alertText: {
            fontSize: 10,
            fontWeight: 'bold',
            marginBottom: 10
        },
        normalText: {
            fontSize: 8,
            margin: 5
        },
        table: {
            display: 'table',
            width: 'auto',
            borderStyle: 'solid',
            borderWidth: 1
        },
        tableHeader: {
            backgroundColor: '#21a84a',
            color: 'white',
            fontWeight: 1000
        },
        tableHeaderText: {
            fontSize: 10,
            margin: 5,
            fontWeight: 1000
        }
    });

    const dataSource = [
        { description: 'Director\'s Remuneration (excluding Directors Fees)', value: initialData ? initialData.directorsRemunerationExcludingDirectorsFees : 0 },
        { description: 'Staff Remuneration (other than Director\'s Remuneration)', value: initialData ? initialData.staffRemunerationOtherThanDirectorsRemuneration : 0 },
        { description: 'CPF Contribution', value: initialData ? initialData.cpfContribution : 0 },
        { description: 'Total Remuneration', value: initialData ? initialData.totalRemuneration : 0 },
        {},
        { description: 'Computation of Disallowed Medical Expenses:' },
        { description: 'Medical Expenses (including Medical Insurance)', value: initialData ? initialData.medicalExpIncludingMedicalInsurance : 0 },
        { description: 'Less: 1% of Total Remuneration', value: initialData ? initialData.onePercentOfTotalRemuneration : 0 },
        { description: 'Medical Expenses Disallowed', value: initialData ? initialData.medicalExpDisallowed : 0 }
    ];

    const generateRow = (description, value) => {
        return (
            <View key={++i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={styles.normalText}>{description}</Text>
                <Text style={styles.normalText}>{value !== undefined ? formatValue(value) : null}</Text>
            </View>
        );
    };

    const formatValue = (value) => {
        if (typeof value === 'number') {
            return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
        return value;
    };

    return (
        <Page size="A4" style={styles.page}>
            <View style={styles.container}>
                <View style={styles.header}>
                    <Text>Medical Expense Schedule</Text>
                </View>
            </View>
            <Text style={styles.alertText}>Medical expense schedule is used to track and itemize expenses that involves curing diseases, health and many more.</Text>
            <View style={styles.table}>
                <View style={[styles.tableHeader, { display: 'flex', flexDirection: 'column' }]}>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={styles.tableHeaderText}>Description</Text>
                        <Text style={styles.normalText}>Balance</Text>
                    </View>
                </View>
                {dataSource.map(data => generateRow(data.description, data.value))}
            </View>
        </Page>
    );
};

export default MedicalExpenseSchedulePdfView;
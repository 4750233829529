import * as Api from 'utils/api';
import { buildApiUrl } from 'utils/session';

const basePath = 'financialStatement';

export function getFinancialStatementList(accountBookDataId) {
    let url = buildApiUrl(`${basePath}/getListing`, { accountBookDataId });
    return Api.getData(url);
}

export function newFinancialStatementEntry(accountBookDataId) {
    let url = buildApiUrl(`${basePath}/newFinancialStatementEntry`, { accountBookDataId });
    return Api.getData(url);
}

export function getFinancialStatementRecord(accountBookDataId, financialStatementId) {
    let url = buildApiUrl(`${basePath}/getFinancialStatementEntry`, { accountBookDataId, financialStatementId });
    return Api.getData(url);
}

export function createFinancialStatementEntry(accountBookDataId, input) {
    let url = buildApiUrl(`${basePath}/createFinancialStatementEntry`, { accountBookDataId });
    return Api.postData(url, input).then(response => response.json());
}

export function refreshFinancialStatement(accountBookDataId, financialStatementId) {
    let url = buildApiUrl(`${basePath}/refreshFinancialStatement`, { accountBookDataId, financialStatementId });
    return Api.postData(url).then(response => response.json());
}

export function updateFinancialStatementEntry(accountBookDataId, financialStatementId, input) {
    let url = buildApiUrl(`${basePath}/updateFinancialStatementEntry`, { accountBookDataId, financialStatementId });
    return Api.postData(url, input).then(response => response.json());
}

export function deleteFinancialStatement(accountBookDataId, financialStatementId) {
    let url = buildApiUrl(`${basePath}`, { accountBookDataId, financialStatementId });
    return Api.deleteData(url);
}

export function uploadFinancialStatementXBRLForValidation(accountBookDataId, financialStatementId, input) {
    let url = buildApiUrl(`${basePath}/uploadFinancialStatementXBRLForValidation`, { accountBookDataId, financialStatementId });
    return Api.postData(url, input).then(response => response.json());
}

export function getFinancialStatementXBRLValidationResult(accountBookDataId, financialStatementId) {
    let url = buildApiUrl(`${basePath}/getFinancialStatementXBRLValidationResult`, { accountBookDataId, financialStatementId });
    return Api.postData(url).then(response => response.json());
}

export function getFinancialStatementXBRLFileDownloadList(accountBookDataId, financialStatementId) {
    let url = buildApiUrl(`${basePath}/getFinancialStatementXBRLFileDownloadList`, { accountBookDataId, financialStatementId });
    return Api.getData(url);
}

export function getAcknowledgeAndUploadFinancialStatementXBRLSummaryData(accountBookDataId, financialStatementId) {
    let url = buildApiUrl(`${basePath}/getAcknowledgeAndUploadFinancialStatementXBRLSummaryData`, { accountBookDataId, financialStatementId });
    return Api.getData(url);
}

export function acknowledgeAndUploadFinancialStatementXBRL(accountBookDataId, financialStatementId) {
    let url = buildApiUrl(`${basePath}/acknowledgeAndUploadFinancialStatementXBRL`, { accountBookDataId, financialStatementId });
    return Api.postData(url).then(response => response.json());
}

export function getPrescribedDataElementDrillDownData(accountBookDataId, financialStatementId, input) {
    let url = buildApiUrl(`${basePath}/getPrescribedDataElementDrillDownData`, { accountBookDataId, financialStatementId });
    return Api.postData(url, input).then(response => response.json());
}
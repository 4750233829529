import { Column, Toolbar, MasterDetail } from 'devextreme-react/data-grid';
import StandardDataGrid from 'components/StandardDataGrid';
import ShareCapitalDetailsDataGrid from './ShareCapitalDetailsDataGrid';

const SharedCapitalDataGrid = (props) => {
    const { data } = props;

    return (
        <div className="card-body px-4 py-3">
            <StandardDataGrid
                dataSource={data}
            >
                <Column
                    caption="S/No."
                    cellRender={(e) => {
                        return e.row.dataIndex + 1;
                    }}
                    width={55}
                    dataType="number"
                />
                <Column
                    dataField="currency"
                    caption="Currency"
                    dataType="string"
                />
                <MasterDetail
                    enabled
                    autoExpandAll
                    render={ShareCapitalDetailsDataGrid}
                />
                <Toolbar visible={false} />
            </StandardDataGrid>
        </div>
    );
};

export default SharedCapitalDataGrid;
const internalServerErrorMessage = 'Unknown error occured. Please try again or reload the page. If this persists, please contact our support team.';

export function createError(error) {
    if (error.status) {
        if (error.status === 409) {
            return 'Unable to create record because of duplicate values.';
        } else if (error.status === 500) {
            return internalServerErrorMessage;
        } else {
            return error.message;
        }
    } else {
        return `Network connection failed, please try again. Detail: "${error}"`;
    }
}

export function updateError(error) {
    if (error.status) {
        if (error.status === 404) {
            return 'Unable to update record because the record was deleted by other user.';
        } else if (error.status === 410) {
            return 'Unable to update record because the record was updated by other user.';
        } else if (error.status === 409) {
            return 'Unable to update record because of duplicate values.';
        } else if (error.status === 500) {
            return internalServerErrorMessage;
        } else {
            return error.message;
        }
    } else {
        return `Network connection failed, please try again. Detail: "${error}"`;
    }
}

export function deleteError(error) {
    if (error.status) {
        if (error.status === 404) {
            return 'Unable to delete record because the record was deleted by other user.';
        } else if (error.status === 500) {
            return internalServerErrorMessage;
        } else {
            return error.message;
        }
    } else {
        return `Network connection failed, please try again. Detail: "${error}"`;
    }
}

export function fetchError(error) {
    if (error.status) {
        if (error.status === 500) {
            return internalServerErrorMessage;
        } else {
            return error.message;
        }
    } else {
        return `Network connection failed, please try again. Detail: "${error}"`;
    }
}
import { Button } from 'devextreme-react/button';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as DateUtils from 'utils/dateUtils';
import FormCsPdfView from './PdfView/FormCsPdfView';

const AcknowledgementView = (props) => {
    const { initialData } = props;

    const { accountBookData } = useAccountBookData();

    return (
        <>
            <div className="dx-card responsive-paddings pt-4 pb-4 mb-3">
                <div className="row">
                    <div className="col-md-9 mb-3 fw-bolder fs-5">Acknowledgement</div>
                    <PDFDownloadLink
                        className="col-md-3 mb-3"
                        document={
                            <FormCsPdfView
                                initialData={initialData}
                                accountBookData={accountBookData}
                            />
                        }
                        fileName={`Form C-S-${initialData.formDraft.filingInfo.uen}-YA${initialData.formDraft.filingInfo.ya}-${DateUtils.formatDate(new Date(), 'yyyy/MM/dd HH:mm:ss')}.pdf`}
                    >
                        {({ url, loading }) => {
                            return (
                                <Button
                                    className="col-md-12"
                                    focusStateEnabled={false}
                                    text={loading || !url ? 'Preparing...' : 'Download As PDF'}
                                    disabled={loading || !url}
                                />
                            );
                        }}
                    </PDFDownloadLink>    
                </div>
                <div className="alert alert-success px-3 py-2">
                    <div className="mb-2 fw-bolder fs-6">Successful Submission</div>
                    <div className="row">
                        <div className="col-md-3 col-xl-2 mb-2">Tax Ref No.</div>
                        <div className="col mb-2">{initialData.formDraft.filingInfo.uen}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 col-xl-2 mb-2">Acknowledgement No.</div>
                        <div className="col mb-2">{initialData.formDraft.acknowledgementNo}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 col-xl-2 mb-2">Date/ Time</div>
                        <div className="col mb-2">{initialData.formDraft.timestamp}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 col-xl-2 mb-2">Year of Assessment</div>
                        <div className="col mb-2">{initialData.formDraft.filingInfo.ya}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 col-xl-2 mb-2">Estimated Tax Payable</div>
                        <div className="col mb-2">S$ {initialData.formDraft.estTaxPayable}</div>
                    </div>
                </div>

                <div className="mb-2 fw-bolder fs-6">Documents to be Submitted to IRAS via the "Submit Document" digital service</div>
                <ul className="row mb-2 align-items-center">
                    <li>Declaration Form for S19B Allowances on IPR if this is the first YA of the writing-down allowances claim.</li>
                    {initialData.formDraft.dataFormCS.appStockConvAsset === 1 && <li> AC Reporting Form if the company had appropriated trading stock for non-trade or capital purposes under Section 10J and/ or converted non-trade or capital asset to trading stock under Section 32A of the Income Tax Act 1947.</li>}
                </ul>

                <div className="mb-2">
                    The relevant
                    <a className="px-1 icon-link icon-link-hover"
                        href="https://www.iras.gov.sg/quick-links/forms/companies"
                        target="_blank"
                    >
                        Form
                        <i className="dx-icon dx-icon-arrowright"></i>
                    </a>
                    for completion is available on IRAS website.
                </div>

                <div className="mb-2 fw-bolder fs-6">Documents Retention</div>
                <div className="mb-2">The company does not need to submit its financial statements, tax computation and supporting schedules  with the Form C-S. Please retain the documents and submit them to IRAS only upon request.</div>

                <div className="mb-2 fw-bolder fs-6">Late Filing</div>
                <div className="mb-2">Penalties may be imposed if this return is filed late.</div>

                <div className="mb-2 fw-bolder fs-6">GST Registration</div>
                <div className="">Companies with revenue of more than S$1 million (excluding dividends and interest) may need to register for GST. For details, refer to
                    <a className="px-1 icon-link icon-link-hover"
                        href="https://www.iras.gov.sg/taxes/goods-services-tax-(gst)/gst-registration-deregistration/do-i-need-to-register-for-gst"
                        target="_blank"
                    >
                        GST Registration
                        <i className="dx-icon dx-icon-arrowright"></i>
                    </a>
                    on IRAS' website.
                </div>
            </div>
        </>
    );
};

export default AcknowledgementView;
import * as Api from 'utils/api';
import { buildApiUrl } from 'utils/session';

const basePath = 'formCS';

export function getDraft(accountBookDataId) {
    let url = buildApiUrl(`${basePath}/getDraft`, { accountBookDataId });
    return Api.getData(url);
}

export function initiateFormCSFiling(accountBookDataId) {
    let url = buildApiUrl(`${basePath}/initiateFormCSFiling`, { accountBookDataId });
    return Api.putData(url);
}

export function generateDraft(accountBookDataId, data) {
    let url = buildApiUrl(`${basePath}/generateDraft`, { accountBookDataId });
    return Api.postData(url, data).then(response => response.json());
}

export function updateDraft(accountBookDataId, data) {
    let url = buildApiUrl(`${basePath}/updateDraft`, { accountBookDataId });
    return Api.putData(url, data);
}

export function deleteDraft(accountBookDataId) {
    let url = buildApiUrl(`${basePath}/deleteDraft`, { accountBookDataId });
    return Api.deleteData(url);
}

export function regenerateDraft(accountBookDataId, data) {
    let url = buildApiUrl(`${basePath}/regenerateDraft`, { accountBookDataId });
    return Api.postData(url, data).then(response => response.json());
}

export function submitFormCS(accountBookDataId, data) {
    let url = buildApiUrl(`${basePath}/submitForm`, { accountBookDataId });
    return Api.postData(url, data).then(response => response.json());
}

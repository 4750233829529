import React from 'react';
import { DateBox } from 'devextreme-react/date-box';
import * as DateUtils from 'utils/dateUtils';

const StandardDateBoxInner = ({ forwardedRef, children, width = '100%', allowNull = false, displayFormat, dateSerializationFormat, ...rest }) => {
    if (!displayFormat) {
        if (rest.type === 'date') displayFormat = 'dd/MM/yyyy';
        else if (rest.type === 'datetime') displayFormat = 'dd/MM/yyyy hh:mm aa';
    }

    if (!dateSerializationFormat) {
        if (rest.type === 'date') dateSerializationFormat = DateUtils.DATE_SERIALIZATION_FORMAT;
        else if (rest.type === 'datetime') dateSerializationFormat = DateUtils.DATETIME_SERIALIZATION_FORMAT;
    }

    return (
        <DateBox
            width={width}
            displayFormat={displayFormat}
            dateSerializationFormat={dateSerializationFormat}
            useMaskBehavior
            acceptCustomValue={false}
            showClearButton={allowNull}
            ref={forwardedRef}
            {...rest}
        >
            {children}
        </DateBox>
    );
};

// eslint-disable-next-line react/no-multi-comp
const StandardDateBox = React.forwardRef((props, ref) => <StandardDateBoxInner {...props} forwardedRef={ref} />);
StandardDateBox.displayName = 'StandardDateBox';

export default StandardDateBox;
import { Column, ColumnChooser, SearchPanel } from 'devextreme-react/data-grid';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import StandardDataGrid from 'components/StandardDataGrid';
import * as ApplicationSourceConstant from 'data/applicationSourceConstant';
import documentTypeConstant from '../data/documentTypeConstant';

const AgingDetailDataGrid = (props) => {
    const { accountBookData } = useAccountBookData();
    const applicationSource = accountBookData && accountBookData.applicationSource;

    const detailsDataSource = props.data?.data?.details;

    function getDocumentTypeDisplayText(row) {
        if (applicationSource === ApplicationSourceConstant.CloudAccounting) {
            const documentType = documentTypeConstant.find(x => x.docType === row.sourceType);
            return documentType?.name ?? row.sourceType;
        }

        return row.sourceType;
    }

    const currencyFormat = '#,##0.00';

    return (
        <StandardDataGrid
            dataSource={detailsDataSource}
        >
            <SearchPanel enabled={false} />
            <ColumnChooser enabled={false} />
            <Column
                dataField="docNo"
                caption="Doc. No."
                dataType="string"
            />
            <Column
                dataField="dueDate"
                caption="Due Date"
                dataType="date"
            />
            <Column
                dataField="docDate"
                caption="Doc. Date"
                dataType="date"
            />
            <Column
                dataField="sourceType"
                caption="Doc. Type"
                dataType="string"
                calculateCellValue={getDocumentTypeDisplayText}
            />
            <Column
                caption="Current Amount"
                dataType="number"
                format={currencyFormat}
                calculateCellValue={data => data.isCurrent ? data.outstanding : null}
            />
            <Column
                caption="Non-Current Amount"
                dataType="number"
                format={currencyFormat}
                calculateCellValue={data => !data.isCurrent ? data.outstanding : null}
            />
        </StandardDataGrid>
    );
};

export default AgingDetailDataGrid;
import RadioGroup from 'devextreme-react/radio-group';
import TextBox from 'devextreme-react/text-box';
import Validator from 'devextreme-react/validator';
import Popover from 'devextreme-react/popover';
import SelectBox from 'devextreme-react/select-box';
import StandardDateBox from 'components/StandardDateBox';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as AccountBookDataStatusConstant from 'data/accountBookDataStatusConstant';

const FinancialStatementEntryFilingInformation = (props) => {
    const { data, onChange } = props;

    const { accountBookData } = useAccountBookData();
    const readOnly = accountBookData.stepList.some(x => x === AccountBookDataStatusConstant.CompletedAnnualReturn);

    const xbrlFilePreparationMethodsDataSource = [
        { text: 'In-house', value: 'In-house' },
        { text: 'Outsourced', value: 'Outsourced' }
    ];

    const financialStatementNatureDataSource = [
        { text: 'Company Level', value: 'Company level' }
    ];

    const financialStatementPositionTypesDataSource = [
        { text: 'Current and non-current', value: 'Current and non-current' },
        { text: 'Order of liquidity', value: 'Order of liquidity' }
    ];

    const accountingStandardsDataSource = [
        { text: 'SFRS(I) (including simultaneous compliance with IFRS)', value: 'SFRS(I) (including simultaneous compliance with IFRS)' },
        { text: 'SFRS', value: 'SFRS' },
        { text: 'SFRS for Small entities', value: 'SFRS for Small entities' },
        { text: 'IFRS only', value: 'IFRS only' },
        { text: 'Other accounting standards', value: 'Other accounting standards' }
    ];

    const roundingLevelTypesDataSource = [
        { text: 'Actual', value: 'Actual' },
        { text: 'Thousands', value: 'Thousands' },
        { text: 'Millions', value: 'Millions' },
        { text: 'Billions', value: 'Billions' }
    ];

    const validationRules = {
        authorisationDate: [
            { type: 'required', message: 'Authorisation Date is required.' }
        ],
        accountingStandardType: [
            { type: 'required', message: 'Accounting Standard is required.' }
        ],
        roundingLevelType: [
            { type: 'required', message: 'Rounding Level is required.' }
        ],
        xbrlPreparationMethod: [
            { type: 'required', message: 'XBRL Preparation method is required.' }
        ],
        financialStatementPosition: [
            { type: 'required', message: 'Financial Position is required.' }
        ],
        financialStatementNature: [
            { type: 'required', message: 'Financial Statement Nature is required.' }
        ]
    };

    return (
        <div className="px-4 py-3">
            <div className="row mb-3">
                <div className="col-md-4">
                    <label className="form-label fw-bolder">Taxonomy Version</label>
                    <TextBox
                        value={data.taxonomyVersion}
                        readOnly
                    />
                </div>
                <div className="offset-md-2 col-md-5">
                    <label className="form-label fw-bolder">Software Name and Version used to generate XBRL file</label>
                    <TextBox
                        value={data.productVersion}
                        readOnly
                    />
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-4">
                    <label className="form-label fw-bolder">Type of XBRL filing</label>
                    <TextBox
                        value={data.xbrlFilingType}
                        readOnly
                    />
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-3">
                    <label className="form-label fw-bolder">Functional Currency of Company</label>
                    <TextBox
                        value={data.functionalCurrency}
                        readOnly
                    />
                </div>

                <div className="offset-md-3 col-md-3">
                    <label className="form-label fw-bolder">Presentation Currency</label>
                    <TextBox
                        value={data.presentationCurrency}
                        readOnly
                    />
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-3">
                    <label className="form-label required fw-bolder">Date of Authorisation for issue of Financial Statements</label>
                    <StandardDateBox
                        value={data.authorisationDate}
                        type="date"
                        readOnly={readOnly}
                        onValueChanged={(e) => onChange({ authorisationDate: e.value })}
                    >
                        <Validator validationRules={validationRules.authorisationDate} />
                    </StandardDateBox>
                </div>

                <div className="offset-md-3 col-md-5">
                    <label className="form-label required fw-bolder">Type of Accounting Standard used to prepare Financial Statements</label>
                    <i id="accountingStandard" className="dx-icon dx-icon-info px-2"></i>
                    <SelectBox
                        className="form-control"
                        dataSource={accountingStandardsDataSource}
                        displayExpr="text"
                        valueExpr="value"
                        value={data.accountingStandardType}
                        readOnly={readOnly}
                        onValueChanged={(e) => onChange({ accountingStandardType: e.value })}
                    >
                        <Validator validationRules={validationRules.accountingStandardType} />
                    </SelectBox>
                    <Popover
                        target="#accountingStandard"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        position="top"
                    >
                        SFRS = Singapore Financial Reporting Standards; IFRS = International Financial Reporting Standards; SFRS(I) = Singapore Financial Reporting Standards (International)
                    </Popover>
                    <div className="form-text">
                        Hint: Please refer <a href="https://www.acra.gov.sg/accountancy/accounting-standards" target="_blank" rel="noopener noreferrer">here</a> for more information
                    </div>
                </div>
            </div>


            <div className="row mb-3">
                <div className="col-md-3">
                    <label className="form-label required fw-bolder">Level of Rounding used in Financial Statements</label>
                    <SelectBox
                        className="form-control"
                        dataSource={roundingLevelTypesDataSource}
                        displayExpr="text"
                        valueExpr="value"
                        value={data.roundingLevelType}
                        readOnly={readOnly}
                        onValueChanged={(e) => onChange({ roundingLevelType: e.value })}
                    >
                        <Validator validationRules={validationRules.roundingLevelType} />
                    </SelectBox>
                </div>
                <div className="offset-md-3 col-md-3">
                    <label className="form-label required fw-bolder">How was XBRL file prepared</label>
                    <RadioGroup
                        layout="vertical"
                        dataSource={xbrlFilePreparationMethodsDataSource}
                        displayExpr="text"
                        valueExpr="value"
                        value={data.xbrlPreparationMethod}
                        readOnly={readOnly}
                        onValueChanged={(e) => onChange({ xbrlPreparationMethod: e.value })}
                    >
                        <Validator validationRules={validationRules.xbrlPreparationMethod} />
                    </RadioGroup>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-4">
                    <label className="form-label required fw-bolder">Type of Statement of financial Position</label>
                    <RadioGroup
                        layout="vertical"
                        dataSource={financialStatementPositionTypesDataSource}
                        displayExpr="text"
                        valueExpr="value"
                        value={data.financialStatementPosition}
                        readOnly={readOnly}
                        onValueChanged={(e) => onChange({ financialStatementPosition: e.value })}
                    >
                        <Validator validationRules={validationRules.financialStatementPosition} />
                    </RadioGroup>
                </div>

                <div className="offset-md-2 col-md-5">
                    <label className="form-label required fw-bolder">Nature of Financial Statements - Company level or consolidated</label>
                    <RadioGroup
                        layout="vertical"
                        dataSource={financialStatementNatureDataSource}
                        displayExpr="text"
                        valueExpr="value"
                        value={data.financialStatementNature}
                        readOnly={readOnly}
                        onValueChanged={(e) => onChange({ financialStatementNature: e.value })}
                    >
                        <Validator validationRules={validationRules.financialStatementNature} />
                    </RadioGroup>
                </div>
            </div>
        </div>
    );
};

export default FinancialStatementEntryFilingInformation;
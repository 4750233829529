import React from 'react';
import './Footer.scss';

export default function Footer() {
    return (
        <footer className="footer">
            AutoCount Seamless Filing Portal v{window.APP_SETTINGS_Version} &copy; {(new Date()).getFullYear()} Auto Count Sdn Bhd. All rights reserved.
        </footer>
    );
}

import React, { useImperativeHandle, useRef, useMemo } from 'react';
import { Accordion, Item } from 'devextreme-react/accordion';
import TextArea from 'devextreme-react/text-area';
import TextBox from 'devextreme-react/text-box';
import RadioGroup from 'devextreme-react/radio-group';
import Validator from 'devextreme-react/validator';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import CompanyOfficersAuditorsDataGrid from './components/CompanyOfficersAuditorsDataGrid';
import GroupShareInfoDataGrid from './components/GroupShareInfoDataGrid';
import RegisteredChargesDataGrid from './components/RegisteredChargesDataGrid';
import ShareCapitalDataGrid from './components/SharedCapitalDataGrid';
import ShareholderDataGrid from './components/ShareholderDataGrid';
import TreasuryShareDataGrid from './components/TreasuryShareDataGrid';
import * as DateUtils from 'utils/dateUtils';

const CompanyDetailsView = (props) => {
    const { accountBookData } = useAccountBookData();
    const companyName = accountBookData && accountBookData.companyName;

    const { profileData, formData, handleFormDataChange, forwardedRef } = props;
    const { remote, initiatedTimestamp } = profileData;

    const companyOfficersAuditorsData = remote && [].concat.apply([], [
        remote.directorsDetails,
        remote.managingDirectorsDetails,
        remote.alternateDirectorsDetails,
        remote.secretaryDetails,
        remote.ceoDetails
    ].filter(arr => arr !== null && arr));

    const {
        primaryActivity,
        secondaryActivity,
        registeredAddress,
        charges,
        shareCapitalDetails,
        shareHoldersDetails,
        treasuryShareDetails,
        groupShareInformation
    } = remote || {};

    const accordionRef = useRef();

    useImperativeHandle(forwardedRef, () => {
        return {
            expandView: () => {
                const items = accordionRef.current.instance.option('items');
                accordionRef.current.instance.option('selectedItemKeys', items);
            }
        };
    }, []);

    const registeredChargesView = useMemo(() => {
        return <RegisteredChargesDataGrid data={charges} />;
    }, [charges]);

    const companyOfficersAuditorsView = useMemo(() => {
        return <CompanyOfficersAuditorsDataGrid data={companyOfficersAuditorsData} />;
    }, [companyOfficersAuditorsData]);

    const shareCapitalView = useMemo(() => {
        return <ShareCapitalDataGrid data={shareCapitalDetails} />;
    }, [shareCapitalDetails]);

    const shareHolderView = useMemo(() => {
        return <ShareholderDataGrid data={shareHoldersDetails} />;
    }, [shareHoldersDetails]);

    const treasuryShareView = useMemo(() => {
        return <TreasuryShareDataGrid data={treasuryShareDetails} />;
    }, [treasuryShareDetails]);

    const groupShareInfoView = useMemo(() => {
        return <GroupShareInfoDataGrid data={groupShareInformation} />;
    }, [groupShareInformation]);

    const registrarController = [
        { text: 'Registered office of the company', value: 1 },
        { text: 'Registered office of a registered filing agent appointed by the company', value: 2 },
        { text: 'Exempted from the requirement to keep a register', value: 3 }
    ];
    const registerNomineeDirector = [
        { text: 'Registered office of the company', value: 1 },
        { text: 'Registered office of a registered filing agent appointed by the company', value: 2 },
        { text: 'Exempted from the requirement to keep a register', value: 3 }
    ];

    const validationRules = {
        registerControllersKept: [
            { type: 'required', message: 'This field is required.' }
        ],
        registerNomineeDirectorsKept: [
            { type: 'required', message: 'This field is required.' }
        ]
    };

    return (
        <div className="companyInfoTab">
            <div className="alert alert-warning">
                Please review the pre-filled information to ensure it is still relevant. To update, please <a href="https://www.bizfile.gov.sg/ngbbizfileinternet/faces/oracle/webcenter/portalapp/pages/BizfileHomepage.jspx" target="_blank" rel="noopener noreferrer">visit the BizFile+</a> portal to make the changes.
            </div>

            <Accordion
                onInitialized={(e) => {
                    const items = e.component.option('items');
                    e.component.option('selectedItemKeys', items);
                }}
                autoExpandAll
                collapsible
                multiple
                ref={accordionRef}
            >
                <Item
                    key="companyInfo"
                    title={`Company Details (as at ${DateUtils.formatDate(initiatedTimestamp)})`}
                >
                    <div className="px-4 py-3">
                        <div className="form-row d-flex">
                            <div className="col-md-5 col-sm-4">
                                <label className="form-label">Company Name</label>
                                <TextBox
                                    className="form-control"
                                    value={companyName}
                                    readOnly
                                />
                            </div>

                            <div className="offset-md-1 offset-sm-1 col-md-5 col-sm-4">
                                <label className="form-label">UEN</label>
                                <TextBox
                                    className="form-control"
                                    value={profileData.local.companyUEN}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </Item>
                <Item
                    key="activitySection"
                    title="Primary/Secondary Activity(ies) Section"
                >
                    <div className="px-4 py-3">
                        <div className="form-row d-flex">
                            <div className="col-md-5">
                                <label className="form-label">Primary Activity</label>
                                <TextBox
                                    className="form-control"
                                    value={primaryActivity && primaryActivity.id}
                                    readOnly
                                />
                            </div>
                            <div className="offset-md-1 col-md-6">
                                <label className="form-label">Primary Activity Name</label>
                                <TextBox
                                    className="form-control"
                                    value={primaryActivity && primaryActivity.name}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="form-row mt-4">
                            <div className="col-md-5">
                                <label className="form-label">Primary User-Described Activity</label>
                                <TextBox
                                    className="form-control"
                                    value={primaryActivity && primaryActivity.description}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="form-row d-flex mt-4">
                            <div className="col-md-5">
                                <label className="form-label">Secondary Activity</label>
                                <TextBox
                                    className="form-control"
                                    value={secondaryActivity && secondaryActivity.id}
                                    readOnly
                                />
                            </div>
                            <div className="offset-md-1 col-md-6">
                                <label className="form-label">Secondary Activity Name</label>
                                <TextBox
                                    className="form-control"
                                    value={secondaryActivity && secondaryActivity.name}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="form-row mt-4">
                            <div className="col-md-5">
                                <label className="form-label">Secondary User-Described Activity</label>
                                <TextBox
                                    className="form-control"
                                    value={secondaryActivity && secondaryActivity.description}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </Item>
                <Item
                    key="addressSection"
                    title="Address Section"
                >
                    <div className="px-4 py-3">
                        <div className="form-row">
                            <div className="col-md-5">
                                <label className="form-label">Registered Office Address</label>
                                <TextArea
                                    className="form-control"
                                    height="150px"
                                    value={registeredAddress && `${registeredAddress.blockHouse} ${registeredAddress.streetName}\n${(registeredAddress.level && registeredAddress.unit) ? `${registeredAddress.level}-${registeredAddress.unit}\n` : ''}${registeredAddress.postal ? `Singapore ${registeredAddress.postal}` : 'Singapore'}`}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </Item>
                <Item
                    key="registeredChargesSection"
                    title="Registered Charges Section"
                    render={() => registeredChargesView}
                />
                <Item
                    key="companyOfficersAuditor"
                    title="Particulars of Company Officers and Auditor"
                    render={() => companyOfficersAuditorsView}
                >
                </Item>
                <Item
                    key="sharedCapitalDetails"
                    title="Share Capital Details"
                    render={() => shareCapitalView}
                >
                </Item>
                <Item
                    key="memberDetails"
                    title="Member Details"
                    render={() => shareHolderView}
                >
                </Item>
                <Item
                    key="treasuryShare"
                    title="Treasury Share"
                    render={() => treasuryShareView}
                />
                <Item
                    key="groupShare"
                    title="Group Share Information"
                    render={() => groupShareInfoView}
                >
                </Item>
                <Item
                    key="nomineeDetails"
                    title="Register of Controllers and Nominee Details"
                >
                    <div className="px-4 py-3">
                        <div className="alert alert-warning">
                            Companies are required to maintain its beneficial ownership information in the register of controllers and to
                            keep a register of its nominee director containing the nominators' particulars. Please declare the location where the registers are kept.
                        </div>

                        <div className="form-row mb-4">
                            <label className="form-label required">Where the Register of Controllers are kept.</label>
                            <RadioGroup
                                layout="vertical"
                                dataSource={registrarController}
                                displayExpr="text"
                                valueExpr="value"
                                value={formData.registerControllersKept}
                                onValueChanged={e => handleFormDataChange({ registerControllersKept: e.value })}
                            >
                                <Validator validationRules={validationRules.registerControllersKept} />
                            </RadioGroup>
                        </div>

                        <div className="form-row">
                            <label className="form-label required">Where the Register of Nominee Directors are kept.</label>
                            <RadioGroup
                                layout="vertical"
                                dataSource={registerNomineeDirector}
                                displayExpr="text"
                                valueExpr="value"
                                value={formData.registerNomineeDirectorsKept}
                                onValueChanged={e => handleFormDataChange({ registerNomineeDirectorsKept: e.value })}
                            >
                                <Validator validationRules={validationRules.registerNomineeDirectorsKept} />
                            </RadioGroup>
                        </div>
                    </div>
                </Item>
            </Accordion>
        </div>
    );
};

// eslint-disable-next-line react/no-multi-comp
const CompanyDetailsViewWithForwardedRef = React.forwardRef((props, ref) => <CompanyDetailsView {...props} forwardedRef={ref} />);
CompanyDetailsViewWithForwardedRef.displayName = 'CompanyDetailsView';

export default CompanyDetailsViewWithForwardedRef;
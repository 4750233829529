import {
    Page,
    StyleSheet,
    Text,
    View
} from '@react-pdf/renderer';

const PartCPdfView = (props) => {
    const { formData } = props;
    let i = 0;

    const styles = StyleSheet.create({
        page: {
            padding: 40
        },
        header: {
            fontSize: 15,
            fontWeight: 1000,
            marginBottom: 10,
            display: 'table',
            width: 'auto',
            textDecoration: 'underline',
            textAlign: 'center'
        },
        normalText: {
            fontSize: 8,
            margin: 5
        },
        table: {
            display: 'table',
            width: 'auto',
            borderStyle: 'solid',
            borderWidth: 1
        },
        tableHeader: {
            backgroundColor: '#21a84a',
            color: 'white',
            fontWeight: 1000
        },
        tableHeaderText: {
            fontSize: 10,
            margin: 5,
            fontWeight: 1000
        }
    });

    const financialStatementInformationDatasource = [
        {
            label: 'Revenue',
            value: formData.dataFormCS.totalRevenue
        },
        {
            label: 'Gross Profit/ Loss',
            value: formData.dataFormCS.grossPL
        },
        {
            label: 'Directors\' Fees and Remuneration',
            value: formData.dataFormCS.directorFee
        },
        {
            label: 'Total Remuneration excluding Directors\' Fees',
            value: formData.dataFormCS.totRemuneration
        },
        {
            label: 'Medical Expenses',
            value: formData.dataFormCS.medicalExp
        },
        {
            label: 'Transport/ Travelling Expenses',
            value: formData.dataFormCS.c1_TransportExp
        },
        {
            label: 'Entertainment Expenses',
            value: formData.dataFormCS.c1_EntertainExp
        },
        {
            label: 'Inventories',
            value: formData.dataFormCS.inventories
        },
        {
            label: 'Trade Receivables',
            value: formData.dataFormCS.tradeReceivables
        }
    ];

    const generateRow = (data) => {
        return (
            <View key={++i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                    <Text>{data.label}</Text>
                </View>
                <View style={[styles.normalText, { width: 120, alignItems: 'flex-end' }]}>
                    <Text>{formatValue(data.value)}</Text>
                </View>
            </View>
        );
    };

    const formatValue = (value) => {
        if (typeof value === 'number') {
            return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
        return value;
    };

    return (
        <Page size="A4" style={styles.page}>
            <View style={styles.container}>
                <View style={styles.header}>
                    <Text>Part C</Text>
                </View>
            </View>
            <View style={styles.table}>
                <View style={styles.tableHeader}>
                    <Text style={styles.tableHeaderText}>Information from Financial Statement</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                    {financialStatementInformationDatasource.map(data => generateRow(data))}
                </View>
                <View style={styles.tableHeader}>
                    <Text style={styles.tableHeaderText}>Deduction of Unutilised Capital Allowances/ Losses/ Donations Brought Forward</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text>Is there a change in the principal activities of the company during the basis period?</Text>
                        </View>
                        <View style={[styles.normalText, { width: 100, alignItems: 'flex-end' }]}>
                            <Text>{formData.dataFormCS.uCALDChangePrinAct !== '' ? formData.dataFormCS.uCALDChangePrinAct === '1' ? 'Yes' : 'No' : 'N.A (Not Applicable)'}</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text>Is there a substantial change in the company\'s ultimate shareholders and their shareholdings as at the relevant dates?</Text>
                        </View>
                        <View style={[styles.normalText, { width: 100, alignItems: 'flex-end' }]}>
                            <Text>{formData.dataFormCS.sholderChange !== '' ? formData.dataFormCS.sholderChange === '1' ? 'Yes' : 'No' : 'N.A (Not Applicable)'}</Text>
                        </View>
                    </View>
                    {generateRow({ label: 'Unutilised Capital Allowances/ Losses/ Donations claimed where waiver of the shareholding test has been/ will be granted by IRAS', value: formData.dataFormCS.unutilCALDClaimS23S37 })}
                </View>
                <View style={styles.tableHeader}>
                    <Text style={styles.tableHeaderText}>Tax Exemption Scheme for New Start-up Companies</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text>Has the company satisfied all conditions to qualify for the tax exemption scheme for new start-up companies?</Text>
                        </View>
                        <View style={[styles.normalText, { width: 100, alignItems: 'flex-end' }]}>
                            <Text>{formData.dataFormCS.fullTxX !== '' ? formData.dataFormCS.fullTxX === '1' ? 'Yes' : 'No' : 'N.A (Not Applicable)'}</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.tableHeader}>
                    <Text style={styles.tableHeaderText}>Deduction for Expenditure on Research and Development (R&D)</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <View key={++i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text>Deduction for expenditure incurred on R&D (undertaken in Singapore and Overseas) - Total deduction under Section 14C</Text>
                        </View>
                        <View style={[styles.normalText, { width: 120, alignItems: 'flex-end' }]}>
                            <Text>{formatValue(formData.dataFormCS.expRD)}</Text>
                        </View>
                    </View>
                    <View key={++i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text>Section 14C deduction for expenditure incurred on R&D activities <Text style={{ textDecoration: 'underline' }}>undertaken in Singapore</Text></Text>
                        </View>
                        <View style={[styles.normalText, { width: 120, alignItems: 'flex-end' }]}>
                            <Text>{formatValue(formData.dataFormCS.expRDSG)}</Text>
                        </View>
                    </View>
                    <View key={++i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text>Staff costs and consumables for R&D activities <Text style={{ textDecoration: 'underline' }}>undertaken in Singapore</Text> qualifying for the additional 150% tax deduction under Section 14D(1) in excess of the $400,000 cap under the Enterprise Innovation Scheme</Text>
                        </View>
                        <View style={[styles.normalText, { width: 120, alignItems: 'flex-end' }]}>
                            <Text>{formatValue(formData.dataFormCS.enhanceDeductRD)}</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.tableHeader}>
                    <Text style={styles.tableHeaderText}>Appropriation of Trading Stock or Conversion of Non-Trade/ Capital Asset</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text>Has the company appropriated any trading stock for non-trade or capital purposes under Section 10J and/ or converted any non-trade or capital asset to trading stock under Section 32A?</Text>
                        </View>
                        <View style={[styles.normalText, { width: 100, alignItems: 'flex-end' }]}>
                            <Text>{formData.dataFormCS.appStockConvAsset === 1 ? 'Yes' : 'No'}</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.tableHeader}>
                    <Text style={styles.tableHeaderText}>Enterprise Innovation Scheme (EIS)</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text>Is the company claiming cash payout under the EIS in this current YA?</Text>
                        </View>
                        <View style={[styles.normalText, { width: 100, alignItems: 'flex-end' }]}>
                            <Text>{formData.dataFormCS.eis_ClaimCashPayout === 1 ? 'Yes' : 'No'}</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text>Is the company claiming enhanced deductions/ allowances under the EIS in this current YA?</Text>
                        </View>
                        <View style={[styles.normalText, { width: 100, alignItems: 'flex-end' }]}>
                            <Text>{formData.dataFormCS.eis_ClaimDedAll === 1 ? 'Yes' : 'No'}</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text style={{ textDecoration: 'underline' }}>Training</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text>Total Qualifying Cost Incurred</Text>
                        </View>
                        <View style={[styles.normalText, { width: 120, alignItems: 'flex-end' }]}>
                            <Text>{formatValue(formData.dataFormCS.eis_TrainTotCost)}</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text>Enhanced Deductions/ Allowances Claimed/ To Be Claimed</Text>
                        </View>
                        <View style={[styles.normalText, { width: 120, alignItems: 'flex-end' }]}>
                            <Text>{formatValue(formData.dataFormCS.eis_TrainDedAll)}</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text style={{ textDecoration: 'underline' }}>Innovation Projects Carried Out with Partner Institutions</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text>Total Qualifying Cost Incurred (capped at $50,000)</Text>
                        </View>
                        <View style={[styles.normalText, { width: 120, alignItems: 'flex-end' }]}>
                            <Text>{formatValue(formData.dataFormCS.eis_InnoProjTotCost)}</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text>Enhanced Deductions/ Allowances Claimed/ To Be Claimed</Text>
                        </View>
                        <View style={[styles.normalText, { width: 120, alignItems: 'flex-end' }]}>
                            <Text>{formatValue(formData.dataFormCS.eis_InnoProjDedAll)}</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text style={{ textDecoration: 'underline' }}>Acquisition of Intellectual Property Rights (IPRs)</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text>Total Qualifying Cost Incurred</Text>
                        </View>
                        <View style={[styles.normalText, { width: 120, alignItems: 'flex-end' }]}>
                            <Text>{formatValue(formData.dataFormCS.eis_AcqIPRTotCost)}</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text>Enhanced Deductions/ Allowances Claimed/ To Be Claimed</Text>
                        </View>
                        <View style={[styles.normalText, { width: 120, alignItems: 'flex-end' }]}>
                            <Text>{formatValue(formData.dataFormCS.eis_AcqIPRDedAll)}</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text style={{ textDecoration: 'underline' }}>Licensing of Intellectual Property Rights (IPRs)</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text>Total Qualifying Cost Incurred</Text>
                        </View>
                        <View style={[styles.normalText, { width: 120, alignItems: 'flex-end' }]}>
                            <Text>{formatValue(formData.dataFormCS.eis_LicensIPRTotCost)}</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text>Enhanced Deductions/ Allowances Claimed/ To Be Claimed</Text>
                        </View>
                        <View style={[styles.normalText, { width: 120, alignItems: 'flex-end' }]}>
                            <Text>{formatValue(formData.dataFormCS.eis_LicensIPRDedAll)}</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text style={{ textDecoration: 'underline' }}>Registration of Intellectual Property (IPs)</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text>Total Qualifying Cost Incurred</Text>
                        </View>
                        <View style={[styles.normalText, { width: 120, alignItems: 'flex-end' }]}>
                            <Text>{formatValue(formData.dataFormCS.eis_RegIPTotCost)}</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text>Enhanced Deductions/ Allowances Claimed/ To Be Claimed</Text>
                        </View>
                        <View style={[styles.normalText, { width: 120, alignItems: 'flex-end' }]}>
                            <Text>{formatValue(formData.dataFormCS.eis_RegIPDedAll)}</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text style={{ textDecoration: 'underline' }}>Qualifying Research and Development (R&D) Undertaken in Singapore</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text>Total Qualifying Cost Incurred</Text>
                        </View>
                        <View style={[styles.normalText, { width: 120, alignItems: 'flex-end' }]}>
                            <Text>{formatValue(formData.dataFormCS.eis_RDSgTotCost)}</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                            <Text>Enhanced Deductions/ Allowances Claimed/ To Be Claimed</Text>
                        </View>
                        <View style={[styles.normalText, { width: 120, alignItems: 'flex-end' }]}>
                            <Text>{formatValue(formData.dataFormCS.eis_RDSgDedAll)}</Text>
                        </View>
                    </View>
                </View>
            </View>
        </Page>
    );
};

export default PartCPdfView;
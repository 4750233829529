import { useState } from 'react';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import { NumberBox } from 'devextreme-react/number-box';
import { ToolbarItem } from 'devextreme-react/popup';
import { SelectBox } from 'devextreme-react/select-box';
import { TextArea } from 'devextreme-react/text-area';
import { TextBox } from 'devextreme-react/text-box';
import { Validator, RequiredRule, RangeRule, CustomRule } from 'devextreme-react/validator';
import { SubmitButton } from 'components/StandardButton';
import StandardPopup from 'components/StandardPopup';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as AccountBookDataStatusConstant from 'data/accountBookDataStatusConstant';

const FixedAssetDataDialog = (props) => {
    const { initialData, onSubmit, onCancel } = props;

    const { accountBookData } = useAccountBookData();
    const readOnly = accountBookData.stepList.some(x => x === AccountBookDataStatusConstant.CompletedFormCS);
    const yearOfAssessment = accountBookData.yearOfAssessment;

    const [data, setData] = useState({
        acquisitionInYearOfAssessment: yearOfAssessment,
        acquisitionPrice: 0,
        disposalPrice: 0,
        capitalAllowanceType: null,
        principalPaidDuringYa: 0,
        principalPaidDuringYaMinus1: 0,
        principalPaidDuringYaMinus2: 0,
        principalPaidBeforeYaMinus2: 0,
        isHirePurchase: false,
        isDisposed: false,
        ...initialData
    });

    const nonHirePurchaseCapitalAllowanceTypes = [
        'Computer and Communication Equipment',
        'Other Property, Plant and Equipment',
        'Other Property, Plant and Equipment (Low Value)'
    ];

    const hirePurchaseCapitalAllowanceTypes = [
        'Other Property, Plant and Equipment'
    ];

    function handleSubmit(e) {
        e.preventDefault();
        onSubmit(data);
    }

    return (
        <StandardPopup
            visible
            onHiding={onCancel}
            dragEnabled={false}
            width={1000}
            title="Fixed Asset Entry"
        >
            <form id="fixedAssetForm" onSubmit={handleSubmit}>
                <div className="content-block px-3 pt-0">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <label className="form-label fw-bolder">Description</label>
                            <TextBox
                                value={data.description}
                                onValueChanged={e => setData({ ...data, description: e.value })}
                                maxLength={100}
                                readOnly={readOnly}
                            >
                                <Validator>
                                    <RequiredRule message="Description is required" />
                                </Validator>
                            </TextBox>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label className="form-label fw-bolder">Purchase in Year Of Assessment</label>
                            <NumberBox
                                value={data.acquisitionInYearOfAssessment}
                                onValueChanged={e => setData({ ...data, acquisitionInYearOfAssessment: e.value })}
                                showSpinButtons
                                readOnly={readOnly}
                            >
                                <Validator>
                                    <RequiredRule message="Year Of Assessment for Purchase is required" />
                                    <CustomRule
                                        validationCallback={e => {
                                            return (data.isDisposed && e.value <= yearOfAssessment) ||
                                                   (data.capitalAllowanceType !== 'Other Property, Plant and Equipment' && e.value === yearOfAssessment) ||
                                                   (data.capitalAllowanceType === 'Other Property, Plant and Equipment' && e.value >= yearOfAssessment - 2 && e.value <= yearOfAssessment);
                                        }}
                                        message={data.isDisposed ? `The purchase year for the disposed asset should be ${yearOfAssessment} or earlier.` : `Capital Allowance Type ('Other Property, Plant and Equipment') can only accept years ${yearOfAssessment - 2}, ${yearOfAssessment - 1}, and ${yearOfAssessment}, meanwhile other Capital Allowance Type can only accept year ${yearOfAssessment}`}
                                    />
                                </Validator>
                            </NumberBox>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label fw-bolder">Purchase Price</label>
                            <NumberBox
                                value={data.acquisitionPrice}
                                onValueChanged={e => setData({ ...data, acquisitionPrice: e.value })}
                                format="#0.00"
                                readOnly={readOnly}
                            >
                                <Validator>
                                    <RequiredRule message="Purchase Price is required." />
                                    <RangeRule
                                        min={0}
                                        message="Purchase Price must not be negative."
                                    />
                                    <CustomRule
                                        validationCallback={e => {
                                            if (data.capitalAllowanceType === 'Other Property, Plant and Equipment (Low Value)')
                                                return e.value <= 5000;
                                            else
                                                return true;
                                        }}
                                        message="Purchase Price cannot exceed 5000 for Low Value Asset."
                                    />
                                </Validator>
                            </NumberBox>
                        </div>
                    </div>
                    <div className="dx-card p-3 mb-3">
                        <div className="row pb-2">
                            <div className="col-6">
                                <CheckBox
                                    value={data.isHirePurchase}
                                    onValueChanged={e => {
                                        const capitalAllowanceTypeDataChange = e.value && !hirePurchaseCapitalAllowanceTypes.includes(data.capitalAllowanceType) && { capitalAllowanceType: null };
                                        setData({ ...data, ...capitalAllowanceTypeDataChange, isHirePurchase: e.value });
                                    }}
                                    text="Is Hire Purchase (HP)"
                                    readOnly={readOnly}
                                />
                            </div>
                        </div>
                        <div className={`${data.isHirePurchase ? '' : 'd-none'}`}>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="form-label fw-bolder">Total Principal Paid Before YA {yearOfAssessment - 2}</label>
                                    <NumberBox
                                        value={data.principalPaidBeforeYaMinus2}
                                        onValueChanged={e => setData({ ...data, principalPaidBeforeYaMinus2: e.value })}
                                        format="#0.00"
                                        readOnly={readOnly}
                                    >
                                        <Validator>
                                            <RangeRule
                                                min={0}
                                                message={`Deposit/ Principal Paid before YA ${yearOfAssessment - 2} cannot be negative`}
                                            />
                                        </Validator>
                                    </NumberBox>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label className="form-label fw-bolder">Deposit/ Principal Paid during YA {yearOfAssessment - 2}</label>
                                    <NumberBox
                                        value={data.principalPaidDuringYaMinus2}
                                        onValueChanged={e => setData({ ...data, principalPaidDuringYaMinus2: e.value })}
                                        format="#0.00"
                                        readOnly={readOnly}
                                    >
                                        <Validator>
                                            <RangeRule
                                                min={0}
                                                message={`Deposit/ Principal Paid during YA ${yearOfAssessment - 2} cannot be negative`}
                                            />
                                        </Validator>
                                    </NumberBox>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="form-label fw-bolder">Deposit/ Principal Paid during YA {yearOfAssessment - 1}</label>
                                    <NumberBox
                                        value={data.principalPaidDuringYaMinus1}
                                        onValueChanged={e => setData({ ...data, principalPaidDuringYaMinus1: e.value })}
                                        format="#0.00"
                                        readOnly={readOnly}
                                    >
                                        <Validator>
                                            <RangeRule
                                                min={0}
                                                message={`Deposit/ Principal Paid during YA ${yearOfAssessment - 1} cannot be negative`}
                                            />
                                        </Validator>
                                    </NumberBox>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label className="form-label fw-bolder">Deposit/ Principal Paid during YA {yearOfAssessment}</label>
                                    <NumberBox
                                        value={data.principalPaidDuringYa}
                                        onValueChanged={e => setData({ ...data, principalPaidDuringYa: e.value })}
                                        format="#0.00"
                                        readOnly={readOnly}
                                    >
                                        <Validator>
                                            <RangeRule
                                                min={0}
                                                message={`Deposit/ Principal Paid during YA ${yearOfAssessment} cannot be negative`}
                                            />
                                        </Validator>
                                    </NumberBox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label className="form-label fw-bolder">Capital Allowance Type</label>
                            <SelectBox
                                dataSource={data.isHirePurchase ? hirePurchaseCapitalAllowanceTypes : nonHirePurchaseCapitalAllowanceTypes}
                                value={data.capitalAllowanceType}
                                onValueChanged={e => setData({ ...data, capitalAllowanceType: e.value })}
                                showClearButton
                                readOnly={readOnly}
                            >
                                <Validator>
                                    <RequiredRule message="Capital Allowance Type is required." />
                                </Validator>
                            </SelectBox>
                        </div>
                    </div>
                    <div className="dx-card p-3 mb-3">
                        <div className="row pb-2">
                            <div className="col-auto">
                                <CheckBox
                                    value={data.isDisposed}
                                    onValueChanged={(e) => setData(
                                        {
                                            ...data,
                                            isDisposed: e.value,
                                            disposalInYearOfAssessment: (e.value ? yearOfAssessment : null)
                                        }
                                    )}
                                    text="Is Disposed"
                                    readOnly={readOnly}
                                />
                            </div>

                        </div>
                        <div className={`row ${data.isDisposed ? '' : 'd-none'}`}>
                            <div className="col-md-6">
                                <label className="form-label fw-bolder">Disposed in Year Of Assessment</label>
                                <NumberBox
                                    value={data.disposalInYearOfAssessment}
                                    readOnly
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label fw-bolder">Disposal Price</label>
                                <NumberBox
                                    value={data.disposalPrice}
                                    onValueChanged={e => setData({ ...data, disposalPrice: e.value })}
                                    disabled={!data.isDisposed}
                                    format="#0.00"
                                    readOnly={readOnly}
                                >
                                    <Validator>
                                        <RangeRule
                                            min={0}
                                            message="Disposal Price must not be negative"
                                        />
                                    </Validator>
                                </NumberBox>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <label className="form-label fw-bolder">Remark</label>
                            <TextArea
                                minHeight="150px"
                                value={data.remark}
                                onValueChanged={e => setData({ ...data, remark: e.value })}
                                maxLength={500}
                                readOnly={readOnly}
                            />
                        </div>
                    </div>
                </div >
            </form >
            <ToolbarItem
                toolbar="bottom"
                location="after"
                render={() => (
                    <Button
                        text={readOnly ? 'Close' : 'Cancel'}
                        onClick={() => onCancel()}
                    />
                )}
            />
            <ToolbarItem
                toolbar="bottom"
                location="after"
                visible={!readOnly}
                render={() => (
                    <SubmitButton
                        text="Save"
                        submitForm="fixedAssetForm"
                    />
                )}
            />
        </StandardPopup >
    );
};

export default FixedAssetDataDialog;
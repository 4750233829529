import { useState, useMemo } from 'react';
import CustomStore from 'devextreme/data/custom_store';
import { SelectBox } from 'devextreme-react/select-box';
import Button from 'devextreme-react/button';
import { ToolbarItem } from 'devextreme-react/popup';
import StandardPopup from 'components/StandardPopup';
import { SubmitButton } from 'components/StandardButton';
import * as FinancialStatementApi from '../../../api/financialStatementApi';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as Toast from 'utils/toast';

const FinancialStatementDownloadFileDialog = (props) => {
    const { financialStatementId, onCancel } = props;
    const [currentSelectedFile, setCurrentSelectedFile] = useState(null);

    const { accountBookData } = useAccountBookData();
    const accountBookDataId = accountBookData && accountBookData.accountBookDataId;

    const downloadFileDataSource = useMemo(() => new CustomStore({
        key: 'fileName',
        loadMode: 'raw',
        load: function () {
            return FinancialStatementApi.getFinancialStatementXBRLFileDownloadList(accountBookDataId, financialStatementId);
        }
    }), [accountBookDataId, financialStatementId]);

    function handleSubmit(e) {
        e.preventDefault();

        if (currentSelectedFile) {
            window.open(currentSelectedFile, '_blank', 'noopener');
        } else {
            Toast.showErrorMessage('Unable to download file as no file is selected.');
        }
    }

    return (
        <StandardPopup
            visible
            onHiding={onCancel}
            showCloseButton
            showTitle
            title="Download Financial Statement XBRL File"
            position="center"
            width="480px"
            height="auto"
        >
            <form id="frmDownloadFile" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <label className="form-label required fw-bolder">File</label>
                        <SelectBox
                            dataSource={downloadFileDataSource}
                            displayExpr="fileName"
                            valueExpr="fileUrl"
                            onValueChanged={e => setCurrentSelectedFile(e.value)}
                        />
                    </div>
                </div>
            </form>

            <ToolbarItem
                toolbar="bottom"
                location="after"
                locateInMenu="auto"
            >
                <div>
                    <Button
                        className="mx-2"
                        text="Close"
                        onClick={onCancel}
                    />
                    <SubmitButton
                        className="mx-2"
                        text="Download"
                        submitForm="frmDownloadFile"
                    >
                    </SubmitButton>
                </div>
            </ToolbarItem>
        </StandardPopup>
    );
};

export default FinancialStatementDownloadFileDialog;
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/themes/generated/theme.base.css';
import './css/themes/generated/theme.button.css';
import './css/dx-styles.scss';
import './css/application.css';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { AccountBookDataProvider } from './contexts/AccountBookDataContext';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';

export default function App() {
    const screenSizeClass = useScreenSizeClass();
    const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

    return (
        <BrowserRouter baseName={baseUrl}>
            <AuthProvider>
                <AccountBookDataProvider>
                    <div className={`app ${screenSizeClass}`}>
                        <Content />
                    </div>
                </AccountBookDataProvider>
            </AuthProvider>
        </BrowserRouter>
    );
}

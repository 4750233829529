import React, { useState, useMemo } from 'react';
import { Accordion, Item } from 'devextreme-react/accordion';
import CheckBox from 'devextreme-react/check-box';
import { ToolbarItem } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { Column, Sorting, Toolbar } from 'devextreme-react/data-grid';
import StandardPopup from 'components/StandardPopup';
import StandardDataGrid from 'components/StandardDataGrid';
import StandardDateBox from 'components/StandardDateBox';
import { PrimaryButton, SubmitButton } from 'components/StandardButton';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import FinancialStatementEntryEntityDetailsView from '../../InitialForm/components/FinancialStatementEntryEntityDetailsView';
import FinancialStatementEntryFinancialPeriodDetails from '../../InitialForm/components/FinancialStatementEntryFinancialPeriodDetails';
import FinancialStatementEntryFilingInformation from '../../InitialForm/components/FinancialStatementEntryFilingInformation';
import * as FinancialStatementApi from '../../../api/financialStatementApi';
import * as MessageBox from 'utils/messageBox';
import * as ErrorMessage from 'utils/errorMessage';
import * as Toast from 'utils/toast';
import * as PageUtils from 'utils/pageUtils';

const FinancialStatementAcknowledgementDialog = (props) => {
    const { financialStatementId, data, entryData, onAfterAcknowledgeAndUpload, onCancel } = props;
    const [isCurrentYearFinancialInfo, setIsCurrentYearFinancialInfo] = useState(true);
    const [isAcknowledgeAndConfirm, setIsAcknowledgeAndConfirm] = useState(false);

    const { accountBookData } = useAccountBookData();
    const accountBookDataId = accountBookData && accountBookData.accountBookDataId;

    const currentYearFinancialInfo = useMemo(() => {
        return data.currentYearFinancialInfoInJson ? JSON.parse(data.currentYearFinancialInfoInJson) : [];
    }, [data]);

    const priorYearFinancialInfo = useMemo(() => {
        return data.priorYearFinancialInfoInJson ? JSON.parse(data.priorYearFinancialInfoInJson) : [];
    }, [data]);

    function handleSubmit(e) {
        e.preventDefault();

        MessageBox.showConfirmMessage('Are you sure you want to Acknowledge and Upload this XBRL to ACRA?', null, 'Yes, upload it', 'No, don\'t upload it')
            .then(result => {
                if (result.confirm) {
                    acknowledgeAndUploadFinancialStatementXBRL();
                }
            });
    }

    function acknowledgeAndUploadFinancialStatementXBRL() {
        PageUtils.blockPage();

        FinancialStatementApi.acknowledgeAndUploadFinancialStatementXBRL(accountBookDataId, financialStatementId)
            .then(() => {
                PageUtils.unblockPage();
                onAfterAcknowledgeAndUpload();
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    }

    return (
        <StandardPopup
            visible
            onHiding={onCancel}
            showCloseButton
            showTitle
            fullScreen
            title="Acknowledge and Upload Financial Statement XBRL"
            position="center"
        >
            <form id="frmAcknowledgeAndUpload" onSubmit={handleSubmit}>
                <div className="alert alert-warning">
                    Please confirm if the information shown below is <b>correct and accurate</b>. If yes, then please tick the acknowledgement checkbox at the bottom and click "Submit"
                </div>
                <Accordion
                    onInitialized={(e) => {
                        const items = e.component.option('items');
                        e.component.option('selectedItemKeys', items);
                    }}
                    autoExpandAll
                    collapsible
                    multiple
                >
                    <Item
                        key="entityDetails"
                        title="Entity Details"
                        render={() => <FinancialStatementEntryEntityDetailsView data={entryData} readOnly />}
                    />

                    <Item
                        key="financialPeriodDetails"
                        title="Financial Period Details"
                        render={() => <FinancialStatementEntryFinancialPeriodDetails data={entryData} />}
                    />

                    <Item
                        key="filingInformation"
                        title="Filing Information"
                        render={() => <FinancialStatementEntryFilingInformation data={entryData} readOnly />}
                    />

                    <Item
                        key="directorStatementAuditorReport"
                        title="Financial Statement XBRL Summary Data"
                    >
                        <div className="px-4 py-3">
                            <div className="d-flex align-items-end mb-2">
                                <label className="fw-bolder align-self-center">
                                    Financial Year Period:
                                </label>

                                <StandardDateBox
                                    className="mx-2 my-auto"
                                    value={isCurrentYearFinancialInfo ? entryData.currentYearStartDate : entryData.priorYearStartDate}
                                    width={100}
                                    readOnly
                                    type="date"
                                />

                                <div className="align-self-center">
                                    to
                                </div>

                                <StandardDateBox
                                    className="mx-2 my-auto"
                                    value={isCurrentYearFinancialInfo ? entryData.currentYearEndDate : entryData.priorYearEndDate}
                                    width={100}
                                    readOnly
                                    type="date"
                                />
                                {data.priorYearFinancialInfoInJson &&
                                    <div className="ms-auto">
                                        <PrimaryButton
                                            text={isCurrentYearFinancialInfo ? 'Switch to Prior Financial Statement Summary Data' : 'Switch to Current Financial Statement Summary Data'}
                                            onClick={() => setIsCurrentYearFinancialInfo(!isCurrentYearFinancialInfo)}
                                        />
                                    </div>
                                }
                            </div>
                            <StandardDataGrid
                                dataSource={isCurrentYearFinancialInfo ? currentYearFinancialInfo : priorYearFinancialInfo}
                            >
                                <Column
                                    caption="Description"
                                    dataField="description"
                                    dataType="string"
                                />
                                <Column
                                    caption="Amount"
                                    dataField="amount"
                                    dataType="number"
                                    format="#,##0.00"
                                />
                                <Toolbar visible={false} />
                                <Sorting mode="none" />
                            </StandardDataGrid>
                        </div>
                    </Item>
                </Accordion>
            </form>

            <ToolbarItem
                toolbar="bottom"
                location="before"
                locateInMenu="auto"
            >
                <CheckBox
                    text="I acknowledge and confirm that the XBRL data shown above is correct and accurate."
                    value={isAcknowledgeAndConfirm}
                    onValueChanged={e => setIsAcknowledgeAndConfirm(e.value)}
                />
            </ToolbarItem>

            <ToolbarItem
                toolbar="bottom"
                location="after"
                locateInMenu="auto"
            >
                <div>
                    <Button
                        className="mx-2"
                        text="Cancel"
                        onClick={onCancel}
                    />
                    <SubmitButton
                        text="Submit"
                        disabled={!isAcknowledgeAndConfirm}
                        submitForm="frmAcknowledgeAndUpload"
                    />
                </div>
            </ToolbarItem>
        </StandardPopup>
    );
};

export default FinancialStatementAcknowledgementDialog;
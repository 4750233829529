import DraftFormContentHeaderRow from './DraftFormContentHeaderRow';
import DraftFormContentDataRow from './DraftFormContentDataRow';
import DraftFormContentFooterRow from './DraftFormContentFooterRow';

const FinancialStatementXBRLFormTradeAndOtherReceivablesView = (props) => {
    const { initialData, onViewPDEDrillDown } = props;

    const dataRows = [
        { dataId: 'tradeAndOtherReceivablesDueFromThirdParties' },
        { dataId: 'tradeAndOtherReceivablesDueFromRelatedParties' },
        { dataId: 'contractAssets' },
        { dataId: 'otherReceivables' },
        { dataId: 'tradeAndOtherReceivables', isFooter: true, enableTopBorder: true, enableBottomBorder: true }
    ];

    const hasPriorYearFinancialData = initialData.xbrlData.priorFinancialYear;

    return (
        <>
            <DraftFormContentHeaderRow description="Note - Trade and Other Receivables" level={1} />
            {dataRows.map((item, index) => {
                const pde = initialData.pdeList.find(x => x.dataId === item.dataId);
                const pdeDescription = pde && pde.description;

                const priorYearValue = hasPriorYearFinancialData && initialData.xbrlData.priorFinancialYear.tradeAndOtherReceivables[item.dataId];
                const currentYearValue = initialData.xbrlData.currentFinancialYear.tradeAndOtherReceivables[item.dataId];

                if (item.isFooter) {
                    return (
                        <DraftFormContentFooterRow
                            key={index}
                            level={1}
                            description={pdeDescription}
                            priorYearValue={priorYearValue}
                            currentYearValue={currentYearValue}
                            enableTopBorder={item.enableTopBorder}
                            enableBottomBorder={item.enableBottomBorder}
                            onViewPDEDrillDown={(isCurrentYear) => onViewPDEDrillDown({ pdeDataId: item.dataId, isCurrentYearFinancialData: isCurrentYear })}
                        />
                    );
                }

                return (
                    <DraftFormContentDataRow
                        key={index}
                        level={1}
                        description={pdeDescription}
                        priorYearValue={priorYearValue}
                        currentYearValue={currentYearValue}
                        onViewPDEDrillDown={(isCurrentYear) => onViewPDEDrillDown({ pdeDataId: item.dataId, isCurrentYearFinancialData: isCurrentYear })}
                    />
                );
            })}
        </>
    );
};

export default FinancialStatementXBRLFormTradeAndOtherReceivablesView;
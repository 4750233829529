import React, { useState } from 'react';
import StandardPopup from 'components/StandardPopup';
import { DeleteButton, ActionButton } from 'components/StandardButton';
import { Button } from 'devextreme-react/button';
import { ToolbarItem } from 'devextreme-react/popup';
import { ActionSheet, Item } from 'devextreme-react/action-sheet';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as AccountBookDataStatusConstant from 'data/accountBookDataStatusConstant';
import DataRequireUpdateDialog from './partials/DataChangeAcknowledgementDialog';
import FinancialStatementUploadForValidationDialog from './partials/FinancialStatementUploadForValidationDialog';
import FinancialStatementValidationResultDialog from './partials/FinancialStatementValidationResultDialog';
import FinancialStatementAcknowledgementDialog from './partials/FinancialStatementAcknowledgementDialog';
import FinancialStatementDownloadFileDialog from './partials/FinancialStatementDownloadFileDialog';
import FinancialStatementPDEDrillDownDialog from './partials/FinancialStatementPDEDrillDownDialog';
import DraftFormContent from './DraftFormContent';
import * as FinancialStatementApi from '../../api/financialStatementApi';
import * as FinancialStatementStatus from '../../data/financialStatementStatus';
import * as MessageBox from 'utils/messageBox';
import * as ErrorMessage from 'utils/errorMessage';
import * as Toast from 'utils/toast';
import * as PageUtils from 'utils/pageUtils';

const FinancialStatementXBRLFormView = (props) => {
    const { financialStatementData, onEdit, onAfterFormAction, onCancel } = props;

    const { financialStatementId, status, entryData, requireNewFiling } = financialStatementData;

    const [financialStatementValidationResultData, setFinancialStatementValidationResultData] = useState(null);
    const [financialStatementAcknowledgeUploadData, setFinancialStatementAcknowledgeUploadData] = useState(null);
    const [financialStatementPDEDrillDownData, setFinancialStatementPDEDrillDownData] = useState(null);

    const [showDataChangeAcknowledgementDialog, setShowDataChangeAcknowledgementDialog] = useState(false);
    const [showFinancialStatementUploadForValidationDialog, setShowFinancialStatementUploadForValidationDialog] = useState(false);
    const [showFinancialStatementValidationResultDialog, setShowFinancialStatementValidationResultDialog] = useState(false);
    const [showFinancialStatementAcknowledgementDialog, setShowFinancialStatementAcknowledgementDialog] = useState(false);
    const [showFinancialStatementDownloadFileDialog, setShowFinancialStatementDownloadFileDialog] = useState(false);
    const [showFinancialStatementPDEDrillDownDialog, setShowFinancialStatementPDEDrillDownDialog] = useState(false);
    const [showActionSheet, setShowActionSheet] = useState(false);

    const { accountBookData } = useAccountBookData();
    const accountBookDataId = accountBookData && accountBookData.accountBookDataId;
    const readOnly = accountBookData.stepList.some(x => x === AccountBookDataStatusConstant.CompletedAnnualReturn);

    function refreshFinancialStatement() {
        PageUtils.blockPage();

        FinancialStatementApi.refreshFinancialStatement(accountBookDataId, financialStatementId)
            .then((data) => {
                PageUtils.unblockPage();

                onAfterFormAction(data.financialStatementId);
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    }

    function deleteFinancialStatement() {
        PageUtils.blockPage();

        FinancialStatementApi.deleteFinancialStatement(accountBookDataId, financialStatementId)
            .then(() => {
                PageUtils.unblockPage();

                onAfterFormAction();
                onCancel();
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.deleteError(error));
            });
    }

    function getFinancialStatementXBRLValidationResult() {
        if (requireNewFiling) {
            setShowDataChangeAcknowledgementDialog(true);
            return;
        }

        PageUtils.blockPage();

        FinancialStatementApi.getFinancialStatementXBRLValidationResult(accountBookDataId, financialStatementId)
            .then((data) => {
                PageUtils.unblockPage();

                onAfterFormAction(financialStatementId);
                setFinancialStatementValidationResultData(data);
                setShowFinancialStatementValidationResultDialog(true);
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    }

    function showAcknowledgeAndConfirmUploadForm() {
        PageUtils.blockPage();

        FinancialStatementApi.getAcknowledgeAndUploadFinancialStatementXBRLSummaryData(accountBookDataId, financialStatementId)
            .then((data) => {
                PageUtils.unblockPage();

                onAfterFormAction(financialStatementId);
                setFinancialStatementAcknowledgeUploadData(data);
                setShowFinancialStatementAcknowledgementDialog(true);
                setShowFinancialStatementValidationResultDialog(false);
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    }

    function viewPrescribedDataElementDrillDown(input) {
        PageUtils.blockPage();

        FinancialStatementApi.getPrescribedDataElementDrillDownData(accountBookDataId, financialStatementId, input)
            .then(data => {
                PageUtils.unblockPage();
                setFinancialStatementPDEDrillDownData(data);
                setShowFinancialStatementPDEDrillDownDialog(true);
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    }

    function onAfterUploadForValidation() {
        onAfterFormAction(financialStatementId);
        setShowFinancialStatementUploadForValidationDialog(false);
    }

    function onAfterAcknowledgeAndUpload() {
        onAfterFormAction(financialStatementId);
        setShowFinancialStatementAcknowledgementDialog(false);

        MessageBox.showConfirmMessage('Financial Statement XBRL has been successfully confirmed and uploaded to ACRA. You may proceed to lodge file in Bizfile as part of Annual Return.', null, 'Yes, proceed to filing', 'No, stay at this page')
            .then(result => {
                if (result.confirm) {
                    window.location.replace(`${window.APP_SETTINGS_SingaporeSeamlessFilingPortalUrl}/annualReturnWithFS`);
                }
            });
    }

    function handleRefreshButtonClicked() {
        MessageBox.showConfirmDangerMessage('Are you sure you want to refresh this Financial Statement? (Financial Statement XBRL will be generated again and will require you to re-upload to ACRA for Validation)', false, 'Yes, refresh it', 'No, don\'t refresh it')
            .then(result => {
                if (result.confirm) {
                    refreshFinancialStatement();
                }
            });
    }

    function handleDeleteButtonClicked() {
        MessageBox.showConfirmDangerMessage('Are you sure you want to delete this Financial Statement?', false, 'Yes, delete it', 'No, don\'t delete it')
            .then(result => {
                if (result.confirm) {
                    deleteFinancialStatement();
                }
            });
    }

    function handleUploadForValidationButtonClicked() {
        if (requireNewFiling) {
            setShowDataChangeAcknowledgementDialog(true);
            return;
        }

        if (status === FinancialStatementStatus.ValidatedWithErrors || status === FinancialStatementStatus.ValidationSuccess) {
            MessageBox.showConfirmDangerMessage('Are you sure you want Upload this Financial Statement to ACRA for Validation again?', false, 'Yes, upload it for validation', 'No, don\'t upload it for validation')
                .then(result => {
                    if (result.confirm) {
                        setShowFinancialStatementUploadForValidationDialog(true);
                    }
                });
        } else {
            setShowFinancialStatementUploadForValidationDialog(true);
        }
    }

    return (
        <>
            <StandardPopup
                visible
                onHiding={onCancel}
                showCloseButton
                showTitle
                fullScreen
                title={`View Financial Statement [${status}]`}
            >
                <DraftFormContent
                    initialData={financialStatementData}
                    onViewPDEDrillDown={viewPrescribedDataElementDrillDown}
                />

                <ToolbarItem
                    toolbar="bottom"
                    location="before"
                    locateInMenu="auto"
                    visible={status !== FinancialStatementStatus.AcknowledgeComplete}
                >
                    <div>
                        <Button
                            text={readOnly ? 'View' : 'Edit'}
                            onClick={onEdit}
                        />
                        <Button
                            className="mx-2"
                            text="Refresh"
                            visible={!readOnly}
                            onClick={handleRefreshButtonClicked}
                        />
                        <DeleteButton
                            text="Delete"
                            visible={!readOnly}
                            onClick={handleDeleteButtonClicked}
                        />
                    </div>
                </ToolbarItem>

                {window.screen.width < 1366 ?
                    <ToolbarItem
                        toolbar="bottom"
                        location="after"
                    >
                        <Button
                            text="..."
                            onClick={() => setShowActionSheet(true)}
                        />
                    </ToolbarItem> :
                    <ToolbarItem
                        toolbar="bottom"
                        location="after"
                        locateInMenu={readOnly ? 'never' : 'auto'}
                    >
                        <div>
                            <Button
                                text="Close"
                                onClick={onCancel}
                            />
                            {(status === FinancialStatementStatus.ValidationSuccess ||
                                status === FinancialStatementStatus.ValidatedWithErrors ||
                                status === FinancialStatementStatus.AcknowledgeComplete
                            ) &&
                                <ActionButton
                                    className="ms-2"
                                    text="Download"
                                    onClick={() => setShowFinancialStatementDownloadFileDialog(true)}
                                />
                            }
                            {
                                !readOnly &&
                                (
                                    status === FinancialStatementStatus.New ||
                                    status === FinancialStatementStatus.Created 
                                ) &&
                                    <ActionButton
                                        className="ms-2"
                                        text={status === FinancialStatementStatus.Created ? 'Next: Upload to ACRA for Validation' : 'Upload to ACRA for Validation'}
                                        onClick={handleUploadForValidationButtonClicked}
                                    />
                            }
                            {
                                !readOnly &&
                                (
                                    status === FinancialStatementStatus.ValidationPending ||
                                    status === FinancialStatementStatus.ValidatedWithErrors ||
                                    status === FinancialStatementStatus.ValidationSuccess
                                ) &&
                                    <ActionButton
                                        className="ms-2"
                                        text="Reupload to ACRA for Validation"
                                        onClick={handleUploadForValidationButtonClicked}
                                    />
                            }
                            {!readOnly &&
                                (
                                    status === FinancialStatementStatus.ValidationPending ||
                                    status === FinancialStatementStatus.ValidatedWithErrors
                                ) && 
                                    <ActionButton
                                        className="ms-2"
                                        text="Next: Get Validation Result from ACRA"
                                        onClick={getFinancialStatementXBRLValidationResult}
                                    />
                            }
                            {!readOnly && status === FinancialStatementStatus.ValidationSuccess && 
                                <ActionButton
                                    className="ms-2"
                                    text="Next: Acknowledge and Upload XBRL"
                                    onClick={getFinancialStatementXBRLValidationResult}
                                />
                            }
                            {status === FinancialStatementStatus.AcknowledgeComplete && !readOnly &&
                                <ActionButton
                                    className="ms-2"
                                    text="Proceed to Annual Return Filing"
                                    onClick={() => window.location.replace(`${window.APP_SETTINGS_SingaporeSeamlessFilingPortalUrl}/annualReturnWithFS`)}
                                />
                            }
                        </div>
                    </ToolbarItem>
                }

                {showActionSheet &&
                    <ActionSheet
                        title="Select Action"
                        width="400px"
                        height="auto"
                        cancelText="Close"
                        onCancelClick={() => setShowActionSheet(false)}
                        onItemClick={() => setShowActionSheet(false)}
                        visible
                    >
                        <Item
                            visible={status === FinancialStatementStatus.ValidationSuccess
                                || status === FinancialStatementStatus.ValidatedWithErrors || status === FinancialStatementStatus.AcknowledgeComplete}
                        >
                            <ActionButton text="Download" onClick={() => setShowFinancialStatementDownloadFileDialog(true)} />
                        </Item>
                        <Item
                            visible={status !== FinancialStatementStatus.New
                                && status !== FinancialStatementStatus.ValidationPending && status !== FinancialStatementStatus.AcknowledgeComplete && !readOnly}
                        >
                            <ActionButton
                                text={status === FinancialStatementStatus.Created ? 'Next: Upload to ACRA for Validation' : 'Upload to ACRA for Validation'}
                                onClick={handleUploadForValidationButtonClicked}
                            />
                        </Item>
                        <Item
                            visible={status === FinancialStatementStatus.ValidationPending ||
                                status === FinancialStatementStatus.ValidatedWithErrors || status === FinancialStatementStatus.ValidationSuccess && !readOnly}
                        >
                            <ActionButton
                                text={status === FinancialStatementStatus.ValidationSuccess ? 'Get Validation Result from ACRA' : 'Next: Get Validation Result from ACRA'}
                                onClick={getFinancialStatementXBRLValidationResult}
                            />
                        </Item>
                        <Item visible={status === FinancialStatementStatus.ValidationSuccess && !readOnly} >
                            <ActionButton
                                text="Next: Acknowledge and Upload XBRL"
                                onClick={showAcknowledgeAndConfirmUploadForm}
                            />
                        </Item>
                        <Item visible={status === FinancialStatementStatus.AcknowledgeComplete && !readOnly}>
                            <ActionButton
                                text="Proceed to Annual Return Filing"
                                onClick={() => window.location.replace(`${window.APP_SETTINGS_SingaporeSeamlessFilingPortalUrl}/annualReturnWithFS`)}
                            />
                        </Item>
                    </ActionSheet>
                }
            </StandardPopup>

            {showDataChangeAcknowledgementDialog && 
                <DataRequireUpdateDialog onCancel={() => setShowDataChangeAcknowledgementDialog(false)} />
            }

            {showFinancialStatementUploadForValidationDialog &&
                <FinancialStatementUploadForValidationDialog
                    financialStatementId={financialStatementId}
                    initialData={entryData}
                    onAfterUploadForValidation={onAfterUploadForValidation}
                    onCancel={() => setShowFinancialStatementUploadForValidationDialog(false)}
                />
            }
            {showFinancialStatementValidationResultDialog &&
                <FinancialStatementValidationResultDialog
                    data={financialStatementValidationResultData}
                    status={status}
                    onRefresh={getFinancialStatementXBRLValidationResult}
                    onAcknowledgeUpload={showAcknowledgeAndConfirmUploadForm}
                    onCancel={() => setShowFinancialStatementValidationResultDialog(false)}
                />
            }
            {showFinancialStatementAcknowledgementDialog &&
                <FinancialStatementAcknowledgementDialog
                    financialStatementId={financialStatementId}
                    data={financialStatementAcknowledgeUploadData}
                    entryData={entryData}
                    onAfterAcknowledgeAndUpload={onAfterAcknowledgeAndUpload}
                    onCancel={() => setShowFinancialStatementAcknowledgementDialog(false)}
                />
            }
            {showFinancialStatementDownloadFileDialog &&
                <FinancialStatementDownloadFileDialog
                    financialStatementId={financialStatementId}
                    onCancel={() => setShowFinancialStatementDownloadFileDialog(false)}
                />
            }
            {showFinancialStatementPDEDrillDownDialog &&
                <FinancialStatementPDEDrillDownDialog
                    data={financialStatementPDEDrillDownData}
                    entryData={entryData}
                    onViewPDEDrillDown={viewPrescribedDataElementDrillDown}
                    onCancel={() => setShowFinancialStatementPDEDrillDownDialog(false)}
                />
            }
        </>
    );
};

export default FinancialStatementXBRLFormView;
import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { useScreenSize } from 'utils/media-query';

const StandardPopupInner = (props) => {
    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const { width, forwardedRef, ...rest } = props;

    let widgetWidth = width;
    if (isXSmall) {
        widgetWidth = '100vw';
    } else if (width) {
        if (isSmall) {
            if (parseFloat(width) > 960) {
                widgetWidth = '100vw';
            }
        } else if (isMedium) {
            if (parseFloat(width) > 1280) {
                widgetWidth = '100vw';
            }
        }
    }

    return (
        <Popup
            width={widgetWidth}
            dragEnabled={false}
            ref={forwardedRef}
            {...rest}
        />
    );
};

// eslint-disable-next-line react/no-multi-comp
const StandardPopup = React.forwardRef((props, ref) => <StandardPopupInner {...props} forwardedRef={ref} />);
StandardPopup.displayName = 'StandardPopup';

export default StandardPopup;
import DraftFormContentHeaderRow from './DraftFormContentHeaderRow';
import DraftFormContentDataRow from './DraftFormContentDataRow';
import DraftFormContentFooterRow from './DraftFormContentFooterRow';

const FinancialStatementXBRLFormRevenueView = (props) => {
    const { initialData, onViewPDEDrillDown } = props;

    const dataRows = [
        { dataId: 'revenueFromPropertyTransferredAtPointInTime' },
        { dataId: 'revenueFromGoodsTransferredAtPointInTime' },
        { dataId: 'revenueFromServicesTransferredAtPointInTime' },
        { dataId: 'revenueFromPropertyTransferredOverTime' },
        { dataId: 'revenueFromConstructionContractsOverTime' },
        { dataId: 'revenueFromServicesTransferredOverTime' },
        { dataId: 'revenueOthers' },
        { dataId: 'totalRevenue', isFooter: true, enableTopBorder: true, enableBottomBorder: true }
    ];

    const hasPriorYearFinancialData = initialData.xbrlData.priorFinancialYear;

    return (
        <>
            <DraftFormContentHeaderRow description="Note - Revenue" level={1} />
            {dataRows.map((item, index) => {
                const pde = initialData.pdeList.find(x => x.dataId === item.dataId);
                const pdeDescription = pde && pde.description;

                const priorYearValue = hasPriorYearFinancialData && initialData.xbrlData.priorFinancialYear.revenue[item.dataId];
                const currentYearValue = initialData.xbrlData.currentFinancialYear.revenue[item.dataId];

                if (item.isFooter) {
                    return (
                        <DraftFormContentFooterRow
                            key={index}
                            description={pdeDescription}
                            priorYearValue={priorYearValue}
                            currentYearValue={currentYearValue}
                            enableTopBorder={item.enableTopBorder}
                            enableBottomBorder={item.enableBottomBorder}
                            onViewPDEDrillDown={(isCurrentYear) => onViewPDEDrillDown({ pdeDataId: item.dataId, isCurrentYearFinancialData: isCurrentYear })}
                        />
                    );
                }

                return (
                    <DraftFormContentDataRow
                        key={index}
                        description={pdeDescription}
                        priorYearValue={priorYearValue}
                        currentYearValue={currentYearValue}
                        onViewPDEDrillDown={(isCurrentYear) => onViewPDEDrillDown({ pdeDataId: item.dataId, isCurrentYearFinancialData: isCurrentYear })}
                    />
                );
            })}
        </>
    );
};

export default FinancialStatementXBRLFormRevenueView;
import DraftFormContentHeaderRow from './DraftFormContentHeaderRow';
import DraftFormContentFooterRow from './DraftFormContentFooterRow';
import FinancialStatementDataView_CurrentAssets from './FinancialStatementData_CurrentAssetsView';
import FinancialStatementDataView_NonCurrentAssets from './FinancialStatementData_NonCurrentAssetsView';

const FinancialStatementDataView_Assets = (props) => {
    const { initialData, onViewPDEDrillDown } = props;

    // eslint-disable-next-line react/no-multi-comp
    const TotalAssetsRow = () => {
        const dataId = 'assets';

        const pde = initialData.pdeList.find(x => x.dataId === dataId);
        const pdeDescription = pde && pde.description;

        const hasPriorYearFinancialData = initialData.xbrlData.priorFinancialYear;
        const priorYearValue = hasPriorYearFinancialData && initialData.xbrlData.priorFinancialYear.assets[dataId];
        const currentYearValue = initialData.xbrlData.currentFinancialYear.assets[dataId];

        return (
            <DraftFormContentFooterRow
                description={pdeDescription}
                priorYearValue={priorYearValue}
                currentYearValue={currentYearValue}
                enableBottomBorder
                onViewPDEDrillDown={(isCurrentYear) => onViewPDEDrillDown({ pdeDataId: dataId, isCurrentYearFinancialData: isCurrentYear })}
            />
        );
    };

    return (
        <>
            <DraftFormContentHeaderRow description="Assets" level={2} />
            {initialData.entryData.financialStatementPosition === 'Current and non-current' && 
                <>
                    <FinancialStatementDataView_CurrentAssets
                        initialData={initialData}
                        onViewPDEDrillDown={onViewPDEDrillDown}
                    />
                    <FinancialStatementDataView_NonCurrentAssets
                        initialData={initialData}
                        onViewPDEDrillDown={onViewPDEDrillDown}
                    />
                </>
            }
            <TotalAssetsRow />
        </>
    );
};

export default FinancialStatementDataView_Assets;
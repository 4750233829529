import DraftFormContentHeaderRow from './DraftFormContentHeaderRow';
import DraftFormContentDataRow from './DraftFormContentDataRow';
import DraftFormContentFooterRow from './DraftFormContentFooterRow';
import * as pdeDataCurrentType from '../data/pdeDataCurrentType';

const FinancialStatementXBRLFormNonCurrentAssetsView = (props) => {
    const { initialData, onViewPDEDrillDown } = props;

    const dataRows = [
        { dataId: 'tradeAndOtherReceivablesNoncurrent' },
        { dataId: 'noncurrentFinanceLeaseReceivables' },
        { dataId: 'noncurrentDerivativeFinancialAssets' },
        { dataId: 'noncurrentFinancialAssetsMeasuredAtFairValueThroughProfitOrLoss' },
        { dataId: 'otherNoncurrentFinancialAssets' },
        { dataId: 'propertyPlantAndEquipment' },
        { dataId: 'investmentProperties' },
        { dataId: 'goodwill' },
        { dataId: 'intangibleAssetsOtherThanGoodwill' },
        { dataId: 'investmentsInSubsidiariesAssociatesOrJointVentures' },
        { dataId: 'deferredTaxAssets' },
        { dataId: 'otherNoncurrentNonfinancialAssets' },
        { dataId: 'noncurrentAssets', isFooter: true, enableTopBorder: true, enableBottomBorder: true }
    ];

    const hasPriorYearFinancialData = initialData.xbrlData.priorFinancialYear;

    return (
        <>
            <DraftFormContentHeaderRow description="Non-Current Assets" level={3} />
            {dataRows.map((item, index) => {
                const pde = initialData.pdeList.find(x => x.dataId === item.dataId);
                const pdeDescription = pde && pde.description;

                const priorYearValue = hasPriorYearFinancialData && initialData.xbrlData.priorFinancialYear.assets.nonCurrentAssets[item.dataId];
                const currentYearValue = initialData.xbrlData.currentFinancialYear.assets.nonCurrentAssets[item.dataId];

                if (item.isFooter) {
                    return (
                        <DraftFormContentFooterRow
                            key={index}
                            description={pdeDescription}
                            priorYearValue={priorYearValue}
                            currentYearValue={currentYearValue}
                            enableTopBorder={item.enableTopBorder}
                            enableBottomBorder={item.enableBottomBorder}
                            onViewPDEDrillDown={(isCurrentYear) => onViewPDEDrillDown({ pdeDataId: item.dataId, isCurrentYearFinancialData: isCurrentYear, pdeCurrentType: pdeDataCurrentType.NonCurrent })}
                        />
                    );
                }

                return (
                    <DraftFormContentDataRow
                        key={index}
                        level={3}
                        description={pdeDescription}
                        priorYearValue={priorYearValue}
                        currentYearValue={currentYearValue}
                        onViewPDEDrillDown={(isCurrentYear) => onViewPDEDrillDown({ pdeDataId: item.dataId, isCurrentYearFinancialData: isCurrentYear, pdeCurrentType: pdeDataCurrentType.NonCurrent })}
                    />
                );
            })}
        </>
    );
};

export default FinancialStatementXBRLFormNonCurrentAssetsView;
import { Column, Toolbar } from 'devextreme-react/data-grid';
import StandardDataGrid from 'components/StandardDataGrid';

const RegisteredChargesDataGrid = (props) => {
    const { data } = props;

    return (
        <div className="card-body px-4 py-3">
            <StandardDataGrid
                dataSource={data}
            >
                <Column
                    dataField="chargeNo"
                    caption="Charge No."
                    dataType="string"
                />
                <Column
                    dataField="registrationDate"
                    caption="Date of Registration"
                    dataType="date"
                />
                <Column
                    dataField="amount"
                    caption="Amount of Indebtness"
                    dataType="number"
                />
                <Toolbar visible={false} />
            </StandardDataGrid>
        </div>
    );
};

export default RegisteredChargesDataGrid;
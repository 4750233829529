import './PageTitle.scss';

const PageTitle = ({ title, icon, children }) => {

    return (
        <div className="page-title d-flex">
            <div className="d-flex justify-content-center align-items-center">
                {icon}
            </div>
            <h2 className="ps-3 h2 align-self-center flex-grow-1">{title}</h2>
            {children}
        </div>
    );
};

export default PageTitle;
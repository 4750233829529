import React, { useMemo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import routes from './app-routes';
import { ErrorBoundary } from 'layouts';
import { SideNavInnerToolbar as SideNavBarLayout } from './layouts';

const Content = () => {
    function generateRoutes() {
        return routes.map(({ path, component }) => {
            const Component = component;
            return (
                <Route
                    key={path}
                    path={path}
                    element={<Component />}
                />
            );
        });
    }

    const generatedRoutes = useMemo(generateRoutes, []);

    return (
        <SideNavBarLayout>
            <ErrorBoundary>
                <Routes>
                    {generatedRoutes}
                    <Route
                        path="*"
                        element={<Navigate to="/" replace />}
                    />
                </Routes>
            </ErrorBoundary>
        </SideNavBarLayout>
    );
};

export default Content;

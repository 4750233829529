import { useMemo } from 'react';
import ReportGrid from '../components/ReportGrid';

const DetailedProfitAndLossView = (props) => {
    const { data } = props;

    const viewData = useMemo(() => {
        const list = [];

        const {
            costOfGoodsSold, grossPL, depreciationExpense,
            ...restData
        } = data.generatedDraft.dataDtlPNL;

        Object.entries(restData).forEach(([key, value]) => {
            const pde = data.pdeList.find(x => x.dataId === key);
            const row = { description: pde && pde.description };

            if ([
                'totalRevenue',
                'totalOtherIncome',
                'totalExpenses',
                'profitLossBeforeTaxation'].includes(key)
            ) {
                row.amount2 = value;
            } else {
                row.amount1 = value;
            }

            if (key === 'sgIntDisc') {
                row.tag = 'Add:';
            }

            list.push(row);

            if (key === 'totalRevenue') {
                list.push({ tag: 'Less :', description: 'Cost of Goods Sold', amount2: costOfGoodsSold });
                list.push({ description: 'Gross Profit/ Loss', amount2: grossPL });
                list.push({});
            } else if (key === 'totalOtherIncome') {
                list.push({});
                list.push({ tag: 'Less :', description: 'Operating expenses' });
            } else if (key === 'commissionOther') {
                list.push({ description: 'Depreciation Expense', amount1: depreciationExpense });
            }
        });

        return list;
    }, [data]);

    return (
        <ReportGrid
            dataSource={viewData}
            onCellPrepared={(e) => {
                if (e.rowType === 'data') {
                    if (e.column.name === 'amount1') {
                        if (e.row.data.description === 'Upkeep of Private Motor Vehicles')
                            e.cellElement.classList.add('view-underline');
                    }
                    if (e.column.name === 'amount2') {
                        if (e.row.data.description === 'Profit/ (Loss) before Taxation')
                            e.cellElement.classList.add('view-double-underline');
                        else if ([
                            'Cost of Goods Sold',
                            'Total Expenses'
                        ].includes(e.row.data.description))
                            e.cellElement.classList.add('view-underline');
                    }
                    if (e.column.name === 'description') {
                        if (e.row.data.description === 'Operating expenses' && !e.row.data.amount1 && !e.row.data.amount2)
                            e.cellElement.classList.add('text-decoration-underline');
                    }
                }
            }}
        />
    );
};

export default DetailedProfitAndLossView;
import React, { useEffect } from 'react';

const ValidationErrorMessageView = (props) => {
    const { validationErrorData, setValidationErrorData } = props;
    const { validationErrors } = validationErrorData;
    let visible = validationErrorData.hasValidationError && validationErrorData.validationErrors;

    useEffect(() => {
        if (visible) {
            // scroll validation error message into view
            let element = document.querySelector('.validationErrorContent');
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [visible]);

    return visible ? (
        <div className="row alert alert-danger alert-dismissable fade show p-3 validationErrorContent">
            <div className="col-11">
                <label className="pt-1">Please check and amend the following fields before re-submitting.</label>
                {validationErrors.errors ?
                    <ul className="mb-0 pl-3">
                        {validationErrors.errors.map(error => (
                            <li key={error.errorCode}>
                                <b>{error.field}</b>: {error.message ? error.message : error.errorCode}
                            </li>
                        ))}
                    </ul> :
                    <ul className="mb-0 pl-3">
                        <li key={validationErrors.message}>
                            {validationErrors.message}
                        </li>
                    </ul>
                }
            </div>
            <div className="col-1 d-flex justify-content-end align-self-start">
                <button
                    type="button"
                    className="alert bg-transparent border-0 fs-3 p-0 mb-auto"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={() => setValidationErrorData(Object.assign({}, validationErrorData, { hasValidationError: false }))}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    ) : <></>;
};

export default ValidationErrorMessageView;
import { Button } from 'devextreme-react/button';
import { Column, Toolbar } from 'devextreme-react/data-grid';
import { ToolbarItem } from 'devextreme-react/popup';
import StandardPopup from 'components/StandardPopup';
import FinancialScheduleGrid from '../components/FinancialScheduleGrid';

const FormCSRentalSchedule = (props) => {
    const { initialData, onCancel } = props;

    const dataSource = [
        { description: 'Rental income (Gross)', value: initialData ? initialData.c1_GrossRent : 0 },
        { description: '', value: null },
        { index: 'Less:', description: 'Deductible expenses', value: null, isHeader: true },
        { description: 'Expenses incurred to derive rental income - Commission', value: initialData ? initialData.commissionExpRentalIncome : 0 },
        { description: 'Expenses incurred to derive rental income - Insurance', value: initialData ? initialData.insuranceExpRentalIncome : 0 },
        { description: 'Expenses incurred to derive rental income - Interest', value: initialData ? initialData.interestExpRentalIncome : 0 },
        { description: 'Expenses incurred to derive rental income - Property tax', value: initialData ? initialData.propertyTaxExpRentalIncome : 0 },
        { description: 'Expenses incurred to derive rental income - Repair and maintenance', value: initialData ? initialData.repairMaintenanceExpRentalIncome : 0 },
        { description: 'Expenses incurred to derive rental income - Others', value: initialData ? initialData.otherExpRentalIncome : 0 },
        { description: 'Net Rental income', value: initialData ? initialData.sgRent : 0 }
    ];

    function onCellPrepared(e) {
        if (e.rowType === 'data') {
            if (e.column.dataField === 'description') {
                if (e.data.isHeader) {
                    e.cellElement.style.textDecoration = 'underline';
                }
            }
        }
    }

    return (
        <StandardPopup
            width="1450px"
            visible
            onHiding={onCancel}
            showCloseButton
            title="Rental Schedule"
        >
            <div className="fs-7 mb-3 d-flex justify-content-left">Rental schedule is used to track and itemize expenses that is related to each tenants' rental which include commission, insurance and many more.</div>
            <FinancialScheduleGrid
                dataSource={dataSource}
                onCellPrepared={onCellPrepared}
            >
                <Column caption="" dataField="index" dataType="string" />
                <Column caption="Description" dataField="description" dataType="string" />
                <Column caption="Balance" dataField="value" format="#,##0.00" dataType="number" />
                <Toolbar visible={false} />
            </FinancialScheduleGrid>
            <ToolbarItem
                toolbar="bottom"
                location="after"
            >
                <Button
                    text="Close"
                    onClick={onCancel}
                />
            </ToolbarItem>
        </StandardPopup>
    );
};

export default FormCSRentalSchedule;
import { Button } from 'devextreme-react/button';
import { ToolbarItem } from 'devextreme-react/popup';
import { DeleteButton } from 'components/StandardButton';
import StandardPopup from 'components/StandardPopup';
import * as annualReturnStatusConstant from '../../AnnualReturn/data/annualReturnStatusConstant';
import * as formCsStatusConstant from '../../FormCS/data/formCsStatusConstant';

const AccountBookDeleteDialog = (props) => {
    const { initialData, onSubmit, onCancel } = props;

    const warningMessages = [];
    if (initialData.annualReturnStatus && initialData.annualReturnStatus !== annualReturnStatusConstant.FormNew)
        warningMessages.push('Annual Return submission is in progress.');
    if (initialData.formCsStatus && initialData.formCsStatus !== formCsStatusConstant.FormNew)
        warningMessages.push('Form C-S submission is in progress.');

    return (
        <StandardPopup
            visible
            onHiding={onCancel}
            dragEnabled={false}
            width={600}
            height="auto"
            showTitle={false}
        >
            {!!warningMessages.length &&
                <div className="alert alert-warning" role="alert">
                    <div className="fs-5 mb-2">Warning</div>
                    {warningMessages.map(message => <div className="fs-7">- {message} </div>)}
                </div>
            }
            <p className="fs-7 d-flex justify-content-center"> {`Are you sure on deleting the account book "${initialData.companyName}" for YA ${initialData.yearOfAssessment}"?`}</p>
            <p className="fs-7 d-flex justify-content-center mb-0"> ( This action cannot be reverted. )</p>
            <ToolbarItem
                toolbar="bottom"
                location="center"
            >
                <DeleteButton
                    text="Delete"
                    onClick={() => onSubmit(initialData.accountBookDataId)}
                />
            </ToolbarItem>
            <ToolbarItem
                toolbar="bottom"
                location="center"
                render={() => (
                    <Button
                        text="Cancel"
                        onClick={onCancel}
                    />
                )}
            />
        </StandardPopup>
    );
};
export default AccountBookDeleteDialog;
import CustomStore from 'devextreme/data/custom_store';
import { Button, Column, Toolbar, Item } from 'devextreme-react/data-grid';
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import { PrimaryButton } from 'components/StandardButton';
import StandardDataGrid from 'components/StandardDataGrid';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as AccountBookDataStatusConstant from 'data/accountBookDataStatusConstant';
import AccountBookNotSelected from 'layouts/AccountBookNotSelected';
import * as ErrorMessage from 'utils/errorMessage';
import * as MessageBox from 'utils/messageBox';
import * as PageUtils from 'utils/pageUtils';
import * as Toast from 'utils/toast';
import * as RenovationExpenseApi from './api/renovationExpenseApi';
import RenovationExpenseDataDialog from './partials/RenovationExpenseDataDialog';

const RenovationExpenseMaintenancePage = () => {
    const { accountBookData } = useAccountBookData();
    const accountBookDataId = accountBookData && accountBookData.accountBookDataId;
    const readOnly = accountBookData.stepList.some(x => x === AccountBookDataStatusConstant.CompletedFormCS);

    const [showRenovationExpenseDataDialog, setShowRenovationExpenseDataDialog] = useState(false);
    const [renovationExpenseData, setRenovationExpenseData] = useState(null);
    const dataGridRef = useRef();

    const navigate = useNavigate();

    const dataSource = useMemo(() => new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: function () {
            return RenovationExpenseApi.getRenovationExpenseList(accountBookDataId);
        }
    }), [accountBookDataId]);

    function editRecord(id) {
        PageUtils.blockPage();
        RenovationExpenseApi.getRenovationExpenseEntry(accountBookDataId, id)
            .then(result => {
                PageUtils.unblockPage();
                setShowRenovationExpenseDataDialog(true);
                setRenovationExpenseData(result);
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    }

    function saveRecord(data) {
        PageUtils.blockPage();
        if (data.id) {
            RenovationExpenseApi.updateRenovationExpense(accountBookDataId, data.id, data)
                .then(() => {
                    PageUtils.unblockPage();
                    setShowRenovationExpenseDataDialog(false);
                    Toast.showSuccessMessage('Renovation Expense is updated successfully.');
                    dataGridRef.current.instance.refresh();
                })
                .catch(error => {
                    PageUtils.unblockPage();
                    Toast.showErrorMessage(ErrorMessage.updateError(error));
                });
        } else {
            RenovationExpenseApi.createRenovationExpenseEntry(accountBookDataId, data)
                .then(() => {
                    PageUtils.unblockPage();
                    setShowRenovationExpenseDataDialog(false);
                    Toast.showSuccessMessage('Renovation Expense is added successfully.');
                    dataGridRef.current.instance.refresh();
                })
                .catch(error => {
                    PageUtils.unblockPage();
                    Toast.showErrorMessage(ErrorMessage.createError(error));
                });
        }
    }

    async function deleteRecord(id) {
        const result = await MessageBox.showConfirmDeleteMessage('<div class="text-center">Are you sure on deleting this Renovation Expense Entry? <p class="mb-0"> ( This action cannot be reverted. )</p> </div>', true);
        if (!(result && result.confirm)) return;

        PageUtils.blockPage();
        RenovationExpenseApi.deleteRenovationExpense(accountBookDataId, id)
            .then(() => {
                PageUtils.unblockPage();
                Toast.showSuccessMessage('Renovation Expense is deleted successfully.');
                dataGridRef.current.instance.refresh();
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.deleteError(error));
            });
    }

    return (
        <>
            <div className="content-block">
                <PageTitle title="Renovation Expense">
                    <div className="align-self-center" style={{ flexGrow: 100 }}>
                        <PrimaryButton
                            className="ms-3"
                            text="Back to Form C-S"
                            onClick={() => navigate('/formCS')}
                        />
                    </div>
                </PageTitle>
            </div>
            {accountBookDataId ?
                <>
                    <div className="content-block">
                        <div className="dx-card responsive-paddings">
                            <StandardDataGrid
                                ref={dataGridRef}
                                dataSource={dataSource}
                            >
                                <Toolbar>
                                    <Item
                                        location="before"
                                        cssClass="dx-swatch-button"
                                        locateInMenu="auto"
                                        widget="dxButton"
                                        visible={!readOnly}
                                        options={{
                                            text: 'New',
                                            icon: 'add',
                                            type: 'default',
                                            stylingMode: 'outlined',
                                            onClick: () => {
                                                setShowRenovationExpenseDataDialog(true);
                                                setRenovationExpenseData({});
                                            }
                                        }}
                                    />
                                    <Item name="columnChooserButton" />
                                    <Item name="searchPanel" />
                                </Toolbar>
                                <Column
                                    caption="Year Of Assessment"
                                    dataField="yearOfAssessment"
                                    dataType="string"
                                    sortIndex={0}
                                    sortOrder="asc"
                                />
                                <Column
                                    caption="Transaction Date"
                                    dataField="transactionDate"
                                    dataType="date"
                                />
                                <Column
                                    caption="Description"
                                    dataField="description"
                                    dataType="string"
                                />
                                <Column
                                    caption="Amount"
                                    dataField="amount"
                                    dataType="number"
                                    format="#0.00"
                                    editorOptions={{
                                        format: '#0.00'
                                    }}
                                />
                                <Column
                                    caption="Deduction Period"
                                    dataField="deductionPeriod"
                                    dataType="number"
                                />
                                <Column
                                    caption="Remark"
                                    dataField="remark"
                                    dataType="string"
                                />
                                <Column
                                    type="buttons"
                                    width={100}
                                    fixed
                                    fixedPosition="right"
                                >
                                    <Button
                                        text={readOnly ? 'View' : 'Edit'}
                                        onClick={(e) => editRecord(e.row.data.id)}
                                    />
                                    <Button
                                        text="Delete"
                                        visible={!readOnly}
                                        onClick={(e) => deleteRecord(e.row.data.id)}
                                    />
                                </Column>
                            </StandardDataGrid>
                        </div>
                    </div>
                    {showRenovationExpenseDataDialog &&
                        <RenovationExpenseDataDialog
                            initialData={renovationExpenseData}
                            onSubmit={saveRecord}
                            onCancel={() => setShowRenovationExpenseDataDialog(false)}
                        />
                    }
                </>
                :
                <AccountBookNotSelected />
            }
        </>
    );
};

export default RenovationExpenseMaintenancePage;
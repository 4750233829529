import { Button } from 'devextreme-react/button';
import { Column, Paging, Toolbar } from 'devextreme-react/data-grid';
import { ToolbarItem } from 'devextreme-react/popup';
import { PrimaryButton } from 'components/StandardButton';
import StandardDataGrid from 'components/StandardDataGrid';
import StandardPopup from 'components/StandardPopup';

const AccountMappingSaveChangesDialog = (props) => {
    const { validateCompleteAccountMapping, initialData, onSubmit, onCancel } = props;
    const { chartOfAccountData, pdeList, dataChanges } = initialData;

    const isAccountMappingComplete = validateCompleteAccountMapping();

    return (
        <StandardPopup
            className="mx-3"
            width={1300}
            visible
            onHiding={onCancel}
            showCloseButton
            title="Are you sure on saving these changes ?"
        >
            {!isAccountMappingComplete &&
                <div className="alert alert-danger">
                    Account Mapping is not complete. Every account must be mapped to a PDE (Prescribed Data Element).
                </div>
            }
            {dataChanges.length > 0 ?
                <StandardDataGrid
                    dataSource={dataChanges}
                    width="100%"
                    columnAutoWidth={false}
                >
                    <Column
                        caption="Acc. No."
                        dataField="accNo"
                        dataType="string"
                        sortIndex={0}
                        sortOrder="asc"
                        width="100px"
                    />
                    <Column
                        caption="Account Description"
                        calculateDisplayValue={rowData => {
                            const account = chartOfAccountData.find(x => x.accNo === rowData.accNo);
                            return account && account.description;
                        }}
                    />
                    <Column
                        caption="Original PDE"
                        dataType="string"
                        cellRender={e => {
                            const pde = pdeList.find(x => x.dataId === e.data.originalPde);
                            return (
                                <>
                                    {e.data.originalLiquidity &&
                                        <div style={{ lineHeight: '13px', marginRight: '4px' }} className="badge bg-dark text-light fw-normal align-self-center">
                                            {e.data.originalLiquidity}
                                        </div>
                                    }
                                    {pde && pde.description}
                                </>
                            );
                        }}
                    />
                    <Column
                        caption="Current PDE"
                        dataType="string"
                        cellRender={e => {
                            const pde = pdeList.find(x => x.dataId === e.data.currentPde);
                            return (
                                <>
                                    {e.data.currentLiquidity &&
                                        <div style={{ lineHeight: '13px', marginRight: '4px' }} className="badge bg-dark text-light fw-normal align-self-center">
                                            {e.data.currentLiquidity}
                                        </div>
                                    }
                                    {pde && pde.description}
                                </>
                            );
                        }}
                    />
                    <Column
                        caption="Status"
                        dataField="status"
                        fixed
                        fixedPosition="right"
                        width="100px"
                        cellRender={e => {
                            if (e.value === 'Added') {
                                return (<div style={{ color: 'green' }}>{e.value}</div>);
                            } else if (e.value === 'Modified') {
                                return (<div style={{ color: 'blue' }}>{e.value}</div>);
                            } else if (e.value === 'Deleted') {
                                return (<div style={{ color: 'red' }}>{e.value}</div>);
                            } else {
                                return (<div style={{ color: 'black' }}>No Action</div>);
                            }
                        }}
                    />
                    <Toolbar visible={false} />
                    <Paging enabled={false} />
                </StandardDataGrid>
                :
                <div className="mt-5 d-flex justify-content-center h2">No changes were found.</div>
            }

            <ToolbarItem
                render={() => (
                    <PrimaryButton
                        text="Save Changes"
                        disabled={dataChanges.length === 0}
                        onClick={onSubmit}
                    />
                )}
                toolbar="bottom"
                location="center"
            />
            <ToolbarItem
                render={() => (
                    <Button
                        text="Cancel"
                        onClick={onCancel}
                    />
                )}
                toolbar="bottom"
                location="center"
            />
        </StandardPopup>
    );
};

export default AccountMappingSaveChangesDialog;
import React, { useRef, useImperativeHandle, useMemo, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Column, Sorting, Selection, Toolbar } from 'devextreme-react/data-grid';
import { Accordion, Item } from 'devextreme-react/accordion';
import CheckBox from 'devextreme-react/check-box';
import TextBox from 'devextreme-react/text-box';
import Popover from 'devextreme-react/popover';
import RadioGroup from 'devextreme-react/radio-group';
import SelectBox from 'devextreme-react/select-box';
import Validator from 'devextreme-react/validator';
import CustomStore from 'devextreme/data/custom_store';
import StandardDataGrid from 'components/StandardDataGrid';
import StandardDateBox from 'components/StandardDateBox';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as DateUtils from 'utils/dateUtils';
import FileBox from './components/FileBox';
import * as entityType from './data/entityType';
import * as entityActiveDormant from './data/entityActiveDormant';
import * as entityAGMType from './data/entityAGMType';
import * as entitySolvencyType from './data/entitySolvencyType';
import * as financialInfoNatureType from './data/financialInfoNatureType';
import * as agmCopyConditions from './data/agmCopyConditions';
import './AnnualReturnDetailView.scss';

const AnnualReturnDetailsView = (props) => {
    const { pathname: currentPath } = useLocation();

    const { accountBookData } = useAccountBookData();
    const companyName = accountBookData && accountBookData.companyName;

    const [attachAGMCopyPDF, setAttachAGMCopyPDF] = useState({
        isAllow: true,
        isRequired: false
    });
    const { initialData, formData, handleFormDataChange, forwardedRef } = props;
    const { profileData, financialStatementList } = initialData;

    const { local, remote, initiatedTimestamp } = profileData;
    const isFilingAgent = !!local.filingAgentNo;
    const {
        uploadedXbrls, basisPeriodTo, lodgerName
    } = remote || {};

    const { agmCopyPDF, agmCopyContinuationPDF } = formData;

    const accordionRef = useRef(null);

    const notFilingFinancialInfo = [{
        dtauthIssuefinstmt: null,
        natureOfFinacialnfo: financialInfoNatureType.NotFilingFinancialInfo,
        reviewCopyPDFURL: null,
        uploadedBy: '-',
        uploadedDate: null,
        xbrlKey: -1
    }];

    useEffect(() => {
        // Refer to v1.3 Page 99, 100, to view conditions where AGMCopy is required/optional/allow to upload
        let findAGMCopyCondition = agmCopyConditions.allAGMCopy.find(row =>
            row.entityType === formData.entityType &&
            row.entitySolvency === formData.entitySolvency &&
            row.entityActiveDormant === formData.entityActiveDormant &&
            row.isListedSecurityExchangeAtFinancial === formData.isListedSecurityExchangeAtFinancial &&
            (
                (row.xbrlNatures.length === 0 && formData.xbrlNature === null) ||
                row.xbrlNatures.includes(formData.xbrlNature)
            ) &&
            row.isDormantExemptFinancialStatement === formData.isDormantExemptFinancialStatement
        );

        if (findAGMCopyCondition) {
            // Special Processing for Exempt Private Company which is Active, Solvent and has Financial Statement SPXBRL
            const isAGMCopyRequired = findAGMCopyCondition.entityType === entityType.PrivateCompanyExemptLimitedShares &&
                                      findAGMCopyCondition.entitySolvency === entitySolvencyType.Solvent &&
                                      findAGMCopyCondition.entityActiveDormant === entityActiveDormant.Active &&
                                      findAGMCopyCondition.isListedSecurityExchangeAtFinancial === null &&
                                      findAGMCopyCondition.isDormantExemptFinancialStatement === false &&
                                      formData.xbrlNature === financialInfoNatureType.SimplifiedXBRL;
            setAttachAGMCopyPDF({ isAllow: true, isRequired: isAGMCopyRequired || findAGMCopyCondition.isAGMCopyRequired });
        } else {
            // Not Allowed to Upload AGM PDF when unable to find condition in the Table
            // Reset value of AGMCopy when Not Allowed to Upload
            handleFormDataChange({ agmCopyPDF: null, agmCopyContinuationPDF: null });
            setAttachAGMCopyPDF({ isAllow: false, isRequired: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        formData.entityType, formData.entitySolvency,
        formData.entityActiveDormant, formData.isListedSecurityExchangeAtFinancial,
        formData.xbrlNature, formData.isDormantExemptFinancialStatement
    ]);

    function checkOutdatedFinancialStatement() {
        if (uploadedXbrls && uploadedXbrls.length > 0 &&
            financialStatementList && financialStatementList.length > 0) {
            const reviewCopyPDFUrls = uploadedXbrls.map(x => x.reviewCopyPDFURL);
            const financialStatementPDFUrls = financialStatementList.map(x => x.urlOfReviewCopy);

            for (const url of reviewCopyPDFUrls) {
                // Sample of the URl: "reviewCopyPDFURL": "https://uat-internet-aws.bizfinx.gov.sg/handlers/reviewhandler.ashx?type=1&id=F3883FBD2FAD47EBA443751CAD54505D&ReqestId=BFX20240104000008454"
                const reviewUrl = url.split('&ReqestId=')[0];

                if (financialStatementPDFUrls.includes(reviewUrl)) {
                    const financialStatementReviewUrl = financialStatementList.find(x => x.urlOfReviewCopy === reviewUrl);
                    const uploadedXbrlRecord = uploadedXbrls.find(x => x.reviewCopyPDFURL === url);

                    if (financialStatementReviewUrl.requireNewFiling) {
                        uploadedXbrlRecord.requireNewFiling = true;
                    }
                }
            }
        }
    }

    const uploadedXbrlsDataSource = useMemo(() => new CustomStore({
        loadMode: 'raw',
        // eslint-disable-next-line react/no-multi-comp
        load: function () {
            checkOutdatedFinancialStatement();
            // Refer to v1.3 Page 99, 100, to view conditions on which XBRL file to include Financial Statement XBRL DataSource
            let findCondition = agmCopyConditions.allAGMCopy.find(row => row.entityType === formData.entityType && row.entitySolvency === formData.entitySolvency &&
                row.entityActiveDormant === formData.entityActiveDormant && row.isListedSecurityExchangeAtFinancial === formData.isListedSecurityExchangeAtFinancial &&
                row.isDormantExemptFinancialStatement === formData.isDormantExemptFinancialStatement);
            if (findCondition) {
                let xbrlNatures = findCondition.xbrlNatures;
                let uploadedXbrlsData = [].concat.apply([], [notFilingFinancialInfo, uploadedXbrls].filter(arr => arr !== null && arr));
                let uploadedXbrlsDataFilter = uploadedXbrlsData.filter(row => xbrlNatures.includes(row.natureOfFinacialnfo));

                if (!xbrlNatures.includes(formData.xbrlNature)) {
                    if (formData.xbrlNature === financialInfoNatureType.NotFilingFinancialInfo &&
                        (
                            formData.entityType === entityType.PrivateCompanyExemptLimitedShares ||
                            formData.entityType.PrivateCompanyExemptUnlimited
                        )
                    ) {
                        handleFormDataChange({ xbrlKey: null, xbrlNature: null, isXbrlKeyRequired: xbrlNatures.length > 0, auditorReportModifiedOpinion: null, auditorReportEmphasisMatter: null });
                    } else {
                        handleFormDataChange({ xbrlKey: null, xbrlNature: null, isXbrlKeyRequired: xbrlNatures.length > 0 });
                    }
                } else {
                    handleFormDataChange({ isXbrlKeyRequired: xbrlNatures.length > 0 });
                }

                return uploadedXbrlsDataFilter;
            } else {
                handleFormDataChange({ xbrlKey: null, xbrlNature: null, isXbrlKeyRequired: false });
                return null;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [formData.entityType, formData.entitySolvency, formData.entityActiveDormant, formData.isListedSecurityExchangeAtFinancial, formData.isDormantExemptFinancialStatement, uploadedXbrls]);

    const companyOfficersAuditorsData = useMemo(() => {
        if (remote) {
            const companyOfficersAuditorsList = [].concat.apply([], [
                remote.directorsDetails,
                remote.managingDirectorsDetails,
                remote.alternateDirectorsDetails,
                remote.secretaryDetails,
                remote.ceoDetails
            ].filter(arr => arr !== null && arr));

            return [...new Map(companyOfficersAuditorsList.map((item) => [item.idno, item])).values()];
        } else {
            return [];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remote]);

    useEffect(() => {
        if (!companyOfficersAuditorsData.some(obj => obj.idno === formData.verifiedFrom)) {
            // reset value of "Verified From" when unable to find in companyDirector List in InitiateAR response 
            handleFormDataChange({ verifiedFrom: '' });
        }
    }, [companyOfficersAuditorsData]);

    const companyDirectorsData = useMemo(() => {
        if (remote) {
            let companyDirectorsList = [].concat.apply([], [
                remote.directorsDetails,
                remote.managingDirectorsDetails,
                remote.alternateDirectorsDetails
            ].filter(arr => arr !== null && arr));

            return [...new Map(companyDirectorsList.map((item) => [item.idno, item])).values()];
        } else {
            return [];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remote]);

    useEffect(() => {
        if (!companyDirectorsData.some(obj => obj.idno === formData.director1IdNo)) {
            // reset value of "Director 1"" when unable to find in companyDirector List in InitiateARResponse
            handleFormDataChange({ director1IdNo: '' });
        }

        if (!companyDirectorsData.some(obj => obj.idno === formData.director2IdNo)) {
            // reset value of "Director 2" when unable to find in companyDirector List in InitiateARResponse
            handleFormDataChange({ director2IdNo: '' });
        }
    }, [companyDirectorsData]);

    const entityTypeDataSource = [
        { text: 'A1 - PUBLIC COMPANY LIMITED BY SHARES', value: entityType.PublicCompanyLimitedShares },
        { text: 'A3 - PUBLIC COMPANY LIMITED BY GUARANTEE', value: entityType.PublicCompanyLimitedGuarantee },
        { text: 'A4 - UNLIMITED PUBLIC COMPANY', value: entityType.PublicCompanyUnlimited },
        { text: 'B1 - PRIVATE COMPANY LIMITED BY SHARES', value: entityType.PrivateCompanyLimitedShares },
        { text: 'B2 - EXEMPT PRIVATE COMPANY LIMITED BY SHARES', value: entityType.PrivateCompanyExemptLimitedShares },
        { text: 'B3 - UNLIMTED PRIVATE COMPANY', value: entityType.PrivateCompanyUnlimited },
        { text: 'B4 - UNLIMITED EXEMPT PRIVATE COMPANY', value: entityType.PrivateCompanyExemptUnlimited }
    ];

    const entityActiveDormantDataSource = [
        { text: 'Active', value: entityActiveDormant.Active },
        { text: 'Dormant (for the entire financial period)', value: entityActiveDormant.Dormant }
    ];

    const entitySolvencyDataSource = [
        { text: 'Solvent', value: entitySolvencyType.Solvent },
        { text: 'Insolvent', value: entitySolvencyType.Insolvent },
        { text: 'EPC Required by law to file accounts', value: entitySolvencyType.EPCRequiredByLaw }
    ];

    const isListedSecurityExchangeAtFinancialDataSource = [
        { text: 'Yes', value: 'Y' },
        { text: 'No', value: 'N' }
    ];

    const isListedSecurityExchangeAtLodgeDataSource = [
        { text: 'Yes', value: 'Y' },
        { text: 'No', value: 'N' }
    ];

    const entityAGMTypeDataSource = [
        { text: 'Yes, Company is required to hold AGM', value: entityAGMType.RequiredAGM },
        { text: 'No, Company is exempted to hold AGM as financial statements has been sent to members and no request received for AGM to be held', value: entityAGMType.ExemptAGM_FinancialStatementSent },
        { text: 'No, Company is exempted to hold an AGM as it is a Private Dormant Relevant Company that is not required to prepare financial statements and no member requested for AGM to be held', value: entityAGMType.ExemptAGM_FinancialStatementNotRequired },
        { text: 'No, a resolution to dispense with holding AGMs passed by all members is in force', value: entityAGMType.WitholdAGM }
    ];

    const emphasisMatterDataSource = [
        { text: 'Yes', value: 'Y' },
        { text: 'No', value: 'N' }
    ];

    const modifiedOpinionDataSource = [
        { text: 'Yes', value: 'Y' },
        { text: 'No', value: 'N' }
    ];

    const financialInfoNature = [
        { text: 'Not filing financial information in XBRL', value: financialInfoNatureType.NotFilingFinancialInfo },
        { text: 'Full set of financial statements in XBRL', value: financialInfoNatureType.FullSetXBRLOld },
        { text: 'Financial Statements Highlights in XBRL', value: financialInfoNatureType.FinancialHighlightsXBRL },
        { text: 'Full set of financial statements in XBRL', value: financialInfoNatureType.FullSetXBRLNew },
        { text: 'Financial Statements in Simplified XBRL Format', value: financialInfoNatureType.SimplifiedXBRL },
        { text: 'Financial Statements Highlights in XBRL(for banks, finance companies and registered insurers only)', value: financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance }
    ];

    const validationRules = {
        agmCopyPDF: [
            { type: 'custom', message: 'AGM Copy is required.', validationCallback: validateAGMCopyPDF, reevaluate: true }
        ],
        auditorReportDate: [
            { type: 'custom', message: 'Auditor Report Date is required.', validationCallback: validateAuditorReportDate, reevaluate: true }
        ],
        auditorReportEmphasisMatter: [
            { type: 'custom', message: 'This field is required.', validationCallback: validateAuditorReportEmphasisMatter, reevaluate: true }
        ],
        auditorReportModifiedOpinion: [
            { type: 'custom', message: 'This field is required.', validationCallback: validateAuditorReportModifiedOpinion, reevaluate: true }
        ],
        entityType: [
            { type: 'required', message: 'Company Type is required.' }
        ],
        entityActiveDormant: [
            { type: 'required', message: 'This field is required.' }
        ],
        entitySolvency: [
            { type: 'required', message: 'This field is required.' }
        ],
        isListedSecurityExchangeAtFinancial: [
            { type: 'required', message: 'This field is required.' },
            { type: 'custom', message: 'A4 - Unlimited Public Company cannot be Dormant and Listed on Security Exchange.', validationCallback: validateListedSecurityExchangeAtFinancial, reevaluate: true }
        ],
        isListedSecurityExchangeAtLodge: [
            { type: 'required', message: 'This field is required.' },
            { type: 'custom', message: 'A4 - Unlimited Public Company cannot be Dormant and Listed on Security Exchange.', validationCallback: validateListedSecurityExchangeatLodge, reevaluate: true }
        ],
        filingAuthoriser: [
            { type: 'required', message: 'Please choose an Authoriser.' }
        ],
        isEntityAGMRequired: [
            { type: 'required', message: 'This field is required.' }
        ],
        agmFinancialStatementDate: [
            { type: 'required', message: 'This field is required.' }
        ],
        financialStatementSentDate: [
            { type: 'required', message: 'This field is required.' }
        ],
        director1IdNo: [
            { type: 'required', message: 'Director 1 is required.' }
        ],
        director2IdNo: [
            { type: 'custom', message: 'Both signing directors must be unique.', validationCallback: validateDirector2IdNo, reevaluate: true }
        ],
        isFinancialStatementAudited: [
            { type: 'custom', message: 'This field must be ticked.', validationCallback: validateIsFinancialStatementAudited, reevaluate: true }
        ],
        declareInfoAccurateUpToDate: [
            { type: 'required', message: 'You must declare that annual return lodged is accurate and complete.' }
        ],
        publicAccountingEntityNo: [
            { type: 'required', message: 'Public Accounting Entity is required.' }
        ],
        auditorRegistrationNo: [
            { type: 'required', message: 'Auditor is required.' }
        ]
    };

    function validateAGMCopyPDF() {
        return attachAGMCopyPDF.isRequired ? agmCopyPDF && agmCopyPDF.documentId !== null : true;
    }

    function validateAuditorReportDate() {
        // refer to v1.3 Page 57 "c211.dateOfIndependentAuditor", only validate field for either the following:-
        // 1. Entity Type: B2 / B4, Solvent, Not Filing Financial Information, Financial Statements Audited
        // 2. Entity Type: A3, Financial Statements Audited
        return !((((formData.entityType === entityType.PrivateCompanyExemptLimitedShares || formData.entityType === entityType.PrivateCompanyExemptUnlimited) &&
            formData.entitySolvency === entitySolvencyType.Solvent && formData.xbrlNature === financialInfoNatureType.NotFilingFinancialInfo) ||
            formData.entityType === entityType.PublicCompanyLimitedGuarantee) && !formData.auditorReportDate);
    }

    function validateAuditorReportEmphasisMatter() {
        // refer to v1.3 Page 56 "c211.auditOpinion", only validate field for either the following:-
        // 1. Entity Type: B2 / B4, Solvent, Not Filing Financial Information, Financial Statements Audited
        // 2. Entity Type: A3, Financial Statements Audited
        return !((((formData.entityType === entityType.PrivateCompanyExemptLimitedShares || formData.entityType === entityType.PrivateCompanyExemptUnlimited) &&
            formData.entitySolvency === entitySolvencyType.Solvent && formData.xbrlNature === financialInfoNatureType.NotFilingFinancialInfo) ||
            formData.entityType === entityType.PublicCompanyLimitedGuarantee) && !formData.auditorReportEmphasisMatter);
    }

    function validateAuditorReportModifiedOpinion() {
        // refer to v1.3 Page 57 "c211.modifiedOpinion", only validate field for either the following:-
        // 1. Entity Type: B2 / B4, Solvent, Not Filing Financial Information, Financial Statements Audited
        // 2. Entity Type: A3, Financial Statements Audited

        const isRequired = (
            (
                (
                    formData.entityType === entityType.PrivateCompanyExemptLimitedShares ||
                    formData.entityType === entityType.PrivateCompanyExemptUnlimited
                ) &&
                formData.entitySolvency === entitySolvencyType.Solvent &&
                formData.xbrlNature === financialInfoNatureType.NotFilingFinancialInfo
            ) ||
            formData.entityType === entityType.PublicCompanyLimitedGuarantee
        );

        return !isRequired || formData.auditorReportModifiedOpinion;
    }

    function validateListedSecurityExchangeAtFinancial() {
        // refer to Page 99, 100 table, Public Company Unlimited cannot be Dormant and Listed at the same time
        // A4 - Public Company Unlimited, Dormant, Is Listed (This condition should not & does not exist [confirmed and verified by ACRA personnel])
        return (formData.entityType === entityType.PublicCompanyUnlimited && formData.entityActiveDormant === entityActiveDormant.Dormant &&
            formData.isListedSecurityExchangeAtFinancial !== 'Y') || formData.entityType !== entityType.PublicCompanyUnlimited;
    }

    function validateListedSecurityExchangeatLodge() {
        // refer to Page 99, 100 table, Public Company Unlimited cannot be Dormant and Listed at the same time
        // A4 - Public Company Unlimited, Dormant, Is Listed (This condition should not & does not exist [confirmed and verified by ACRA personnel])
        return (formData.entityType === entityType.PublicCompanyUnlimited && formData.entityActiveDormant === entityActiveDormant.Dormant &&
            formData.isListedSecurityExchangeAtLodge !== 'Y') || formData.entityType !== entityType.PublicCompanyUnlimited;
    }

    function validateIsFinancialStatementAudited() {
        // refer to v1.3 Page 53 "c211.isFinStatementsAudited", only validate field for either the following:-
        // 1. Comapny Small Exempt Audit
        // 2. Company Dormant Exempt Audit
        return !(!formData.isSmallCompanyExemptAudit && !formData.isDormantExemptAudit && !formData.isFinancialStatementAudited);
    }

    function validateDirector2IdNo() {
        return (formData.director1IdNo !== formData.director2IdNo) || (formData.director1IdNo === '' || !formData.director1IdNo);
    }

    useImperativeHandle(forwardedRef, () => {
        return {
            expandView: () => {
                if (accordionRef.current.instance) {
                    const items = accordionRef.current.instance.option('items');
                    accordionRef.current.instance.option('selectedItemKeys', items);
                }
            }
        };
    }, []);

    return (
        <div className="detailsTab">
            <Accordion
                onInitialized={(e) => {
                    const items = e.component.option('items');
                    e.component.option('selectedItemKeys', items);
                }}
                autoExpandAll
                collapsible
                multiple
                ref={accordionRef}
            >
                <Item
                    key="companyInfo"
                    title="Company Information"
                >
                    <div className="px-4 py-3">
                        <div className="form-row d-flex">
                            <div className="col-md-5 col-sm-4">
                                <label className="form-label">Company Name</label>
                                <TextBox
                                    className="form-control"
                                    value={companyName}
                                    readOnly
                                />
                            </div>

                            <div className="offset-md-1 offset-sm-1 col-md-5 col-sm-4">
                                <label className="form-label">UEN</label>
                                <TextBox
                                    className="form-control"
                                    value={profileData.local.companyUEN}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </Item>
                <Item
                    key="financialYear"
                    title="Financial Year"
                >
                    <div className="px-4 py-3 form-row d-flex">
                        <div className="col-md-5">
                            <label className="form-label">Financial Year End for this Annual Return</label>
                            <StandardDateBox
                                className="form-control"
                                value={basisPeriodTo}
                                type="date"
                                readOnly
                            />
                        </div>
                        <div className="offset-md-1 col-md-5">
                            <label className="form-label">Date of Annual Return</label>
                            <StandardDateBox
                                className="form-control"
                                value={initiatedTimestamp}
                                type="date"
                                readOnly
                            />
                        </div>
                    </div>
                </Item>
                <Item
                    key="companyTypeAndStatus"
                    title="Section A: Company Type and Status"
                >
                    <div className="card-body px-4 py-3">
                        <div className="alert alert-warning">
                            Some of the information below is pre-selected based on the last Annual Return filed.
                            You are required to review and update accordingly if there are changes for this current Annual Return.
                        </div>
                        <label className="form-label fw-bolder">Company Type Details</label>
                        <div className="card responsive-paddings">
                            <div className="form-row">
                                <div className="col-md-8">
                                    <label className="form-label required">Company Type During Financial Period Concerned</label>
                                    <SelectBox
                                        className="form-control"
                                        dataSource={entityTypeDataSource}
                                        displayExpr="text"
                                        valueExpr="value"
                                        value={formData.entityType}
                                        onValueChanged={e => {
                                            if (e.value === entityType.PrivateCompanyLimitedShares ||
                                                e.value === entityType.PrivateCompanyUnlimited ||
                                                e.value === entityType.PublicCompanyLimitedGuarantee) {
                                                if (e.value === entityType.PublicCompanyLimitedGuarantee) {
                                                    handleFormDataChange({ entityType: e.value, isEntityAGMRequired: entityAGMType.RequiredAGM, financialStatementSentDate: null, entitySolvency: null, isListedSecurityExchangeAtFinancial: null, isListedSecurityExchangeAtLodge: null, isSmallCompanyExemptAudit: false });
                                                } else {
                                                    handleFormDataChange({ entityType: e.value, entitySolvency: null, isListedSecurityExchangeAtFinancial: null, isListedSecurityExchangeAtLodge: null, auditorReportModifiedOpinion: null, auditorReportEmphasisMatter: null });
                                                }
                                            } else {
                                                if (e.value === entityType.PrivateCompanyExemptLimitedShares || e.value === entityType.PrivateCompanyExemptUnlimited) {
                                                    if (formData.isDormantExemptAudit && formData.entitySolvency === entitySolvencyType.Solvent && formData.xbrlNature === financialInfoNatureType.NotFilingFinancialInfo) {
                                                        handleFormDataChange({ entityType: e.value, isListedSecurityExchangeAtFinancial: null, isListedSecurityExchangeAtLodge: null });
                                                    } else {
                                                        handleFormDataChange({ entityType: e.value, isListedSecurityExchangeAtFinancial: null, isListedSecurityExchangeAtLodge: null, auditorReportModifiedOpinion: null, auditorReportEmphasisMatter: null });
                                                    }
                                                } else {
                                                    if (e.value === entityType.PublicCompanyLimitedShares || e.value === entityType.PublicCompanyUnlimited) {
                                                        handleFormDataChange({ entityType: e.value, isEntityAGMRequired: entityAGMType.RequiredAGM, financialStatementSentDate: null, entitySolvency: null, isDormantExemptFinancialStatement: false, isSmallCompanyExemptAudit: false, auditorReportModifiedOpinion: null, auditorReportEmphasisMatter: null });
                                                    } else {
                                                        handleFormDataChange({ entityType: e.value, entitySolvency: null, auditorReportModifiedOpinion: null, auditorReportEmphasisMatter: null });
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        <Validator validationRules={validationRules.entityType} />
                                    </SelectBox>
                                </div>
                            </div>

                            <div className="form-row mt-4">
                                <label className="form-label required">Company Type Status (I)</label>
                                <RadioGroup
                                    layout="vertical"
                                    dataSource={entityActiveDormantDataSource}
                                    displayExpr="text"
                                    valueExpr="value"
                                    value={formData.entityActiveDormant}
                                    onValueChanged={e => {
                                        if (e.value === entityActiveDormant.Active) {
                                            handleFormDataChange({ isDormantExemptFinancialStatement: false, isDormantExemptAudit: false, entityActiveDormant: e.value });
                                        } else {
                                            handleFormDataChange({ entityActiveDormant: e.value });
                                        }
                                    }}
                                >
                                    <Validator validationRules={validationRules.entityActiveDormant} />
                                </RadioGroup>
                            </div>

                            {(formData.entityType === entityType.PrivateCompanyExemptLimitedShares || formData.entityType === entityType.PrivateCompanyExemptUnlimited) &&
                                <div className="form-row mt-4">
                                    <label className="form-label required">Company Type Status (II)</label>
                                    <RadioGroup
                                        layout="vertical"
                                        dataSource={entitySolvencyDataSource}
                                        displayExpr="text"
                                        valueExpr="value"
                                        value={formData.entitySolvency}
                                        onValueChanged={e => {
                                            if (e.value !== entitySolvencyType.Solvent && e.value !== entityType.PublicCompanyLimitedGuarantee) {
                                                handleFormDataChange({ entitySolvency: e.value, auditorReportModifiedOpinion: null, auditorReportEmphasisMatter: null });
                                            } else {
                                                handleFormDataChange({ entitySolvency: e.value });
                                            }
                                        }}
                                    >
                                        <Validator validationRules={validationRules.entitySolvency} />
                                    </RadioGroup>
                                </div>
                            }

                            {(formData.entityType === entityType.PublicCompanyLimitedShares || formData.entityType === entityType.PublicCompanyUnlimited) &&
                                <div>
                                    <div className="form-row mt-4">
                                        <label className="form-label required">Is Public Company Listed on a securities exchange in Singapore during <u>Financial Period Concerned?</u></label>
                                        <RadioGroup
                                            layout="vertical"
                                            dataSource={isListedSecurityExchangeAtFinancialDataSource}
                                            displayExpr="text"
                                            valueExpr="value"
                                            value={formData.isListedSecurityExchangeAtFinancial}
                                            onValueChanged={e => {
                                                // refer to Page 99, 100 table for the following conditions for Not Allowed to Tick IsDormantExemptFinancialStatement (Exemption under S201A):-
                                                // 1. A1 - Public Company Limited Shares, Dormant, Is Listed 
                                                // 2. (*)A4 - Public Company Unlimited, Dormant, Is Listed
                                                if ((formData.entityType === entityType.PublicCompanyUnlimited || formData.entityType === entityType.PublicCompanyLimitedShares) &&
                                                    formData.entityActiveDormant === entityActiveDormant.Dormant && e.value) {
                                                    handleFormDataChange({ isListedSecurityExchangeAtFinancial: e.value, isDormantExemptFinancialStatement: false });
                                                } else {
                                                    handleFormDataChange({ isListedSecurityExchangeAtFinancial: e.value });
                                                }
                                            }}
                                        >
                                            <Validator validationRules={validationRules.isListedSecurityExchangeAtFinancial} />
                                        </RadioGroup>
                                    </div>

                                    <div className="form-row mt-4">
                                        <label className="form-label required">Are there any securities exchange as at AR lodgement date?</label>
                                        <RadioGroup
                                            layout="vertical"
                                            dataSource={isListedSecurityExchangeAtLodgeDataSource}
                                            displayExpr="text"
                                            valueExpr="value"
                                            value={formData.isListedSecurityExchangeAtLodge}
                                            onValueChanged={e => handleFormDataChange({ isListedSecurityExchangeAtLodge: e.value })}
                                        >
                                            <Validator validationRules={validationRules.isListedSecurityExchangeAtLodge} />
                                        </RadioGroup>
                                    </div>
                                </div>
                            }

                            {formData.entityActiveDormant === entityActiveDormant.Dormant &&
                                <div>
                                    {(((formData.entityType === entityType.PublicCompanyUnlimited && formData.isListedSecurityExchangeAtFinancial !== 'Y') ||
                                        (formData.entityType === entityType.PublicCompanyLimitedShares && formData.isListedSecurityExchangeAtFinancial !== 'Y')) ||
                                        (formData.entityType !== entityType.PublicCompanyLimitedShares && formData.entityType !== entityType.PublicCompanyUnlimited)) &&
                                            // refer to Page 99, 100 table for the following conditions for 
                                            // Not Allowed to Tick IsDormantExemptFinancialStatement (Exemption under S201A):-
                                            // 1. A1 - Public Company Limited Shares, Dormant, Is Listed
                                            // 2. (*)A4 - Public Company Unlimited, Dormant, Is Listed
                                            <div className="form-row mt-4">
                                                <div className="form-group">
                                                    <CheckBox
                                                        text="Check this box to declare that the company is a dormant relevant company exempt from preparing financial statements."
                                                        value={formData.isDormantExemptFinancialStatement}
                                                        onValueChanged={e => {
                                                            if (e.value) {
                                                                handleFormDataChange({ director1IdNo: '', director2IdNo: '', isDormantExemptFinancialStatement: e.value });
                                                            } else {
                                                                handleFormDataChange({ isDormantExemptFinancialStatement: e.value });
                                                            }
                                                        }}
                                                    />

                                                    {formData.isDormantExemptFinancialStatement &&
                                                        <div className="alert alert-info mt-2">
                                                            <div>The directors make the following statement:</div>
                                                            <ul className="mb-0 pl-2">
                                                                <li>
                                                                    (i) that the company qualifies as a small company under section 205C read with the Thirteenth Schedule;
                                                                </li>
                                                                <li>
                                                                    (ii) that no notice has been received under section 205C(2) read with 205B(6) of the Companies Act in relation to the financial year; and
                                                                </li>
                                                                <li>
                                                                    (iii) the accounting and other records required by the Companies Act to be kept by the company have been kept in accordance with section 199 of the Companies Act.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                    }

                                    <div className="form-row mt-4">
                                        <div className="form-group">
                                            <CheckBox
                                                text="Check this box to declare that the company is dormant and exempt from audit requirements."
                                                value={formData.isDormantExemptAudit}
                                                onValueChanged={e => handleFormDataChange({ isDormantExemptAudit: e.value })}
                                            />

                                            {formData.isDormantExemptAudit &&
                                                <div className="alert alert-info mt-2">
                                                    <div>The directors make the following statement:</div>
                                                    <ul className="mb-0 pl-2">
                                                        <li>
                                                            (i) that the company has been dormant for the period from the time of its formation or since the end of the previous financial year, as the case may be;
                                                        </li>
                                                        <li>
                                                            (ii) that no notice has been received under section 205B(6) of the Companies Act in relation to the financial year; and;
                                                        </li>
                                                        <li>
                                                            (iii) the accounting and other records required by the Companies Act to be kept by the company have been kept in accordance with section 199 of the Companies Act.
                                                        </li>
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                            {(formData.entityType === entityType.PrivateCompanyLimitedShares ||
                                formData.entityType === entityType.PrivateCompanyExemptLimitedShares ||
                                formData.entityType === entityType.PrivateCompanyUnlimited ||
                                formData.entityType === entityType.PrivateCompanyExemptUnlimited) &&
                                    <div className="form-row mt-4">
                                        <div className="form-group">
                                            <CheckBox
                                                text="Check this box to declare that the company is small company exempt from audit requirements."
                                                value={formData.isSmallCompanyExemptAudit}
                                                onValueChanged={e => handleFormDataChange({ isSmallCompanyExemptAudit: e.value })}
                                            />

                                            {formData.isSmallCompanyExemptAudit &&
                                                <div className="alert alert-info mt-2">
                                                    <div>The directors make the following statement:</div>
                                                    <ul className="mb-0 pl-2">
                                                        <li>
                                                            (i) that the company qualifies as a small company under section 205C read with the Thirteenth Schedule;
                                                        </li>
                                                        <li>
                                                            (ii) that no notice has been received under section 205C(2) of the Companies Act in relation to the financial year; and;
                                                        </li>
                                                        <li>
                                                            (ii) the accounting and other records required by the Companies Act to be kept by the company have been kept in accordance with section 199 of the Companies Act.
                                                        </li>
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    </div>
                            }
                        </div>

                        <label className="form-label fw-bolder mt-4">AGM Details</label>
                        <div className="card responsive-paddings">
                            {(formData.entityType === entityType.PrivateCompanyLimitedShares ||
                                formData.entityType === entityType.PrivateCompanyExemptLimitedShares ||
                                formData.entityType === entityType.PrivateCompanyUnlimited ||
                                formData.entityType === entityType.PrivateCompanyExemptUnlimited) &&
                                    // refer to v1.3 Page 54 "c211.isEntityAgmRequired", only display field for the following:-
                                    // 1. Entity Type: B1/B2/B3/B4
                                    <div className="form-row mb-4">
                                        <div className="col-md-12">
                                            <label className="form-label required">Is Company required to hold AGM?</label>
                                            <RadioGroup
                                                layout="vertical"
                                                dataSource={entityAGMTypeDataSource}
                                                displayExpr="text"
                                                valueExpr="value"
                                                value={formData.isEntityAGMRequired}
                                                onValueChanged={e => {
                                                    switch (e.value) {
                                                        case entityAGMType.RequiredAGM:
                                                            handleFormDataChange({ financialStatementSentDate: null, isEntityAGMRequired: e.value });
                                                            break;
                                                        case entityAGMType.ExemptAGM_FinancialStatementSent:
                                                        case entityAGMType.WitholdAGM:
                                                            handleFormDataChange({ agmFinancialStatementDate: null, isEntityAGMRequired: e.value });
                                                            break;
                                                        case entityAGMType.ExemptAGM_FinancialStatementNotRequired:
                                                        default:
                                                            handleFormDataChange({ financialStatementSentDate: null, agmFinancialStatementDate: null, isEntityAGMRequired: e.value });
                                                            break;
                                                    }
                                                }}
                                            >
                                                <Validator validationRules={validationRules.isEntityAGMRequired} />
                                            </RadioGroup>
                                        </div>
                                    </div>
                            }

                            <div className="form-row mb-4">
                                <div className="col-md-6">
                                    <label className="form-label">Date of Annual General Meeting at which Financial Statements were laid</label>
                                    <StandardDateBox
                                        className="form-control"
                                        value={formData.agmFinancialStatementDate}
                                        onValueChanged={e => {
                                            handleFormDataChange({ agmFinancialStatementDate: e.value });
                                        }}
                                        disabled={formData.isEntityAGMRequired !== entityAGMType.RequiredAGM
                                            // refer to v1.3 Page 55 "c211.agmFinStatementDate", only allow edit field for the following:-
                                            // 1. isEntityAGMRequired: Y
                                            // 2. Entity Type: A1/A3/A4 (isEntityAGMRequired is set to required in onValueChanged of entityType)
                                        }
                                        type="date"
                                    >
                                        <Validator validationRules={validationRules.agmFinancialStatementDate} />
                                    </StandardDateBox>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="col-md-6">
                                    <label className="form-label">Date financial statements sent to members</label>
                                    <StandardDateBox
                                        className="form-control"
                                        value={formData.financialStatementSentDate}
                                        onValueChanged={e => {
                                            handleFormDataChange({ financialStatementSentDate: e.value });
                                        }}
                                        disabled={formData.isEntityAGMRequired !== entityAGMType.ExemptAGM_FinancialStatementSent &&
                                            formData.isEntityAGMRequired !== entityAGMType.WitholdAGM
                                            // refer to v1.3 Page 55 "c211.finStatementsSentDate", only allow edit field for the following:-
                                            // 1. isEntityAGMRequired: N1/N3
                                        }
                                        type="date"
                                    >
                                        <Validator validationRules={validationRules.financialStatementSentDate} />
                                    </StandardDateBox>
                                </div>
                            </div>
                        </div>
                    </div>
                </Item>
                <Item
                    key="financialInfo"
                    title="Section B: Financial Information"
                >
                    <div className="card-body px-4 py-3">
                        <label className="form-label fw-bolder required" id="xbrlFinancialStatementList">Financial Information in XBRL Format</label>
                        <div className="card responsive-paddings">
                            <div className="alert alert-warning">
                                Except for Dormant Relevant Companies, Solvent Exempt Private Companies and Companies Limited by
                                Guarantee, all other companies <b><u>are required</u></b> to file financial information in XBRL format, unless approval from
                                Registrar has been obtained. For more information on preparing and filing financial information in XBRL format,
                                please click <a href="https://www.acra.gov.sg/xbrl-filing-and-resources/who-needs-to-file-financial-statements" target="_blank" rel="noopener noreferrer">here</a>.
                            </div>
                            {currentPath === '/annualReturnWithFS' &&
                                <div className="alert alert-warning">
                                    To file a new Financial Statement, proceed to <Link to="/financialStatement">Financial Statement</Link>.
                                </div>
                            }
                            <StandardDataGrid
                                dataSource={uploadedXbrlsDataSource}
                                onSelectionChanged={e => {
                                    const { selectedRowsData } = e;
                                    if (selectedRowsData && selectedRowsData.length) {
                                        if (selectedRowsData[0].natureOfFinacialnfo !== financialInfoNatureType.NotFilingFinancialInfo) {
                                            handleFormDataChange({ xbrlKey: selectedRowsData[0].xbrlKey, xbrlNature: selectedRowsData[0].natureOfFinacialnfo, auditorReportModifiedOpinion: null, auditorReportEmphasisMatter: null });
                                        } else {
                                            handleFormDataChange({ xbrlKey: selectedRowsData[0].xbrlKey, xbrlNature: selectedRowsData[0].natureOfFinacialnfo });
                                        }
                                    }
                                }}
                                onRowClick={e => {
                                    if (e.data.xbrlKey === formData.xbrlKey) {
                                        e.component.clearSelection();
                                        handleFormDataChange({ xbrlKey: null, xbrlNature: null });
                                    }
                                }}
                                allowEditing
                            >
                                <Selection
                                    mode="single"
                                    key="xbrlKey"
                                    showCheckBoxesMode="always"
                                    allowSelectAll={false}
                                    allowEditing
                                />
                                <Column
                                    caption="Select"
                                    dataField="Select"
                                    dataType="boolean"
                                    calculateCellValue={rowData => {
                                        const { xbrlKey } = rowData;
                                        return formData.xbrlKey ? parseInt(formData.xbrlKey) === parseInt(xbrlKey) : formData.xbrlKey === xbrlKey;
                                    }}
                                    width={65}
                                    allowEditing
                                />
                                <Column
                                    caption="Nature of Financial Information"
                                    calculateCellValue={rowData => {
                                        const nature = financialInfoNature.find(x => x.value === rowData.natureOfFinacialnfo);
                                        return nature ? nature.text : '-';
                                    }}
                                    minWidth={250}
                                    dataType="string"
                                    cellRender={(cellInfo) => {
                                        return (
                                            <>
                                                <div className="d-flex">
                                                    {cellInfo.text}
                                                    {cellInfo.data.requireNewFiling &&
                                                        <div
                                                            id="financialStatementStatus"
                                                            className="outdated-badge"
                                                        >
                                                            May be Outdated
                                                            <i className="dx-icon dx-icon-info ms-1" style={{ fontSize: '13px' }} />
                                                        </div>
                                                    }
                                                </div>
                                                <Popover
                                                    target="#financialStatementStatus"
                                                    showEvent="mouseenter"
                                                    hideEvent="mouseleave"
                                                >
                                                    <div>
                                                        This badge indicates that this Financial Statement may be outdated.
                                                        <br />
                                                        This is caused by the following actions after the creation of Financial Statement:
                                                        <br />
                                                        - Re-import Account Book
                                                        <br />
                                                        - Modify Account Mapping
                                                    </div>
                                                </Popover>
                                            </>
                                        );
                                    }}
                                />
                                <Column
                                    caption="Uploaded By"
                                    dataField="uploadedBy"
                                    dataType="string"
                                />
                                <Column
                                    caption="Uploaded Date"
                                    dataField="uploadedDate"
                                    calculateCellValue={rowData => {
                                        const { uploadedDate } = rowData;
                                        return uploadedDate ? DateUtils.formatDate(uploadedDate) : '-';
                                    }}
                                    dataType="string"
                                />
                                <Column
                                    caption="Link"
                                    fixed
                                    fixedPosition="right"
                                    width={80}
                                    cellRender={(e) => {
                                        return (
                                            e.data.reviewCopyPDFURL ? <a href={e.data.reviewCopyPDFURL} target="_blank" rel="noopener noreferrer">Download</a> : '-'
                                        );
                                    }}
                                    dataType="string"
                                />
                                <Sorting enabled={false} />
                                <Toolbar visible={false} />
                            </StandardDataGrid>
                        </div>

                        {attachAGMCopyPDF.isAllow &&
                            <div>
                                <label className="form-label fw-bolder mt-4">Financial Information in PDF Format</label>
                                <div className="card responsive-paddings">
                                    <div className="alert alert-warning">
                                        <ul className="mb-0 pl-3">
                                            <li>
                                                Optional for Dormant Relevant Companies and Solvent Exempt Private Companies
                                            </li>
                                            <li>
                                                Mandatory for companies that are required to file financial statements but not filing a
                                                full set of financial statements in XBRL format. Please attach full set of financial statements as tabled at AGM or sent to members
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="form-row mt-2">
                                        <div className="col-md-8">
                                            <label className="form-label">AGM copy of Financial Statements ({attachAGMCopyPDF.isRequired ? 'Required' : 'Optional'})</label>
                                            <FileBox
                                                value={agmCopyPDF}
                                                acceptedFileType="application/pdf"
                                                handleFormDataChange={value => handleFormDataChange({ agmCopyPDF: value })}
                                                maxFileSize={5000000}
                                                validationRules={validationRules.agmCopyPDF}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row mt-4">
                                        <div className="col-md-8">
                                            <label className="form-label">Continuation of Attachment</label>
                                            <FileBox
                                                value={agmCopyContinuationPDF}
                                                acceptedFileType="application/pdf"
                                                handleFormDataChange={value => handleFormDataChange({ agmCopyContinuationPDF: value })}
                                                maxFileSize={5000000}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <label className="form-label fw-bolder mt-4">The Statement by Directors have been signed by:</label>
                        <div className="card responsive-paddings">
                            <div className="form-row d-flex">
                                <div className="col-md-5">
                                    <label className="form-label">(i) Director Name 1</label>
                                    <SelectBox
                                        dataSource={companyDirectorsData}
                                        dropDownOptions={{ maxHeight: 200 }}
                                        displayExpr={(item) => item && `${item.idno} - ${item.name}`}
                                        valueExpr="idno"
                                        value={formData.director1IdNo}
                                        onValueChanged={e => {
                                            handleFormDataChange({ director1IdNo: e.value });
                                        }}
                                        showClearButton
                                        disabled={formData.entityActiveDormant === entityActiveDormant.Dormant && formData.isDormantExemptFinancialStatement}
                                    >
                                        <Validator validationRules={validationRules.director1IdNo} />
                                    </SelectBox>
                                </div>
                                <div className="offset-md-1 col-md-5">
                                    <label className="form-label">(ii) Director Name 2</label>
                                    <SelectBox
                                        dataSource={companyDirectorsData}
                                        dropDownOptions={{ maxHeight: 200 }}
                                        displayExpr={(item) => item && `${item.idno} - ${item.name}`}
                                        valueExpr="idno"
                                        value={formData.director2IdNo}
                                        onValueChanged={e => {
                                            handleFormDataChange({ director2IdNo: e.value });
                                        }}
                                        showClearButton
                                        disabled={formData.entityActiveDormant === entityActiveDormant.Dormant && formData.isDormantExemptFinancialStatement}
                                    >
                                        <Validator validationRules={validationRules.director2IdNo} />
                                    </SelectBox>
                                </div>
                            </div>
                        </div>
                    </div>
                </Item>
                <Item
                    key="auditInfo"
                    title="Section C: Audit Information"
                >
                    <div className="card-body px-4 py-3">
                        <div className="form-row">
                            <CheckBox
                                text="Check this box to confirm that the company has audited its financial statements"
                                value={formData.isFinancialStatementAudited}
                                onValueChanged={e => {
                                    if (e.value) {
                                        handleFormDataChange({ isFinancialStatementAudited: e.value });
                                    } else {
                                        handleFormDataChange({ isFinancialStatementAudited: e.value, publicAccountingEntityNo: '', auditorRegistrationNo: '', auditorReportDate: null, auditorReportModifiedOpinion: null, auditorReportEmphasisMatter: null });
                                    }
                                }}
                            >
                                <Validator validationRules={validationRules.isFinancialStatementAudited} />
                            </CheckBox>
                        </div>
                        {formData.isFinancialStatementAudited &&
                            <div>
                                <div className="form-row mt-4">
                                    <div className="col-md-7">
                                        <label className="form-label required">Registration No. of Public Accounting Entity that audited the financial statements</label>
                                        <TextBox
                                            className="form-control"
                                            value={formData.publicAccountingEntityNo}
                                            onValueChanged={e => handleFormDataChange({ publicAccountingEntityNo: e.value })}
                                        >
                                            <Validator validationRules={validationRules.publicAccountingEntityNo} />
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="form-row d-flex mt-4">
                                    <div className="col-md-7 required">
                                        <label className="form-label required">Registration No. of Auditor who audited and signed off the financial statements</label>
                                        <TextBox
                                            className="form-control"
                                            value={formData.auditorRegistrationNo}
                                            onValueChanged={e => handleFormDataChange({ auditorRegistrationNo: e.value })}
                                        >
                                            <Validator validationRules={validationRules.auditorRegistrationNo} />
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="form-row mt-4">
                                    <div className="col-md-6">
                                        <label className="form-label">Date of Independent auditor's report</label>
                                        <StandardDateBox
                                            className="form-control"
                                            value={formData.auditorReportDate}
                                            onValueChanged={e => handleFormDataChange({ auditorReportDate: e.value })}
                                            type="date"
                                        >
                                            <Validator validationRules={validationRules.auditorReportDate} />
                                        </StandardDateBox>
                                    </div>
                                </div>
                                <div className="form-row mt-4">
                                    <label className="form-label">
                                        Whether there is emphasis of matter in audit opinion
                                        <i id="emphasisMatterAuditOpinion" className="dx-icon dx-icon-info px-1"></i>
                                    </label>
                                    <RadioGroup
                                        layout="horizontal"
                                        dataSource={emphasisMatterDataSource}
                                        displayExpr="text"
                                        valueExpr="value"
                                        value={formData.auditorReportEmphasisMatter}
                                        onValueChanged={e => handleFormDataChange({ auditorReportEmphasisMatter: e.value })}
                                    >
                                        <Validator validationRules={validationRules.auditorReportEmphasisMatter} />
                                    </RadioGroup>
                                    <Popover
                                        target="#emphasisMatterAuditOpinion"
                                        showEvent="dxhoverstart"
                                        hideEvent="dxhoverend"
                                        position="top"
                                        maxWidth="700px"
                                    >
                                        <sup>#</sup>An Emphasis of matter is one where:
                                        <ul className="mb-0 pl-3">
                                            <li>
                                                An emphasis of matter paragraph is added to highlight a matter affecting the financial statements which is included in a note to the financial statements that more extensively discusses the matter.
                                            </li>
                                        </ul>
                                    </Popover>
                                </div>
                                <div className="form-row mt-4">
                                    <label className="form-label">
                                        Whether there is modified opinion of independent auditor's report (i.e. qualified opinion, disclaimer of opinion or adverse opinion)
                                        <i id="modifiedOpinionAuditReport" className="dx-icon dx-icon-info px-1"></i>
                                    </label>

                                    <RadioGroup
                                        layout="horizontal"
                                        dataSource={modifiedOpinionDataSource}
                                        displayExpr="text"
                                        valueExpr="value"
                                        value={formData.auditorReportModifiedOpinion}
                                        onValueChanged={e => handleFormDataChange({ auditorReportModifiedOpinion: e.value })}
                                    >
                                        <Validator validationRules={validationRules.auditorReportModifiedOpinion} />
                                    </RadioGroup>
                                    <Popover
                                        target="#modifiedOpinionAuditReport"
                                        showEvent="dxhoverstart"
                                        hideEvent="dxhoverend"
                                        position="top"
                                        maxWidth="700px"
                                    >
                                        <sup>#</sup>A modified opinion is one where:
                                        <ul className="mb-0 pl-3">
                                            <li>
                                                The auditor concludes that except for the effects of the matter to which the qualification relates the accounts is true and fair (qualified opinion); or
                                            </li>
                                            <li>
                                                The auditor express the possible effect of a limitation on scope is so material and pervasive that the auditor has not been able to obtain sufficient appropriate audit evidence and accordingly is unable to express an opinion on the financial statements (disclaimer of opinion); or
                                            </li>
                                            <li>
                                                The auditor states that effect of a disagreement is so material and pervasive to the financial statements that the auditor concludes that a qualification of the report is not adequate to disclose the misleading or incomplete nature of the financial statements (adverse opinion)
                                            </li>
                                        </ul>
                                    </Popover>
                                </div>
                            </div>
                        }
                    </div>
                </Item>
                <Item
                    key="declaration"
                    title="Declaration"
                >
                    <div className="card-body px-4 py-3">
                        <div className="form-row">
                            {!isFilingAgent ?
                                <p>I, <b>{lodgerName}</b>, director/secretary of the company, hereby declare that:-</p> :
                                <div className="mb-2">
                                    <div className="form-row d-flex mb-2">
                                        I, <b style={{ marginLeft: '5px' }}>{lodgerName}</b>, hereby declare that I have verified from
                                    </div>
                                    <div className="form-row d-flex py-3">
                                        <SelectBox
                                            dataSource={companyOfficersAuditorsData}
                                            dropDownOptions={{ maxHeight: 200 }}
                                            displayExpr={(item) => item && `${item.idno} - ${item.name}`}
                                            valueExpr="idno"
                                            value={formData.verifiedFrom}
                                            onValueChanged={e => handleFormDataChange({ verifiedFrom: e.value })}
                                            showClearButton
                                            width={300}
                                        >
                                            <Validator validationRules={validationRules.filingAuthoriser} />
                                        </SelectBox>
                                        <span className="mx-2">director/secretary of the company that:-</span>
                                    </div>
                                </div>
                            }
                            <CheckBox
                                text="The information in this annual return lodged with ACRA is up-to-date, accurate and complete and prepared in accordance with the Companies Act."
                                value={formData.declareInfoAccurateUpToDate}
                                onValueChanged={e => handleFormDataChange({ declareInfoAccurateUpToDate: e.value })}
                            >
                                <Validator validationRules={validationRules.declareInfoAccurateUpToDate} />
                            </CheckBox>
                        </div>
                    </div>
                </Item>
            </Accordion>
        </div>
    );
};

// eslint-disable-next-line react/no-multi-comp
const AnnualReturnDetailsViewWithForwardedRef = React.forwardRef((props, ref) => <AnnualReturnDetailsView {...props} forwardedRef={ref} />);
AnnualReturnDetailsViewWithForwardedRef.displayName = 'AnnualReturnDetailsView';

export default AnnualReturnDetailsViewWithForwardedRef;
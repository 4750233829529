import { Column, Toolbar, Sorting, Summary, TotalItem } from 'devextreme-react/data-grid';
import React, { useState } from 'react';
import StandardDataGrid from 'components/StandardDataGrid';
import { ActionButton, PrimaryButton } from 'components/StandardButton';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as DateUtils from 'utils/dateUtils';
import * as MessageBox from 'utils/messageBox';
import * as PageUtils from 'utils/pageUtils';
import * as Toast from 'utils/toast';
import useAcraAuth from 'hooks/useAcraAuth';
import * as AnnualReturnApi from '../../api/annualReturnApi';
import * as paymentStatusConstants from '../../data/paymentStatusConstants';
import PaymentResultView from './PaymentResultView';

const PaymentStatusEnquireForm = (props) => {
    const { previewFileInfoData, enquirePaymentData, paymentData, onRetryPayment, onEnquirePayment, loadData } = props;
    const { paymentDetails } = paymentData;
    const [previewCopyDownloadData, setPreviewCopyDownloadData] = useState({ downloadUrl: '', isExpired: true });

    const { startAuth } = useAcraAuth();
    const { accountBookData, refreshAccountBookData } = useAccountBookData();
    const accountBookDataId = accountBookData && accountBookData.accountBookDataId;

    let paymentDate = enquirePaymentData ? DateUtils.formatDate(enquirePaymentData.dateTime, 'dd/MM/yyyy h:mm aa') : '';

    function handleRetryPayment() {
        MessageBox.showConfirmDangerMessage('Are you sure you want to Retry Payment?')
            .then(result => {
                if (result.confirm) {
                    onBeforeRetryPayment();
                }
            });
    }

    function downloadPreviewCopyFile() {
        PageUtils.blockPage();
        const { documentId, fileName } = previewFileInfoData;
        const { downloadUrl, isExpired } = previewCopyDownloadData;

        if (isExpired) {
            AnnualReturnApi.getAttachmentDownloadUrl(accountBookDataId, { documentId: documentId, documentName: fileName })
                .then(result => {
                    PageUtils.unblockPage();
                    setPreviewCopyDownloadData({ downloadUrl: result, isExpired: false });
                    setTimeout(() => setPreviewCopyDownloadData(prevState => ({ ...prevState.previewCopyDownloadData, isExpired: true }), [288000]));

                    window.open(result, '_blank', 'noopener');
                })
                .catch(error => {
                    PageUtils.unblockPage();
                    Toast.showErrorMessage(error);
                });
        } else {
            PageUtils.unblockPage();
            window.open(downloadUrl, '_blank', 'noopener');
        }
    }

    function onBeforeRetryPayment() {
        if (enquirePaymentData.status === paymentStatusConstants.Pending) {
            PageUtils.blockPage();

            AnnualReturnApi.getPaymentStatus(accountBookDataId)
                .then(result => {
                    PageUtils.unblockPage();

                    if (result.requireAuth) {
                        startAuth(result.authUrl, onBeforeRetryPayment);
                    } else {
                        loadData();
                        if (result.isPending || result.isFailed) {
                            if (result.hasPaymentAttempt) {
                                Toast.showErrorMessage('ACRA has lock transactions for this UEN for about 30 minutes as there is a pending payment attempt. Please try again later.');
                            } else {
                                onRetryPayment();
                            }
                        } else {
                            refreshAccountBookData();
                            Toast.showErrorMessage('Unable to retry payment as your Annual Return filing for this year assessment has already been complete.');
                        }
                    }
                })
                .catch(error => {
                    PageUtils.unblockPage();
                    Toast.showErrorMessage(error);
                });
        } else {
            onRetryPayment();
        }
    }

    return enquirePaymentData ? (
        <>
            {enquirePaymentData.status === paymentStatusConstants.Success &&
                <div className="alert alert-success p-3">
                    <span className="dx-icon-check text-success align-self-center mx-2" />Your payment at "{paymentDate}" is Successful. Your Annual Return filing for this year assessment is now <b>complete</b>.
                </div>
            }

            {enquirePaymentData.status === paymentStatusConstants.Pending &&
                <div className="alert alert-warning p-3">
                    <span className="dx-icon-info text-warning align-self-center mx-2" />Your payment at "{paymentDate}" is still Pending, please click on "Reload Payment Result" to get the latest payment result
                </div>
            }

            {enquirePaymentData.status === paymentStatusConstants.Failed &&
                <div className="alert alert-danger p-3">
                    <span className="dx-icon-clear text-danger align-self-center mx-2" />Your payment at "{paymentDate}" has failed, please try again.
                </div>
            }

            {(enquirePaymentData.status === paymentStatusConstants.Failed || enquirePaymentData.status === paymentStatusConstants.Pending) &&
                <div>
                    <div className="dx-card dx-theme-accent-as-background-color p-2 mb-3">
                        <span className="fs-5 text-white">Payment Details</span>
                    </div>

                    <StandardDataGrid
                        dataSource={paymentDetails}
                    >
                        <Column
                            caption="Item Description"
                            dataField="itemDescription"
                            dataType="string"
                        />
                        <Column
                            caption="Amount"
                            dataField="amount"
                            dataType="number"
                            format="#0.00"
                        />
                        <Summary>
                            <TotalItem
                                column="amount"
                                summaryType="sum"
                                displayFormat="{0}"
                                valueFormat="#0.00"
                            />
                        </Summary>
                        <Sorting enabled={false} />
                        <Toolbar visible={false} />
                    </StandardDataGrid>
                </div>
            }

            <div className="d-flex pt-2 pb-4">
                <ActionButton
                    className="me-2"
                    icon="dx-icon dx-icon-download"
                    text="Download Preview Copy"
                    onClick={downloadPreviewCopyFile}
                />
                {(enquirePaymentData.status === paymentStatusConstants.Pending || enquirePaymentData.status === paymentStatusConstants.Failed) &&
                    <ActionButton
                        className="me-2"
                        icon="dx-icon dx-icon-money"
                        text="Retry Payment"
                        onClick={handleRetryPayment}
                    />
                }
                {enquirePaymentData.status === paymentStatusConstants.Pending &&
                    <ActionButton
                        className="me-2"
                        icon="dx-icon dx-icon-refresh"
                        text="Reload Payment Result"
                        onClick={onEnquirePayment}
                    />
                }
            </div>

            <PaymentResultView data={enquirePaymentData} />
        </>
    ) : (
        <>
            <div className="alert alert-primary p-3">
                To view Payment Result, please click the Enquire Payment button below.
            </div>
            <div>
                <PrimaryButton
                    text="Enquire Payment"
                    icon="dx-icon dx-icon-help"
                    onClick={onEnquirePayment}
                />
            </div>
        </>
    );
};

export default PaymentStatusEnquireForm;
import { Button } from 'devextreme-react/button';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { PrimaryButton } from 'components/StandardButton';
import StandardPopup from 'components/StandardPopup';

const ConfirmBeforeSubmissionDialog = (props) => {
    const { onSubmit, onCancel } = props;

    return (
        <StandardPopup
            width={500}
            height="auto"
            visible
            onHiding={onCancel}           
            showTitle={false}
        >
            <div className="row mb-3">
                <p className="d-flex justify-content-center"> Do you want to submit Form C-S ?</p>
                <p className="d-flex justify-content-center">(This action can't be reverted)</p>
            </div>
            <Toolbar>
                <Item
                    location="center"
                    render={() => (
                        <PrimaryButton
                            text="Submit"
                            onClick={onSubmit}
                        />
                    )}
                />
                <Item
                    location="center"
                    render={() => (
                        <Button
                            text="Cancel"
                            onClick={onCancel}
                        />
                    )}
                />
            </Toolbar>
        </StandardPopup>
    );
};

export default ConfirmBeforeSubmissionDialog;
import { Button } from 'devextreme-react/button';
import { ToolbarItem } from 'devextreme-react/popup';
import { SelectBox } from 'devextreme-react/select-box';
import { TextBox } from 'devextreme-react/text-box';
import { Validator, RequiredRule } from 'devextreme-react/validator';
import { useState } from 'react';
import { SubmitButton } from 'components/StandardButton';
import StandardPopup from 'components/StandardPopup';
import * as ApplicationUtils from 'utils/applicationUtils';

const CreateAccountBookDataDialog = (props) => {
    const { onSubmit, onCancel } = props;
    const currentYA = ApplicationUtils.getYearOfAssessment();

    const [data, setData] = useState({ yearOfAssessment: currentYA });

    function handleSubmit(e) {
        e.preventDefault();
        onSubmit(data);
    }

    return (
        <StandardPopup
            visible
            width={600}
            height="auto"
            onHiding={onCancel}
            dragEnabled={false}
            showCloseButton
            showTitle
            title="Submision for Annual Return - Create Account Book Data"
        >
            <form id="createAccountBookDataForm" onSubmit={handleSubmit}>
                <div className="col-12 mb-3">
                    <label className="form-label fw-bolder">Company Name</label>
                    <TextBox
                        value={data.companyName}
                        onValueChanged={e => setData({ ...data, companyName: e.value })}
                    >
                        <Validator>
                            <RequiredRule message="Company Name is required" />
                        </Validator>
                    </TextBox>
                </div>

                <div className="col-12 mb-3">
                    <label className="form-label fw-bolder">UEN</label>
                    <TextBox
                        value={data.uen}
                        onValueChanged={e => setData({ ...data, uen: e.value })}
                    >
                        <Validator>
                            <RequiredRule message="UEN is required" />
                        </Validator>
                    </TextBox>
                </div>

                <div className="col-12">
                    <label className="form-label fw-bolder">Year</label>
                    <SelectBox
                        dataSource={[
                            { text: currentYA - 1, value: currentYA },
                            { text: currentYA, value: currentYA + 1 }
                        ]}
                        displayExpr="text"
                        valueExpr="value"
                        value={data.yearOfAssessment}
                        onValueChanged={e => setData({ ...data, yearOfAssessment: e.value })}
                    >
                    </SelectBox>
                </div>
            </form>
            <ToolbarItem
                toolbar="bottom"
                location="after"
                render={() => (
                    <Button
                        text="Cancel"
                        onClick={() => onCancel()}
                    />
                )}
            />
            <ToolbarItem
                toolbar="bottom"
                location="after"
                render={() => (
                    <SubmitButton
                        text="Proceed"
                        submitForm="createAccountBookDataForm"
                    />
                )}
            />
        </StandardPopup>
    );
};
export default CreateAccountBookDataDialog;
import notify from 'devextreme/ui/notify';

export function showSuccessMessage(message) {
    notify({ message, maxWidth: 600, position: { at: 'top center', offset: '0 50' } }, 'success', 5000);
}

export function showErrorMessage(message, position = { at: 'top center', offset: '0 50' }) {
    notify({ message, maxWidth: 600, position }, 'error', 5000);
}

export function showWarningMessage(message) {
    notify({ message, maxWidth: 600, position: { at: 'top center', offset: '0 50' } }, 'warning', 5000);
}
import React, { useState } from 'react';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as ErrorMessage from 'utils/errorMessage';
import * as PageUtils from 'utils/pageUtils';
import * as Toast from 'utils/toast';
import useAcraAuth from 'hooks/useAcraAuth';
import * as annualReturnStatusConstant from '../../data/annualReturnStatusConstant';
import * as AnnualReturnApi from '../../api/annualReturnApi';
import ValidationErrorMessageView from '../ValidationErrorMessageView';
import PaymentForm from './PaymentForm';
import PaymentStatusEnquireForm from './PaymentStatusEnquireForm';

const AnnualReturnPaymentView = (props) => {
    const { accountBookData, refreshAccountBookData } = useAccountBookData();
    const accountBookDataId = accountBookData && accountBookData.accountBookDataId;

    const { initialData, loadData } = props;
    const { startAuth } = useAcraAuth();

    const [validationErrorData, setValidationErrorData] = useState({ hasValidationError: false, validationErrors: null });

    function makePayment() {
        PageUtils.blockPage();

        AnnualReturnApi.makePayment(accountBookDataId)
            .then(result => {
                PageUtils.unblockPage();

                if (result.requireAuth) {
                    startAuth(result.authUrl, makePayment, null);
                } else {
                    if (result.validationErrors) {
                        Toast.showWarningMessage('There are validation errors returned by ACRA, please check them and then re-submit the form.');
                        setValidationErrorData({ hasValidationError: true, validationErrors: result.validationErrors });
                    } else {
                        if (result.data) {
                            loadData();
                            setValidationErrorData({ hasValidationError: false, validationErrors: null });
                        }

                        if (result.paymentUrl) {
                            PageUtils.blockPage();

                            let windowWidth = 400;
                            let windowHeight = 800;
                            let windowTop = window.outerHeight / 2 + window.screenY - (windowHeight / 2);
                            let windowLeft = window.outerWidth / 2 + window.screenX - (windowWidth / 2);

                            let popupWindow = window.open(result.paymentUrl, 'BizFile Payment Gateway', `width=${windowWidth},height=${windowHeight},top=${windowTop},left=${windowLeft}`);

                            let timer = setInterval(() => {
                                if (popupWindow && popupWindow.closed) {
                                    clearInterval(timer);
                                    PageUtils.unblockPage();
                                    enquirePayment();
                                }
                            }, 1000);
                        } else {
                            Toast.showWarningMessage('Unable to redirect to payment page at this time, please try again later. If this issue persist please contact our support team.');
                        }
                    }
                }
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.updateError(error));
            });
    }

    function enquirePayment() {
        PageUtils.blockPage();

        AnnualReturnApi.enquirePayment(accountBookDataId)
            .then(result => {
                PageUtils.unblockPage();

                if (result.requireAuth) {
                    startAuth(result.authUrl, enquirePayment, null);
                } else {
                    if (result.validationErrors) {
                        Toast.showWarningMessage('There are validation errors returned by ACRA, please check them and then re-submit the form.');
                        setValidationErrorData({ hasValidationError: true, validationErrors: result.validationErrors });
                    } else {
                        if (result.data) {
                            loadData();
                            setValidationErrorData({ hasValidationError: false, validationErrors: null });
                            if (result.data.status === annualReturnStatusConstant.PaymentCompleted) {
                                refreshAccountBookData();
                            }
                        }
                    }
                }
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.updateError(error));
            });
    }

    return (
        <>
            {initialData &&
                <div className="dx-card responsive-paddings">
                    {initialData.status !== annualReturnStatusConstant.PaymentCompleted &&
                        <ValidationErrorMessageView
                            validationErrorData={validationErrorData}
                            setValidationErrorData={setValidationErrorData}
                        />
                    }
                    {initialData.status === annualReturnStatusConstant.FormSubmitted &&
                        <PaymentForm
                            previewFileInfoData={initialData.previewFileInfoData}
                            paymentData={initialData.formResultData}
                            onMakePayment={makePayment}
                        />
                    }
                    {(initialData.status === annualReturnStatusConstant.PaymentPending || initialData.status === annualReturnStatusConstant.PaymentFailed || initialData.status === annualReturnStatusConstant.PaymentCompleted) &&
                        <PaymentStatusEnquireForm
                            previewFileInfoData={initialData.previewFileInfoData}
                            enquirePaymentData={initialData.enquirePaymentData}
                            paymentData={initialData.formResultData}
                            onRetryPayment={makePayment}
                            onEnquirePayment={enquirePayment}
                            loadData={loadData}
                        />
                    }
                </div>
            }
        </>
    );
};

export default AnnualReturnPaymentView;
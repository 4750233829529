import { Button } from 'devextreme-react/button';
import { Column, Toolbar } from 'devextreme-react/data-grid';
import { ToolbarItem } from 'devextreme-react/popup';
import StandardDataGrid from 'components/StandardDataGrid';
import StandardPopup from 'components/StandardPopup';

const FormCSMedicalSchedule = (props) => {
    const { initialData, onCancel } = props;

    const dataSource = [
        { description: 'Director\'s Remuneration (excluding Directors Fees)', value: initialData ? initialData.directorsRemunerationExcludingDirectorsFees : 0 },
        { description: 'Staff Remuneration (other than Director\'s Remuneration)', value: initialData ? initialData.staffRemunerationOtherThanDirectorsRemuneration : 0 },
        { description: 'CPF Contribution', value: initialData ? initialData.cpfContribution : 0 },
        { description: 'Total Remuneration', value: initialData ? initialData.totalRemuneration : 0 },
        {},
        { description: 'Computation of Disallowed Medical Expenses:' },
        { description: 'Medical Expenses (including Medical Insurance)', value: initialData ? initialData.medicalExpIncludingMedicalInsurance : 0 },
        { description: 'Less: 1% of Total Remuneration', value: initialData ? initialData.onePercentOfTotalRemuneration : 0 },
        { description: 'Medical Expenses Disallowed', value: initialData ? initialData.medicalExpDisallowed : 0 }
    ];

    return (
        <StandardPopup
            width="1450px"
            height="650px"
            visible
            onHiding={onCancel}
            showCloseButton
            title="Medical Expense Schedule"
        >
            <div className="fs-7 mb-3 d-flex justify-content-left">Medical expense schedule is used to track and itemize expenses that involves curing diseases, health and many more.</div>
            <StandardDataGrid
                dataSource={dataSource}
                showRowLines={false}
                showColumnLines={false}
                paging={null}
            >
                <Column caption="Description" dataField="description" dataType="string" />
                <Column caption="Balance" dataField="value" format="#,##0.00" dataType="number" />
                <Toolbar visible={false} />
            </StandardDataGrid>
            <ToolbarItem
                toolbar="bottom"
                location="after"
            >
                <Button
                    text="Close"
                    onClick={onCancel}
                />
            </ToolbarItem>
        </StandardPopup>
    );
};

export default FormCSMedicalSchedule;
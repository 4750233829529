import Validator from 'devextreme-react/validator';
import StandardDateBox from 'components/StandardDateBox';

const FinancialStatementEntryFinancialPeriodDetails = (props) => {
    const { data } = props;

    const validationRules = {
        priorYearStartDate: [
            { type: 'required', message: 'Prior Year Start Date is required.' },
            { type: 'custom', message: 'Prior Year Start Date must be before Prior Year End Date.', validationCallback: validatePriorYearStartDate, reevaluate: true }
        ]
    };

    function validatePriorYearStartDate() {
        return data.priorYearStartDate < data.priorYearEndDate;
    }

    return (
        <div className="px-4 py-3">
            <div className="row mb-3">
                <div className="col-md-3">
                    <label className="form-label fw-bolder">Current Year Start Date</label>
                    <StandardDateBox
                        value={data.currentYearStartDate}
                        type="date"
                        readOnly
                    />
                    <div className="form-text">Hint: You can maintain your Current Year Start Date in the Home Page</div>
                </div>

                <div className="offset-md-3 col-md-3">
                    <label className="form-label fw-bolder">Current Year End Date</label>
                    <StandardDateBox
                        value={data.currentYearEndDate}
                        type="date"
                        readOnly
                    />
                </div>
            </div>

            {data.priorYearEndDate &&
                <div className="row">
                    <div className="col-md-3">
                        <label className="form-label fw-bolder">Prior Year Start Date</label>
                        <StandardDateBox
                            value={data.priorYearStartDate}
                            type="date"
                            readOnly
                        >
                            <Validator validationRules={validationRules.priorYearStartDate} />
                        </StandardDateBox>
                        <div className="form-text">Hint: You can maintain your Prior Year Start Date in the Home Page</div>
                    </div>

                    <div className="offset-md-3 col-md-3">
                        <label className="form-label fw-bolder">Prior Year End Date</label>
                        <StandardDateBox
                            value={data.priorYearEndDate}
                            type="date"
                            readOnly
                        />
                    </div>
                </div>
            }
        </div>
    );
};

export default FinancialStatementEntryFinancialPeriodDetails;
import React, { useState } from 'react';
import Tabs from 'devextreme-react/tabs';
import PageTitle from 'components/PageTitle';
import { PrimaryButton } from 'components/StandardButton';
import StandardDateBox from 'components/StandardDateBox';

import AccountBookNotSelected from 'layouts/AccountBookNotSelected';
import BalanceSheetStatementView from './BalanceSheetStatementView';
import CreditorAccountBalanceView from './CreditorAccountBalanceView';
import DebtorAccountBalanceView from './DebtorAccountBalanceView';
import ProfitAndLossStatementView from './ProfitAndLossStatementView';

const tabs = [
    'Profit and Loss Statement',
    'Balance Sheet Statement',
    'Debtor Account Balance',
    'Creditor Account Balance'
];

const FinancialDataView = (props) => {
    const { accountBookDataId, financialData, isCurrentYear, setIsCurrentYear } = props;
    const [tab, setTab] = useState(tabs[0]);

    return (
        <>
            <div className="content-block">
                <PageTitle title="Financial Data" />
            </div>
            {accountBookDataId ?
                <div className="content-block">
                    {financialData &&
                        <div className="dx-card responsive-paddings">
                            <div className="d-flex mb-3">
                                <div className="d-flex form-row">
                                    <label className="align-self-center fw-bold">
                                        Financial Year Period:
                                    </label>

                                    <StandardDateBox
                                        className="mx-2"
                                        value={isCurrentYear ? financialData.currentYearStartDate : financialData.priorYearStartDate}
                                        width={100}
                                        readOnly
                                        type="date"
                                    />

                                    <div className="align-self-center">
                                        to
                                    </div>

                                    <StandardDateBox
                                        className="mx-2"
                                        value={isCurrentYear ? financialData.currentYearEndDate : financialData.priorYearEndDate}
                                        width={100}
                                        readOnly
                                        type="date"
                                    />
                                </div>
                                {financialData.hasPriorYearFinancialData &&
                                    <div style={{ marginLeft: 'auto' }}>
                                        <PrimaryButton
                                            text={isCurrentYear ? 'Switch to Prior Fiscal Year Data' : 'Switch to Current Fiscal Year Data'}
                                            onClick={() => setIsCurrentYear(!isCurrentYear)}
                                        />
                                    </div>
                                }
                            </div>
                            <div className="mb-3">
                                <Tabs
                                    dataSource={tabs}
                                    scrollByContent
                                    showNavButtons
                                    onItemClick={e => setTab(e.itemData)}
                                    selectedItem={tab}
                                    className="mb-3"
                                />
                                <ProfitAndLossStatementView
                                    currentYearData={financialData.currentYearProfitAndLossStatement}
                                    priorYearData={financialData.priorYearProfitAndLossStatement}
                                    isCurrentYear={isCurrentYear}
                                    visible={tab === 'Profit and Loss Statement'}
                                />
                                <BalanceSheetStatementView
                                    currentYearData={financialData.currentYearBalanceSheetStatement}
                                    priorYearData={financialData.priorYearBalanceSheetStatement}
                                    isCurrentYear={isCurrentYear}
                                    visible={tab === 'Balance Sheet Statement'}
                                />
                                <DebtorAccountBalanceView
                                    currentYearData={financialData.currentYearDebtorAccountBalance}
                                    priorYearData={financialData.priorYearDebtorAccountBalance}
                                    isCurrentYear={isCurrentYear}
                                    visible={tab === 'Debtor Account Balance'}
                                />
                                <CreditorAccountBalanceView
                                    currentYearData={financialData.currentYearCreditorAccountBalance}
                                    priorYearData={financialData.priorYearCreditorAccountBalance}
                                    isCurrentYear={isCurrentYear}
                                    visible={tab === 'Creditor Account Balance'}
                                />
                            </div>
                        </div>
                    }
                </div>
                :
                <AccountBookNotSelected />
            }
        </>
    );
};

export default FinancialDataView;
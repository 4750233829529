import DraftFormContentHeaderRow from './DraftFormContentHeaderRow';
import DraftFormContentDataRow from './DraftFormContentDataRow';
import DraftFormContentFooterRow from './DraftFormContentFooterRow';

const FinancialStatementXBRLFormEquityView = (props) => {
    const { initialData, onViewPDEDrillDown } = props;

    const dataRows = [
        { dataId: 'shareCapital' },
        { dataId: 'treasuryShares' },
        { dataId: 'accumulatedProfitsLosses' },
        { dataId: 'reservesOtherThanAccumulatedProfitsLosses' },
        { dataId: 'noncontrollingInterests' },
        { dataId: 'equity', isFooter: true, enableTopBorder: true, enableBottomBorder: true }
    ];

    const hasPriorYearFinancialData = initialData.xbrlData.priorFinancialYear;
    return (
        <>
            <DraftFormContentHeaderRow description="Equity" level={3} />
            {dataRows.map((item, index) => {
                const pde = initialData.pdeList.find(x => x.dataId === item.dataId);
                const pdeDescription = pde && pde.description;

                const priorYearValue = hasPriorYearFinancialData && initialData.xbrlData.priorFinancialYear.equities[item.dataId];
                const currentYearValue = initialData.xbrlData.currentFinancialYear.equities[item.dataId];

                if (item.isFooter) {
                    return (
                        <DraftFormContentFooterRow
                            key={index}
                            description={pdeDescription}
                            priorYearValue={priorYearValue}
                            currentYearValue={currentYearValue}
                            enableTopBorder={item.enableTopBorder}
                            enableBottomBorder={item.enableBottomBorder}
                            onViewPDEDrillDown={(isCurrentYear) => onViewPDEDrillDown({ pdeDataId: item.dataId, isCurrentYearFinancialData: isCurrentYear })}
                        />
                    );
                }

                return (
                    <DraftFormContentDataRow
                        key={index}
                        level={3}
                        description={pdeDescription}
                        priorYearValue={priorYearValue}
                        currentYearValue={currentYearValue}
                        onViewPDEDrillDown={(isCurrentYear) => onViewPDEDrillDown({ pdeDataId: item.dataId, isCurrentYearFinancialData: isCurrentYear })}
                    />
                );
            })}
        </>
    );
};

export default FinancialStatementXBRLFormEquityView;
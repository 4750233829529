import { useEffect, useState } from 'react';

const ErrorMessageView = (props) => {
    const { errors } = props;
    const [visible, setVisible] = useState(errors.length > 0);

    useEffect(() => {
        setVisible(errors.length > 0);
    }, [errors]);

    return (
        <>
            {visible &&
                <div className="alert alert-danger alert-dismissible error-message">
                    <h4 className="form-label">Error</h4>
                    <button type="button" className="btn-close" style={{ top: '10px', right: '20px' }} onClick={() => setVisible(false)} />
                    <div className="mb-1">Please rectify the following issues to proceed.</div>
                    <ul className="mb-0">
                        {errors.map(fieldError => (
                            <li key={fieldError.field} >
                                <span className="fw-bold">{fieldError.message}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            }
        </>
    );
};

export default ErrorMessageView;
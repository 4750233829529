import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'devextreme-react/button';
import { SelectBox } from 'devextreme-react/select-box';
import { Tooltip } from 'devextreme-react/tooltip';
import * as ApplicationApi from 'api/applicationApi';
import * as AccountBookDataStatusConstant from 'data/accountBookDataStatusConstant';
import PageTitle from 'components/PageTitle';
import { PrimaryButton } from 'components/StandardButton';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as ErrorMessage from 'utils/errorMessage';
import * as MessageBox from 'utils/messageBox';
import * as PageUtils from 'utils/pageUtils';
import * as Toast from 'utils/toast';
import * as AccountMappingApi from './api/accountMappingApi';
import AccountMappingSaveChangesDialog from './partials/AccountMappingSaveChangesDialog';
import AccountMappingImportDialog from './partials/AccountMappingImportDialog';
import EditableAccountMappingGrid from './EditableAccountMappingGrid';
import ReadOnlyAccountMappingGrid from './ReadOnlyAccountMappingGrid';
import AccountMappingCompletedDialog from './partials/AccountMappingCompletedDialog';
import './AccountMappingMaintenance.scss';

const AccountMappingMaintenancePage = () => {
    const { accountBookData, refreshAccountBookData } = useAccountBookData();
    const accountBookDataId = accountBookData && accountBookData.accountBookDataId;

    const [initialData, setInitialData] = useState(null);
    const [accountMappingData, setAccountMappingData] = useState([]);
    const [itemCount, setItemCount] = useState({ accountCount: 0, pdeCount: 0 });

    const [showAccountMappingCompletedDialog, setShowAccountMappingCompletedDialog] = useState(false);
    const [showAccountMappingImportDialog, setShowAccountMappingImportDialog] = useState(false);
    const [showAccountMappingSaveChangesDialog, setShowAccountMappingSaveChangesDialog] = useState(false);

    const dataChanges = useRef([]);

    const navigate = useNavigate();

    const viewModes = ['Map Account to PDE', 'View PDE Mapping'];
    const [viewMode, setViewMode] = useState(viewModes[0]);

    const hasAccountUen = accountBookData.stepList.includes(AccountBookDataStatusConstant.CompletedCompanyInfo);
    const readOnly = accountBookData.stepList.some(x => x === AccountBookDataStatusConstant.InitiatedAnnualReturn || x === AccountBookDataStatusConstant.InitiatedFormCS);

    useEffect(() => {
        if (accountBookData.stepList.includes(AccountBookDataStatusConstant.CompletedCompanyInfo) &&
            !accountBookData.stepList.includes(AccountBookDataStatusConstant.VisitedAccountMapping)) {
            MessageBox.showConfirmMessage('Do you want to copy Account Mapping from other Account Book?')
                .then(result => {
                    if (result.confirm) setShowAccountMappingImportDialog(true);
                    ApplicationApi.updateAccountBookDataStatus(accountBookDataId, AccountBookDataStatusConstant.VisitedAccountMapping)
                        .then(() => refreshAccountBookData());
                });
        }
    }, [accountBookData.stepList]);

    const loadData = () => {
        if (accountBookDataId) {
            PageUtils.blockPage();
            AccountMappingApi.getAccountMapping(accountBookDataId)
                .then(data => {
                    PageUtils.unblockPage();
                    const { chartOfAccountInJson, accountTypeInJson, accountMappingData, ...rest } = data;
                    const chartOfAccountData = JSON.parse(data.chartOfAccountInJson);
                    const accountTypeData = JSON.parse(data.accountTypeInJson);

                    setInitialData({
                        chartOfAccountData,
                        accountTypeData,
                        accountMappingData: accountMappingData.map(x => ({ ...x })),
                        ...rest
                    });

                    setAccountMappingData(accountMappingData);
                })
                .catch(error => {
                    PageUtils.unblockPage();
                    Toast.showErrorMessage(ErrorMessage.fetchError(error));
                });
        }
    };

    useEffect(() => {
        loadData();
    }, [accountBookDataId]);

    useEffect(() => {
        setItemCount({
            accountCount: accountMappingData.length,
            pdeCount: (new Set([...accountMappingData.map(x => x.pde)])).size
        });
    }, [accountMappingData.length]);

    function validateCompleteAccountMapping() {
        const leafAccountList = initialData.chartOfAccountData.filter(x => !initialData.chartOfAccountData.some(a => a.parentAccNo === x.accNo));
        return leafAccountList.length === accountMappingData.length;
    }

    function recordDataChanges(accountMapping) {
        const dataChange = {
            accNo: accountMapping.accNo,
            originalPde: null,
            currentPde: accountMapping.pde,
            originalLiquidity: null,
            currentLiquidity: accountMapping.liquidity,
            status: null
        };

        const existingDataChange = dataChanges.current.find(x => x.accNo === dataChange.accNo);
        if (existingDataChange) {
            if (dataChange.currentPde === existingDataChange.originalPde && dataChange.currentLiquidity === existingDataChange.originalLiquidity) {
                dataChanges.current.splice(dataChanges.current.indexOf(existingDataChange), 1);
            } else if (dataChange.currentPde === null) {
                dataChange.status = 'Deleted';

                existingDataChange.currentPde = dataChange.currentPde;
                existingDataChange.currentLiquidity = dataChange.currentLiquidity;
            } else if (existingDataChange.currentPde !== dataChange.currentPde || existingDataChange.currentLiquidity !== dataChange.currentLiquidity) {
                dataChange.status = 'Modified';

                existingDataChange.currentPde = dataChange.currentPde;
                existingDataChange.currentLiquidity = dataChange.currentLiquidity;
                existingDataChange.status = dataChange.status;
            }
        } else {
            const existingAccountMapping = initialData.accountMappingData.find(x => x.accNo === accountMapping.accNo);
            if (existingAccountMapping) {
                dataChange.originalPde = existingAccountMapping.pde;
                dataChange.originalLiquidity = existingAccountMapping.liquidity;

                if (dataChange.currentPde === null)
                    dataChange.status = 'Deleted';
                else if (existingAccountMapping.pde !== dataChange.currentPde || existingAccountMapping.liquidity !== dataChange.currentLiquidity)
                    dataChange.status = 'Modified';

            } else {
                dataChange.status = 'Added';
            }
            dataChanges.current.push(dataChange);
        }
    }

    function updateAccountMapping() {
        PageUtils.blockPage();
        AccountMappingApi.updateAccountMapping(accountBookDataId, accountMappingData)
            .then(data => {
                PageUtils.unblockPage();
                setShowAccountMappingSaveChangesDialog(false);
                dataChanges.current = [];
                initialData.accountMappingData = accountMappingData.map(x => ({ ...x }));

                refreshAccountBookData();
                if (data.isAccountMappingComplete) {
                    setShowAccountMappingCompletedDialog(true);
                }

                Toast.showSuccessMessage('Account Mapping is updated successfully.');
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.updateError(error));
            });
    }

    function importAccountMapping(accountMappingData) {
        PageUtils.blockPage();
        AccountMappingApi.importAccountMapping(accountBookDataId, accountMappingData)
            .then(data => {
                PageUtils.unblockPage();
                setShowAccountMappingImportDialog(false);
                loadData();

                refreshAccountBookData();
                if (data.isAccountMappingComplete) {
                    setShowAccountMappingCompletedDialog(true);
                }

                Toast.showSuccessMessage('Account Mapping is imported successfully.');
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.updateError(error));
            });
    }

    return (
        <>
            <div className="content-block">
                <PageTitle title="Account Mapping" />
            </div>
            {hasAccountUen && initialData ?
                <div className="content-block">
                    {readOnly &&
                        <div className="alert alert-warning">
                            Account Mapping cannot be modified because the filing is in progress or completed.
                        </div>
                    }
                    <div className="dx-card responsive-paddings">
                        <div className="row mb-3">
                            {viewMode === viewModes[0] && <div className="fw-bold px-0 mb-3">Please map accounts to PDE here. You should map all accounts to PDE to generate Financial Statements for the filing.</div>}
                            <div className="d-flex px-0">
                                {viewMode === viewModes[0] &&
                                    <>
                                        <div className="me-2" id="saveAccountMapping">
                                            <PrimaryButton
                                                text="Save"
                                                onClick={() => setShowAccountMappingSaveChangesDialog(true)}
                                                width="100%"
                                                disabled={readOnly}
                                            />
                                            {readOnly &&
                                                <Tooltip
                                                    target="#saveAccountMapping"
                                                    showEvent="mouseenter"
                                                    hideEvent="mouseleave"
                                                    hideOnOutsideClick={false}
                                                >
                                                    <div>Save is disabled due to filing is in progress or completed</div>
                                                </Tooltip>
                                            }
                                        </div>
                                        <div className="me-2">
                                            <Button
                                                text="Copy Account Mapping from other Account Book"
                                                onClick={() => setShowAccountMappingImportDialog(true)}
                                            />
                                        </div>
                                    </>
                                }
                                {viewMode === viewModes[1] &&
                                    <>
                                        <div className="row">
                                            <div className="align-self-center me-2">
                                                Number of Mapped Account(s) : <b>{itemCount.accountCount}</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="ms-5 align-self-center me-2">
                                                Number of Mapped PDE(s) : <b>{itemCount.pdeCount}</b>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="ms-auto">
                                    <SelectBox
                                        value={viewMode}
                                        dataSource={viewModes}
                                        onValueChanged={(e) => setViewMode(e.value)}
                                        width={250}
                                    />
                                </div>
                            </div>
                        </div>
                        {viewMode === viewModes[0] &&
                            <EditableAccountMappingGrid
                                initialData={initialData}
                                accountMappingData={accountMappingData}
                                recordDataChanges={recordDataChanges}
                            />
                        }
                        {viewMode === viewModes[1] &&
                            <ReadOnlyAccountMappingGrid
                                initialData={initialData}
                                accountMappingData={accountMappingData}
                            />
                        }
                        {showAccountMappingImportDialog &&
                            <AccountMappingImportDialog
                                initialData={{
                                    accountMappingData,
                                    pdeList: initialData.pdeList,
                                    chartOfAccountData: initialData.chartOfAccountData,
                                    hasOnPremiseAccountMapping: initialData.hasOnPremiseAccountMapping
                                }}
                                onSubmit={importAccountMapping}
                                onCancel={() => setShowAccountMappingImportDialog(false)}
                            />
                        }
                        {showAccountMappingSaveChangesDialog &&
                            <AccountMappingSaveChangesDialog
                                validateCompleteAccountMapping={validateCompleteAccountMapping}
                                initialData={{
                                    chartOfAccountData: initialData.chartOfAccountData,
                                    pdeList: initialData.pdeList,
                                    dataChanges: dataChanges.current
                                }}
                                onSubmit={updateAccountMapping}
                                onCancel={() => setShowAccountMappingSaveChangesDialog(false)}
                            />
                        }
                        {showAccountMappingCompletedDialog &&
                            <AccountMappingCompletedDialog
                                onCancel={() => setShowAccountMappingCompletedDialog(false)}
                                startAnnualReturn={() => navigate('/annualReturnWithFS')}
                                startFormCs={() => navigate('/formCS')}
                            />
                        }
                    </div>
                </div>
                :
                <div className="content-block">
                    <div className="alert alert-danger"><p className="fw-bold d-inline">Account UEN</p> is not filled. Please complete it in <p className="d-inline text-decoration-underline" role="button" onClick={() => navigate('/accountBookData')}>Account Book Data</p> page.</div>
                </div>
            }
        </>
    );
};

export default AccountMappingMaintenancePage;
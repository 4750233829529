import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { Column, Toolbar } from 'devextreme-react/data-grid';
import StandardDataGrid from 'components/StandardDataGrid';
import './ShareholderMasterDetailView.scss';

const ShareholderMasterDetailView = (props) => {
    const { data } = props;
    const { shareHolders: shareholderDetailsData, shares: groupShareDetailsData } = data;

    return (
        <div className="shareholder-tabs d-flex justify-content-start">
            <TabPanel className="dx-card">
                <Item
                    title="Shareholder Details"
                >
                    <div className="px-4 py-3">
                        <StandardDataGrid
                            dataSource={shareholderDetailsData}
                        >
                            <Column
                                dataField="identificationNo"
                                caption="Identification No."
                                dataType="string"
                            />
                            <Column
                                dataField="shareHolderName"
                                caption="Share Holder Name"
                                dataType="string"
                            />
                            <Toolbar visible={false} />
                        </StandardDataGrid>
                    </div>
                </Item>
                <Item title="Group Share Details">
                    <div className="px-4 py-3">
                        <StandardDataGrid
                            dataSource={groupShareDetailsData}
                        >
                            <Column
                                dataField="classOfShare"
                                caption="Class Of Share"
                                dataType="string"
                            />
                            <Column
                                dataField="numberOfShare"
                                caption="No. Shares Held"
                                dataType="number"
                            />
                            <Toolbar visible={false} />
                        </StandardDataGrid>
                    </div>
                </Item>
            </TabPanel>
        </div>
    );
};

export default ShareholderMasterDetailView;
import {
    Page,
    StyleSheet,
    Text,
    View
} from '@react-pdf/renderer';

const TaxComputationPdfView = (props) => {
    const { data } = props;
    let i = 0;

    const styles = StyleSheet.create({
        page: {
            padding: 40
        },
        header: {
            fontSize: 15,
            fontWeight: 1000,
            marginBottom: 10,
            display: 'table',
            width: 'auto',
            textDecoration: 'underline',
            textAlign: 'center'
        },
        normalText: {
            fontSize: 8,
            margin: 3,
            paddingBottom: 5
        },
        table: {
            display: 'table',
            width: 'auto',
            borderStyle: 'solid',
            borderWidth: 1
        },
        tableHeader: {
            backgroundColor: '#21a84a',
            color: 'white',
            fontWeight: 1000
        },
        tableHeaderText: {
            fontSize: 10,
            margin: 5,
            fontWeight: 1000
        }
    });

    const viewData = (() => {
        const list = [];

        const rawData = data.generatedDraft.dataTCSch;

        Object.entries(rawData).forEach(([key, value]) => {
            const pde = data.pdeList.find(x => x.dataId === key);

            const row = { description: pde && pde.description };
            if (key === 'receiptNotTxAmt') row.description = 'Total Non-Taxable Income';

            if ([
                'sgIntDisc',
                'c1_GrossRent',
                'sgOtherI',
                'oneTierTaxDividendIncome',
                'otherNonTaxableIncome',
                'amortisationExpense',
                'depreciationExpense',
                'donations',
                'commissionExpRentalIncome',
                'insuranceExpRentalIncome',
                'interestExpRentalIncome',
                'propertyTaxExpRentalIncome',
                'repairMaintenanceExpRentalIncome',
                'otherExpRentalIncome',
                'fixedAssetsExpdOff',
                'netGainsOrLossesOnDisposalOfPPE',
                'netGainsOrLossesOnOtherItems',
                'otherPrivateOrCapitalExp',
                'penaltiesOrFine',
                'repairsMaintenanceForPrivateVehicles',
                'upkeepPrivateVehicles',
                'medicalExpDisallowed'
            ].includes(key)
            ) {
                row.amount1 = value;
            } else {
                row.amount2 = value;
            }

            if (key === 'renoWorksExpS14Q' || key === 'unutilCABFNorm' || key === 'unutilLossBFNorm' ||
                key === 'taxRebate' || key === 'taxExemptAmount' || key === 'unutilDonationBFNorm') {
                row.tag = 'Less:';
            } else if (key === 'c1_BC') {
                row.tag = 'Add:';
            }

            list.push(row);

            if (key === 'profitLossBeforeTaxation') {
                list.push({});
                list.push({ tag: 'Less :', description: 'Separate Source Income' });
            } else if (key === 'sepSrcIncome') {
                list.push({});
                list.push({ tag: 'Less :', description: 'Non-Taxable Income' });
            } else if (key === 'receiptNotTxAmt') {
                list.push({});
                list.push({ tag: 'Add :', description: 'Non-Tax Deductible Income' });
            } else if (key === 'adjPLBefDed') {
                list.push({});
            } else if (key === 'sgAdjPLAft') {
                list.push({});
            } else if (key === 'adjustedProfitOrLossAfterCapitalAllowanceAndUnutilisedLossesBF') {
                list.push({});
                list.push({ tag: 'Add:', description: 'Separate Source Income' });
            } else if (key === 'sgRent') {
                list.push({ description: 'Interest Income', amount2: rawData.sgIntDisc });
                list.push({ description: 'Other Types of Income - Taxable Income', amount2: rawData.sgOtherI });
            } else if (key === 'netTaxPayable') {
                list.push({});
            } else if (key === 'chargeableIncomeAftTaxExemptAmt') {
                list.push({});
            }
        });

        return list;
    })();

    const generateRow = (data) => {
        return (
            <View key={++i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <View style={[styles.normalText, { width: 30 }]}>
                    <Text>{data.tag ? `${data.tag}` : null} </Text>
                </View>
                <View style={[
                    styles.normalText,
                    { flex: 1, alignItems: 'flex-start' },
                    (
                        [
                            'Separate Source Income',
                            'Non-Taxable Income',
                            'Non-Tax Deductible Income'
                        ].includes(data.description) && !data.amount1 && !data.amount2
                    ) ? { textDecoration: 'underline' } : null
                ]}
                >
                    <Text>{data.description}</Text>
                </View>
                <View style={[
                    styles.normalText,
                    { width: 60, alignItems: 'flex-end', marginRight: 20 },
                    (
                        [
                            'Other Taxable Income',
                            'Other Non-taxable Income',
                            'Medical Expenses Disallowed'
                        ].includes(data.description)
                    ) ? { borderBottom: '1px solid underline' } : null
                ]}
                >
                    <Text>{data.amount1 ? formatValue(Number(data.amount1)) : null}</Text>
                </View>
                <View style={[
                    styles.normalText,
                    { width: 60, alignItems: 'flex-end' },
                    (
                        [
                            'Net Tax Payable',
                            'Chargeable Income (after Tax Exempt Amount)'
                        ].includes(data.description)
                    ) ? { borderBottom: '2px double black' } : null,
                    (
                        [
                            'Non-tax Deductible Expenses',
                            'Further Deductions/ Other Deductions',
                            'Unutilised Losses b/f',
                            'Other Types of Income - Taxable Income',
                            'Current Year Donations',
                            'Tax Exempt Amount',
                            'Tax Rebate'
                        ].includes(data.description)
                    ) ? { borderBottom: '1px solid underline' } : null
                ]}
                >
                    <Text>{data.amount2 ? formatValue(Number(data.amount2)) : null}</Text>
                </View>
            </View>
        );
    };

    const formatValue = (value) => {
        if (typeof value === 'number') {
            if (value < 0) {
                return `(${Math.abs(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')})`;
            } else {
                return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            }
        }
        return value;
    };

    return (
        <Page size="A4" style={styles.page}>
            <View style={styles.container}>
                <View style={styles.header}>
                    <Text>Tax Computation</Text>
                </View>
            </View>
            <View style={styles.table}>
                <View style={[styles.tableHeader, { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }]}>
                    <View style={[styles.normalText, { width: 30 }]}>
                        <Text></Text>
                    </View>
                    <View style={[styles.tableHeaderText, { flex: 1, alignItems: 'flex-start' }]}>
                        <Text>Description</Text>
                    </View>
                    <View style={[styles.tableHeaderText, { width: 60, alignItems: 'flex-end', marginRight: 20 }]}>
                        <Text>S$</Text>
                    </View>
                    <View style={[styles.tableHeaderText, { width: 60, alignItems: 'flex-end' }]}>
                        <Text>S$</Text>
                    </View>
                </View>
                {viewData.map(data => {
                    return generateRow(data);
                })}
            </View>
        </Page>
    );
};

export default TaxComputationPdfView;
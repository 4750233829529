import React, { useState, useRef } from 'react';
import { Accordion, Item } from 'devextreme-react/accordion';
import { PrimaryButton } from 'components/StandardButton';
import CheckBox from 'devextreme-react/check-box';
import Popover from 'devextreme-react/popover';
import TextBox from 'devextreme-react/text-box';
import ValidationGroup from 'devextreme-react/validation-group';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import useAcraAuth from 'hooks/useAcraAuth';
import * as ErrorMessage from 'utils/errorMessage';
import * as PageUtils from 'utils/pageUtils';
import * as Toast from 'utils/toast';
import * as AnnualReturnApi from '../../api/annualReturnApi';
import ValidationErrorMessageView from '../ValidationErrorMessageView';

const AnnualReturnProfileView = (props) => {
    const { initialData, onSubmitPostAction } = props;

    const [data, setData] = useState({
        ...initialData, isFilingAgent: !!initialData.filingAgentNo
    });
    const [validationErrorData, setValidationErrorData] = useState({ hasValidationError: false, validationErrors: null });

    const { startAuth } = useAcraAuth();
    const { accountBookData } = useAccountBookData();
    const companyName = accountBookData && accountBookData.companyName;
    const accountBookDataId = accountBookData && accountBookData.accountBookDataId;

    const initialFocusRef = useRef();
    const filingAgentNoFieldValidationRef = useRef();

    function handleSubmit(e) {
        e.preventDefault();
        const { companyUEN } = data;

        if (!companyUEN || companyUEN === '') {
            Toast.showErrorMessage('Company UEN cannot be empty. Please maintain your Company UEN at Home page.');
        } else {
            submitAnnualReturnProfileData(data);
        }
    }

    function submitAnnualReturnProfileData(input) {
        PageUtils.blockPage();
        AnnualReturnApi.createAnnualReturnProfile(accountBookDataId, input)
            .then(result => {
                if (result.requireAuth) {
                    PageUtils.unblockPage();
                    startAuth(result.authUrl, submitAnnualReturnProfileData, input);
                } else {
                    if (result.isSuccess) {
                        PageUtils.unblockPage();
                        onSubmitPostAction();
                    } else {
                        PageUtils.unblockPage();
                        Toast.showWarningMessage('There are validation errors returned by ACRA, please check them before submitting the form again.');
                        setValidationErrorData({ hasValidationError: true, validationErrors: result.validationErrors });
                    }
                }
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    }

    return (
        <form onSubmit={handleSubmit}>
            <ValidationErrorMessageView
                validationErrorData={validationErrorData}
                setValidationErrorData={setValidationErrorData}
            />
            <ValidationGroup name="annualReturnProfile">
                <Accordion
                    onItemRendered={(e) => {
                        const items = e.component.option('items');
                        e.component.option('selectedItems', items);
                    }}
                    keyExpr="text"
                    collapsible
                    multiple
                >
                    <Item
                        text="1"
                        title="Company Information"
                    >
                        <div className="form-row d-flex">
                            <div className="col-md-5 col-sm-4">
                                <label className="form-label">Company Name</label>
                                <TextBox
                                    className="form-control"
                                    value={companyName}
                                    ref={initialFocusRef}
                                    readOnly
                                />
                            </div>
                            <div className="offset-md-1 col-md-5">
                                <label className="form-label required">Company UEN</label>
                                <TextBox
                                    className="form-control"
                                    value={data.companyUEN}
                                    readOnly
                                />
                            </div>
                        </div>
                    </Item>
                    <Item
                        text="2"
                        title="Filing Agent Information"
                    >
                        <div className="form-row d-flex">
                            <div className="col-md-5 d-flex align-content-center">
                                <CheckBox
                                    text="Is Filing Agent"
                                    value={data.isFilingAgent}
                                    onValueChanged={e => {
                                        setData(prevState => ({ ...prevState, isFilingAgent: e.value }));
                                        if (!e.value) setData(prevState => ({ ...prevState, filingAgentNo: '' }));
                                        filingAgentNoFieldValidationRef.current.instance.reset();
                                    }}
                                />
                            </div>
                            <div className="offset-md-1 col-md-5">
                                <label className="form-label">Filing Agent No.</label>
                                <TextBox
                                    className="form-control"
                                    value={data.filingAgentNo}
                                    onValueChanged={e => {
                                        setData(prevState => ({ ...prevState, filingAgentNo: e.value }));
                                    }}
                                    disabled={!data.isFilingAgent}
                                >
                                    <Validator ref={filingAgentNoFieldValidationRef}>
                                        <RequiredRule message="Filing Agent is required." />
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                    </Item>
                    <Item
                        text="3"
                        title="Director / Public Accounting Entity / Audit Partner Information"
                    >
                        <div className="form-row d-flex">
                            <div className="form-group col-md-5">
                                <label className="form-label">Director 1 Identification No.</label>
                                <i id="directorIDNo1" className="dx-icon dx-icon-info px-2"></i>
                                <TextBox
                                    className="form-control"
                                    value={data.signingDirectorId1}
                                    onValueChanged={e => setData({ ...data, signingDirectorId1: e.value })}
                                />
                                <Popover
                                    target="#directorIDNo1"
                                    showEvent="dxhoverstart"
                                    hideEvent="dxhoverend"
                                    position="top"
                                >
                                    Identification No. of Director 1 who has signed the Financial Documents
                                </Popover>
                            </div>
                            <div className="form-group offset-md-1 col-md-5">
                                <label className="form-label">Director 2 Identification No.</label>
                                <i id="directorIDNo2" className="dx-icon dx-icon-info px-2"></i>
                                <TextBox
                                    className="form-control"
                                    value={data.signingDirectorId2}
                                    onValueChanged={e => setData({ ...data, signingDirectorId2: e.value })}
                                />
                                <Popover
                                    target="#directorIDNo2"
                                    showEvent="dxhoverstart"
                                    hideEvent="dxhoverend"
                                    position="top"
                                >
                                    Identification No. of Director 2 who has signed the Financial Documents
                                </Popover>
                            </div>
                        </div>
                        <div className="form-row mt-3 d-flex">
                            <div className="form-group col-md-5">
                                <label className="form-label">Public Accounting Entity UEN</label>
                                <i id="publicAccountingUEN" className="dx-icon dx-icon-info px-2"></i>
                                <TextBox
                                    className="form-control"
                                    value={data.accountingEntityUEN}
                                    onValueChanged={e => setData({ ...data, accountingEntityUEN: e.value })}
                                />
                                <Popover
                                    target="#publicAccountingUEN"
                                    showEvent="dxhoverstart"
                                    hideEvent="dxhoverend"
                                    position="top"
                                >
                                    UEN of the Public Accounting Entity that audited the financial statements
                                </Popover>
                            </div>
                            <div className="form-group offset-md-1 col-md-5">
                                <label className="form-label">Audit Partner Registration No.</label>
                                <i id="auditPartnerRegNo" className="dx-icon dx-icon-info px-2"></i>
                                <TextBox
                                    className="form-control"
                                    value={data.auditPartnerRegNo}
                                    onValueChanged={e => setData({ ...data, auditPartnerRegNo: e.value })}
                                />
                                <Popover
                                    target="#auditPartnerRegNo"
                                    showEvent="dxhoverstart"
                                    hideEvent="dxhoverend"
                                    position="top"
                                >
                                    Registration No. of Audit Partner who has signed the auditor's report
                                </Popover>
                            </div>
                        </div>
                    </Item>
                </Accordion>
                <div className="mt-2">
                    <PrimaryButton
                        text="Retrieve Data from ACRA"
                        useSubmitBehavior
                    />
                </div>
            </ValidationGroup>
        </form>
    );
};

export default AnnualReturnProfileView;
import CheckBox from 'devextreme-react/check-box';
import TextBox from 'devextreme-react/text-box';
import { Validator, RequiredRule } from 'devextreme-react/validator';
import { PrimaryButton } from 'components/StandardButton';
import * as formCsStatusConstant from '../../data/formCsStatusConstant';

const DeclarationView = (props) => {
    const {
        status,
        formData,
        handleDeclarationDataChange,
        viewFinancialSchedule
    } = props;

    const isReadOnlyForm = status === formCsStatusConstant.FormSubmitted;

    return (
        <div className="declarationView">
            <div className="d-flex mb-3">
                <div className="align-self-center">
                    <CheckBox
                        readOnly={isReadOnlyForm}
                        value={formData.declaration.isFullAndTrueAccOfCompInc}
                        onValueChanged={e => handleDeclarationDataChange({ isFullAndTrueAccOfCompInc: e.value })}
                    >
                        <Validator validationGroup="draftValidation">
                            <RequiredRule message="This field is required" />
                        </Validator>
                    </CheckBox>
                </div>
                <div className="flex-grow-1 ms-3 align-self-center">The company declares that this return gives a full and true account of the whole of the company's income for the financial period ending in the preceding year.</div>
            </div>
            <div className="d-flex mb-2">
                <div style={{ lineHeight: '27px', verticalAlign: 'middle' }}>
                    <CheckBox
                        readOnly={isReadOnlyForm}
                        value={formData.declaration.isQualifiedToUseConvFormCS}
                        onValueChanged={e => handleDeclarationDataChange({ isQualifiedToUseConvFormCS: e.value })}
                    />
                </div>
                <div className="ms-3 align-self-center">
                    <div className="py-1">The company maintains Singapore dollar as its functional/ presentation currency.</div>
                    <div className="py-1">The company is not an investment holding company or a service company that provides only related party services.</div>
                    <div className="py-1">The company does not own subsidiaries, associates or joint ventures, and has no investment in intangibles.</div>
                </div>
            </div>
            <hr />

            <div className="mb-2 fs-6 fw-bolder">
                Details of Filer & Contact Person
            </div>
            <div className="row">
                <div className="col-md-3 mb-2 align-self-center">Name of Filer</div>
                <div className="col-md-3 mb-2">
                    <TextBox
                        readOnly
                        value={formData.nameOfFiler}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 mb-2 align-self-center">Designation</div>
                <div className="col-md-3 mb-2">
                    <TextBox
                        readOnly
                        value={formData.filingInfo.designationOfFiler}
                    />
                </div>
            </div>
            {formData.taxAgentFirmName && 
                <div className="row">
                    <div className="col-md-3 mb-2 align-self-center">Tax Agent Firm</div>
                    <div className="col-md-3 mb-2">
                        <TextBox
                            readOnly
                            value={formData.taxAgentFirmName}
                        />
                    </div>
                </div>
            }
            <div className="row">
                <div className="col-md-3 mb-2 align-self-center">Name of Contact Person</div>
                <div className="col-md-3 mb-2">
                    <TextBox
                        readOnly
                        value={formData.filingInfo.nameOfContactPerson}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 mb-2 align-self-center">Designation</div>
                <div className="col-md-3 mb-2">
                    <TextBox
                        readOnly
                        value={formData.filingInfo.designationOfContactPerson}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 mb-2 align-self-center">Contact No.</div>
                <div className="col-md-3 mb-2">
                    <TextBox
                        readOnly
                        value={formData.filingInfo.contactNumber}
                    />
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="col-10 fs-7 d-flex align-items-center">Please review Medical Expenses Schedule, Rental Schedule, Renovation Schedule and Capital Allowance Schedule if you haven't done it.</div>
                <div className="col-2">
                    <PrimaryButton
                        text="Review"
                        onClick={() => viewFinancialSchedule()}
                    />
                </div>
            </div>
        </div>
    );
};

export default DeclarationView;
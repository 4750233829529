const AcraFilingQualificationCriteria = () => {
    return (
        <>
            <h5>ACRA Filing Qualification Criteria</h5>
            <hr />
            <b>A company can file <u>Annual Return and Financial Statements (in Simplified XBRL format)</u> with ACRA using this web portal if it meets the following conditions:</b>
            <ol type="a">
                <li>The company has revenue of S$500,000<sup>1</sup> or less for the current financial year;</li>
                <li>The company has total assets of S$500,000<sup>1</sup> or less as at the current financial year-end; and</li>
                <li>The company is NOT</li>
                <ul>
                    <li>listed or is in the process of issuing its debt or equity instruments for trading on a securities exchange in Singapore;</li>
                    <li>listed on a securities exchange outside Singapore; and</li>
                    <li>a financial institution.</li>
                </ul>
            </ol>
            <p>
                <i><sup>1</sup>
                    The amount threshold of S$500,000 is determined based on the current financial year in the financial statements or (where the company is a parent company) consolidated financial statements as required to be prepared under the Companies Act, regardless of the number of months in that current financial year.
                </i>
            </p>
            <b>A company can file <u>Annual Return</u> with ACRA using this web portal if it meets the following condition:</b>
            <ol type="a">
                <li>The company is either:</li>
                <ul>
                    <li>filing financial statements in PDF format as company limited by guarantee; or</li>
                    <li>not required to file financial statements with ACRA as a solvent exempt private company or a dormant relevant company.</li>
                </ul>
            </ol>
        </>
    );
};

export default AcraFilingQualificationCriteria;
import * as Api from 'utils/api';
import { buildApiUrl } from 'utils/session';

const basePath = 'annualReturn';

export function getAnnualReturnData(accountBookDataId) {
    let url = buildApiUrl(`${basePath}/getAnnualReturnFormData`, { accountBookDataId });
    return Api.getData(url);
}

export function reloadAnnualReturnForm(accountBookDataId) {
    let url = buildApiUrl(`${basePath}/reloadAnnualReturnProfile`, { accountBookDataId });
    return Api.postData(url).then(response => response.json());
}

export function createAnnualReturnProfile(accountBookDataId, input) {
    let url = buildApiUrl(`${basePath}/generateAnnualReturnProfile`, { accountBookDataId });
    return Api.postData(url, input).then(response => response.json());
}

export function confirmAnnualReturn(accountBookDataId, input) {
    let url = buildApiUrl(`${basePath}/confirmAnnualReturn`, { accountBookDataId });
    return Api.postData(url, input).then(response => response.json());
}

export function saveAnnualReturnFilingFormData(accountBookDataId, input) {
    let url = buildApiUrl(`${basePath}/saveAnnualReturnFilingFormData`, { accountBookDataId });
    return Api.postData(url, input).then(response => response.json());
}

export function uploadAttachment(accountBookDataId, input) {
    let url = buildApiUrl(`${basePath}/uploadAttachment`, { accountBookDataId });
    return Api.postData(url, input).then(response => response.json());
}

export function getAttachmentDownloadUrl(accountBookDataId, input) {
    let url = buildApiUrl(`${basePath}/getAttachmentDownloadUrl`, { accountBookDataId });
    return Api.postData(url, input).then(response => response.json());
}

export function downloadTransactionPreview(accountBookDataId, input) {
    let url = buildApiUrl(`${basePath}/downloadTransactionPreview`, { accountBookDataId });
    return Api.postData(url, input).then(response => response.blob());
}

export function makePayment(accountBookDataId) {
    let url = buildApiUrl(`${basePath}/makePayment`, { accountBookDataId });
    return Api.postData(url).then(response => response.json());
}

export function enquirePayment(accountBookDataId) {
    let url = buildApiUrl(`${basePath}/enquirePayment`, { accountBookDataId });
    return Api.postData(url).then(response => response.json());
}

export function getPaymentStatus(accountBookDataId) {
    let url = buildApiUrl(`${basePath}/getPaymentStatus`, { accountBookDataId });
    return Api.getData(url);
}

export function initiateAnnualReturnFiling(accountBookDataId) {
    let url = buildApiUrl(`${basePath}/initiateAnnualReturnFiling`, { accountBookDataId });
    return Api.putData(url);
}

export function deleteDraft(accountBookDataId) {
    let url = buildApiUrl(`${basePath}/deleteDraft`, { accountBookDataId });
    return Api.deleteData(url);
}
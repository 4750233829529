import React from 'react';
import Button from 'devextreme-react/button';

const PrimaryButton = ({ type, ...rest }) => <Button type="default" {...rest} />;
// eslint-disable-next-line react/no-multi-comp
const QueryButton = ({ type, ...rest }) => <Button type="default" {...rest} />;
// eslint-disable-next-line react/no-multi-comp
const ActionButton = ({ type, ...rest }) => <Button type="success" {...rest} />;
// eslint-disable-next-line react/no-multi-comp
const DeleteButton = ({ type, ...rest }) => <Button type="danger" {...rest} />;
// eslint-disable-next-line react/no-multi-comp
const GridRefreshButton = ({ type, ...rest }) => <Button type="default" {...rest} />;

// eslint-disable-next-line react/no-multi-comp
const SubmitButton = ({ type, submitForm, ...rest }) => {
    function insertAttribute(e) {
        if (submitForm) {
            let inputElement = e.element.getElementsByTagName('input')[0];
            inputElement.setAttribute('form', submitForm);
        }
    }

    return (
        <Button
            type="success"
            useSubmitBehavior
            onContentReady={insertAttribute}
            {...rest}
        />
    );
};

export { PrimaryButton, QueryButton, ActionButton, DeleteButton, GridRefreshButton, SubmitButton };
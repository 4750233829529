import FinancialStatementDataView_Assets from './FinancialStatementData_AssetsView';
import FinancialStatementDataView_Liabilities from './FinancialStatementData_LiabilitiesView';
import FinancialStatementDataView_Equity from './FinancialStatementData_EquityView';
import DraftFormContentHeaderRow from './DraftFormContentHeaderRow';

const FinancialStatementXBRLFormFinancialPositionStatementView = (props) => {
    // Statement of Financial Position = Balance Sheet
    const { initialData, onViewPDEDrillDown } = props;

    return (
        <>
            <DraftFormContentHeaderRow description="Statement of Financial Position" level={1} />

            <FinancialStatementDataView_Assets
                initialData={initialData}
                onViewPDEDrillDown={onViewPDEDrillDown}
            />

            <DraftFormContentHeaderRow description="Liabities and Equity" level={2} />

            <FinancialStatementDataView_Liabilities
                initialData={initialData}
                onViewPDEDrillDown={onViewPDEDrillDown}
            />

            <FinancialStatementDataView_Equity
                initialData={initialData}
                onViewPDEDrillDown={onViewPDEDrillDown}
            />
        </>
    );
};

export default FinancialStatementXBRLFormFinancialPositionStatementView;
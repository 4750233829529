import NumberBox from 'devextreme-react/number-box';

const DraftFormContentFooterRow = (props) => {
    const {
        description,
        currentYearValue,
        priorYearValue,
        enableTopBorder,
        enableBottomBorder,
        onViewPDEDrillDown
    } = props;

    const styles = {
        ...enableTopBorder && { borderTop: '2px solid #ccc' },
        ...enableBottomBorder && { borderBottom: 'double' }
    };

    const expensesDescription = [
        'Income Tax (Expense) Benefit from Continuing Operations'
    ];

    const formatValue = (value) => {
        if (expensesDescription.includes(description) && value !== 0) {
            return -value;
        }
        return value;
    };

    return (
        <>
            <tr>
                <td className="align-middle px-4 text-end fw-bolder">{description}</td>
                <td className="text-center" style={styles}>
                    <button
                        className="border-0 bg-transparent"
                        onClick={() => onViewPDEDrillDown(true)}
                    >
                        <NumberBox
                            className="text-center border-0 bg-transparent pe-auto"
                            value={formatValue(currentYearValue)}
                            format="#,##0.00;(#,##0.00)"
                            readOnly
                        />
                    </button>
                </td>
                <td className="text-center" style={styles}>
                    {(priorYearValue || priorYearValue === 0) &&
                        <button
                            className="border-0 bg-transparent"
                            onClick={() => onViewPDEDrillDown(false)}
                        >
                            <NumberBox
                                className="text-center border-0 bg-transparent pe-auto"
                                value={formatValue(priorYearValue)}
                                format="#,##0.00;(#,##0.00)"
                                readOnly
                            />
                        </button>
                    }
                </td>
            </tr>
            <tr style={{ height: '6px' }} />
        </>
    );
};

export default DraftFormContentFooterRow;
import {
    Page,
    StyleSheet,
    Text,
    View
} from '@react-pdf/renderer';
import DeclarationPdfView from './DeclarationPdfView';

const AcknowledgementPdfView = (props) => {
    const { initialData, accountBookData } = props;

    const styles = StyleSheet.create({
        page: {
            padding: 40
        },
        header: {
            fontSize: 15,
            fontWeight: 1000,
            marginBottom: 10,
            display: 'table',
            width: 'auto',
            textDecoration: 'underline',
            textAlign: 'center'
        },
        alert: {
            backgroundColor: '#d1e7dd',
            padding: 10,
            marginBottom: 10,
            borderRadius: 5
        },
        alertText: {
            fontSize: 10,
            fontWeight: 'bold',
            marginBottom: 10
        },
        normalText: {
            fontSize: 8,
            margin: 5
        }
    });

    return (
        <Page size="A4" style={styles.page}>
            <View style={styles.container}>
                <View style={styles.header}>
                    <Text>Acknowledgement</Text>
                </View>
                <View style={styles.alert}>
                    <Text style={styles.alertText}>Successful Submission</Text>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <View style={{ width: '30%' }}>
                            <Text style={styles.normalText}>Company Name</Text>
                            <Text style={styles.normalText}>Tax Ref No.</Text>
                            <Text style={styles.normalText}>Acknowledgement No.</Text>
                            <Text style={styles.normalText}>Date/ Time</Text>
                            <Text style={styles.normalText}>Year of Assessment</Text>
                            <Text style={styles.normalText}>Estimated Tax Payable</Text>
                        </View>
                        <View>
                            <Text style={styles.normalText}>{accountBookData.companyName}</Text>
                            <Text style={styles.normalText}>{initialData.formDraft.filingInfo.uen}</Text>
                            <Text style={styles.normalText}>{initialData.formDraft.acknowledgementNo}</Text>
                            <Text style={styles.normalText}>{initialData.formDraft.timestamp}</Text>
                            <Text style={styles.normalText}>{initialData.formDraft.filingInfo.ya}</Text>
                            <Text style={styles.normalText}>S$ {initialData.formDraft.estTaxPayable}</Text>
                        </View>
                    </View>
                </View>
            </View>
            <DeclarationPdfView formData={initialData.formDraft} />
        </Page>
    );
};

export default AcknowledgementPdfView;
import StandardPopup from 'components/StandardPopup';
import AnnualReturn from 'pages/AnnualReturn/AnnualReturn';
import './AnnualReturnDialog.css';

const QuickSubmissionDialog_AnnualReturn = (props) => {
    const { onCancel } = props;

    return (
        <StandardPopup
            visible
            onHiding={onCancel}
            dragEnabled={false}
            width={1000}
            height={600}
            fullScreen
            title="Submission for Annual Return"
            wrapperAttr={{ class: 'quick-submission' }}
        >
            <div className="app h-100">
                <div className="content">
                    <AnnualReturn />
                </div>
            </div>
        </StandardPopup>
    );
};

export default QuickSubmissionDialog_AnnualReturn;
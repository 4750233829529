import CustomStore from 'devextreme/data/custom_store';
import { Column, Toolbar, Button, Item } from 'devextreme-react/data-grid';
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import StandardDataGrid from 'components/StandardDataGrid';
import { PrimaryButton } from 'components/StandardButton';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as AccountBookDataStatusConstant from 'data/accountBookDataStatusConstant';
import AccountBookNotSelected from 'layouts/AccountBookNotSelected';
import * as ErrorMessage from 'utils/errorMessage';
import * as MessageBox from 'utils/messageBox';
import * as PageUtils from 'utils/pageUtils';
import * as Toast from 'utils/toast';
import * as FixedAssetApi from './api/fixedAssetApi';
import FixedAssetDataDialog from './partials/FixedAssetDataDialog';
import FixedAssetImportDialog from './partials/FixedAssetImportDialog';
import './FixedAssetMaintenance.scss';

const FixedAssetMaintenancePage = () => {
    const dataGridRef = useRef();
    const { accountBookData } = useAccountBookData();
    const accountBookDataId = accountBookData && accountBookData.accountBookDataId;
    const readOnly = accountBookData.stepList.some(x => x === AccountBookDataStatusConstant.CompletedFormCS);

    const [showFixedAssetDataDialog, setShowFixedAssetDataDialog] = useState(false);
    const [showFixedAssetImportDialog, setShowFixedAssetImportDialog] = useState(false);
    const [fixedAssetData, setFixedAssetData] = useState(null);
    const [fixedAssetImportData, setFixedAssetImportData] = useState(null);

    const navigate = useNavigate();

    const dataSource = useMemo(() => new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: function () {
            return FixedAssetApi.getFixedAssetEntryList(accountBookDataId);
        }
    }), [accountBookDataId]);

    const editRecord = (id) => {
        PageUtils.blockPage();
        FixedAssetApi.getFixedAssetEntry(accountBookDataId, id)
            .then(result => {
                PageUtils.unblockPage();
                setShowFixedAssetDataDialog(true);
                setFixedAssetData(result);
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    };

    const submitRecord = (data) => {
        PageUtils.blockPage();
        if (data.id) {
            FixedAssetApi.updateFixedAssetEntry(accountBookDataId, data.id, data)
                .then(() => {
                    PageUtils.unblockPage();
                    setShowFixedAssetDataDialog(false);
                    Toast.showSuccessMessage('Fixed Asset Entry is updated successfully.');
                    dataGridRef.current.instance.refresh();
                })
                .catch(error => {
                    PageUtils.unblockPage();
                    Toast.showErrorMessage(ErrorMessage.updateError(error));
                });
        } else {
            FixedAssetApi.createFixedAssetEntry(accountBookDataId, data)
                .then(() => {
                    PageUtils.unblockPage();
                    setShowFixedAssetDataDialog(false);
                    Toast.showSuccessMessage('Fixed Asset Entry is added successfully.');
                    dataGridRef.current.instance.refresh();
                })
                .catch(error => {
                    PageUtils.unblockPage();
                    Toast.showErrorMessage(ErrorMessage.createError(error));
                });
        }
    };

    const deleteRecord = async (id) => {
        const result = await MessageBox.showConfirmDeleteMessage('<div class="text-center">Are you sure on deleting this Fixed Asset Entry? <p class="mb-0"> ( This action cannot be reverted. )</p> </div>', true);
        if (!(result && result.confirm)) return;

        PageUtils.blockPage();
        FixedAssetApi.deleteFixedAssetEntry(accountBookDataId, id)
            .then(() => {
                PageUtils.unblockPage();
                Toast.showSuccessMessage('Fixed Asset Entry is deleted successfully.');
                dataGridRef.current.instance.refresh();
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.deleteError(error));
            });
    };

    const getPreviousYearFixedAssetEntries = () => {
        PageUtils.blockPage();
        FixedAssetApi.getPriorYearFixedAssetEntries(accountBookDataId)
            .then(data => {
                PageUtils.unblockPage();
                setFixedAssetImportData(data);
                setShowFixedAssetImportDialog(true);
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    };

    const importFixedAssetEntries = (fixedAssets) => {
        PageUtils.blockPage();
        FixedAssetApi.importFixedAssetEntries(accountBookDataId, fixedAssets)
            .then(() => {
                PageUtils.unblockPage();
                Toast.showSuccessMessage('Fixed Asset Entries are imported successfully.');
                dataGridRef.current.instance.refresh();
                setShowFixedAssetImportDialog(false);
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.updateError(error));
            });
    };

    return (
        <>
            <div className="content-block">
                <PageTitle title="Fixed Asset" >
                    <div className="align-self-center" style={{ flexGrow: 100 }}>
                        <PrimaryButton
                            className="ms-3"
                            text="Back to Form C-S"
                            onClick={() => navigate('/formCS')}
                        />
                    </div>
                </PageTitle>
                <div className="fw-light px-3">
                    Fixed Asset Data is required to calculate Capital Allowance.
                </div>
            </div>
            {accountBookDataId ?
                <>
                    <div className="content-block">
                        <div className="dx-card responsive-paddings">
                            <StandardDataGrid
                                ref={dataGridRef}
                                dataSource={dataSource}
                            >
                                <Toolbar>
                                    <Item
                                        location="before"
                                        cssClass="dx-swatch-button"
                                        locateInMenu="auto"
                                        widget="dxButton"
                                        visible={!readOnly}
                                        options={{
                                            text: 'New',
                                            icon: 'add',
                                            type: 'default',
                                            stylingMode: 'outlined',
                                            onClick: () => {
                                                setShowFixedAssetDataDialog(true);
                                                setFixedAssetData({});
                                            }
                                        }}
                                    />
                                    <Item
                                        location="before"
                                        locateInMenu="auto"
                                        widget="dxButton"
                                        visible={!readOnly}
                                        options={{
                                            text: 'Import Entries From Prior Years (Year of Assessment)',
                                            type: 'success',
                                            stylingMode: 'contained',
                                            onClick: () => getPreviousYearFixedAssetEntries()
                                        }}
                                    />
                                    <Item name="columnChooserButton" />
                                    <Item name="searchPanel" />
                                </Toolbar>
                                <Column
                                    caption="Description"
                                    dataField="description"
                                    dataType="string"
                                />
                                <Column
                                    caption="Purchase In Year Of Assessment"
                                    dataField="acquisitionInYearOfAssessment"
                                    dataType="number"
                                />
                                <Column
                                    caption="Purchase Price"
                                    dataField="acquisitionPrice"
                                    dataType="number"
                                    format="#0.00"
                                />
                                <Column
                                    caption="Capital Allowance Type"
                                    dataField="capitalAllowanceType"
                                    dataType="string"
                                />
                                <Column
                                    caption="Is Hire Purchase (HP)"
                                    dataField="isHirePurchase"
                                    dataType="boolean"
                                    visible={false}
                                />
                                <Column
                                    caption="Deposit / Principal Paid Before YA Minus 2"
                                    dataField="principalPaidBeforeYaMinus2"
                                    dataType="number"
                                    format="#0.00"
                                    visible={false}
                                />
                                <Column
                                    caption="Deposit / Principal Paid During YA Minus 2"
                                    dataField="principalPaidDuringYaMinus2"
                                    dataType="number"
                                    format="#0.00"
                                    visible={false}
                                />
                                <Column
                                    caption="Deposit / Principal Paid During YA Minus 1"
                                    dataField="principalPaidDuringYaMinus1"
                                    dataType="number"
                                    format="#0.00"
                                    visible={false}
                                />
                                <Column
                                    caption="Deposit / Principal Paid During Current YA"
                                    dataField="principalPaidDuringYa"
                                    dataType="number"
                                    format="#0.00"
                                    visible={false}
                                />
                                <Column
                                    caption="Disposal In Year Of Assessment"
                                    dataField="disposalInYearOfAssessment"
                                    dataType="number"
                                />
                                <Column
                                    caption="Disposal Price"
                                    dataField="disposalPrice"
                                    dataType="number"
                                    format="#0.00"
                                />
                                <Column
                                    caption="Remarks"
                                    dataField="remark"
                                    dataType="string"
                                    visible={false}
                                />
                                <Column
                                    type="buttons"
                                    width={100}
                                    fixed
                                    fixedPosition="right"
                                >
                                    <Button
                                        text={readOnly ? 'View' : 'Edit'}
                                        onClick={(e) => editRecord(e.row.data.id)}
                                    />
                                    <Button
                                        text="Delete"
                                        visible={!readOnly}
                                        onClick={(e) => deleteRecord(e.row.data.id)}
                                    />
                                </Column>
                            </StandardDataGrid>
                        </div>
                    </div>
                    {showFixedAssetDataDialog &&
                        <FixedAssetDataDialog
                            initialData={fixedAssetData}
                            onSubmit={submitRecord}
                            onCancel={() => setShowFixedAssetDataDialog(false)}
                        />
                    }
                    {showFixedAssetImportDialog &&
                        <FixedAssetImportDialog
                            initialData={fixedAssetImportData}
                            onSubmit={importFixedAssetEntries}
                            onCancel={() => setShowFixedAssetImportDialog(false)}
                        />
                    }
                </>
                :
                <AccountBookNotSelected />
            }
        </>
    );
};

export default FixedAssetMaintenancePage;
export const Initiated = 'Initiated';
export const UploadSuccess = 'Upload Success';
export const UploadSuccessAcknowledgement = 'Upload Success Acknowledgement';
export const UploadFailure = 'Upload Failure';
export const UploadFailureAcknowledgement = 'Upload Failure Acknowledgement';
export const CompletedCompanyInfo = 'Completed Company Info';
export const VisitedAccountMapping = 'Visited Account Mapping';
export const CompletedAccountMapping = 'Completed Account Mapping';
export const NoFormCS = 'No Form C-S';
export const InitiatedFormCS = 'Initiated Form C-S';
export const VisitedRenovationExpense = 'Visited Renovation Expense';
export const VisitedFixedAsset = 'Visited Fixed Asset';
export const CompletedFormCS = 'Completed Form C-S';
export const NoAnnualReturn = 'No Annual Return';
export const InitiatedAnnualReturn = 'Initiated Annual Return';
export const VisitedFinancialStatement = 'Visited Financial Statement';
export const CompletedAnnualReturn = 'Completed Annual Return';
import React from 'react';

import TreeList, {
    ColumnChooser,
    Pager,
    Paging,
    SearchPanel,
    Sorting,
    Scrolling
} from 'devextreme-react/tree-list';

const StandardTreeListInner = ({ forwardedRef, children, useDefaultPaging, ...rest }) => (
    <TreeList
        ref={forwardedRef}
        width="100%"
        columnAutoWidth
        allowColumnReordering
        allowColumnResizing
        columnResizingMode="widget"
        showRowLines
        showBorders
        hoverStateEnabled
        customizeColumns={(columns) => {
            columns.forEach((c) => {
                if (c.dataType === 'date' && c.format === 'shortDate')
                    //shortDate is default value for column which dataType="date"
                    c.format = 'dd/MM/yyyy';

                if (c.dataType === 'datetime' && c.format === 'shortDateShortTime')
                    //shortDateShortTime is default value for column which dataType="datetime"
                    c.format = 'dd/MM/yyyy hh:mm aa';
            });
        }}
        {...rest}
    >
        <ColumnChooser enabled mode={window.innerWidth >= 992 ? 'dragAndDrop' : 'select'} />
        <SearchPanel visible searchVisibleColumnsOnly />
        <Sorting mode="multiple" />
        <Scrolling mode="default" useNative />
        {useDefaultPaging && <Pager allowedPageSizes={[10, 25, 50, 100]} showInfo showNavigationButtons showPageSizeSelector visible />}
        {useDefaultPaging && <Paging enabled defaultPageSize={10} />}
        {children}
    </TreeList>
);

// eslint-disable-next-line react/no-multi-comp
const StandardTreeList = React.forwardRef((props, ref) => <StandardTreeListInner {...props} forwardedRef={ref} />);
StandardTreeList.displayName = 'StandardTreeList';

export default StandardTreeList;
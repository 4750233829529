import {
    Page,
    StyleSheet,
    Text,
    View
} from '@react-pdf/renderer';

const RentalSchedulePdfView = (props) => {
    const { initialData } = props;
    let i = 0;

    const styles = StyleSheet.create({
        page: {
            padding: 40
        },
        header: {
            fontSize: 15,
            fontWeight: 1000,
            marginBottom: 10,
            display: 'table',
            width: 'auto',
            textDecoration: 'underline',
            textAlign: 'center'
        },
        alertText: {
            fontSize: 10,
            fontWeight: 'bold',
            marginBottom: 10
        },
        normalText: {
            fontSize: 8,
            margin: 5
        },
        table: {
            display: 'table',
            width: 'auto',
            borderStyle: 'solid',
            borderWidth: 1
        },
        tableHeader: {
            backgroundColor: '#21a84a',
            color: 'white',
            fontWeight: 1000
        },
        tableHeaderText: {
            fontSize: 10,
            margin: 5,
            fontWeight: 1000
        }
    });

    const dataSource = [
        { description: 'Rental income (Gross)', value: initialData ? initialData.c1_GrossRent : 0 },
        { description: '', value: null },
        { index: 'Less:', description: 'Deductible expenses', value: null, isHeader: true },
        { description: 'Expenses incurred to derive rental income - Commission', value: initialData ? initialData.commissionExpRentalIncome : 0 },
        { description: 'Expenses incurred to derive rental income - Insurance', value: initialData ? initialData.insuranceExpRentalIncome : 0 },
        { description: 'Expenses incurred to derive rental income - Interest', value: initialData ? initialData.interestExpRentalIncome : 0 },
        { description: 'Expenses incurred to derive rental income - Property tax', value: initialData ? initialData.propertyTaxExpRentalIncome : 0 },
        { description: 'Expenses incurred to derive rental income - Repair and maintenance', value: initialData ? initialData.repairMaintenanceExpRentalIncome : 0 },
        { description: 'Expenses incurred to derive rental income - Others', value: initialData ? initialData.otherExpRentalIncome : 0 },
        { description: 'Net Rental income', value: initialData ? initialData.sgRent : 0 }
    ];

    const generateRow = (data) => {
        return (
            <View key={++i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <View style={[styles.normalText, { width: 30 }]}>
                    <Text>{data.index !== undefined ? `${data.index}` : null} </Text>
                </View>
                <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }, (data.isHeader) ? { textDecoration: 'underline' } : null]}>
                    <Text>{data.description}</Text>
                </View>
                <View style={styles.normalText}>
                    <Text>{data.value !== null ? formatValue(data.value) : null}</Text>
                </View>
            </View>
        );
    };

    const formatValue = (value) => {
        if (typeof value === 'number') {
            return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
        return value;
    };

    return (
        <Page size="A4" style={styles.page}>
            <View style={styles.container}>
                <View style={styles.header}>
                    <Text>Rental Schedule</Text>
                </View>
            </View>
            <Text style={styles.alertText}>Rental schedule is used to track and itemize expenses that is related to each tenants' rental which include commission, insurance and many more.</Text>
            <View style={styles.table}>
                <View style={[styles.tableHeader, { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }]}>
                    <View style={[styles.tableHeaderText, { width: 30 }]}>
                        <Text></Text>
                    </View>
                    <View style={[styles.tableHeaderText, { flex: 1, alignItems: 'flex-start' }]}>
                        <Text>Description</Text>
                    </View>
                    <View style={styles.tableHeaderText}>
                        <Text>Balance</Text>
                    </View>
                </View>
                {dataSource.map(data => generateRow(data))}
            </View>
        </Page>
    );
};

export default RentalSchedulePdfView;
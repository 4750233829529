import { useEffect, useState, useRef } from 'react';
import { Column, Item, Toolbar } from 'devextreme-react/tree-list';
import StandardTreeList from 'components/StandardTreeList';

const BalanceSheetStatementView = (props) => {
    const { isCurrentYear, visible } = props;
    const [dataSource, setDataSource] = useState(null);
    const treeListRef = useRef();

    useEffect(() => {
        if (visible) {
            setDataSource(isCurrentYear ? props.currentYearData : props.priorYearData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, props.currentYearData, props.priorYearData, isCurrentYear]);

    function onCellPrepared(e) {
        if (e.rowType === 'data') {
            if (e.column.dataField === 'description') {
                if (e.data.isHeader) {
                    e.cellElement.style.textDecoration = 'underline';
                }

                if (e.data.isHeader || e.data.isFooter) {
                    e.cellElement.style.fontWeight = 'bold';
                }
            } else if (e.column.dataField.includes('balance')) {
                if (e.data.isFooter) {
                    e.cellElement.style.fontWeight = 'bold';
                }
            }
        }
    }

    return (
        <StandardTreeList
            dataSource={dataSource}
            keyExpr="key"
            parentIdExpr="parentKey"
            onCellPrepared={onCellPrepared}
            autoExpandAll
            visible={visible}
            ref={treeListRef}
        >
            <Toolbar>
                <Item
                    name="columnChooserButton"
                />
            </Toolbar>
            <Column caption="Description" dataField="description" dataType="string" />
            <Column caption="Account Code" dataField="accNo" dataType="string" />
            <Column caption="Balance" dataField="balance" format="#,##0.00" dataType="number" />
        </StandardTreeList>
    );
};

export default BalanceSheetStatementView;
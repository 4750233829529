import { Validator, CustomRule } from 'devextreme-react/validator';
import { PrimaryButton } from 'components/StandardButton';
import FormNumberBox from '../components/FormNumberBox';
import * as formCsStatusConstant from '../../data/formCsStatusConstant';

const PartBView = (props) => {
    let i = 0;
    const {
        status,
        formData,
        handleDraftDataChange,
        showCapitalAllowanceSchedule,
        showMedicalSchedule,
        showRenovationRefurbishmentSchedule,
        showRentalSchedule
    } = props;

    const isReadOnlyForm = status === formCsStatusConstant.FormSubmitted;

    return (
        <div className="partBView">
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Tax Adjustment</div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col-6">{++i}.</div>
                    <div className="offset-col-6"></div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Net Profit/ Loss before Tax as per Financial Statements</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.dataFormCS.profitLossBeforeTaxation}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col-6">{++i}.</div>
                    <div className="col-6">Less:</div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Separate Source Income</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.dataFormCS.sepSrcIncome}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col-6">{++i}.</div>
                    <div className="offset-col-6"></div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Non-Taxable Income</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.dataFormCS.receiptNotTxAmt}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col-6">{++i}.</div>
                    <div className="offset-col-6">Add:</div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Non-Tax Deductible Expenses</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.dataFormCS.c1_NTDeductibleExp}
                        readOnly
                    />
                </div>
                <div className="col-md-3 align-self-center text-center">
                    <PrimaryButton
                        className="col-12 col-md-9"
                        text="Medical Schedule"
                        onClick={showMedicalSchedule}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col-6">{++i}.</div>
                    <div className="offset-col-6"></div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Adjusted Profit/ Loss before Other Deductions</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.dataFormCS.adjPLBefDed}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col">{++i}.</div>
                    <div className="col"></div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Deduction for Renovation or Refurbishment Works under Section 14N</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.dataFormCS.renoWorksExpS14Q}
                        readOnly
                    />
                </div>
                <div className="col-md-3 align-self-center text-center mb-2">
                    <PrimaryButton
                        className="col-12 col-md-9"
                        text="Renovation Schedule"
                        onClick={showRenovationRefurbishmentSchedule}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex">
                    <div className="col-6">{++i}.</div>
                    <div className="offset-col-6"></div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">
                    Enhanced Deductions under Enterprise Innovation Scheme (EIS) for:
                    <ul className="mt-2 mb-0">
                        <li>Training;</li>
                        <li>Innovation Projects carried out with Partner Institutions;</li>
                        <li>Licensing of Intellectual Property Rights;</li>
                        <li>Registration of Intellectual Property;</li>
                        <li>Qualifying R&D undertaken in Singapore;</li>
                    </ul>
                </div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        className="dx-state-active"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.c1_EnhancedEISDed}
                        onValueChanged={e => handleDraftDataChange({ c1_EnhancedEISDed: e.value })}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col-6">{++i}.</div>
                    <div className="offset-col-6"></div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Further Deductions/ Other Deductions including revenue expenses capitalised or expenses incurred under section 14R</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        className="dx-state-active"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.c1_FurtherDed}
                        onValueChanged={e => handleDraftDataChange({ c1_FurtherDed: e.value })}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col-6">{++i}.</div>
                    <div className="offset-col-6"></div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Adjusted Profit/ Loss before Capital Allowances</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.dataFormCS.sgAdjPLAft}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col">{++i}.</div>
                    <div className="col">Add:</div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Balancing Charge</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.dataFormCS.c1_BC}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col">{++i}. </div>
                    <div className="col">Less:</div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Unutilised Capital Allowances brought forward (IRAS record)</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.prefillData.unutilCABFNorm}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="offset-md-1 col-md-6 mb-2 align-self-center">Company's Declaration</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        className="dx-state-active"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.unutilCABFNorm}
                        onValueChanged={e => handleDraftDataChange({ unutilCABFNorm: e.value })}
                    >
                        <Validator validationGroup="taxCalculationValidation">
                            <CustomRule
                                validationCallback={e => e.value >= 0}
                                message="This value must be positive."
                            />
                        </Validator>
                    </FormNumberBox>
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col">{++i}.</div>
                    <div className="col"></div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center" >Current Year Capital Allowance</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.dataFormCS.cyCANorm}
                        readOnly
                    />
                </div>
                <div className="col-md-3 align-self-center text-center">
                    <PrimaryButton
                        className="col-12 col-md-9"
                        text="Capital Allowance Schedule"
                        onClick={showCapitalAllowanceSchedule}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col-6">{++i}.</div>
                    <div className="offset-col-6"></div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Unutilised Losses brought forward (IRAS' record)</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.prefillData.unutilLossBFNorm}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="offset-md-1 col-md-6 mb-2 align-self-center">Company's Declaration</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        className="dx-state-active"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.unutilLossBFNorm}
                        onValueChanged={e => handleDraftDataChange({ unutilLossBFNorm: e.value })}
                    >
                        <Validator validationGroup="taxCalculationValidation">
                            <CustomRule
                                validationCallback={e => e.value >= 0}
                                message="This value must be positive."
                            />
                        </Validator>
                    </FormNumberBox>
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col-6"></div>
                    <div className="offset-col-6">Add:</div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Separate Source Income</div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col">{++i}a.</div>
                    <div className="col"></div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Gross Rental Income</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.dataFormCS.c1_GrossRent}
                        readOnly
                    />
                </div>
                <div className="col-md-3 align-self-center text-center">
                    <PrimaryButton
                        className="col-12 col-md-9"
                        text="Rental Schedule"
                        onClick={showRentalSchedule}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col-6">{i}b.</div>
                    <div className="offset-col-6">Less:</div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Deductible Expenses</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.dataFormCS.c1_DedExp}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col-6">{i}c.</div>
                    <div className="offset-col-6"></div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Net Rental Income</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.dataFormCS.sgRent}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col-6">{++i}.</div>
                    <div className="offset-col-6"></div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Interest Income</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.dataFormCS.sgIntDisc}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col-6">{++i}.</div>
                    <div className="offset-col-6"></div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Other Taxable Income</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.dataFormCS.sgOtherI}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col-6">{++i}.</div>
                    <div className="offset-col-6"></div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Total Income/ Losses (before Donations)</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.dataFormCS.totSgFI}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col-6">{++i}.</div>
                    <div className="offset-col-6">Less:</div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Unutilised Donations brought forward (IRAS record)</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.prefillData.unutilDonationBFNorm}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="offset-md-1 col-md-6 mb-2 align-self-center">Company's Declaration</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        className="dx-state-active"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.unutilDonationBFNorm}
                        onValueChanged={e => handleDraftDataChange({ unutilDonationBFNorm: e.value })}
                    >
                        <Validator validationGroup="taxCalculationValidation">
                            <CustomRule
                                validationCallback={e => e.value >= 0}
                                message="This value must be positive."
                            />
                        </Validator>
                    </FormNumberBox>
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col">{++i}.</div>
                    <div className="col">Less:</div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Current Year Donation (IRAS record)</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.prefillData.cyDonation}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="offset-md-1 col-md-6 mb-2 align-self-center">Company's Declaration</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        className="dx-state-active"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.cyDonation}
                        onValueChanged={e => handleDraftDataChange({ cyDonation: e.value })}
                    >
                        <Validator validationGroup="taxCalculationValidation">
                            <CustomRule
                                validationCallback={e => e.value >= 0}
                                message="This value must be positive."
                            />
                            <CustomRule
                                validationCallback={e => e.value <= formData.prefillData.cyDonation}
                                message="Value cannot be more than the prefilled amount."
                            />
                        </Validator>
                    </FormNumberBox>
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col-6">{++i}.</div>
                    <div className="offset-col-6"></div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Total Income/ Losses (after Donations)</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.dataFormCS.ci}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col-6">{++i}.</div>
                    <div className="offset-col-6"></div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Unutilised Capital Allowances carried forward</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.dataFormCS.unutilCACFNorm}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col-6">{++i}.</div>
                    <div className="offset-col-6"></div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Unutilised Losses carried forward</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.dataFormCS.unutilLossCFNorm}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-1 mb-2 d-flex align-self-center">
                    <div className="col-6">{++i}.</div>
                    <div className="offset-col-6"></div>
                </div>
                <div className="col-9 col-md-6 mb-2 align-self-center">Unutilised Donations carried forward</div>
                <div className="col-md-2 mb-2 g-md-0">
                    <FormNumberBox
                        value={formData.dataFormCS.unutilDonationCFNorm}
                        readOnly
                    />
                </div>
            </div>

        </div>
    );
};

export default PartBView;
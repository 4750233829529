import DraftFormContentHeaderRow from './DraftFormContentHeaderRow';
import DraftFormContentFooterRow from './DraftFormContentFooterRow';
import FinancialStatementData_CurrentLiabilitiesView from './FinancialStatementData_CurrentLiabilitiesView';
import FinancialStatementData_NonCurrentLiabilitiesView from './FinancialStatementData_NonCurrentLiabilitiesView';

const FinancialStatementXBRLFormLiabilitiesView = (props) => {
    const { initialData, onViewPDEDrillDown } = props;

    // eslint-disable-next-line react/no-multi-comp
    const TotalLiabilitiesRow = () => {
        const dataId = 'liabilities';

        const pde = initialData.pdeList.find(x => x.dataId === dataId);
        const pdeDescription = pde && pde.description;

        const hasPriorYearFinancialData = initialData.xbrlData.priorFinancialYear;
        const priorYearValue = hasPriorYearFinancialData && initialData.xbrlData.priorFinancialYear.liabilities[dataId];
        const currentYearValue = initialData.xbrlData.currentFinancialYear.liabilities[dataId];

        return (
            <DraftFormContentFooterRow
                description={pdeDescription}
                priorYearValue={priorYearValue}
                currentYearValue={currentYearValue}
                enableBottomBorder
                onViewPDEDrillDown={(isCurrentYear) => onViewPDEDrillDown({ pdeDataId: dataId, isCurrentYearFinancialData: isCurrentYear })}
            />
        );
    };

    return (
        <>
            <DraftFormContentHeaderRow description="Liabilities" level={3} />

            {initialData.entryData.financialStatementPosition === 'Current and non-current' &&
                <>
                    <FinancialStatementData_CurrentLiabilitiesView
                        initialData={initialData}
                        onViewPDEDrillDown={onViewPDEDrillDown}
                    />

                    <FinancialStatementData_NonCurrentLiabilitiesView
                        initialData={initialData}
                        onViewPDEDrillDown={onViewPDEDrillDown}
                    />
                </>
            }

            <TotalLiabilitiesRow />
        </>
    );
};

export default FinancialStatementXBRLFormLiabilitiesView;
import SelectBox from 'devextreme-react/select-box';

const FormSelectBox = (props) => {
    const { value, onValueChanged, isRequired = false, children, ...rest } = props;

    const dataSource = [
        { text: 'Yes', value: 1 },
        { text: 'No', value: 2 }
    ];

    if (!isRequired)
        dataSource.push({ text: 'N.A. (Not Applicable)', value: null });

    return (
        <SelectBox
            dataSource={dataSource}
            displayExpr={e => {
                if (e === null) return 'N.A. (Not Applicable)';
                return e.text;
            }}
            valueExpr="value"
            value={value}
            onValueChanged={onValueChanged}
            {...rest}
        >
            {children}
        </SelectBox>
    );
};

export default FormSelectBox;
export const generalPageNavigation = [
    {
        text: 'Home',
        path: '/',
        icon: 'home'
    },
    {
        text: 'User',
        path: '/userMaintenance',
        icon: 'group'
    },
    {
        separator: true
    },
    {
        text: 'Annual Return',
        path: '/annualReturn',
        icon: 'folder'
    }
];

export const formPageNavigation = [
    {
        text: 'Account Book Data',
        path: '/accountBookData',
        displayStep: '1'
    },
    {
        text: 'Account Mapping',
        path: '/accountMappingMaintenance',
        displayStep: '2'
    },
    {
        text: 'Annual Return with XBRL FS',
        path: '/annualReturnWithFS',
        displayStep: '3A'
    },
    {
        text: 'Form C-S',
        path: '/formCS',
        displayStep: '3B'
    }
];

export const filingDataPageNavigation = [
    {
        text: 'Renovation Expense',
        path: '/renovationExpenseMaintenance'
    },
    {
        text: 'Fixed Asset',
        path: '/fixedAssetMaintenance'
    },
    {
        text: 'Financial Statement',
        path: '/financialStatement'
    }
];
import * as entityType from './entityType';
import * as entityActiveDormant from './entityActiveDormant';
import * as entitySolvencyType from './entitySolvencyType';
import * as financialInfoNatureType from './financialInfoNatureType';

// refer to Page 99, 100 table for the following conditions for AGMCopy & Additional Business Rules
export const allAGMCopy = [
    {
        entityType: entityType.PrivateCompanyExemptLimitedShares,
        entitySolvency: entitySolvencyType.Solvent,
        entityActiveDormant: entityActiveDormant.Active,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance,
            financialInfoNatureType.NotFilingFinancialInfo
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: false
    },
    {
        entityType: entityType.PrivateCompanyExemptLimitedShares,
        entitySolvency: entitySolvencyType.Insolvent,
        entityActiveDormant: entityActiveDormant.Active,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: true
    },
    {
        entityType: entityType.PrivateCompanyExemptLimitedShares,
        entitySolvency: entitySolvencyType.EPCRequiredByLaw,
        entityActiveDormant: entityActiveDormant.Active,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: true
    },
    {
        entityType: entityType.PrivateCompanyExemptLimitedShares,
        entitySolvency: entitySolvencyType.Solvent,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance,
            financialInfoNatureType.NotFilingFinancialInfo
        ],
        isDormantExemptFinancialStatement: true,
        isAGMCopyRequired: false
    },
    {
        entityType: entityType.PrivateCompanyExemptLimitedShares,
        entitySolvency: entitySolvencyType.Solvent,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance,
            financialInfoNatureType.NotFilingFinancialInfo
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: false
    },
    {
        entityType: entityType.PrivateCompanyExemptLimitedShares,
        entitySolvency: entitySolvencyType.Insolvent,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance,
            financialInfoNatureType.NotFilingFinancialInfo
        ],
        isDormantExemptFinancialStatement: true,
        isAGMCopyRequired: false
    },
    {
        entityType: entityType.PrivateCompanyExemptLimitedShares,
        entitySolvency: entitySolvencyType.Insolvent,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: true
    },
    {
        entityType: entityType.PrivateCompanyExemptLimitedShares,
        entitySolvency: entitySolvencyType.EPCRequiredByLaw,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance,
            financialInfoNatureType.NotFilingFinancialInfo
        ],
        isDormantExemptFinancialStatement: true,
        isAGMCopyRequired: false
    },
    {
        entityType: entityType.PrivateCompanyExemptLimitedShares,
        entitySolvency: entitySolvencyType.EPCRequiredByLaw,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance,
            financialInfoNatureType.NotFilingFinancialInfo
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: true
    },
    {
        entityType: entityType.PrivateCompanyLimitedShares,
        entitySolvency: null,
        entityActiveDormant: entityActiveDormant.Active,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: true
    },
    {
        entityType: entityType.PrivateCompanyLimitedShares,
        entitySolvency: null,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: true
    },
    {
        entityType: entityType.PrivateCompanyLimitedShares,
        entitySolvency: null,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance,
            financialInfoNatureType.NotFilingFinancialInfo
        ],
        isDormantExemptFinancialStatement: true,
        isAGMCopyRequired: false
    },
    {
        entityType: entityType.PublicCompanyLimitedGuarantee,
        entitySolvency: null,
        entityActiveDormant: entityActiveDormant.Active,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: true
    },
    {
        entityType: entityType.PublicCompanyLimitedGuarantee,
        entitySolvency: null,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: true
    },
    {
        entityType: entityType.PublicCompanyLimitedGuarantee,
        entitySolvency: null,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [],
        isDormantExemptFinancialStatement: true,
        isAGMCopyRequired: false
    },
    {
        entityType: entityType.PublicCompanyLimitedShares,
        entitySolvency: null,
        entityActiveDormant: entityActiveDormant.Active,
        isListedSecurityExchangeAtFinancial: 'Y',
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: true
    },
    {
        entityType: entityType.PublicCompanyLimitedShares,
        entitySolvency: null,
        entityActiveDormant: entityActiveDormant.Active,
        isListedSecurityExchangeAtFinancial: 'N',
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: true
    },
    {
        entityType: entityType.PublicCompanyLimitedShares,
        entitySolvency: null,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: 'N',
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: true
    },
    {
        entityType: entityType.PublicCompanyLimitedShares,
        entitySolvency: null,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: 'Y',
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: true
    },
    {
        entityType: entityType.PublicCompanyLimitedShares,
        entitySolvency: null,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: 'N',
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance,
            financialInfoNatureType.NotFilingFinancialInfo
        ],
        isDormantExemptFinancialStatement: true,
        isAGMCopyRequired: false
    },
    {
        entityType: entityType.PrivateCompanyExemptUnlimited,
        entitySolvency: entitySolvencyType.Insolvent,
        entityActiveDormant: entityActiveDormant.Active,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: true
    },
    {
        entityType: entityType.PrivateCompanyExemptUnlimited,
        entitySolvency: entitySolvencyType.EPCRequiredByLaw,
        entityActiveDormant: entityActiveDormant.Active,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: true
    },
    {
        entityType: entityType.PrivateCompanyExemptUnlimited,
        entitySolvency: entitySolvencyType.Insolvent,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: true
    },
    {
        entityType: entityType.PrivateCompanyExemptUnlimited,
        entitySolvency: entitySolvencyType.EPCRequiredByLaw,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance,
            financialInfoNatureType.NotFilingFinancialInfo
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: true
    },
    {
        entityType: entityType.PrivateCompanyExemptUnlimited,
        entitySolvency: entitySolvencyType.Solvent,
        entityActiveDormant: entityActiveDormant.Active,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance,
            financialInfoNatureType.NotFilingFinancialInfo
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: false
    },
    {
        entityType: entityType.PrivateCompanyExemptUnlimited,
        entitySolvency: entitySolvencyType.Solvent,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance,
            financialInfoNatureType.NotFilingFinancialInfo
        ],
        isDormantExemptFinancialStatement: true,
        isAGMCopyRequired: false
    },
    {
        entityType: entityType.PrivateCompanyExemptUnlimited,
        entitySolvency: entitySolvencyType.Solvent,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance,
            financialInfoNatureType.NotFilingFinancialInfo
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: false
    },
    {
        entityType: entityType.PrivateCompanyExemptUnlimited,
        entitySolvency: entitySolvencyType.Insolvent,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance,
            financialInfoNatureType.NotFilingFinancialInfo
        ],
        isDormantExemptFinancialStatement: true,
        isAGMCopyRequired: false
    },
    {
        entityType: entityType.PrivateCompanyExemptUnlimited,
        entitySolvency: entitySolvencyType.EPCRequiredByLaw,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance,
            financialInfoNatureType.NotFilingFinancialInfo
        ],
        isDormantExemptFinancialStatement: true,
        isAGMCopyRequired: false
    },
    {
        entityType: entityType.PrivateCompanyUnlimited,
        entitySolvency: null,
        entityActiveDormant: entityActiveDormant.Active,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: true
    },
    {
        entityType: entityType.PrivateCompanyUnlimited,
        entitySolvency: null,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: true
    },
    {
        entityType: entityType.PrivateCompanyUnlimited,
        entitySolvency: null,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: null,
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.SimplifiedXBRL,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance,
            financialInfoNatureType.NotFilingFinancialInfo
        ],
        isDormantExemptFinancialStatement: true,
        isAGMCopyRequired: false
    },
    {
        entityType: entityType.PublicCompanyUnlimited,
        entitySolvency: null,
        entityActiveDormant: entityActiveDormant.Active,
        isListedSecurityExchangeAtFinancial: 'Y',
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: true
    },
    {
        entityType: entityType.PublicCompanyUnlimited,
        entitySolvency: null,
        entityActiveDormant: entityActiveDormant.Active,
        isListedSecurityExchangeAtFinancial: 'N',
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: true
    },
    {
        entityType: entityType.PublicCompanyUnlimited,
        entitySolvency: null,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: 'N',
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance
        ],
        isDormantExemptFinancialStatement: false,
        isAGMCopyRequired: true
    },
    {
        entityType: entityType.PublicCompanyUnlimited,
        entitySolvency: null,
        entityActiveDormant: entityActiveDormant.Dormant,
        isListedSecurityExchangeAtFinancial: 'N',
        xbrlNatures: [
            financialInfoNatureType.FullSetXBRLNew,
            financialInfoNatureType.FullSetXBRLOld,
            financialInfoNatureType.FinancialHighlightsXBRL,
            financialInfoNatureType.FinancialHighlightsXBRLForBankInsurance,
            financialInfoNatureType.NotFilingFinancialInfo
        ],
        isDormantExemptFinancialStatement: true,
        isAGMCopyRequired: false
    }
];
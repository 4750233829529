import { Column, Toolbar } from 'devextreme-react/data-grid';
import StandardDataGrid from 'components/StandardDataGrid';

const CompanyOfficersAuditorsDataGrid = (props) => {
    const { data } = props;

    return (
        <div className="card-body px-4 py-3">
            <StandardDataGrid
                dataSource={data}
            >
                <Column
                    caption="S/No."
                    cellRender={(e) => {
                        return e.row.dataIndex + 1;
                    }}
                    dataType="number"
                />
                <Column
                    dataField="name"
                    caption="Name"
                    dataType="string"
                />
                <Column
                    dataField="idno"
                    caption="Identification No./UEN/Nationality"
                    cellRender={(e) => {
                        let cellData = e.data;
                        return `${cellData.idno} / ${cellData.nationality}`;
                    }}
                    dataType="string"
                />
                <Column
                    dataField="position"
                    caption="Position"
                    dataType="string"
                />
                <Column
                    dataField="appointmentDate"
                    caption="Date of Appointment"
                    dataType="date"
                />
                <Toolbar visible={false} />
            </StandardDataGrid>
        </div>
    );
};

export default CompanyOfficersAuditorsDataGrid;
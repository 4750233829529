import * as Dialog from 'devextreme/ui/dialog';
import * as HtmlUtils from './htmlUtils';

export function showMessage(message, htmlFormat = false, buttonText = 'OK') {
    let dlg = Dialog.custom({
        showTitle: false,
        messageHtml: formatMessage(message, htmlFormat),
        buttons: [{
            text: buttonText,
            onClick: () => ({ ok: true })
        }]
    });

    return dlg.show();
}

export function showConfirmMessage(message, htmlFormat = false, yesButtonText = 'Yes', noButtonText = 'No', yesButtonType = 'success') {
    let dlg = Dialog.custom({
        showTitle: false,
        messageHtml: formatMessage(message, htmlFormat),
        buttons: [{
            text: yesButtonText,
            type: yesButtonType,
            onClick: () => ({ confirm: true })
        },
        {
            text: noButtonText,
            onClick: () => ({ confirm: false })
        }]
    });

    return dlg.show();
}

export function showConfirmDangerMessage(message, htmlFormat = false, yesButtonText = 'Yes', noButtonText = 'No') {
    return showConfirmMessage(message, htmlFormat, yesButtonText, noButtonText, 'danger');
}

export function showConfirmDeleteMessage(message, htmlFormat = false) {
    return showConfirmDangerMessage(message, htmlFormat, 'Yes, delete it', 'No, keep it');
}

function formatMessage(message, htmlFormat) {
    let messageHtml = htmlFormat ? message : HtmlUtils.escapeHTML(message);
    return `<div style="max-width:500px">${messageHtml}</div>`;
}
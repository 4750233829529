import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomStore from 'devextreme/data/custom_store';
import { Button, Column, Toolbar, Item } from 'devextreme-react/data-grid';
import { PrimaryButton } from 'components/StandardButton';
import PageTitle from 'components/PageTitle';
import StandardDataGrid from 'components/StandardDataGrid';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as AccountBookDataStatusConstant from 'data/accountBookDataStatusConstant';
import * as ErrorMessage from 'utils/errorMessage';
import * as Toast from 'utils/toast';
import * as PageUtils from 'utils/pageUtils';
import DraftForm from './partials/DraftForm';
import InitialForm from './partials/InitialForm';
import * as FinancialStatementApi from './api/financialStatementApi';
import './FinancialStatement.scss';

const FinancialStatementPage = () => {
    const [timestamp, setTimestamp] = useState(Date.now());
    const [financialStatementData, setFinancialStatementData] = useState({
        financialStatementId: null,
        status: null,
        entryData: null,
        xbrlData: null
    });

    const [showFinancialStatementEntryForm, setShowFinancialStatementEntryForm] = useState(false);
    const [showFinancialStatementXBRLForm, setShowFinancialStatementXBRLForm] = useState(false);

    const navigate = useNavigate();

    const { accountBookData } = useAccountBookData();
    const accountBookDataId = accountBookData && accountBookData.accountBookDataId;
    const isAccountMappingComplete = accountBookData && accountBookData.isAccountMappingComplete;

    const readOnly = accountBookData.stepList.some(x => x === AccountBookDataStatusConstant.CompletedAnnualReturn);

    const financialStatementDataSource = useMemo(() => new CustomStore({
        loadMode: 'raw',
        load: function () {
            return FinancialStatementApi.getFinancialStatementList(accountBookDataId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [accountBookDataId, timestamp]);

    function newFinancialStatementEntry() {
        PageUtils.blockPage();

        FinancialStatementApi.newFinancialStatementEntry(accountBookDataId)
            .then(data => {
                PageUtils.unblockPage();
                const { entryDataInJson, xbrlDataInJson, ...rest } = data;

                setFinancialStatementData({
                    entryData: data.entryDataInJson ? JSON.parse(entryDataInJson) : null,
                    xbrlData: data.xbrlDataInJson ? JSON.parse(xbrlDataInJson) : null,
                    ...rest
                });
                setShowFinancialStatementEntryForm(true);
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    }

    function getFinancialStatementData(financialStatementId) {
        PageUtils.blockPage();

        FinancialStatementApi.getFinancialStatementRecord(accountBookDataId, financialStatementId)
            .then(data => {
                PageUtils.unblockPage();

                const { entryDataInJson, xbrlDataInJson, ...rest } = data;

                setFinancialStatementData({
                    entryData: data.entryDataInJson ? JSON.parse(data.entryDataInJson) : null,
                    xbrlData: data.xbrlDataInJson ? JSON.parse(data.xbrlDataInJson) : null,
                    ...rest
                });
                setShowFinancialStatementXBRLForm(true);
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    }

    function editFinancialStatementEntry() {
        getFinancialStatementData(financialStatementData.financialStatementId);
        setShowFinancialStatementEntryForm(true);
    }

    function financialStatementXBRLFormClosed() {
        setFinancialStatementData({
            financialStatementId: null,
            status: null,
            entryData: null,
            xbrlData: null
        });

        refreshData();
        setShowFinancialStatementXBRLForm(false);
    }

    function onAfterEntryFormSaveAction(data) {
        const { entryDataInJson, xbrlDataInJson, ...rest } = data;
        setFinancialStatementData({
            entryData: data.entryDataInJson ? JSON.parse(entryDataInJson) : null,
            xbrlData: data.xbrlDataInJson ? JSON.parse(xbrlDataInJson) : null,
            ...rest
        });
        setShowFinancialStatementEntryForm(false);
        setShowFinancialStatementXBRLForm(true);
    }

    function refreshData(financialStatementId = null) {
        if (financialStatementId) {
            getFinancialStatementData(financialStatementId);
        }

        setTimestamp(Date.now());
    }

    return (
        <>
            <div className="content-block">
                <PageTitle title="Financial Statement">
                    <div className="align-self-center" style={{ flexGrow: 100 }}>
                        <PrimaryButton
                            className="ms-3"
                            text="Back to Annual Return"
                            onClick={() => navigate('/annualReturnWithFS')}
                        />
                    </div>
                </PageTitle>
            </div>
            {isAccountMappingComplete ?
                <div className="content-block">
                    <div className="dx-card responsive-paddings">
                        <StandardDataGrid
                            dataSource={financialStatementDataSource}
                        >
                            <Toolbar>
                                <Item
                                    location="before"
                                    cssClass="dx-swatch-button"
                                    locateInMenu="auto"
                                    widget="dxButton"
                                    visible={!readOnly}
                                    options={{
                                        text: 'New',
                                        icon: 'add',
                                        type: 'default',
                                        stylingMode: 'outlined',
                                        onClick: () => newFinancialStatementEntry()
                                    }}
                                />
                                <Item name="columnChooserButton" />
                                <Item name="searchPanel" />
                            </Toolbar>
                            <Column
                                dataField="description"
                                caption="Description"
                                dataType="string"
                            />
                            <Column
                                dataField="createdTimestamp"
                                caption="Created Timestamp"
                                dataType="datetime"
                                sortIndex={0}
                                sortOrder="desc"
                            />
                            <Column
                                dataField="status"
                                caption="Status"
                                dataType="string"
                            />
                            <Column
                                caption="Action"
                                type="buttons"
                                width={100}
                                fixed
                                fixedPosition="right"
                            >
                                <Button text="View" onClick={(e) => getFinancialStatementData(e.row.data.id)} />
                            </Column>
                        </StandardDataGrid>
                    </div>
                </div>
                :
                <div className="content-block">
                    <div className="alert alert-danger"><p className="fw-bold d-inline">Account Mapping</p> is not complete. Please complete it in <p className="d-inline text-decoration-underline" role="button" onClick={() => navigate('/accountMappingMaintenance')}>Account Mapping Maintenance</p>.</div>
                </div >
            }
            {showFinancialStatementEntryForm &&
                <InitialForm
                    financialStatementId={financialStatementData.financialStatementId}
                    initialData={Object.assign(financialStatementData.entryData, { description: financialStatementData.description })}
                    status={financialStatementData.status}
                    onAfterSaveAction={onAfterEntryFormSaveAction}
                    onCancel={() => setShowFinancialStatementEntryForm(false)}
                />
            }
            {showFinancialStatementXBRLForm &&
                <DraftForm
                    financialStatementData={financialStatementData}
                    onEdit={editFinancialStatementEntry}
                    onAfterFormAction={refreshData}
                    onCancel={financialStatementXBRLFormClosed}
                />
            }
        </>
    );
};

export default FinancialStatementPage;
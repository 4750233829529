import {
    Page,
    StyleSheet,
    Text,
    View
} from '@react-pdf/renderer';


const RenovationSchedulePdfView = (props) => {
    const { initialData, accountBookData } = props;

    const yearOfAssessment = accountBookData && accountBookData.yearOfAssessment;

    const styles = StyleSheet.create({
        page: {
            padding: 40
        },
        header: {
            fontSize: 15,
            fontWeight: 1000,
            marginBottom: 10,
            display: 'table',
            width: 'auto',
            textDecoration: 'underline',
            textAlign: 'center'
        },
        alertText: {
            fontSize: 10,
            fontWeight: 'bold',
            marginBottom: 10
        },
        normalText: {
            fontSize: 8,
            margin: 5
        },
        table: {
            display: 'table',
            width: 'auto',
            borderStyle: 'solid',
            borderWidth: 1
        },
        tableHeader: {
            backgroundColor: '#21a84a',
            color: 'white',
            fontWeight: 1000
        },
        tableHeaderText: {
            fontSize: 10,
            margin: 5,
            fontWeight: 1000
        }
    });

    const dataSource = [
        {
            key: `YA ${yearOfAssessment - 2}`,
            leaseholdImprovementsAndRenoCostIncurred: initialData ? initialData.leaseholdImprovementsAndRenoCostIncurredInYAMinus2 : 0,
            s14QDeduction: initialData ? initialData.s14QDeductionForCurrentYAMinus2 : 0,
            qualifyingRAndRCost: initialData ? initialData.qualifyingRAndRCostsforCurrentYAMinus2 : 0
        },
        {
            key: `YA ${yearOfAssessment - 1}`,
            leaseholdImprovementsAndRenoCostIncurred: initialData ? initialData.leaseholdImprovementsAndRenoCostIncurredInYAMinus1 : 0,
            s14QDeduction: initialData ? initialData.s14QDeductionForCurrentYAMinus1 : 0,
            qualifyingRAndRCost: initialData ? initialData.qualifyingRAndRCostsforCurrentYAMinus1 : 0
        },

        {
            key: `YA ${yearOfAssessment}`,
            leaseholdImprovementsAndRenoCostIncurred: initialData ? initialData.leaseholdImprovementsAndRenoCostIncurredInCurrentYA : 0,
            s14QDeduction: initialData ? initialData.s14QDeductionForCurrentYA : 0,
            qualifyingRAndRCost: initialData ? initialData.qualifyingRAndRCostsforCurrentYA : 0
        },
        {
            key: 'Total Section 14N Deduction',
            s14QDeduction: initialData ? initialData.renoWorksExpS14Q : 0
        }
    ];

    const generateRow = (data) => {
        return (
            <View key={data.key} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <View style={[styles.normalText, { width: '25%' }]}>
                    <Text>{data.key}</Text>
                </View>
                <View style={[styles.normalText, { width: '30%', alignItems: 'flex-end' }]}>
                    <Text>{data.leaseholdImprovementsAndRenoCostIncurred !== undefined ? formatValue(data.leaseholdImprovementsAndRenoCostIncurred) : null}</Text>
                </View>
                <View style={[styles.normalText, { width: '25%', alignItems: 'flex-end', marginRight: 20 }]}>
                    <Text>{data.qualifyingRAndRCost !== undefined ? formatValue(data.qualifyingRAndRCost) : null}</Text>
                </View>
                <View style={[styles.normalText, { width: '25%', alignItems: 'flex-end' }]}>
                    <Text>{formatValue(data.s14QDeduction)}</Text>
                </View>  
            </View>
        );
    };

    const formatValue = (value) => {
        if (typeof value === 'number') {
            return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
        return value;
    };

    return (
        <Page size="A4" style={styles.page}>
            <View style={styles.container}>
                <View style={styles.header}>
                    <Text>Renovation & Refurbishment Schedule</Text>
                </View>
            </View>
            <Text style={styles.alertText}>The Leasehold Improvements and Renovation Cost do not require the approval of the Commissioner of Building Control under the Building Control Act.</Text>
            <View style={styles.table}>
                <View style={[styles.tableHeader, { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }]}>
                    <View style={[styles.tableHeaderText, { width: '25%' }]}>
                        <Text>Year of Assessment (YA)</Text>
                    </View>
                    <View style={[styles.tableHeaderText, { width: '30%', alignItems: 'flex-end' }]}>
                        <Text>Leasehold Improvements And Renovation Cost Incurred</Text>
                    </View>
                    <View style={[styles.tableHeaderText, { width: '25%', alignItems: 'flex-end', marginRight: 20 }]}>
                        <Text>Qualifying R&R Cost</Text>
                    </View>
                    <View style={[styles.tableHeaderText, { width: '25%', alignItems: 'flex-end' }]}>
                        <Text>Section 14N Deduction</Text>
                    </View>
                </View>
                {dataSource.map(data => {
                    return generateRow(data);
                })}
            </View>
        </Page>
    );
};

export default RenovationSchedulePdfView;
import React, { useState, useRef } from 'react';
import { Accordion, Item } from 'devextreme-react/accordion';
import { Button } from 'devextreme-react/button';
import { ToolbarItem } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator from 'devextreme-react/validator';
import validationEngine from 'devextreme/ui/validation_engine';
import { SubmitButton } from 'components/StandardButton';
import StandardPopup from 'components/StandardPopup';
import * as CurrencyCodeConstants from './data/currencyCodeConstant';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as AccountBookDataStatusConstant from 'data/accountBookDataStatusConstant';
import FinancialStatementEntryEntityDetailsView from './components/FinancialStatementEntryEntityDetailsView';
import FinancialStatementEntryFinancialPeriodDetails from './components/FinancialStatementEntryFinancialPeriodDetails';
import FinancialStatementEntryFilingInformation from './components/FinancialStatementEntryFilingInformation';
import FinancialStatementEntryDirectorStatementAuditorReportView from './components/FinancialStatementEntryDirectorStatementAuditorReportView';
import * as FinancialStatementStatus from '../../data/financialStatementStatus';
import * as FinancialStatementApi from '../../api/financialStatementApi';
import * as MessageBox from 'utils/messageBox';
import * as ErrorMessage from 'utils/errorMessage';
import * as Toast from 'utils/toast';
import * as PageUtils from 'utils/pageUtils';

const FinancialStatementEntryFormView = (props) => {
    const { financialStatementId, initialData, status, onAfterSaveAction, onCancel } = props;
    const [data, setData] = useState(initialData ? initialData : {
        description: '',
        senderEmail: '',
        senderName: '',
        companyName: '',
        uen: '',
        currentYearStartDate: '',
        currentYearEndDate: '',
        priorYearStartDate: '',
        priorYearEndDate: '',
        authorisationDate: '',
        entityOperationsActivitiesNatureDescription: '',
        functionalCurrency: CurrencyCodeConstants.SGD,
        taxonomyVersion: '',
        productVersion: `AutoCount Seamless Filing Portal v${window.APP_SETTINGS_Version}`,
        xbrlPreparationMethod: '',
        xbrlFilingType: '',
        accountingStandardType: '',
        presentationCurrency: CurrencyCodeConstants.SGD,
        roundingLevelType: '',
        financialStatementNature: '',
        financialStatementPosition: '',
        companyPrincipalBusinessPlace: '',
        parentEntityName: '',
        ultimateParentGroupName: '',
        independentAuditorOpinionType: '',
        auditingStandard: '',
        isCurrentYearContainChangesToComparativeAmounts: '',
        isCompanyEmployeeMoreThanFifty: '',
        isFinancialStatementPreparedOnConcernBasis: '',
        isFinancialStatementDirectorOpinionTrueAndFair: '',
        isCompanyAbleToPayDebtsOnDateOfStatement: '',
        isMaterialUncertaintyInAuditorReport: '',
        isAuditOpinionAccountingRecordsKeptAccordanceWithCompanyAct: ''
    });

    const { accountBookData } = useAccountBookData();
    const accountBookDataId = accountBookData && accountBookData.accountBookDataId;
    const readOnly = accountBookData.stepList.some(x => x === AccountBookDataStatusConstant.CompletedAnnualReturn);

    const isNewRecord = status === FinancialStatementStatus.New;

    function submitButtonClicked() {
        // Expand all items in accordion to display validation message
        if (accordionRef && accordionRef.current) {
            let accordionItems = accordionRef.current.instance.option('items');
            accordionItems.forEach(item => accordionRef.current.instance.expandItem(accordionItems.indexOf(item)));
        }

        const validationResult = validationEngine.validateGroup();
        if (!validationResult.isValid) {
            // scroll to validation message
            let element = document.querySelector('.dx-validator.dx-invalid');
            if (element) {
                let prevElement = element.previousElementSibling;
                if (prevElement) {
                    // scroll to previous element to include label when scrolling into view
                    prevElement.scrollIntoView();
                    element.classList.add('dx-state-focused');
                } else {
                    element.scrollIntoView();
                    element.classList.add('dx-state-focused');
                }
            }
        }
    }

    function handleSubmit(e) {
        e.preventDefault();

        if (status !== FinancialStatementStatus.New && status !== FinancialStatementStatus.Created) {
            MessageBox.showConfirmDangerMessage('Are you sure you want to Edit this Financial Statement Entry? (Financial Statement XBRL will be generated again and will require you to re-upload to ACRA for Validation)',
                false, 'Yes, edit it', 'No, don\'t edit it')
                .then(result => {
                    if (result.confirm) {
                        saveFinancialStatementEntry(data);
                    }
                });
        } else {
            saveFinancialStatementEntry(data);
        }
    }

    function saveFinancialStatementEntry(input) {
        PageUtils.blockPage();

        if (status === FinancialStatementStatus.New) {
            FinancialStatementApi.createFinancialStatementEntry(accountBookDataId, input)
                .then((data) => {
                    PageUtils.unblockPage();
                    onAfterSaveAction(data);
                })
                .catch(error => {
                    PageUtils.unblockPage();
                    Toast.showErrorMessage(ErrorMessage.createError(error));
                });
        } else {
            FinancialStatementApi.updateFinancialStatementEntry(accountBookDataId, financialStatementId, input)
                .then((data) => {
                    PageUtils.unblockPage();
                    onAfterSaveAction(data);
                })
                .catch(error => {
                    PageUtils.unblockPage();
                    Toast.showErrorMessage(ErrorMessage.updateError(error));
                });
        }
    }

    function handleChange(value) {
        setData(prevState => ({ ...prevState, ...value }));
    }

    const accordionRef = useRef(null);

    const validationRules = {
        description: [
            { type: 'required', message: 'Description is required.' }
        ]
    };

    return (
        <StandardPopup
            visible
            fullScreen
            showCloseButton
            showTitle
            title={isNewRecord ? 'Create Financial Statement' : 'Edit Financial Statement'}
            onHiding={onCancel}
        >
            <form id="frmFinancialStatementEntry" onSubmit={handleSubmit}>
                <Accordion
                    onInitialized={(e) => {
                        const items = e.component.option('items');
                        e.component.option('selectedItemKeys', items);
                    }}
                    collapsible
                    multiple
                    ref={accordionRef}
                >
                    <Item
                        key="financialStatementDetails"
                        title="Financial Statement Details"
                        render={() => {
                            return (
                                <div className="px-4 py-3">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="form-label required fw-bolder">Financial Statement XBRL Description</label>
                                            <TextBox
                                                value={data.description}
                                                readOnly={readOnly}
                                                onValueChanged={(e) => handleChange({ description: e.value })}
                                            >
                                                <Validator validationRules={validationRules.description} />
                                            </TextBox>
                                            <div className="form-text">Hint: This field is not used by ACRA, used for display purposes only.</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }}
                    />

                    <Item
                        key="entityDetails"
                        title="Entity Details"
                        render={() => <FinancialStatementEntryEntityDetailsView data={data} onChange={handleChange} />}
                    />

                    <Item
                        key="financialPeriodDetails"
                        title="Financial Period Details"
                        render={() => <FinancialStatementEntryFinancialPeriodDetails data={data} />}
                    />

                    <Item
                        key="filingInformation"
                        title="Filing Information"
                        render={() => <FinancialStatementEntryFilingInformation data={data} onChange={handleChange} />}
                    />

                    <Item
                        key="directorStatementAuditorReport"
                        title="Statement by Directors and Independent Auditors' Report"
                        render={() => <FinancialStatementEntryDirectorStatementAuditorReportView data={data} onChange={handleChange} />}
                    />
                </Accordion>
            </form>
            <ToolbarItem
                toolbar="bottom"
                location="after"
                render={() => {
                    return (
                        <div>
                            <Button
                                className="mx-2"
                                text={readOnly ? 'Close' : 'Cancel'}
                                onClick={onCancel}
                            />
                            <SubmitButton
                                text={isNewRecord ? 'Next: Generate Financial Statement XBRL' : 'Save'}
                                onClick={submitButtonClicked}
                                submitForm="frmFinancialStatementEntry"
                                visible={!readOnly}
                            />
                        </div>
                    );
                }}
            />
        </StandardPopup>
    );
};

export default FinancialStatementEntryFormView;
import DraftFormContentHeaderRow from './DraftFormContentHeaderRow';
import DraftFormContentDataRow from './DraftFormContentDataRow';
import DraftFormContentFooterRow from './DraftFormContentFooterRow';
import * as pdeDataCurrentType from '../data/pdeDataCurrentType';

const FinancialStatementXBRLFormCurrentAssetsView = (props) => {
    const { initialData, onViewPDEDrillDown } = props;

    const dataRows = [
        { dataId: 'cashAndBankBalances' },
        { dataId: 'tradeAndOtherReceivablesCurrent' },
        { dataId: 'currentFinanceLeaseReceivables' },
        { dataId: 'currentDerivativeFinancialAssets' },
        { dataId: 'currentFinancialAssetsMeasuredAtFairValueThroughProfitOrLoss' },
        { dataId: 'otherCurrentFinancialAssets' },
        { dataId: 'developmentProperties' },
        { dataId: 'inventoriesOthers' },
        { dataId: 'otherCurrentNonfinancialAssets' },
        { dataId: 'noncurrentAssetsOrDisposalGroupsClassifiedAsHeldForSaleOrAsHeldForDistributionToOwners' },
        { dataId: 'currentAssets', isFooter: true, enableTopBorder: true, enableBottomBorder: true }
    ];

    const hasPriorYearFinancialData = initialData.xbrlData.priorFinancialYear;

    return (
        <>
            <DraftFormContentHeaderRow description="Current Assets" level={3} />
            {dataRows.map((item, index) => {
                const pde = initialData.pdeList.find(x => x.dataId === item.dataId);
                const pdeDescription = pde && pde.description;

                const priorYearValue = hasPriorYearFinancialData && initialData.xbrlData.priorFinancialYear.assets.currentAssets[item.dataId];
                const currentYearValue = initialData.xbrlData.currentFinancialYear.assets.currentAssets[item.dataId];

                if (item.isFooter) {
                    return (
                        <DraftFormContentFooterRow
                            key={index}
                            description={pdeDescription}
                            priorYearValue={priorYearValue}
                            currentYearValue={currentYearValue}
                            enableTopBorder={item.enableTopBorder}
                            enableBottomBorder={item.enableBottomBorder}
                            onViewPDEDrillDown={(isCurrentYear) => onViewPDEDrillDown({ pdeDataId: item.dataId, isCurrentYearFinancialData: isCurrentYear, pdeDataCurrentType: pdeDataCurrentType.Current })}
                        />
                    );
                }

                return (
                    <DraftFormContentDataRow
                        key={index}
                        level={3}
                        description={pdeDescription}
                        priorYearValue={priorYearValue}
                        currentYearValue={currentYearValue}
                        onViewPDEDrillDown={(isCurrentYear) => onViewPDEDrillDown({ pdeDataId: item.dataId, isCurrentYearFinancialData: isCurrentYear, pdeDataCurrentType: pdeDataCurrentType.Current })}
                    />
                );
            })}
        </>
    );
};

export default FinancialStatementXBRLFormCurrentAssetsView;